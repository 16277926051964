/* eslint-disable max-len */
// BASEUI-GENERATED-REACT-ICON
// DO NOT EDIT THIS FILE DIRECTLY, SEE README.md
import * as React from 'react';
import type { IconProps } from '../Icon';
import Icon from '../Icon';

const EllipsesHorizontalOutlineIcon = (props: IconProps): JSX.Element => {
  const {
    title = 'Ellipses Horizontal Outline Icon',
    size,
    color,
    onClick,
    ...restProps
  } = props;
  return (
    <Icon
      title={title}
      size={size}
      color={color}
      onClick={onClick}
      {...restProps}
    >
      <circle cx="12" cy="12" r="2"></circle>
      <circle cx="4" cy="12" r="2"></circle>
      <circle cx="20" cy="12" r="2"></circle>
    </Icon>
  );
};

export default EllipsesHorizontalOutlineIcon;
/* eslint-enable max-len */
