import { CautionOutlineIcon, CheckmarkOutlineIcon, InfoOutlineIcon, CircleCautionOutlineIcon } from '../PremiseDesign/Icons';
import { useCallback } from 'react';

import { useSnackbar, SnackbarMessage, OptionsObject, SnackbarKey } from 'notistack';
import { useTheme, Button, Paper, Box, Typography, styled, ButtonProps } from '@mui/material';

// ----- STYLES -----
const SnackbarButton = styled(Button)(({ $variantColor }: Partial<ButtonProps> & { $variantColor: string }) => ({
  color: $variantColor,
  borderColor: $variantColor,
  '&:hover': {
    borderColor: $variantColor
  }
}));

const useAppSnackbar = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const theme = useTheme();

  const variantColors: { [key: string]: string } = {
    info: theme.palette.info.light,
    error: theme.palette.error.light,
    success: theme.palette.success.light,
    warning: theme.palette.warning.light,
    default: theme.palette.primary.light
  };

  const getEnhancer = (variant: string) => {
    switch (variant) {
      case 'info': {
        return <InfoOutlineIcon color={variantColors[variant]} />;
      }
      case 'error': {
        return <CautionOutlineIcon color={variantColors[variant]} />;
      }
      case 'success': {
        return <CheckmarkOutlineIcon color={variantColors[variant]} />;
      }
      case 'warning': {
        return <CircleCautionOutlineIcon color={variantColors[variant]} />;
      }
      default: {
        return <InfoOutlineIcon color={variantColors['default']} />;
      }
    }
  };

  const getSnackbar = (message: SnackbarMessage, key: SnackbarKey, variant: string) => {
    return (
      <Paper id={`${key}`} style={{ backgroundColor: theme.color['neutral-100'] }}>
        <Box display="flex" flexDirection="row" flexWrap="nowrap" alignItems="center" gap="10px" padding="10px">
          {getEnhancer(variant)}
          <Typography variant="label-bold" color={theme.color['neutral-0']}>
            {message}
          </Typography>
          <SnackbarButton
            variant="text"
            $variantColor={variantColors[variant]}
            onClick={() => {
              closeSnackbar(key);
            }}
          >
            Dismiss
          </SnackbarButton>
        </Box>
      </Paper>
    );
  };

  const enqueue = useCallback((message: SnackbarMessage, options?: OptionsObject) => {
    enqueueSnackbar(message, {
      ...options,
      content: (key, message) => getSnackbar(message, key, options?.variant ?? 'default')
    });
  }, []);

  return { enqueue, closeSnackbar };
};

export default useAppSnackbar;
