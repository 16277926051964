// ----- REACT -----

import React, { useEffect, useMemo, useState } from 'react';

// ----- REDUX -----
import { useAppSelector } from '../../../hooks/useRedux';
import { grayzoneSelectors } from '../../../features/grayzone/grayzoneSlice';

// ----- API -----
import { placesEndpoints } from '../../../api/places';

// ----- MODULES -----
import { Box, Skeleton, styled, Typography, useTheme } from '@mui/material';

// ----- OURS -----
import useAppSnackbar from '../../../hooks/useAppSnackbar';
import POIGrid from './POIGrid';
import POIViewer from './POIViewer';

// ----- CONSTANTS -----

// ----- STYLES -----
import { Spinner } from '../../../styles';

const PhotoWidgetContainer = styled(Box)({
  width: '100%',
  height: '100%',
  boxSizing: 'border-box',
  padding: '68px 16px 18px 32px'
});

const PhotoWidgetContent = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  overflowX: 'hidden',
  overflowY: 'scroll',
  // transition: 'opacity 1.0s ease',
  '&::-webkit-scrollbar': {
    width: '4px',
    marginLeft: theme.spacing(1),
    overflow: 'visible',
    borderRadius: theme.borderRadius.borderRadiusSemiSharp
  },
  '&::-webkit-scrollbar-thumb': {
    overflow: 'hidden',
    borderRadius: theme.borderRadius.borderRadiusSemiSharp,
    background: theme.color['neutral-50']
  },
  'mask-image': 'linear-gradient(to top, transparent, black),linear-gradient(to left, transparent 4px, black 4px)',
  'mask-size': '100% 20000px',
  'mask-position': 'left bottom',
  '-webkit-mask-image': 'linear-gradient(to top, transparent, black), linear-gradient(to left, transparent 4px, black 4px)',
  '-webkit-mask-size': '100% 20000px',
  '-webkit-mask-position': 'left bottom',
  transition: 'mask-position 0.2s, -webkit-mask-position 0.2s',
  '&:hover': { '-webkit-mask-position': 'left top' }
}));

// ----- TYPES -----
import { Widget } from '../../../types/grayzone';

// ----- COMPONENT -----
type Props = {
  nColumns: number;
  nRows: number;
  widget: Widget;
};
const PhotoWidget = ({ nColumns, nRows, widget }: Props) => {
  const theme = useTheme();
  const { enqueue } = useAppSnackbar();

  // ----- Redux -----

  // ----- Local State -----
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  // const [data, setData] = useState<POISearchResult[]>([]);
  const [presentationInterval, setPresentationInterval] = useState<NodeJS.Timer | null>(null);

  // ----- Selectors -----

  const viewConfig = useAppSelector((state) => grayzoneSelectors.viewConfigurations.selectViewConfigurationByGridLayoutId(state, widget.gridId));
  if (!viewConfig) {
    throw new Error('View configuration could not be found for the provided gridId');
  }
  const idle = useAppSelector(grayzoneSelectors.selectIdleState);

  // ----- Queries -----
  const poiQueryResult = placesEndpoints.getPOIs.useQuery({ body: { filters: { hascs: viewConfig.countries, topics: ['Disinformation'] }, start: 0, stop: 50 } });

  // useEffect(() => {
  //   if (poiQueryResult.isSuccess) {
  //     let responseData = poiQueryResult.data;
  //     const dateFilter = viewConfig.dateFilter;
  //     if (dateFilter) {
  //       responseData = responseData.filter((r) => {
  //         const firstSeen = new Date(r.first_seen).getTime();
  //         const lastSeen = new Date(r.last_seen).getTime();
  //       });
  //     }
  //   }
  // }, [poiQueryResults.isSuccess, poiQueryResult.data]);

  // ----- Helpers -----

  const contentMaxHeight: React.CSSProperties['height'] = useMemo(() => {
    if ((nColumns <= 7 && nRows <= 9) || (nColumns <= 12 && nRows <= 6)) {
      return '200px';
    } else if ((nColumns <= 11 && nRows <= 9) || (nColumns <= 12 && nRows <= 8)) {
      return '400px';
    } else {
      return '600px';
    }
  }, [nColumns, nRows]);

  const contentFlex: React.CSSProperties['flex'] = useMemo(() => {
    if ((nColumns <= 7 && nRows <= 9) || (nColumns <= 12 && nRows <= 6)) {
      return '1 1 200px';
    } else if ((nColumns <= 11 && nRows <= 9) || (nColumns <= 12 && nRows <= 8)) {
      return '1 1 50%';
    } else {
      return '1 1 67%';
    }
  }, [nColumns, nRows]);

  const thumbnailHeight: React.CSSProperties['height'] = useMemo(() => {
    if ((nColumns <= 7 && nRows <= 9) || (nColumns <= 12 && nRows <= 6)) {
      return '48px';
    } else if ((nColumns <= 11 && nRows <= 9) || (nColumns <= 12 && nRows <= 8)) {
      return '72px';
    } else {
      return '96px';
    }
  }, [nColumns, nRows]);

  const viewerStyle = useMemo(
    () => ({
      flex: contentFlex,
      maxHeight: contentMaxHeight,
      width: '100%',
      transition: 'all 0.4s ease'
    }),
    [contentMaxHeight, contentFlex]
  );

  const incrementSelectedPhoto = () => {
    const data = poiQueryResult.data;
    if (data) {
      setSelectedIndex(selectedIndex === data.length - 1 ? 0 : selectedIndex + 1);
    }
  };

  // const placeHolderGridItems = useMemo(
  //   () =>
  //     Array.from(Array(20)).map((_, index) => (
  //       <Skeleton
  //         key={`skeleton-${index}`}
  //         animation={false}
  //         variant="rectangular"
  //         style={{ width: `93px`, height: `93px`, borderRadius: theme.borderRadius.borderRadiusSemiSharp }}
  //       />
  //     )),
  //   []
  // );

  // ----- Handlers -----

  // ----- Effects -----

  useEffect(() => {
    if (poiQueryResult.data !== undefined) {
      setSelectedIndex(0);
    }
  }, [poiQueryResult.data]);
  useEffect(() => {
    if (idle) {
      setPresentationInterval(setInterval(incrementSelectedPhoto, 8500));
    } else {
      presentationInterval && clearInterval(presentationInterval);
      setPresentationInterval(null);
    }
    return () => {
      presentationInterval && clearInterval(presentationInterval);
      setPresentationInterval(null);
    };
  }, [idle, selectedIndex]);

  useEffect(() => {
    if (poiQueryResult.isError) {
      enqueue('POI data could not be fetched', { variant: 'error' });
    }
  }, [poiQueryResult.isError]);

  // ----- Return Component -----

  return (
    <PhotoWidgetContainer>
      <PhotoWidgetContent>
        {poiQueryResult.isLoading && (
          <Box display="flex" alignItems="center" justifyContent="center" height="100%" width="100%">
            <Spinner color="primary" />
          </Box>
        )}
        {!poiQueryResult.isLoading && (
          <Box display="flex" flexDirection="column" width="100%" height="100%" boxSizing="border-box" paddingRight="8px" gap="8px">
            {!poiQueryResult.isError && poiQueryResult.data && poiQueryResult.data.length > 0 && (
              <POIViewer POIs={poiQueryResult.data} selectedIndex={selectedIndex} updateSelectedIndex={setSelectedIndex} style={viewerStyle} thumbnailSize={thumbnailHeight} />
            )}

            {(poiQueryResult.isError || (poiQueryResult.data && poiQueryResult.data.length === 0)) && <Skeleton variant="rectangular" animation={false} style={viewerStyle} />}

            {!poiQueryResult.isError && poiQueryResult.data && poiQueryResult.data.length > 0 && (
              <POIGrid POIs={poiQueryResult.data} updateSelectedIndex={setSelectedIndex} selectedIndex={selectedIndex} />
            )}

            {poiQueryResult.isError && (
              <Typography variant="label-small" color={theme.color['neutral-75']} whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                Data could not be accessed due to an error. Please try again in a bit.
              </Typography>
            )}
            {poiQueryResult.data && poiQueryResult.data.length === 0 && (
              <Typography variant="label-small" color={theme.color['neutral-75']} whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                No data found for this region and topic.
              </Typography>
            )}
          </Box>
        )}
      </PhotoWidgetContent>
    </PhotoWidgetContainer>
  );
};

export default PhotoWidget;
