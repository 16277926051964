// ----- REDUX -----
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// ----- OURS -----
import { countryAdapter, formAdapter, formQuestionAdapter } from '../../helpers/grayzone';

// ----- Types -----
import { Country, Form, FormQuestion, ThinResponseItem, TimeseriesPoint, Alert } from '../../types/grayzone';
import { EntityState } from '@reduxjs/toolkit';
import { AuthState } from '../../features/auth/authSlice';

export type GetTimeseriesResponse = {
  data: TimeseriesPoint[];
};
export type GetTimeseriesAlertsResponse = {
  alerts: Alert[];
};
export type GetTimeseriesApiArg = {
  form_id: string | symbol;
  question_name: string | symbol;
  hasc_code: string | symbol;
};
export type GetTimeseriesAlertApiArg = {
  form_id: string | symbol;
  question_name: string | symbol;
  hasc_code: string | symbol;
  alertSampleSize: number;
  alertSignificance: number;
  alertPercentChange: number;
};
export type ThinGeoApiArg = {
  formId: string;
  hasc: string;
  level: number;
  ordering: { label: string; order: string; responses: string[] }[];
  question: string;
  time_break: [string, string];
  focusGroup?: string; // categorical choropleth
};

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_HOST,
  prepareHeaders: async (headers, { getState }) => {
    const state = (await getState()) as { auth: AuthState };
    const token = state.auth.token;

    // If we have a token set in state, let's assume that we should be passing it.
    if (token) {
      headers.set('X-Auth-Token', token);
      headers.set('Content-Type', 'application/json');
    }
    return headers;
  }
});

export const sentimentApi = createApi({
  reducerPath: 'sentimentApi',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getAvailableForms: builder.query<EntityState<Form>, void>({
      transformResponse: (forms: Form[]) => {
        const availableForms = forms.filter((form) => form.completed_questions !== 0);
        return formAdapter.addMany(formAdapter.getInitialState(), availableForms);
      },
      query: () => ({
        url: '/sentiment/v0/lookups/forms',
        method: 'POST'
      })
    }),
    getQuestionsFromForm: builder.query<EntityState<FormQuestion>, { selectedForms: string[] | symbol }>({
      transformResponse: (questions: FormQuestion[]) => {
        // filter out questions that haven't been ingested yet
        const qs = questions.filter((question) => {
          const misconfiguredOrdering =
            !question.ordering || // malformed, no ordering object
            typeof question.ordering !== 'object' || // malformed
            Object.entries(question.ordering).length === 0; // misconfigured ordering
          return !(!question.user_request_completed_on || misconfiguredOrdering);
        });

        qs.sort((a, b) => a.question_proper.localeCompare(b.question_proper));
        return formQuestionAdapter.addMany(formQuestionAdapter.getInitialState(), qs);
        // return qs;
      },
      query: ({ selectedForms }) => ({ url: '/sentiment/v0/lookups/questions', method: 'post', body: { form_ids: selectedForms } })
    }),
    getAvailableGeographies: builder.query<EntityState<Country>, { form_id: string; question_names: string[] }>({
      transformResponse: (geos: Country[]) => countryAdapter.addMany(countryAdapter.getInitialState(), geos),
      query: ({ form_id, question_names }) => ({
        url: '/sentiment/v0/lookups/regionsFromQuestion',
        method: 'POST',
        body: {
          form_id,
          question_names
        }
      })
    }),
    getHascBounds: builder.query<number[], { hasc: string }>({
      query: ({ hasc }) => `/sentiment/v0/lookups/bounds-for/${hasc}`
    }),
    getChoropleth: builder.query<
      string,
      {
        form_id: string;
        question_name: string;
        ordering: { [x: string]: string };
        hasc: string;
        type: string;
        cropped: boolean;
        color_scale: string;
        bounds: { minx: number; maxx: number; miny: number; maxy: number };
        height: number;
        width: number;
        zoom: number;
        level: number;
        time_break?: undefined;
      }
    >({
      query: ({ form_id, question_name, ordering, hasc, type, cropped, color_scale, bounds, height, width, zoom, level, time_break }) => ({
        url: '/sentiment/v0/render/question',
        method: 'post',
        body: {
          form_id,
          question_name,
          ordering,
          hasc,
          type,
          cropped,
          color_scale,
          bounds,
          height,
          width,
          zoom,
          level,
          time_break
        },
        responseHandler: async (response) => response.text()
      })
    }),
    getTimeseries: builder.query<GetTimeseriesResponse, GetTimeseriesApiArg>({
      query: ({ form_id, question_name, hasc_code }) => ({
        url: '/sentiment/v0/geo-sentiment/tseries',
        body: {
          form_id,
          question_name,
          hasc: hasc_code
        },
        method: 'post'
      })
    }),
    getThinGeo: builder.query<string[], ThinGeoApiArg>({
      query: ({ formId, hasc, level, ordering, question, time_break, focusGroup }) => ({
        url: '/sentiment/v0/geo-sentiment/thin-geo',
        method: 'POST',
        body: {
          left: {
            form_list: [formId],
            hascs: [hasc],
            level,
            ordering,
            question_list: [question],
            time_break,
            focusGroup
          },
          right: null
        },
        responseHandler: async (resp: Response) =>
          window.irisProto.ThinResponse.decode(new Uint8Array(await resp.arrayBuffer()))
            .toJSON()
            .response.flatMap((d: ThinResponseItem) => [d.hascCode, d.colorCode[0]])
      })
    }),
    getOrdering: builder.query<
      { [x: string]: string },
      {
        questionName: string;
        formId: string;
      }
    >({
      query: ({ questionName, formId }) => ({
        url: '/sentiment/v0/lookups/ordering',
        method: 'POST',
        body: {
          question_name: questionName,
          form_id: formId
        }
      })
    }),
    getTimeseriesAlerts: builder.query<GetTimeseriesAlertsResponse, GetTimeseriesAlertApiArg>({
      query: ({ form_id, question_name, alertSampleSize, alertSignificance, alertPercentChange }) => {
        const body = {
          forms: [form_id],
          questions: [question_name],
          hasc: null,
          min_sample_size: alertSampleSize,
          significance_level: alertSignificance,
          min_daily_alerts: 1,
          pct_change: alertPercentChange
        };
        return { url: `/sentiment/v0/lookups/alerts/daily`, method: 'post', body };
      }
    })
  })
});

export const sentimentEndpoints = { ...sentimentApi.endpoints };
