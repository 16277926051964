/* eslint-disable max-len */
// BASEUI-GENERATED-REACT-ICON
// DO NOT EDIT THIS FILE DIRECTLY, SEE README.md
import * as React from 'react';
import type { IconProps } from '../Icon';
import Icon from '../Icon';

const HomeFillIcon = (props: IconProps): JSX.Element => {
  const {
    title = 'Home Fill Icon',
    size,
    color,
    onClick,
    ...restProps
  } = props;
  return (
    <Icon
      title={title}
      size={size}
      color={color}
      onClick={onClick}
      {...restProps}
    >
      <path
        d="M4 9.30402C4 8.99132 4.14628 8.69661 4.39535 8.50753L11.3953 3.1935C11.7528 2.92215 12.2472 2.92215 12.6047 3.1935L19.6047 8.50753C19.8537 8.69661 20 8.99132 20 9.30402V18.9999C20 19.5522 19.5523 19.9999 19 19.9999H5C4.44771 19.9999 4 19.5522 4 18.9999V9.30402ZM14 9.99988H10C8.89543 9.99988 8 10.8953 8 11.9999V15.9999C8 17.1044 8.89543 17.9999 10 17.9999H14C15.1046 17.9999 16 17.1044 16 15.9999V11.9999C16 10.8953 15.1046 9.99988 14 9.99988Z"
        stroke={color}
        strokeWidth="2"
      ></path>
    </Icon>
  );
};

export default HomeFillIcon;
/* eslint-enable max-len */
