// TO-DO: we can trim some fat off metadata we don't need, definitely want more than just a string though

import ReactGridLayout from 'react-grid-layout';

// ----- GENERIC -----
export type GenericMap<T> = {
  [key: string]: T;
};

// ----- VIEW CONFIGURATION -----
export type ViewConfiguration = {
  id: string;
  name: string;
  forms: string[];
  questions: string[];
  dateRange: [string, string];
  dateFilter: [number | null, number | null];
  countries: string[];
  topics: string[];
  homeBounds: [[number, number], [number, number]];
  selectedCategoricalResponse: string | null;
  gridLayoutId?: string;
};

// ----- View Data types -----

export type FormValue = {
  id: string;
  label: string;
};

export type FormQuestion = {
  id: string;
  form_id: string;
  question_name: string;
  question_proper: string;
  quality: number;
  first_time: string;
  last_time: string;
  max_lat: number;
  min_lat: number;
  max_lon: number;
  min_lon: number;
  responses: string[];
  user_requested_on: string;
  user_request_completed_on: string;
  updated_on: string;
  ordering: { [x: string]: string };
  is_active: boolean;
  is_ordinal: boolean;
  alerts_daily_last_30: number;
};

export enum FeedDataType {
  GDELT = 'gdelt',
  ACLED = 'acled',
  EventQueryArticle = 'event_query_article'
}
export enum ACLEDEventEnum {
  PROTEST = 'Protests',
  BATTLES = 'Battles',
  EXPLOSION = 'Explosion/Remote violence',
  CIVIL_VIOLENCE = 'Violence against civilians',
  STRATEGIC_DEV = 'Strategic developments',
  RIOTS = 'Riots'
}
export type ACLEDEvents =
  | ACLEDEventEnum.PROTEST
  | ACLEDEventEnum.BATTLES
  | ACLEDEventEnum.EXPLOSION
  | ACLEDEventEnum.CIVIL_VIOLENCE
  | ACLEDEventEnum.STRATEGIC_DEV
  | ACLEDEventEnum.RIOTS;
export type ACLEDdataType = {
  id: string;
  type: FeedDataType.ACLED;
  date_event: string;
  event_type: ACLEDEvents;
  sub_event_type: string;
  lat: number;
  lon: number;
  scale: string;
  event_description: string;
  example_event: boolean;
};
export type GDELTdataType = {
  id: string;
  type: FeedDataType.GDELT;
  date_news: string;
  title: string;
  url: string;
  top_img: string;
  text: string;
  relevancy_score: number;
  news_l0: string;
  news_l1: string;
  location_score: number;
  location_position: number;
  domain_text: string;
  domain_url: string;
  lat: number;
  lon: number;
};
export type FeedData = {
  gdelt: GDELTdataType[];
  acled: ACLEDdataType[];
};

export type GDELTTopArticle = { type: FeedDataType.EventQueryArticle; url: string; title: string; eventName: string; date: string };
export type GDELTKeywordQueryDataItem = { id: string; date: string; value: number; norm?: number; toparts: GDELTTopArticle[] };

export type GDELTEventQueryResult = {
  id: string;
  eventSetId: string;
  eventName: string;
  data: GDELTKeywordQueryDataItem[];
  keywords: string[];
  sourceCountries: string[];
  startDate: number;
  endDate: number;
  hidden: boolean;
};

// ----- API response shapes -----
export type Form = {
  available_questions: string;
  completed_questions: number;
  data_ends: number;
  data_starts: number;
  form_id: string;
  form_name: string;
  hasAlerts: boolean;
  is_anchor: boolean;
  organization_id: string;
  organization_name: string;
  project_id: string;
  project_name: string;
  quality: number;
};

// TO-DO: we can trim this when we figure out what we don't need

export type Country = {
  hasc: string;
  l0_name: string;
};

export type TimeseriesPoint = {
  date: number;
  lower_bound: number;
  num_samples: number;
  response: string;
  smoothed_mean: number;
  upper_bound: number;
};

export type Alert = {
  id: string;
  date: number;
  response: string;
  samples: number;
  probability: number;
};

// ----- GRID -----

export enum Breakpoint {
  LG = 'lg',
  MD = 'md',
  SM = 'sm'
}

export type Breakpoints = {
  [Breakpoint.LG]: number;
  [Breakpoint.MD]: number;
  [Breakpoint.SM]: number;
};

export type Layouts = { [Breakpoint.LG]: ReactGridLayout.Layout[]; [Breakpoint.MD]: ReactGridLayout.Layout[]; [Breakpoint.SM]: ReactGridLayout.Layout[] };

export type GridLayout = {
  id: string;
  viewConfigId: string;
  layouts: Layouts;
  widgetIds: string[];
  widgetConfigurationsId: string;
};

// ----- WIDGET -----
export enum WidgetType {
  MAP = 'map',
  TIMELINE = 'timeline',
  FEED = 'feed',
  PHOTO = 'photo'
  // PLACEHOLDER = 'placeholder'
}

export type MapWidget = {
  id: string;
  readonly type: WidgetType.MAP;
  gridId: string;
  name: string;
  centerLatitude: number;
  centerLongitude: number;
  zoomLevel: number;
  choroplethLevel: 0 | 1 | 2;
};

export enum MarkerState {
  HOVER = 'hover',
  SELECTED = 'selected',
  DEFAULT = 'default'
}

export type MapMarker = {
  latitude: number;
  longitude: number;
  tooltip: string;
  state: MarkerState;
};

export type PhotoWidget = {
  id: string;
  readonly type: WidgetType.PHOTO;
  gridId: string;
  name: string;
};

export type TimelineWidget = {
  id: string;
  readonly type: WidgetType.TIMELINE;
  gridId: string;
  name: string;
  enabledCustomEvents: GenericMap<boolean>;
};

export type FeedWidget = {
  id: string;
  readonly type: WidgetType.FEED;
  gridId: string;
  name: string;
};

// ----- Widget Filters -----
export type FeedWidgetConfigurations = {
  sort: 'alphaAscending' | 'alphaDescending' | 'dateAscending' | 'dateDescending';
  sourcesFilter: {
    GDELT: boolean;
    ACLED: boolean;
  };
  GDELTSourceFilters: string[];
  relevanceFilter: [number, number] | null;
  excludeACLEDEvents: {
    [ACLEDEventEnum.BATTLES]: boolean;
    [ACLEDEventEnum.CIVIL_VIOLENCE]: boolean;
    [ACLEDEventEnum.EXPLOSION]: boolean;
    [ACLEDEventEnum.PROTEST]: boolean;
    [ACLEDEventEnum.RIOTS]: boolean;
    [ACLEDEventEnum.STRATEGIC_DEV]: boolean;
  };
};
export type TimelineWidgetConfigurations = {
  alertSampleSize: number;
  alertSignificance: number;
  alertPercentChange: number;
};
export type MapWidgetConfigurations = { test: string };
export type PhotoWidgetConfigurations = { test: string };
export type WidgetConfigurations = {
  id: string;
  [WidgetType.FEED]: FeedWidgetConfigurations;
  [WidgetType.MAP]?: MapWidgetConfigurations;
  [WidgetType.TIMELINE]: TimelineWidgetConfigurations;
  [WidgetType.PHOTO]?: PhotoWidgetConfigurations;
};

export type Widget = MapWidget | PhotoWidget | TimelineWidget | FeedWidget;

export type View = {
  viewConfiguration: ViewConfiguration;
  gridLayout: GridLayout;
  widgets: Widget[];
  widgetConfigurations: WidgetConfigurations;
  id: string;
};

export type ViewState = {
  viewConfiguration: ViewConfiguration;
  gridLayout: GridLayout;
  widgets: Widget[];
  widgetConfigurations: WidgetConfigurations;
};

export type ViewMap = {
  viewId: string;
  configId: string;
  viewAccessId?: string;
};

export type Share = {
  id: string;
  viewId: string;
  permissions: number;
  ownerId: string;
  createdAt: string;
  duration: string | null;
  isActive: boolean;
};

export type ShareCreationDto = Pick<Share, 'viewId' | 'permissions' | 'isActive' | 'duration'>;

export type ViewAccess = {
  id: string;
  viewName: string;
  viewId: string;
  isActive: boolean;
  permissions: number;
  duration: string;
  userId: string;
};

export type CustomEvent = {
  id: string;
  name: string;
  country: string;
  latitude?: number;
  longitude?: number;
  startDate: number;
  endDate: number;
};

export type CustomEventSet = {
  id: string;
  name: string;
  events: CustomEvent[];
  ownerId: string;
};

export type CustomEventSetBinding = {
  id: string;
  eventSetId: string;
  viewId: string;
};

export type CustomEventSetState = Omit<CustomEventSet, 'id' | 'ownerId'>;
export type CustomEventState = Omit<CustomEvent, 'id'>;

export type POISearchResult = {
  place_id: string;
  place_name: string;
  topic: string;
  photo: string;
  x_lon: number;
  y_lat: number;
  num_submissions: number;
  first_seen: string;
  last_seen: string;
  row_created_at: string;
  type: string;
};

// ----- STATE RESTORE -----
export type ViewConfigurationDTO = Omit<ViewConfiguration, 'id' | 'gridLayoutId'>;
export type GridLayoutDTO = {
  layouts: { [Breakpoint.LG]: Omit<ReactGridLayout.Layout, 'i'>[]; [Breakpoint.MD]: Omit<ReactGridLayout.Layout, 'i'>[]; [Breakpoint.SM]: Omit<ReactGridLayout.Layout, 'i'>[] };
};
export type WidgetDTO = Omit<Widget, 'id' | 'gridId'>;
export type WidgetConfigurationsDTO = Omit<WidgetConfigurations, 'id'>;

export type ViewExport = {
  viewConfiguration: ViewConfigurationDTO;
  gridLayout: GridLayoutDTO;
  widgets: WidgetDTO[];
  widgetConfigurations: WidgetConfigurationsDTO;
};

// ----- JSON SCHEMAS -----
export enum GrayzoneSchema {
  VIEW = 'view',
  VIEW_EXPORT = 'view_export',
  VIEW_CONFIGURATION = 'view_configuration',
  VIEW_CONFIGURATION_DTO = 'view_configuration_dto',
  CUSTOM_EVENT_SET = 'custom_event_set'
}

// ----- PERMISSIONS -----

export enum ViewPermission {
  READ = 0b1000,
  WRITE = 0b0100,
  SHARE = 0b0010,
  OWNER = 0b0001
}

// ----- ProtoBUF -----
export type ThinResponseItem = {
  colorCode: number[];
  hascCode: string;
  sampleSize: number[];
  significance: boolean;
  hasAlert: boolean;
};
