import { useCallback } from 'react';

// ----- MUI -----
import { Box } from '@mui/material';

// ----- Redux -----
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux';
import { grayzoneActions, grayzoneSelectors } from './grayzoneSlice';

// ----- OURS -----
import GridView from './containers/GridView';
import ConfigDrawer from './containers/ConfigDrawer';
import WidgetConfigModal from './containers/WidgetConfigModal';
import ViewConfigModal from './containers/ViewConfigModal';
import GridProvider from '../../contexts/GridContext';
import NavBar from './containers/NavBar';

// ----- MODULES -----
import SplitPane from 'react-split-pane';
import { useIdleTimer } from 'react-idle-timer';

const IDLE_TIME = 5000; // 5 seconds

// ----- COMPONENT -----
const GrayzoneView = () => {
  // ----- Redux -----
  const dispatch = useAppDispatch();

  // ----- Selectors -----
  const primaryView = useAppSelector(grayzoneSelectors.viewConfigurations.selectPrimaryView);
  const primaryViewConfiguration = useAppSelector(grayzoneSelectors.viewConfigurations.selectPrimaryViewConfiguration);
  const secondaryViewConfiguration = useAppSelector(grayzoneSelectors.viewConfigurations.selectSecondaryViewConfiguration);
  const idle = useAppSelector(grayzoneSelectors.selectIdleState);

  if (!primaryView || !primaryViewConfiguration) {
    throw new Error('No current view found! A view must be configured.');
  }

  // ----- Idle Handlers -----
  const onIdle = () => {
    dispatch(grayzoneActions.setIdle(true));
    document.body.style.cursor = 'none';
  };

  const onActive = () => {
    dispatch(grayzoneActions.setIdle(false));
    document.body.style.cursor = 'auto';
  };

  useIdleTimer({
    timeout: IDLE_TIME,
    onIdle,
    onActive,
    debounce: 500,
    events: ['mousemove', 'keydown', 'wheel', 'mousewheel', 'mousedown']
  });

  const getGridDisplay = useCallback(() => {
    if (primaryViewConfiguration && secondaryViewConfiguration) {
      return (
        <Box flexGrow={1}>
          <SplitPane split="vertical" defaultSize="50%" style={{ position: 'relative', height: '100%' }} resizerStyle={{ margin: '0px 2px' }}>
            <GridProvider gridLayoutId={primaryViewConfiguration.gridLayoutId ?? ''}>
              <GridView />
            </GridProvider>
            <GridProvider gridLayoutId={secondaryViewConfiguration.gridLayoutId ?? ''}>
              <GridView />
            </GridProvider>
          </SplitPane>
        </Box>
      );
    } else if (primaryViewConfiguration) {
      return (
        <Box flexGrow={1}>
          <GridView />
        </Box>
      );
    }
  }, [primaryViewConfiguration, secondaryViewConfiguration]);

  // ----- Return Component -----
  return (
    <Box display={'flex'} height={'100%'} flexDirection={'column'}>
      {!idle && <NavBar />}
      <WidgetConfigModal />
      <ViewConfigModal />
      <Box flex={1} display={'flex'} width={'100%'} flexDirection={'row'}>
        <GridProvider gridLayoutId={primaryViewConfiguration.gridLayoutId ?? ''}>
          {getGridDisplay()}
          <ConfigDrawer />
        </GridProvider>
      </Box>
    </Box>
  );
};

export default GrayzoneView;
