import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { eventEndpoints } from '../../../../../api/grayzone/event';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import { grayzoneActions, grayzoneSelectors } from '../../../grayzoneSlice';
import { EntityState } from '@reduxjs/toolkit';

// ----- REDUX -----
import { grayzoneEndpoints } from '../../../../../api/grayzone';

// ----- MUI -----
import { Autocomplete, Box, CircularProgress, Menu, MenuItem, Slider, styled, TextField, Typography, useTheme } from '@mui/material';

// ----- PDS -----
import {
  CheckmarkOutlineIcon,
  DoubleCheckmarkOutlineIcon,
  EllipsesHorizontalOutlineIcon,
  GroupOutlineIcon,
  ProfileOutlineIcon,
  TrendNeutralOutlineIcon,
  TrendOutlineIcon
} from '../../../../../PremiseDesign/Icons';
import IconButton from '../../../../../PremiseDesign/Components/IconButton';
import Checkbox from '../../../../../PremiseDesign/Components/Checkbox';

// ----- Ours -----
import FileUploader from '../../../../../components/FileUploader';
import { v4 as uuid_v4 } from 'uuid';
import { customEventSetAdapter, customEventSetBindingAdapter, viewAccessAdapter } from '../../../../../helpers/grayzone';
import useAppSnackbar from '../../../../../hooks/useAppSnackbar';
import { DisappearingBox } from '../../../../../styles';
import useGrid from '../../../../../hooks/useGrid';
import { SectionedBox, SectionLabel } from '.';
import useSelectWidgetConfigurations from '../../../../../hooks/useSelectWidgetConfigurations';

// ----- Modules -----
import { omit } from 'lodash';

// ----- Types -----
import { CustomEventSet, GrayzoneSchema, TimelineWidget, TimelineWidgetConfigurations, ViewPermission, WidgetType } from '../../../../../types/grayzone';

const SliderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacings.small
}));

type CustomEventsRowProps = {
  eventSetId: string;
  eventSetBindingId: string;
  eventSetData: EntityState<CustomEventSet>;
  widget: TimelineWidget;
  viewPermission: ViewPermission | undefined;
};
const CustomEventsRow = memo(({ eventSetId, eventSetData, eventSetBindingId, widget }: CustomEventsRowProps) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { enqueue } = useAppSnackbar();

  // ----- Local State -----
  const [isActive, setIsActive] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  // ----- Selectors -----

  const customEventSetSelectors = useMemo(() => {
    return customEventSetAdapter.getSelectors();
  }, []);

  // ----- Helpers -----
  // const isOwner = useMemo(() => viewPermission && (viewPermission & ViewPermission.OWNER) > 0, [viewPermission]);

  // ----- Mutations -----
  const [triggerDeleteEventSetBindingMutation, deleteEventSetBindingQueryResult] = eventEndpoints.deleteEventSetBindingById.useMutation();
  const [triggerDeleteEventSetMutation, deleteEventSetQueryResult] = eventEndpoints.deleteEventSetByEventSetId.useMutation();

  const queriesAreLoading = useMemo(() => {
    return deleteEventSetBindingQueryResult.isLoading || deleteEventSetQueryResult.isLoading;
  }, [deleteEventSetBindingQueryResult.isLoading, deleteEventSetQueryResult.isLoading]);

  // ----- Handlers -----
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (anchorEl !== event.currentTarget) {
        setAnchorEl(event.currentTarget);
      }
    },
    [anchorEl]
  );

  const handleClickedRemove = useCallback(async () => {
    try {
      await triggerDeleteEventSetBindingMutation({ eventBindingId: eventSetBindingId }).unwrap();

      const enabledEventSets = { ...widget.enabledCustomEvents };
      delete enabledEventSets[eventSetId];
      dispatch(grayzoneActions.updateWidget({ id: widget.id, changes: { enabledCustomEvents: enabledEventSets } }));

      enqueue('Events successfully removed from view', { variant: 'success' });
    } catch (e) {
      enqueue('Something went wrong and the events were not removed from this view', { variant: 'error' });
    }
  }, [eventSetBindingId, eventSetId, widget]);

  const handleClickedPremanentlyDelete = useCallback(async () => {
    try {
      await triggerDeleteEventSetMutation({ eventSetId: eventSetId }).unwrap();
      const enabledEventSets = { ...widget.enabledCustomEvents };
      delete enabledEventSets[eventSetId];

      dispatch(grayzoneActions.updateWidget({ id: widget.id, changes: { enabledCustomEvents: enabledEventSets } }));
      enqueue('Events successfully deleted', { variant: 'success' });
    } catch (e) {
      console.log(e);
      enqueue('Something went wrong and the events were not removed from this view', { variant: 'error' });
    }
  }, [eventSetId, widget]);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <Box style={{ display: 'flex', flexDirection: 'row', gap: '10.5px', alignItems: 'center' }} onMouseOver={() => setIsActive(true)} onMouseLeave={() => setIsActive(false)}>
      <Checkbox
        checked={widget.enabledCustomEvents[eventSetId] === true}
        // disabled={widget.enabledCustomEvents[eventSetId] === undefined}
        onChange={() => {
          dispatch(
            grayzoneActions.updateWidget({
              id: widget.id,
              changes: { enabledCustomEvents: { ...widget.enabledCustomEvents, [eventSetId]: !widget.enabledCustomEvents[eventSetId] } }
            })
          );
        }}
      />
      <Typography variant="label">{customEventSetSelectors.selectById(eventSetData, eventSetId)?.name}</Typography>
      <DisappearingBox style={{ marginLeft: 'auto' }} onClick={handleClick} $active={isActive}>
        <IconButton variant="mini" onClick={handleClick}>
          <EllipsesHorizontalOutlineIcon size={theme.iconScale.small} />
        </IconButton>
      </DisappearingBox>

      <Menu
        style={{ zIndex: 4000, borderRadius: theme.borderRadius.borderRadiusSemiSharp, padding: 0 }}
        id="widget-header-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'icon-button-widget-header',
          disablePadding: true
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuItem onClick={handleClickedRemove} style={{ justifyContent: 'left', alignItems: 'center' }} disabled={queriesAreLoading}>
          {deleteEventSetBindingQueryResult.isLoading && <CircularProgress size="20px" />}
          {!deleteEventSetBindingQueryResult.isLoading && <Typography variant="label">Remove</Typography>}
        </MenuItem>
        <MenuItem onClick={handleClickedPremanentlyDelete} disabled={queriesAreLoading}>
          {deleteEventSetQueryResult.isLoading && <CircularProgress size="20px" />}
          {!deleteEventSetQueryResult.isLoading && <Typography variant="label">Delete Everywhere</Typography>}
        </MenuItem>
      </Menu>
    </Box>
  );
});
CustomEventsRow.displayName = 'CustomEventsRow';

type Props = {
  widget: TimelineWidget;
};
const TimelineConfigDrawerContent = ({ widget }: Props) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { enqueue } = useAppSnackbar();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  // ----- Selectors -----
  const viewAccessSelectors = useMemo(() => viewAccessAdapter.getSelectors(), []);
  const primaryViewId = useAppSelector(grayzoneSelectors.viewConfigurations.selectPrimaryViewId);
  if (!primaryViewId) {
    throw new Error('No primary view id found. A primary view must be defined!');
  }

  const { [WidgetType.TIMELINE]: timelineConfigs } = useSelectWidgetConfigurations();

  const [alertSampleSize, setAlertSampleSize] = useState([timelineConfigs?.alertSampleSize ?? 25]);
  const [alertSignificance, setAlertSignificance] = useState([timelineConfigs?.alertSignificance ?? 99]);
  const [alertPercentChange, setAlertPercentChange] = useState([timelineConfigs?.alertPercentChange ?? 0.01]);

  const customEventSetSelectors = useMemo(() => {
    return customEventSetAdapter.getSelectors();
  }, []);

  const customEventSetBindingSelectors = useMemo(() => {
    return customEventSetBindingAdapter.getSelectors();
  }, []);

  // ----- Queries -----
  const viewAccessQueryResult = grayzoneEndpoints.getViewAccess.useQuery({});
  const { data: eventSetData, isFetching: eventSetsIsFetching, isError: eventSetsIsError } = eventEndpoints.getEventSets.useQuery({ viewId: primaryViewId });
  const {
    data: eventSetBindingData,
    isFetching: eventSetBindingsIsFetching,
    isError: eventSetBindingsIsError
  } = eventEndpoints.getEventSetBinding.useQuery({ viewId: primaryViewId });

  // ----- Mutation -----
  const [triggerPostEventSetMutation] = eventEndpoints.postEventSet.useMutation();
  const [triggerPostEventSetBindingMutation] = eventEndpoints.postEventSetBinding.useMutation();

  // ----- Refs -----
  const { gridLayout } = useGrid();

  const hideFeedData = useAppSelector(grayzoneSelectors.timeline.selectHideFeedDataState);
  const hideAverageSentiment = useAppSelector(grayzoneSelectors.timeline.selectHideAverageSentimentState);
  const hideSentimentAlerts = useAppSelector(grayzoneSelectors.timeline.selectHideSentimentAlertsState);
  const hideSubmissions = useAppSelector(grayzoneSelectors.timeline.selectHideSubmissionsState);
  const widgets = useAppSelector((state) => grayzoneSelectors.widgets.selectAllWidgetsByGridLayoutId(state, gridLayout.id));

  const parseCSV = (csv: string) => {
    const lines = csv.split('\n');
    const result = [];
    const title = lines[0].split(',')[0];
    const headers = lines[1].split(',').map((header) => header.replace(/(\r\n|\n|\r)/gm, ''));

    for (let i = 2; i < lines.length; i++) {
      const obj: { [x: string]: string | number } = {};
      const currentline = lines[i].split(',');

      for (let j = 0; j < headers.length; j++) {
        if (headers[j] === 'latitude' || headers[j] === 'longitude') {
          obj[headers[j]] = Number(currentline[j]);
        } else if (headers[j] === 'startDate' || headers[j] === 'endDate') {
          const stamp = Date.parse(currentline[j]);
          if (isNaN(stamp)) {
            console.error('INVALID DATE DETECTED');
          } else {
            obj[headers[j]] = +new Date(currentline[j]);
          }
        } else obj[headers[j]] = currentline[j];
      }

      obj.id = uuid_v4().toString();

      result.push(obj);
    }

    const res = {
      id: uuid_v4().toString(),
      name: title,
      events: result
    };
    return res;
  };

  const isAllMainTimelineFiltersChecked = !(hideAverageSentiment || hideSentimentAlerts || hideSubmissions);

  // ----- Handlers -----

  const onSelect = useCallback(async (eventSetId: string, viewId: string) => {
    try {
      // TODO: LONG TERM SOLUTION IS TO ATTACH USER ID TO EVENT SET BINDINGS
      await triggerPostEventSetBindingMutation({ body: { eventSetId: eventSetId, viewId: viewId } }).unwrap();
    } catch (e) {
      enqueue('Something went wrong and the events were not added to this view', { variant: 'error' });
    }
  }, []);

  // ----- Effects -----

  useEffect(() => {
    if (eventSetsIsError) {
      enqueue('Could not fetch custom event sets', { variant: 'error' });
    }
  }, [eventSetsIsError]);

  useEffect(() => {
    if (eventSetBindingsIsError) {
      enqueue('Could not fetch custom event sets bound to this view', { variant: 'error' });
    }
  }, [eventSetBindingsIsError]);

  // useEffect(() => {
  //   if (eventSetBindingData) {
  //     dispatch(
  //       grayzoneActions.setEnabledCustomEvents({
  //         ...customEventSetBindingSelectors.selectAll(eventSetBindingData).reduce((runningObj, binding) => {
  //           runningObj[binding.eventSetId] = false;
  //           return runningObj;
  //         }, {} as GenericMap<boolean>),
  //         ...enabledCustomEvents
  //       })
  //     );
  //   }
  // }, [eventSetBindingData]);

  const toggleHideFeedData = useCallback(() => {
    dispatch(grayzoneActions.toggleHideFeedData());
  }, []);
  const toggleHideAverageSentiment = useCallback(() => {
    dispatch(grayzoneActions.toggleHideAverageSentiment());
  }, []);
  const toggleHideSentimentAlerts = useCallback(() => {
    dispatch(grayzoneActions.toggleHideSentimentAlerts());
  }, []);
  const toggleHideSubmissions = useCallback(() => {
    dispatch(grayzoneActions.toggleHideSubmissions());
  }, []);
  const setAllMainTimelineFilters = useCallback(() => {
    dispatch(grayzoneActions.setAllMainTimelineFilters(!isAllMainTimelineFiltersChecked));
  }, [isAllMainTimelineFiltersChecked]);

  const updateTimelineConfigs = useCallback(
    (changes: Partial<TimelineWidgetConfigurations>) => {
      const timelineChanges = { ...timelineConfigs, ...changes };
      dispatch(
        grayzoneActions.updateWidgetConfigurations({
          id: gridLayout.widgetConfigurationsId,
          changes: {
            [WidgetType.TIMELINE]: timelineChanges
          }
        })
      );
    },
    [timelineConfigs]
  );

  const updateAlertSampleSize = useCallback(
    (_e, newValue) => {
      const alertSampleSize = (newValue as [number])[0];
      updateTimelineConfigs({ alertSampleSize });
    },
    [updateTimelineConfigs]
  );
  const updateAlertPercentChange = useCallback(
    (_e, newValue) => {
      const alertPercentChange = (newValue as [number])[0];
      updateTimelineConfigs({ alertPercentChange });
    },
    [updateTimelineConfigs]
  );
  const updateAlertSignificance = useCallback(
    (_e, newValue) => {
      const alertSignificance = (newValue as [number])[0];
      updateTimelineConfigs({ alertSignificance });
    },
    [updateTimelineConfigs]
  );

  // ----- Handlers -----
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (anchorEl !== event.currentTarget) {
        setAnchorEl(event.currentTarget);
      }
    },
    [anchorEl]
  );
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);
  const openModal = useCallback(() => {
    dispatch(grayzoneActions.updateAddEventModalStateTo(true));
  }, []);

  return (
    <>
      <SectionedBox>
        <SectionLabel>Sample Size</SectionLabel>
        <SliderContainer>
          <ProfileOutlineIcon color={theme.color['neutral-75']} />
          <Slider
            valueLabelDisplay="auto"
            max={300}
            min={5}
            value={alertSampleSize}
            onChange={(_e, newValue) => setAlertSampleSize(newValue as [number])}
            onChangeCommitted={updateAlertSampleSize}
          />
          <GroupOutlineIcon color={theme.color['neutral-75']} />
        </SliderContainer>

        <SectionLabel>Percent Change</SectionLabel>
        <SliderContainer>
          <TrendNeutralOutlineIcon color={theme.color['neutral-75']} />
          <Slider
            valueLabelDisplay="auto"
            valueLabelFormat={(value) => `${(value * 100).toPrecision(2)}%`}
            max={0.1}
            min={0}
            step={0.001}
            value={alertPercentChange}
            onChange={(_e, newValue) => setAlertPercentChange(newValue as [number])}
            onChangeCommitted={updateAlertPercentChange}
          />
          <TrendOutlineIcon color={theme.color['neutral-75']} />
        </SliderContainer>

        <SectionLabel>Significance</SectionLabel>
        <SliderContainer>
          <CheckmarkOutlineIcon color={theme.color['neutral-75']} />
          <Slider
            valueLabelDisplay="auto"
            marks={true}
            max={99}
            min={90}
            step={5}
            value={alertSignificance}
            onChange={(_e, newValue) => setAlertSignificance(newValue as [number])}
            onChangeCommitted={updateAlertSignificance}
          />
          <DoubleCheckmarkOutlineIcon color={theme.color['neutral-75']} />
        </SliderContainer>
      </SectionedBox>
      <SectionedBox>
        <SectionLabel>Custom Events</SectionLabel>
        <Box>
          {eventSetData && eventSetBindingData && eventSetBindingData.ids.length > 0 && (
            <Box style={{ display: 'flex', flexDirection: 'column', paddingBottom: '20px', gap: theme.spacings['xxx-small'] }}>
              {customEventSetBindingSelectors.selectAll(eventSetBindingData).map((binding, index) => (
                <CustomEventsRow
                  key={`event-set-row-${index}`}
                  widget={widget}
                  eventSetId={binding.eventSetId}
                  eventSetData={eventSetData}
                  eventSetBindingId={binding.id}
                  viewPermission={viewAccessQueryResult.data ? viewAccessSelectors.selectById(viewAccessQueryResult.data, primaryViewId)?.permissions : undefined}
                />
              ))}
            </Box>
          )}
          <Box style={{ display: 'flex', flexDirection: 'row', gap: '10px', width: '100%', alignItems: 'center' }}>
            <Autocomplete
              disabled={eventSetsIsFetching || eventSetBindingsIsFetching}
              options={
                eventSetData && eventSetBindingData
                  ? customEventSetSelectors.selectAll(eventSetData).filter((eventSet) => !customEventSetBindingSelectors.selectById(eventSetBindingData, eventSet.id))
                  : []
              }
              style={{ flex: 1 }}
              getOptionLabel={(opt) => opt.name}
              onChange={(_e, eventSet) => {
                if (eventSet) {
                  onSelect(eventSet.id, primaryViewId);
                }
              }}
              loading={eventSetsIsFetching || eventSetBindingsIsFetching}
              clearOnEscape
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    label="My Uploaded Events"
                    variant={'standard'}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {eventSetsIsFetching || eventSetBindingsIsFetching ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      )
                    }}
                  />
                );
              }}
            />

            <DisappearingBox style={{ marginLeft: 'auto' }} onClick={handleClick} $active={true}>
              <IconButton variant="mini" onClick={handleClick}>
                <EllipsesHorizontalOutlineIcon size={theme.iconScale.small} />
              </IconButton>
            </DisappearingBox>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
              <MenuItem onClick={openModal}>
                <Typography>Add Event</Typography>
              </MenuItem>
              <FileUploader
                accept=".csv"
                onLoad={(data: CustomEventSet) => {
                  const eventSetState = omit(data, 'id');
                  if (primaryViewId) {
                    triggerPostEventSetMutation({ body: { eventSetState: eventSetState, viewId: primaryViewId } });
                  }
                }}
                schema={GrayzoneSchema.CUSTOM_EVENT_SET}
                customParser={parseCSV}
              >
                <MenuItem>
                  <Typography>Import CSV</Typography>
                </MenuItem>
              </FileUploader>
            </Menu>
          </Box>
        </Box>
      </SectionedBox>

      <SectionedBox>
        <SectionLabel>News Events</SectionLabel>
        <Box>
          <Box>
            <Checkbox label="Select all" checked={!hideFeedData} onChange={toggleHideFeedData} variant="dash" />
            {widgets
              .filter((w) => w.type === WidgetType.FEED)
              .map((widget) => (
                <Checkbox key={`hide-selector-${widget.name}`} label={widget.name} checked={!hideFeedData} onChange={toggleHideFeedData} />
              ))}
          </Box>
        </Box>
      </SectionedBox>

      <SectionedBox>
        <SectionLabel>Sentiment</SectionLabel>
        <Box>
          <Box>
            <Checkbox label="Select all" variant="dash" checked={isAllMainTimelineFiltersChecked} onChange={setAllMainTimelineFilters} />
            <Checkbox label="Average Sentiment" checked={!hideAverageSentiment} onChange={toggleHideAverageSentiment} />
            <Checkbox label="Sentiment Alerts" checked={!hideSentimentAlerts} onChange={toggleHideSentimentAlerts} />
            <Checkbox label="Submissions" checked={!hideSubmissions} onChange={toggleHideSubmissions} />
          </Box>
        </Box>
      </SectionedBox>
    </>
  );
};

export default memo(TimelineConfigDrawerContent);
