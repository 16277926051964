/* eslint-disable max-len */
// BASEUI-GENERATED-REACT-ICON
// DO NOT EDIT THIS FILE DIRECTLY, SEE README.md
import * as React from 'react';
import type { IconProps } from '../Icon';
import Icon from '../Icon';

const ArrowUpOutlineIcon = (props: IconProps): JSX.Element => {
  const {
    title = 'Arrow Up Outline Icon',
    size,
    color,
    onClick,
    ...restProps
  } = props;
  return (
    <Icon
      title={title}
      size={size}
      color={color}
      onClick={onClick}
      {...restProps}
    >
      <path d="M18.7019 10.2666C19.0994 10.6631 19.0994 11.3061 18.7019 11.7026C18.3045 12.0991 17.6602 12.0991 17.2628 11.7026L13 7.44919L13 18.9286C13 19.5203 12.5523 20 12 20C11.4477 20 11 19.5203 11 18.9286L11 7.44919L6.73721 11.7026C6.3398 12.0991 5.69547 12.0991 5.29806 11.7026C4.90065 11.3061 4.90065 10.6631 5.29806 10.2666L11.2804 4.2974C11.6778 3.90087 12.3222 3.90087 12.7196 4.2974L18.7019 10.2666Z"></path>
    </Icon>
  );
};

export default ArrowUpOutlineIcon;
/* eslint-enable max-len */
