import { Theme } from '@mui/material/styles';
// sentiment can only be up to 7 (at least in terms of design)

export const getColorScheme = (length: number, theme: Theme) => {
  switch (length) {
    case 2:
      return {
        [0]: theme.color['brand-secondary-4-100'],
        [1]: theme.color['brand-secondary-3-100']
      };
    case 3:
      return {
        [0]: theme.color['brand-secondary-4-100'],
        [0.5]: theme.color['neutral-50'],
        [1]: theme.color['brand-secondary-3-100']
      };
    case 4:
      return {
        [0]: theme.color['brand-secondary-4-125'],
        [0.33]: theme.color['brand-secondary-4-75'],
        [0.66]: theme.color['brand-secondary-3-75'],
        [1]: theme.color['brand-secondary-3-125']
      };
    case 5:
      return {
        [0]: theme.color['brand-secondary-4-125'],
        [0.25]: theme.color['brand-secondary-4-75'],
        [0.5]: theme.color['neutral-50'],
        [0.75]: theme.color['brand-secondary-3-75'],
        [1]: theme.color['brand-secondary-3-125']
      };
    case 6:
      return {
        [0]: theme.color['brand-secondary-4-125'],
        [0.2]: theme.color['brand-secondary-4-75'],
        [0.4]: theme.color['brand-secondary-4-25'],
        [0.6]: theme.color['brand-secondary-3-25'],
        [0.8]: theme.color['brand-secondary-3-75'],
        [1]: theme.color['brand-secondary-3-125']
      };
    case 7:
      return {
        [0]: theme.color['brand-secondary-4-125'],
        [0.16]: theme.color['brand-secondary-4-75'],
        [0.33]: theme.color['brand-secondary-4-25'],
        [0.5]: theme.color['neutral-50'],
        [0.66]: theme.color['brand-secondary-3-25'],
        [0.83]: theme.color['brand-secondary-3-75'],
        [1]: theme.color['brand-secondary-3-125']
      };
  }
};
