/* eslint-disable max-len */
// BASEUI-GENERATED-REACT-ICON
// DO NOT EDIT THIS FILE DIRECTLY, SEE README.md
import * as React from 'react';
import type { IconProps } from '../Icon';
import Icon from '../Icon';

const ShowOutlineIcon = (props: IconProps): JSX.Element => {
  const {
    title = 'Show Outline Icon',
    size,
    color,
    onClick,
    ...restProps
  } = props;
  return (
    <Icon
      title={title}
      size={size}
      color={color}
      onClick={onClick}
      {...restProps}
    >
      <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 12C22 14 16.6935 18 12 18C7.30648 18 2 14 2 12C2 10 7.30648 6 12 6C16.6935 6 22 10 22 12ZM19.5885 11.5313C19.7756 11.7464 19.8805 11.9023 19.9384 12C19.8805 12.0977 19.7756 12.2536 19.5885 12.4687C19.1651 12.9554 18.5062 13.5269 17.6662 14.0757C15.9569 15.1924 13.8351 16 12 16C10.1649 16 8.04314 15.1924 6.33381 14.0757C5.49382 13.5269 4.83488 12.9554 4.4115 12.4687C4.22442 12.2536 4.11953 12.0977 4.06159 12C4.11953 11.9023 4.22442 11.7464 4.4115 11.5313C4.83488 11.0446 5.49382 10.4731 6.33381 9.92434C8.04314 8.80761 10.1649 8 12 8C13.8351 8 15.9569 8.80761 17.6662 9.92434C18.5062 10.4731 19.1651 11.0446 19.5885 11.5313Z"
      ></path>
    </Icon>
  );
};

export default ShowOutlineIcon;
/* eslint-enable max-len */
