// ----- REACT -----
import { Box, Skeleton, styled, useTheme } from '@mui/material';
import { memo, useMemo, useState } from 'react';
import IconButton from '../../../PremiseDesign/Components/IconButton';
import { SplitScreenOutlineIcon } from '../../../PremiseDesign/Icons';
import { MapMarker, MarkerState, POISearchResult } from '../../../types/grayzone';
import ImageContainer from './ImageContainer';
import MiniMap from './MiniMap';

// ----- REDUX -----

// ----- API -----

// ----- MODULES -----

// ----- OURS -----

// ----- CONSTANTS -----

// ----- STYLES -----
const MinimizedContentContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  borderRadius: theme.borderRadius.borderRadiusSemiSharp,
  position: 'relative'
}));

const SplitContentContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: theme.borderRadius.borderRadiusSemiSharp,
  position: 'relative'
}));

const SplitScreenButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: 0,
  bottom: 0,
  marginRight: theme.spacing(1),
  marginBottom: theme.spacing(1),
  zIndex: 4000
}));

const ThumbnailContainer = styled(Box)<{ $size: React.CSSProperties['height'] }>(({ theme, $size }) => ({
  width: $size,
  height: $size,
  transition: 'all 0.4 ease',
  position: 'absolute',
  left: '0',
  top: '0',
  marginLeft: theme.spacing(1),
  marginTop: theme.spacing(1),
  cursor: 'pointer',
  borderRadius: theme.borderRadius.borderRadiusSemiSharp,
  zIndex: 4000
}));

// ----- TYPES -----

// ----- COMPONENT -----
type Props = {
  selectedIndex: number;
  updateSelectedIndex: (index: number) => void;
  POIs: POISearchResult[];
  style: React.CSSProperties;
  thumbnailSize: React.CSSProperties['height'];
};
const POIViewer = ({ selectedIndex, updateSelectedIndex, POIs, style, thumbnailSize }: Props) => {
  const theme = useTheme();
  // ----- Redux -----

  // ----- Local State -----
  const [splitMapAndImage, setSplitMapAndImage] = useState<boolean>(false);
  const [mapIsMinimized, setMapIsMinimized] = useState<boolean>(false);

  // ----- Selectors -----

  // ----- Queries -----

  // ----- Helpers -----

  // ----- Handlers -----
  const handleClickedSplitButton = () => {
    setSplitMapAndImage(!splitMapAndImage);
  };

  const minimizedView = useMemo(() => {
    if (mapIsMinimized) {
      return (
        <MinimizedContentContainer>
          <ImageContainer interactable style={{ borderRadius: theme.borderRadius.borderRadiusSemiSharp, width: '100%', height: '100%' }} source={POIs[selectedIndex].photo} />
          <ThumbnailContainer
            $size={thumbnailSize}
            onClick={() => {
              setMapIsMinimized(!mapIsMinimized);
            }}
          >
            <MiniMap
              style={{
                borderRadius: theme.borderRadius.borderRadiusSemiSharp
              }}
              markers={[]}
              onSelectedMarkerChanged={updateSelectedIndex}
              centerLatitude={POIs[selectedIndex].y_lat ?? 50}
              centerLongitude={POIs[selectedIndex].x_lon ?? 50}
              scrollWheelZoom={false}
              doubleClickZoom={false}
              zoomLevel={8}
              placeholder={<Skeleton variant="rectangular" />}
            />
          </ThumbnailContainer>
          <SplitScreenButton variant="mini" selected={splitMapAndImage} onClick={handleClickedSplitButton}>
            <SplitScreenOutlineIcon />
          </SplitScreenButton>
        </MinimizedContentContainer>
      );
    } else {
      return (
        <MinimizedContentContainer>
          <ThumbnailContainer
            $size={thumbnailSize}
            onClick={() => {
              setMapIsMinimized(!mapIsMinimized);
            }}
          >
            <ImageContainer style={{ borderRadius: '4px', width: '100%', height: '100%' }} source={POIs[selectedIndex].photo} />
          </ThumbnailContainer>
          <MiniMap
            style={{ borderRadius: '4px', width: '100%', height: '100%' }}
            markers={
              POIs.map(
                (info, index) =>
                  ({
                    latitude: info.y_lat,
                    longitude: info.x_lon,
                    tooltip: `${info.num_submissions} submissions between ${new Date(info.first_seen).toLocaleDateString()} and ${new Date(info.last_seen).toLocaleDateString()}`,
                    state: selectedIndex === index ? MarkerState.SELECTED : MarkerState.DEFAULT
                  } as MapMarker)
              ) ?? []
            }
            onSelectedMarkerChanged={updateSelectedIndex}
            centerLatitude={POIs[selectedIndex].y_lat ?? 50}
            centerLongitude={POIs[selectedIndex].x_lon ?? 50}
            zoomLevel={12}
            placeholder={<Skeleton variant="rectangular" />}
          />
          <SplitScreenButton variant="mini" selected={splitMapAndImage} onClick={handleClickedSplitButton}>
            <SplitScreenOutlineIcon />
          </SplitScreenButton>
        </MinimizedContentContainer>
      );
    }
  }, [mapIsMinimized, selectedIndex, POIs, thumbnailSize]);
  // ----- Effects -----

  // ----- Return Component -----
  return (
    <Box style={style}>
      {splitMapAndImage && (
        <SplitContentContainer>
          <Box style={{ display: 'flex', flex: 1, height: '100%' }}>
            <MiniMap
              style={{ width: '100%', height: '100%' }}
              markers={
                POIs.map(
                  (info, index) =>
                    ({
                      latitude: info.y_lat,
                      longitude: info.x_lon,
                      tooltip: `${info.num_submissions} submissions between ${new Date(info.first_seen).toLocaleDateString()} and ${new Date(info.last_seen).toLocaleDateString()}`,
                      state: selectedIndex === index ? MarkerState.SELECTED : MarkerState.DEFAULT
                    } as MapMarker)
                ) ?? []
              }
              onSelectedMarkerChanged={updateSelectedIndex}
              centerLatitude={POIs[selectedIndex].y_lat ?? 50}
              centerLongitude={POIs[selectedIndex].x_lon ?? 50}
              zoomLevel={12}
              placeholder={<Skeleton variant="rectangular" style={{ borderRadius: theme.borderRadius.borderRadiusSemiSharp }} />}
            />
          </Box>
          <Box style={{ display: 'flex', flex: 1, height: '100%' }}>
            <ImageContainer
              style={{
                width: '100%',
                height: '100%',
                borderRadius: `0px ${theme.borderRadius.borderRadiusSemiSharp} ${theme.borderRadius.borderRadiusSemiSharp} 0px`
              }}
              interactable
              source={POIs[selectedIndex].photo}
            />
          </Box>
          <SplitScreenButton variant="mini" selected={splitMapAndImage} onClick={handleClickedSplitButton}>
            <SplitScreenOutlineIcon />
          </SplitScreenButton>
        </SplitContentContainer>
      )}
      {!splitMapAndImage && minimizedView}
    </Box>
  );
};

export default memo(POIViewer);
