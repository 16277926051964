// ----- REACT -----
import { useContext } from 'react';

// ----- OURS -----
import { GridContext, GridContextValue } from '../contexts/GridContext';

// ----- HOOK -----
const useGrid = (): GridContextValue => {
  const contextValue = useContext(GridContext);

  if (!contextValue) {
    throw new Error('No GridContext.Provider found when calling useGrid.');
  } else {
    // grid in initial null state, so do some setup here
    return contextValue;
  }
};

export default useGrid;
