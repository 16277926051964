/* eslint-disable max-len */
// BASEUI-GENERATED-REACT-ICON
// DO NOT EDIT THIS FILE DIRECTLY, SEE README.md
import * as React from 'react';
import type { IconProps } from '../Icon';
import Pictogram from '../Pictogram';

const RiotPictogram = (props: IconProps): JSX.Element => {
  const { title = 'Riot Pictogram', ...restProps } = props;
  return (
    <Pictogram title={title} {...restProps}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.54911 17.408C9.10123 11.2928 14.241 6.50098 20.5 6.50098L21 6.50098C26.3588 6.50098 30.8972 10.0136 32.4383 14.8619C33.9042 13.7433 35.7831 13.1597 37.7619 13.3653L51.3589 14.8226C55.4451 15.2471 58.4294 18.8801 58.0522 22.971L56.5618 39.1387L53.5745 38.8633L55.0649 22.6956C55.2912 20.241 53.5006 18.0613 51.0489 17.8066L37.4519 16.3492C34.9799 16.0924 32.7678 17.8882 32.511 20.3602L29.6122 48.2643C29.3554 50.7363 31.1512 52.9484 33.6232 53.2052L47.5834 54.6554C50.0553 54.9122 52.2675 53.1165 52.5243 50.6445L52.939 46.6524L55.9229 46.9624L55.5082 50.9545C55.0802 55.0744 51.3934 58.0674 47.2734 57.6394L33.3132 56.1892C29.1932 55.7612 26.2003 52.0743 26.6283 47.9544L27.9739 35.0009H16.5C12.3579 35.0009 9 31.643 9 27.5009V24.2876C7.54351 23.7728 6.5 22.3837 6.5 20.751C6.5 19.2922 7.33291 18.028 8.54911 17.408ZM11.6244 17.001C12.3386 12.7444 16.0405 9.50098 20.5 9.50098H21C25.4595 9.50098 29.1614 12.7444 29.8756 17.001H11.6244ZM28.2856 32.0009L29.0647 24.501H28.0527L28.0875 24.534L20.994 32.0009H28.2856ZM29.3763 21.501L29.5271 20.0502C29.5288 20.0338 29.5305 20.0174 29.5323 20.001L10.25 20.001C9.83579 20.001 9.5 20.3368 9.5 20.751C9.5 21.1652 9.83579 21.501 10.25 21.501H29.3763ZM21.0626 24.501H23.981L16.9125 31.9415L16.9751 32.0009H16.5C15.7816 32.0009 15.1025 31.8326 14.5001 31.5332L21.0923 24.5289L21.0626 24.501ZM12 24.501L16.9989 24.501L12.4089 29.3778C12.1464 28.8065 12 28.1708 12 27.5009V24.501Z"
        fill="black"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.8489 22.1228C34.9345 21.2988 35.6719 20.7002 36.4959 20.7858L51.4358 22.3378C52.2598 22.4234 52.8584 23.1608 52.7728 23.9848L52.6171 25.4842C52.3032 28.5055 49.5995 30.7003 46.5782 30.3864L39.5954 29.6611C36.5741 29.3472 34.3793 26.6435 34.6931 23.6222L34.8489 22.1228ZM37.6779 23.9247L37.6771 23.9322C37.5344 25.3055 38.5321 26.5344 39.9054 26.6771L46.8882 27.4025C48.2615 27.5452 49.4905 26.5475 49.6331 25.1742L49.6339 25.1668L37.6779 23.9247Z"
        fill="black"
      ></path>
      <path
        d="M36.0769 41.3068C35.9911 42.1322 35.2525 42.7318 34.4271 42.646C33.6018 42.5603 33.0022 41.8217 33.0879 40.9963C33.1736 40.1709 33.9123 39.5713 34.7376 39.657C35.563 39.7428 36.1626 40.4814 36.0769 41.3068Z"
        fill="black"
      ></path>
      <path
        d="M51.0169 42.8585C50.9312 43.6839 50.1926 44.2835 49.3672 44.1978C48.5418 44.112 47.9422 43.3734 48.028 42.548C48.1137 41.7227 48.8523 41.1231 49.6777 41.2088C50.5031 41.2945 51.1027 42.0332 51.0169 42.8585Z"
        fill="black"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.0489 17.8066L44 17.0751L44.31 14.0911L51.3589 14.8226C55.4451 15.2471 58.4294 18.8801 58.0522 22.971L56.5618 39.1387L53.5745 38.8633L55.0649 22.6956C55.2912 20.241 53.5006 18.0613 51.0489 17.8066Z"
        fill="#7E3BFF"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.8489 22.1228C34.9345 21.2988 35.6719 20.7002 36.4959 20.7858L51.4358 22.3378C52.2598 22.4234 52.8584 23.1608 52.7728 23.9848L52.6171 25.4842C52.3032 28.5055 49.5995 30.7003 46.5782 30.3864L39.5954 29.6611C36.5741 29.3472 34.3793 26.6435 34.6931 23.6222L34.8489 22.1228ZM37.6779 23.9247L37.6771 23.9322C37.5344 25.3055 38.5321 26.5344 39.9054 26.6771L46.8882 27.4025C48.2615 27.5452 49.4905 26.5475 49.6331 25.1742L49.6339 25.1668L37.6779 23.9247Z"
        fill="#7E3BFF"
      ></path>
      <path
        d="M36.0769 41.3068C35.9911 42.1322 35.2525 42.7318 34.4271 42.646C33.6018 42.5603 33.0022 41.8217 33.0879 40.9963C33.1736 40.1709 33.9123 39.5713 34.7376 39.657C35.563 39.7428 36.1626 40.4814 36.0769 41.3068Z"
        fill="black"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.5305 41.6513C34.8066 41.68 35.0536 41.4795 35.0823 41.2034C35.111 40.9274 34.9104 40.6803 34.6344 40.6517C34.3583 40.623 34.1113 40.8235 34.0826 41.0996C34.0539 41.3756 34.2544 41.6227 34.5305 41.6513ZM34.4271 42.646C35.2525 42.7318 35.9911 42.1322 36.0769 41.3068C36.1626 40.4814 35.563 39.7428 34.7376 39.657C33.9123 39.5713 33.1736 40.1709 33.0879 40.9963C33.0022 41.8217 33.6018 42.5603 34.4271 42.646Z"
        fill="#7E3BFF"
      ></path>
      <path
        d="M51.0169 42.8585C50.9312 43.6839 50.1926 44.2835 49.3672 44.1978C48.5418 44.112 47.9422 43.3734 48.028 42.548C48.1137 41.7227 48.8523 41.1231 49.6777 41.2088C50.5031 41.2945 51.1027 42.0332 51.0169 42.8585Z"
        fill="black"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.4706 43.2031C49.7466 43.2318 49.9937 43.0312 50.0223 42.7552C50.051 42.4791 49.8505 42.2321 49.5744 42.2034C49.2984 42.1747 49.0513 42.3753 49.0226 42.6513C48.994 42.9274 49.1945 43.1744 49.4706 43.2031ZM49.3672 44.1978C50.1926 44.2835 50.9312 43.6839 51.0169 42.8585C51.1027 42.0332 50.5031 41.2945 49.6777 41.2088C48.8523 41.1231 48.1137 41.7227 48.028 42.548C47.9422 43.3734 48.5418 44.112 49.3672 44.1978Z"
        fill="#7E3BFF"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.5745 38.8633L54.5918 27.8273L57.5792 28.1027L56.5618 39.1387L53.5745 38.8633Z"
        fill="#FF5B4A"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.8489 22.1228C34.9345 21.2988 35.6719 20.7002 36.4959 20.7858L51.4358 22.3378C52.2598 22.4234 52.8584 23.1608 52.7728 23.9848L52.6171 25.4842C52.3032 28.5055 49.5995 30.7003 46.5782 30.3864L39.5954 29.6611C36.5741 29.3472 34.3793 26.6435 34.6931 23.6222L34.8489 22.1228ZM37.6779 23.9247L37.6771 23.9322C37.5344 25.3055 38.5321 26.5344 39.9054 26.6771L46.8882 27.4025C48.2615 27.5452 49.4905 26.5475 49.6331 25.1742L49.6339 25.1668L37.6779 23.9247Z"
        fill="black"
      ></path>
      <path
        d="M36.0769 41.3068C35.9911 42.1322 35.2525 42.7318 34.4271 42.646C33.6018 42.5603 33.0022 41.8217 33.0879 40.9963C33.1736 40.1709 33.9123 39.5713 34.7376 39.657C35.563 39.7428 36.1626 40.4814 36.0769 41.3068Z"
        fill="black"
      ></path>
      <path
        d="M51.0169 42.8585C50.9312 43.6839 50.1926 44.2835 49.3672 44.1978C48.5418 44.112 47.9422 43.3734 48.028 42.548C48.1137 41.7227 48.8523 41.1231 49.6777 41.2088C50.5031 41.2945 51.1027 42.0332 51.0169 42.8585Z"
        fill="black"
      ></path>
    </Pictogram>
  );
};

export default RiotPictogram;
/* eslint-enable max-len */
