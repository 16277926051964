import * as React from 'react';
import premiseTheme from '../premiseTheme';

const numberPattern = /[-]{0,1}[\d]*[.]{0,1}[\d]+/g;

export type IconProps = {
  children?: React.ReactNode;
  size?: number | string;
  color?: string;
  title?: string;
  onClick?: () => void;
};

function Icon(props: IconProps): JSX.Element {
  const {
    children,
    title,
    size = premiseTheme.iconScale.regular,
    color = premiseTheme.color['neutral-100'],
    ...restProps
  } = props;

  // Using a size such as scale(1.0), extract the number and set that to
  // width and height
  const [match] = String(size).match(numberPattern) as RegExpMatchArray;
  const dimension = 24 * Number(match);

  const passThroughProps = {
    fill: color,
    height: dimension,
    title,
    viewBox: '0 0 24 24',
    width: dimension,
    ...restProps,
  };
  return (
    <svg data-baseweb="icon" {...passThroughProps}>
      {title ? <title>{title}</title> : null}
      {children}
    </svg>
  );
}

export default Icon;
