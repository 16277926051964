import React from 'react';

/* 
This is the actual draggable corner element for widgets.
It will be invisible to keep interactivity and to fake our hover state
In Widget.tsx, we have an icon that is directly on top of this invisible
element to have a fade-in and fade-out effect on hover. is a hack lol
*/

type Props = {
  handleAxis: 's' | 'w' | 'e' | 'n' | 'sw' | 'nw' | 'se' | 'ne';
};

const DraggableCorner = React.forwardRef<HTMLDivElement, Props>(({ handleAxis, ...restProps }: Props, ref) => {
  return (
    <div
      ref={ref}
      className={`foo handle-${handleAxis}`}
      {...restProps}
      style={{
        // match this up with the actual visible element in Widget.tsx
        width: '19px',
        height: '19px',
        position: 'absolute',
        right: '0px',
        bottom: '0px',
        opacity: 0, // hide
        cursor: 'nwse-resize',
        zIndex: 9001
      }}
    />
  );
});

DraggableCorner.displayName = 'DraggableCorner';

export default DraggableCorner;
