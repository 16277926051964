import { viewEndpoints } from './view';
import { shareEndpoints } from './share';
import { revereNewsEndpoints } from './revereNews';
import { eventEndpoints } from './event';
// import { gdeltEndpoints } from './gdelt';

import { grayzoneApi } from './grayzone';
export * from './grayzone';

export const grayzoneEndpoints = { ...grayzoneApi.endpoints, ...viewEndpoints, ...shareEndpoints, ...eventEndpoints, ...revereNewsEndpoints };
