import { useContext } from 'react';
import { TimelineTooltipContext } from '../contexts/TimelineTooltipContext';

const useTimelineTooltip = () => {
  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, setAndShowTooltip, hideTooltip, highlightedComponent, setHighlightedComponent, setOffset } =
    useContext(TimelineTooltipContext);

  return { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, setAndShowTooltip, hideTooltip, highlightedComponent, setHighlightedComponent, setOffset };
};

export default useTimelineTooltip;
