/* eslint-disable max-len */
// BASEUI-GENERATED-REACT-ICON
// DO NOT EDIT THIS FILE DIRECTLY, SEE README.md
import * as React from 'react';
import type { IconProps } from '../Icon';
import Icon from '../Icon';

const CheckmarkOutlineIcon = (props: IconProps): JSX.Element => {
  const {
    title = 'Checkmark Outline Icon',
    size,
    color,
    onClick,
    ...restProps
  } = props;
  return (
    <Icon
      title={title}
      size={size}
      color={color}
      onClick={onClick}
      {...restProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.50139 12.0844C2.89191 11.6939 3.52508 11.6939 3.9156 12.0844L7.61522 15.784L18.1996 5.19963C18.5902 4.80911 19.2233 4.80911 19.6138 5.19963C20.0044 5.59016 20.0044 6.22332 19.6138 6.61384L8.32232 17.9054C8.13479 18.0929 7.88043 18.1983 7.61522 18.1983C7.35 18.1983 7.09565 18.0929 6.90811 17.9054L2.50139 13.4986C2.11087 13.1081 2.11087 12.475 2.50139 12.0844Z"
      ></path>
    </Icon>
  );
};

export default CheckmarkOutlineIcon;
/* eslint-enable max-len */
