import { EntityState } from '@reduxjs/toolkit';
import { customEventSetAdapter, customEventSetBindingAdapter } from '../../helpers/grayzone';
import { CustomEvent, CustomEventSet, CustomEventSetState, CustomEventSetBinding } from '../../types/grayzone';
import { grayzoneApi } from './grayzone';

export type GetEventApiResponse = /** status 200 Any CustomEventSets found are returned in an array */ CustomEventSet[];
export type GetEventApiArg = {
  id?: string;
  name?: string;
  ownerId?: string;
  maxResults?: string;
  viewId?: string;
};
export type PostEventApiResponse = /** status 200 OK */ {
  eventSetId: string;
};
export type PostEventApiArg = {
  body: {
    eventSetState: CustomEventSetState;
    viewId: string;
  };
};
export type GetEventBindingApiResponse = /** status 200 Any CustomEventSets found are returned in an array */ CustomEventSetBinding[];
export type GetEventBindingApiArg = {
  /** The view id associated with a CustomEventSet */
  viewId?: string;
  /** Id of the CustomEventSetBinding */
  id?: string;
  /** Maximum number of returned results */
  maxResults?: string;
  /** Id of the CustomEventSet */
  eventSetId?: string;
};
export type PostEventBindingApiResponse = /** status 200 The binding was successfully created and the id of the binding is returned in the response body */ {
  eventSetBindingId: string;
};
export type PostEventBindingApiArg = {
  body: {
    eventSetId: string;
    viewId: string;
  };
};
export type DeleteEventBindingByEventBindingIdApiResponse = /** status 200 OK */ {
  eventSetBindingId: string;
};
export type DeleteEventBindingByEventBindingIdApiArg = {
  eventBindingId: string;
};
export type PutEventByEventSetIdApiResponse = /** status 200 Event set was put and the id of the set is returned */ CustomEventSet;
export type PutEventByEventSetIdApiArg = {
  eventSetId: string;
  customEventSetState: CustomEventSetState;
};
export type DeleteEventByEventSetIdApiResponse = /** status 200 CustomEventSet was deleted and returned in the response body */ {
  eventSetId: string;
};
export type DeleteEventByEventSetIdApiArg = {
  eventSetId: string;
};

const injectedRtkApi = grayzoneApi.injectEndpoints({
  endpoints: (build) => ({
    getEventSets: build.query<EntityState<CustomEventSet>, GetEventApiArg>({
      query: (queryArg) => ({
        url: `/event`,
        params: { name: queryArg.name, viewId: queryArg.viewId, id: queryArg.id, maxResults: queryArg.maxResults, ownerId: queryArg.ownerId }
      }),
      transformResponse: (val: GetEventApiResponse) => {
        return customEventSetAdapter.addMany(customEventSetAdapter.getInitialState(), val);
      },
      providesTags: (result) => {
        return result
          ? [...result.ids.map((setId) => ({ type: 'EventSet' as const, id: setId })), 'EventSet', { type: 'EventSet', id: 'LIST' }]
          : ['EventSet', { type: 'EventSet', id: 'LIST' }];
      }
    }),
    postEventSet: build.mutation<PostEventApiResponse, PostEventApiArg>({
      query: (queryArg) => ({ url: `/event`, method: 'POST', body: queryArg.body }),
      invalidatesTags: (result) => {
        return result ? [{ type: 'EventSet', id: result.eventSetId }, { type: 'EventSet', id: 'LIST' }, 'EventSet', 'EventSetBinding'] : [];
      }
    }),
    getEventSetBinding: build.query<EntityState<CustomEventSetBinding>, GetEventBindingApiArg>({
      query: (queryArg) => ({ url: `/event/binding`, params: { viewId: queryArg.viewId, id: queryArg.id, maxResults: queryArg.maxResults, eventSetId: queryArg.eventSetId } }),
      transformResponse: (val: GetEventBindingApiResponse) => {
        return customEventSetBindingAdapter.addMany(customEventSetBindingAdapter.getInitialState(), val);
      },
      providesTags: (result) => {
        return result
          ? [...result.ids.map((bindingId) => ({ type: 'EventSetBinding' as const, id: bindingId })), 'EventSetBinding', { type: 'EventSetBinding', id: 'LIST' }]
          : ['EventSetBinding', { type: 'EventSetBinding', id: 'LIST' }];
      }
    }),
    postEventSetBinding: build.mutation<PostEventBindingApiResponse, PostEventBindingApiArg>({
      query: (queryArg) => ({ url: `/event/binding`, method: 'POST', body: queryArg.body }),
      invalidatesTags: (result) => {
        return result ? [{ type: 'EventSetBinding', id: result.eventSetBindingId }, { type: 'EventSetBinding', id: 'LIST' }, 'EventSetBinding'] : [];
      }
    }),
    deleteEventSetBindingById: build.mutation<DeleteEventBindingByEventBindingIdApiResponse, DeleteEventBindingByEventBindingIdApiArg>({
      query: (queryArg) => ({ url: `/event/binding/${queryArg.eventBindingId}`, method: 'DELETE' }),
      invalidatesTags: (result) => {
        return result ? [{ type: 'EventSetBinding', id: result.eventSetBindingId }, { type: 'EventSetBinding', id: 'LIST' }, 'EventSetBinding'] : [];
      }
    }),
    putEventSetByEventSetId: build.mutation<PutEventByEventSetIdApiResponse, PutEventByEventSetIdApiArg>({
      query: (queryArg) => ({ url: `/event/${queryArg.eventSetId}`, method: 'PUT', body: queryArg.customEventSetState })
    }),
    deleteEventSetByEventSetId: build.mutation<DeleteEventByEventSetIdApiResponse, DeleteEventByEventSetIdApiArg>({
      query: (queryArg) => ({ url: `/event/${queryArg.eventSetId}`, method: 'DELETE' }),
      invalidatesTags: (result) => {
        return result ? [{ type: 'EventSet', id: result.eventSetId }, { type: 'EventSet', id: 'LIST' }, 'EventSet', 'EventSetBinding'] : [];
      }
    }),
    addEventToEventSet: build.mutation<{ eventSetId: string }, { eventSetId: string; event: CustomEvent }>({
      query: (queryArg) => ({ url: `event/${queryArg.eventSetId}`, body: { event: queryArg.event }, method: 'PATCH' }),
      invalidatesTags: (result) => {
        return result ? [{ type: 'EventSet', id: result.eventSetId }, { type: 'EventSet', id: 'LIST' }, 'EventSet', 'EventSetBinding'] : [];
      }
    })
  }),
  overrideExisting: false
});

export const eventEndpoints = injectedRtkApi.endpoints;
