import { memo, useMemo } from 'react';

// ----- MUI -----
import { Tooltip, Typography } from '@mui/material';
import styled from 'styled-components';

// ----- PDS -----
import { CloseCircleOutlineIcon } from '../Icons';
import Theme from '../premiseTheme';

const Pill = styled('div')<{ $color?: string; $icon?: boolean; $maxWidth?: string; $cursor: string; $active: boolean; $hoverColor?: string }>(
  ({ $color, $icon = false, $maxWidth, $cursor, $active, $hoverColor }) => ({
    ...Theme.typography['label-small-bold'],
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '20px',
    maxWidth: $maxWidth ?? 'none',
    paddingTop: '2px',
    paddingBottom: '2px',
    paddingLeft: '8px',
    paddingRight: $icon ? '4px' : '8px',
    borderRadius: Theme.borderRadius.borderRadiusPill,
    // backgroundColor: color ?? 'none',
    border: $color ? `1px solid ${$color}` : 'none',
    backgroundColor: $active ? $color : 'transparent',
    cursor: $cursor,
    '&:hover': {
      backgroundColor: $active ? $color : $hoverColor ?? 'inherit'
    }
  })
);
const PillIcon = styled('span')({
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center'
});

type Props = {
  label: string;
  maxWidth?: string;
  dismissible?: boolean;
  color?: string;
  hoverColor?: string;
  active?: boolean;
  onDismiss?: () => void;
  onHover?: () => void;
  onMouseLeave?: () => void;
  onClick?: () => void;
};
const Badge = ({ label, maxWidth, dismissible, color = Theme.color['neutral-50'], hoverColor, active = false, onDismiss, onHover, onMouseLeave, onClick }: Props) => {
  const icon = dismissible !== undefined && dismissible === true;

  const Label = useMemo(() => {
    if (maxWidth) {
      return (
        <Tooltip title={label}>
          <Typography variant="label-small-bold" paddingRight={icon ? '4px' : 0} color={active ? 'white' : color} noWrap>
            {label}
          </Typography>
        </Tooltip>
      );
    } else {
      return (
        <Typography variant="label-small-bold" paddingRight={icon ? '4px' : 0} color={active ? 'white' : color} noWrap>
          {label}
        </Typography>
      );
    }
  }, [maxWidth, label, active]);

  return (
    <Pill
      $icon={icon}
      $color={color}
      $maxWidth={maxWidth}
      $cursor={onClick ? 'pointer' : 'default'}
      $active={active}
      $hoverColor={hoverColor}
      onMouseEnter={onHover}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
    >
      {Label}
      {dismissible && (
        <PillIcon onClick={onDismiss}>
          <CloseCircleOutlineIcon size={Theme.iconScale.small} color={color} />
        </PillIcon>
      )}
    </Pill>
  );
};

export default memo(Badge);
