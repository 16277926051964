/* eslint-disable max-len */
// BASEUI-GENERATED-REACT-ICON
// DO NOT EDIT THIS FILE DIRECTLY, SEE README.md
import * as React from 'react';
import type { IconProps } from '../Icon';
import Icon from '../Icon';

const SettingsOutlineIcon = (props: IconProps): JSX.Element => {
  const {
    title = 'Settings Outline Icon',
    size,
    color,
    onClick,
    ...restProps
  } = props;
  return (
    <Icon
      title={title}
      size={size}
      color={color}
      onClick={onClick}
      {...restProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2496 6.4354L13 5.92958V4H11V5.92957L9.75042 6.43539C9.24741 6.639 8.7774 6.90939 8.35157 7.23617L7.29135 8.04977L5.74169 7.15507L4.675 9.00264L6.22175 9.89565L6.05022 11.2182C6.01714 11.4732 5.99997 11.7341 5.99997 12C5.99997 12.2748 6.01831 12.5442 6.05359 12.8074L6.23161 14.1353L4.69088 15.0248L5.72558 16.817L7.26916 15.9258L8.33081 16.7478C8.76211 17.0818 9.23922 17.3577 9.75042 17.5646L11 18.0704V20H13V18.0704L14.2496 17.5646C14.7526 17.361 15.2225 17.0906 15.6484 16.7638L16.7086 15.9502L18.2584 16.845L19.3251 14.9974L17.7782 14.1044L17.9497 12.7818C17.9828 12.5268 18 12.2659 18 12C18 11.7251 17.9816 11.4555 17.9463 11.1922L17.7682 9.86418L19.309 8.97459L18.2743 7.18245L16.7304 8.0738L15.6688 7.2519C15.2376 6.91807 14.7606 6.64227 14.2496 6.4354ZM15 4.58153V3C15 2.44772 14.5523 2 14 2H9.99999C9.44771 2 8.99999 2.44772 8.99999 3V4.58151C8.32774 4.85363 7.7009 5.21447 7.13398 5.64951L5.87567 4.92302C5.39737 4.64688 4.78578 4.81076 4.50964 5.28905L2.44295 8.86866C2.16681 9.34696 2.33068 9.95855 2.80897 10.2347L4.06683 10.9609C4.02272 11.301 3.99997 11.6479 3.99997 12C3.99997 12.3639 4.02427 12.7221 4.07132 13.0731L2.82486 13.7928C2.34656 14.0689 2.18269 14.6805 2.45883 15.1588L4.49353 18.683C4.76967 19.1613 5.38126 19.3252 5.85955 19.049L7.10634 18.3292C7.6805 18.7738 8.3168 19.1419 8.99999 19.4185V21C8.99999 21.5523 9.44771 22 9.99999 22H14C14.5523 22 15 21.5523 15 21V19.4185C15.6722 19.1464 16.299 18.7855 16.866 18.3505L18.1244 19.0771C18.6027 19.3532 19.2143 19.1893 19.4904 18.711L21.5571 15.1314C21.8333 14.6531 21.6694 14.0415 21.1911 13.7654L19.9331 13.0391C19.9772 12.699 20 12.3521 20 12C20 11.6359 19.9756 11.2775 19.9285 10.9263L21.175 10.2066C21.6533 9.9305 21.8172 9.31891 21.541 8.84062L19.5063 5.31642C19.2302 4.83813 18.6186 4.67425 18.1403 4.9504L16.8931 5.67045C16.3191 5.22605 15.683 4.858 15 4.58153Z"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4142 10.5858C12.6332 9.80474 11.3668 9.80474 10.5858 10.5858C9.80474 11.3668 9.80474 12.6332 10.5858 13.4142C10.9763 13.8047 10.9763 14.4379 10.5858 14.8284C10.1953 15.219 9.5621 15.219 9.17157 14.8284C7.60948 13.2663 7.60948 10.7337 9.17157 9.17157C10.7337 7.60948 13.2663 7.60948 14.8284 9.17157C16.3905 10.7337 16.3905 13.2663 14.8284 14.8284C14.4379 15.219 13.8047 15.219 13.4142 14.8284C13.0237 14.4379 13.0237 13.8047 13.4142 13.4142C14.1953 12.6332 14.1953 11.3668 13.4142 10.5858Z"
      ></path>
    </Icon>
  );
};

export default SettingsOutlineIcon;
/* eslint-enable max-len */
