import { styled, Typography } from '@mui/material';
import { Text } from '@visx/text';
import { Line } from '@visx/shape';

export const TickLabel = styled(Text)(({ theme }) => ({
  ...theme.typography['label-small-tight'],
  fill: theme.color['neutral-50']
}));

export const TickLabelBold = styled(Text)(({ theme }) => ({
  ...theme.typography['label-small-bold-tight'],
  fill: theme.color['neutral-50']
}));

export const TruncatedTypography = styled(Typography)(({ theme }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  width: '100%',
  textAlign: 'right',
  color: theme.color['neutral-50'],
  lineHeight: '14px'
}));

export const TickLine = styled(Line)(({ theme }) => ({
  stroke: theme.color['neutral-25']
}));

export const AxisLine = styled(Line)(({ theme }) => ({
  stroke: theme.color['neutral-25'],
  strokeWidth: 2
}));

export const SMALL_TICK_LENGTH = 4;
export const TICK_LENGTH = 6;

export const CHART_TOP_OFFSET = 20;
