// ----- REACT -----
import { useMemo } from 'react';

// ----- REDUX -----
import { useAppSelector } from './hooks/useRedux';
import { authSelectors } from './features/auth/authSlice';
import { grayzoneSelectors } from './features/grayzone/grayzoneSlice';

// ----- OURS -----
import AuthView from './features/auth';
import GrayzoneView from './features/grayzone';
import ConfigureView from './features/grayzone/containers/ConfigureView';

// ----- SENTRY -------
import * as Sentry from '@sentry/react';

// ---- AMPLITUDE ----
import amplitude from 'amplitude-js';
amplitude.getInstance().init('83a3174407c0363c71734e4211631f80');

// ----- APP -----
const App = () => {
  // ----- Selectors -----
  const viewConfigurationSelectors = useMemo(() => grayzoneSelectors.viewConfigurations.getSelectors(), []);
  const token = useAppSelector(authSelectors.selectToken);
  const configuredViewIds = useAppSelector((state) => viewConfigurationSelectors.selectIds(state.grayzone.viewConfigurations));

  if (!token) {
    return <AuthView />;
  } else if (configuredViewIds.length === 0) {
    return (
      <div style={{ height: '100vh', width: '100vw' }}>
        <ConfigureView />
      </div>
    );
  } else {
    return (
      <div style={{ height: '100vh', width: '100vw' }}>
        <GrayzoneView />
      </div>
    );
  }
};

export default Sentry.withProfiler(App);
