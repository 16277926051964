import { useMemo, memo, useRef, useState, useLayoutEffect } from 'react';

// ----- MUI -----
import { Box, Link, styled, Typography, useTheme } from '@mui/material';

// ----- Ours -----
import { DATE_FORMATTER } from './constants';

// ----- Types -----
import { ACLEDdataType, FeedDataType, GDELTdataType } from '../../../../types/grayzone';

const ScrollContainer = styled(Box)<{ x: number; y: number }>(({ theme, x, y }) => ({
  width: '250px',
  maxHeight: '300px',
  position: 'absolute',
  left: `${x + 4}px`,
  top: `${y + 4}px`,
  boxShadow: theme['box-shadow'].overlay,
  backgroundColor: theme.color['neutral-0'],
  borderRadius: theme.borderRadius.borderRadius,
  padding: theme.spacings.small,
  zIndex: 10000
}));
const Container = styled(Box)(({ theme }) => ({
  width: '250px',
  height: '100%',
  maxHeight: '300px',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '4px',
    marginLeft: theme.spacing(1),
    overflow: 'visible',
    borderRadius: theme.borderRadius.borderRadiusSemiSharp
  },
  '&::-webkit-scrollbar-thumb': {
    overflow: 'hidden',
    borderRadius: theme.borderRadius.borderRadiusSemiSharp,
    background: theme.color['neutral-50']
  },
  'mask-image': 'linear-gradient(to top, transparent, black),linear-gradient(to left, transparent 4px, black 4px)',
  'mask-size': '100% 20000px',
  'mask-position': 'left bottom',
  '-webkit-mask-image': 'linear-gradient(to top, transparent, black), linear-gradient(to left, transparent 4px, black 4px)',
  '-webkit-mask-size': '100% 20000px',
  '-webkit-mask-position': 'left bottom',
  transition: 'mask-position 0.2s, -webkit-mask-position 0.2s',
  '&:hover': { '-webkit-mask-position': 'left top' }
}));
const Title = styled(Typography)(({ theme }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  ...theme.typography['label-small-bold']
}));
const Item = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacings['x-small']
}));

type Props = {
  feedData: (GDELTdataType | ACLEDdataType)[];
  containerWidth: number;
  containerLeft: number;
  highlightedNewsDate: { date: string; x: number; y: number };
};
const FeedItemTooltip = ({ feedData, containerWidth, containerLeft, highlightedNewsDate }: Props) => {
  const theme = useTheme();
  const ref = useRef<HTMLDivElement | null>(null);
  const [boundedX, setBoundedX] = useState(highlightedNewsDate.x);
  const [boundedY, setBoundedY] = useState(highlightedNewsDate.y);

  useLayoutEffect(() => {
    // for when switching selected feed item without changing to null in between
    setBoundedX(highlightedNewsDate.x);
    setBoundedY(highlightedNewsDate.y);
  }, [highlightedNewsDate.x, highlightedNewsDate.y]);

  useLayoutEffect(() => {
    // moves tooltip to left side if out of window
    if (ref.current) {
      const dims = ref.current.getBoundingClientRect();

      if (dims.left + dims.width >= containerWidth + containerLeft) {
        setBoundedX(highlightedNewsDate.x - dims.width - 8); // subtract initial padding and then another padding
      }

      const height = window.innerHeight;
      if (dims.bottom >= height) {
        setBoundedY(highlightedNewsDate.y - dims.height - 8); // subtract initial padding and then another padding
      }
    }
  }, [ref.current, boundedX, boundedY, containerWidth, containerLeft]);

  const dataToShow = useMemo(() => {
    return feedData.filter((item) => {
      if (item.type === FeedDataType.ACLED) {
        return item.date_event === highlightedNewsDate?.date;
      } else {
        return item.date_news === highlightedNewsDate?.date;
      }
    });
  }, [feedData, highlightedNewsDate]);

  if (highlightedNewsDate === null || dataToShow.length === 0) return null;

  return (
    <ScrollContainer ref={ref} x={boundedX} y={boundedY}>
      <Container>
        <Box marginBottom={theme.spacings.small}>
          <Typography variant="label-small" display="span" color={theme.color['neutral-75']}>
            News articles related to
            <Typography variant="label-small-bold" display="span" color={theme.color['neutral-75']}>
              {` ${DATE_FORMATTER(new Date(highlightedNewsDate?.date).valueOf())}`}
            </Typography>
          </Typography>
        </Box>

        {dataToShow.map((item) => {
          if (item.type === FeedDataType.ACLED) {
            return (
              <Item>
                <Typography variant="label-small" display={'block'} color={theme.color['neutral-75']}>
                  {item.event_type} - {item.sub_event_type}
                </Typography>
                <Title>{item.event_description}</Title>
              </Item>
            );
          } else {
            return (
              <Item>
                <Title>{item.title}</Title>
                <Link href={item.url} variant="label-small">
                  {item.domain_text}
                </Link>
              </Item>
            );
          }
        })}
      </Container>
    </ScrollContainer>
  );
};

export default memo(FeedItemTooltip);
