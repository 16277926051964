import { Components } from '@mui/material';
import type {} from '@mui/lab/themeAugmentation';
import premiseTheme from './premiseTheme';
import { CaretUpOutlineIcon } from './Icons';

const MuiTextField: Components['MuiTextField'] = {
  styleOverrides: {
    root: () => ({
      ...premiseTheme.typography.label
    })
  }
};

const MuiInputLabel: Components['MuiInputLabel'] = {
  styleOverrides: {
    root: () => ({
      ...premiseTheme.typography.label,
      paddingLeft: premiseTheme.spacings['x-small']
    })
  }
};

const MuiDialog: Components['MuiDialog'] = {
  styleOverrides: {
    root: () => ({
      ...premiseTheme.typography.label
    })
  }
};

const MuiInput: Components['MuiInput'] = {
  styleOverrides: {
    root: () => ({
      paddingLeft: premiseTheme.spacings['x-small'],
      '&::after': {
        borderBottom: `2px solid ${premiseTheme.color['neutral-100']}`
      }
    })
  }
};

const MuiSelect: Components['MuiSelect'] = {
  styleOverrides: {
    select: () => ({
      ...premiseTheme.typography.label,
      paddingLeft: premiseTheme.spacings['xx-small']
    })
  }
};

const MuiAutocomplete: Components['MuiAutocomplete'] = {
  styleOverrides: {
    popper: () => ({
      ...premiseTheme.typography.label
    })
  }
};

const MuiChip: Components['MuiChip'] = {
  styleOverrides: {
    root: () => ({
      ...premiseTheme.typography['label-small']
    })
  }
};

const MuiDatePicker: Components['MuiDatePicker'] = {
  styleOverrides: {
    root: () => ({
      ...premiseTheme.typography['label-small']
    })
  }
};

const MuiButton: Components['MuiButton'] = {
  variants: [
    {
      props: { variant: 'text' },
      style: {
        color: premiseTheme.color['brand-primary']
      }
    },
    {
      props: { variant: 'contained' },
      style: {
        color: premiseTheme.color['neutral-0']
      }
    },
    {
      props: { variant: 'outlined' },
      style: {
        color: premiseTheme.color['brand-primary']
      }
    }
  ],
  styleOverrides: {
    root: () => ({
      ...premiseTheme.typography['label-small-bold'],
      borderRadius: premiseTheme.borderRadius.borderRadiusPill,
      boxShadow: 'none'
    })
  }
};

const MuiCircularProgress: Components['MuiCircularProgress'] = {
  defaultProps: {
    thickness: 8
  }
};

const MuiTab: Components['MuiTab'] = {
  styleOverrides: {
    root: () => ({
      ...premiseTheme.typography['label']
    })
  }
};

const MuiAccordion: Components['MuiAccordion'] = {
  defaultProps: {
    disableGutters: true,
    elevation: 0
  },
  styleOverrides: {
    root: () => ({
      backgroundColor: 'transparent'
    })
  }
};
const MuiAccordionSummary: Components['MuiAccordionSummary'] = {
  defaultProps: {
    expandIcon: <CaretUpOutlineIcon size={premiseTheme.iconScale['x-small']} />
  },
  styleOverrides: {
    root: () => ({
      ...premiseTheme.typography['label-small-bold'],
      flexDirection: 'row-reverse',
      padding: '0px',
      minHeight: '32px',
      '& .MuiAccordionSummary-content': {
        marginLeft: premiseTheme.spacings.small
      }
    })
  }
};

const MuiAccordionDetails: Components['MuiAccordionDetails'] = {
  styleOverrides: {
    root: () => ({
      padding: '0px 16px 16px'
    })
  }
};

const MuiCard: Components['MuiCard'] = {
  variants: [
    {
      props: { variant: 'elevation' },
      style: {
        boxShadow: premiseTheme['box-shadow'].card,
        borderRadius: premiseTheme.borderRadius.borderRadiusSemiSharp
      }
    },
    {
      props: { variant: 'outlined' },
      style: {
        border: `1px solid ${premiseTheme.color['neutral-25']}`,
        borderRadius: premiseTheme.borderRadius.borderRadiusSemiSharp
      }
    }
  ]
};

const MuiLink: Components['MuiLink'] = {
  styleOverrides: {
    root: () => ({
      transition: 'ease-in-out 0.1s all',
      color: premiseTheme.color['brand-primary-125'],
      '&:focus': {
        outline: 'none !important',
        padding: '0px 1px',
        borderRadius: premiseTheme.borderRadius.borderRadiusSemiSharp,
        boxShadow: premiseTheme['box-shadow'].focus,
        color: premiseTheme.color['brand-primary-150']
      }
    })
  }
};

const MuiMenuItem: Components['MuiMenuItem'] = {
  styleOverrides: {
    root: () => ({
      ...premiseTheme.typography.label,
      '&:active': {
        backgroundColor: premiseTheme.color['brand-primary-150'],
        color: premiseTheme.color['neutral-0']
      }
    })
  }
};

const MuiAppBar: Components['MuiAppBar'] = {
  styleOverrides: {
    root: () => ({
      boxShadow: premiseTheme['box-shadow']['card-light']
    })
  }
};

const MuiTooltip: Components['MuiTooltip'] = {
  styleOverrides: {
    tooltip: () => ({
      padding: '8px 12px',
      background: 'white',
      boxShadow: premiseTheme['box-shadow'].overlay,
      borderRadius: premiseTheme.borderRadius.borderRadius,
      ...premiseTheme.typography['label-small-tight'],
      color: premiseTheme.color['neutral-75'],
      pointerEvents: 'none'
    })
  }
};

const overrides: Components = {
  MuiTextField,
  MuiInput,
  MuiInputLabel,
  MuiDialog,
  MuiSelect,
  MuiAutocomplete,
  MuiChip,
  MuiDatePicker,
  MuiButton,
  MuiCircularProgress,
  MuiTab,
  MuiAccordion,
  MuiAccordionSummary,
  MuiAccordionDetails,
  MuiCard,
  MuiLink,
  MuiAppBar,
  MuiMenuItem,
  MuiTooltip
};

export default overrides;
