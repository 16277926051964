import * as React from 'react';
import type { IconProps } from './Icon';

function Pictogram(props: IconProps): JSX.Element {
  const { children, title, ...restProps } = props;
  const passThroughProps = {
    fill: 'none',
    height: 64,
    width: 64,
    ...restProps,
  };
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 64,
        height: 64,
      }}
    >
      <svg data-baseweb="icon" {...passThroughProps}>
        {title ? <title>{title}</title> : null}
        {children}
      </svg>
    </div>
  );
}

export default Pictogram;
