import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { FeedDataType, GDELTKeywordQueryDataItem } from 'types/grayzone';
import { v4 as uuid_v4 } from 'uuid';

type GetGDELTKeywordTimelineApiArg = {
  query?: string;
  sourcelang?: string;
  mode: 'TimelineVolRaw' | 'TimelineVol' | 'TimelineTone' | 'TimelineVolInfo';
  startdatetime: string;
  enddatetime: string;
  sourcecountry?: string;
  format?: 'html' | 'json';
};
type GetGDELTKeywordTimelineApiResponse = {
  query_details?: { title: string; date_resolution: string };
  timeline?: { series: string; data: GDELTKeywordQueryDataItem[] }[];
};
type GetGDELTKeywordContentApiArg = {
  query?: string;
  sourcelang?: string;
  startdatetime: string;
  enddatetime: string;
  maxrecords?: number;
  sourcecountry?: string;
  mode: 'ArtList';
  format?: 'html' | 'json';
};
type GetGDELTKeywordContentApiResponse = {
  articles: { url: string; url_mobile: string; title: string; seendate: string; socialimage: string; domain: string; language: string; sourcecountry: string }[];
};

const baseQuery = fetchBaseQuery({
  baseUrl: `https://api.gdeltproject.org/api/v2/doc/doc`
  //   prepareHeaders: async (headers, { getState }) => {
  //     const state = (await getState()) as { auth: AuthState };
  //     const token = state.auth.token;

  //     // If we have a token set in state, let's assume that we should be passing it.
  //     if (token) {
  //       if (!headers.get('X-Auth-Token')) {
  //         headers.set('X-Auth-Token', token);
  //       }
  //       headers.set('Content-Type', 'application/json');
  //     }
  //     return headers;
  //   }
});

export const gdeltApi = createApi({
  reducerPath: 'gdeltApi',
  baseQuery: baseQuery,
  endpoints: (build) => ({
    getGDELTKeywordTimeline: build.query<GetGDELTKeywordTimelineApiResponse, GetGDELTKeywordTimelineApiArg>({
      query: (queryArg) => ({
        url: '',
        method: 'GET',
        params: queryArg
      }),
      transformResponse: (result: GetGDELTKeywordTimelineApiResponse) => {
        if (result.timeline?.[0]?.data) {
          const transformed = {
            ...result,
            timeline: result.timeline.map((timeline) => ({
              ...timeline,
              data: timeline.data.map((item) => {
                const date = `${new Date(+item.date.substring(0, 4), +item.date.substring(4, 6) - 1, +item.date.substring(6, 8), 0, 0, 0, 0).toISOString()}`;

                return {
                  ...item,
                  id: uuid_v4(),
                  date: date,
                  toparts: item.toparts.map((article) => ({ ...article, date: date, type: FeedDataType.EventQueryArticle }))
                };
              })
            }))
          };

          return transformed as GetGDELTKeywordTimelineApiResponse;
        } else {
          return result;
        }
      }
    }),
    getGDELTKeywordContent: build.query<GetGDELTKeywordContentApiResponse, GetGDELTKeywordContentApiArg>({
      query: (queryArg) => ({
        url: '',
        method: 'GET',
        params: queryArg
      })
    })
  })
});
