import { memo } from 'react';
import { styled } from '@mui/material';
import { TooltipWithBounds } from '@visx/tooltip';
import useTimelineTooltip from '../../../../hooks/useTimelineTooltip';
import { v4 as uuid_v4 } from 'uuid';

const Tooltip = styled(TooltipWithBounds)(({ theme }) => ({
  padding: '8px 12px',
  background: 'white',
  boxShadow: theme['box-shadow'].overlay,
  borderRadius: theme.borderRadius.borderRadius,
  ...theme.typography['label-small-tight'],
  color: theme.color['neutral-75'],
  position: 'absolute',
  pointerEvents: 'none'
}));

type Props = {
  grabLeftOffset: () => number;
};
const TimelineTooltip = ({ grabLeftOffset }: Props) => {
  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData } = useTimelineTooltip();

  if (!tooltipOpen) return null;

  // left is relative to parent div, but we pass x relative to screen aka client. just subtract offset
  const leftOffset = grabLeftOffset();

  return (
    <Tooltip key={`tooltip-${uuid_v4()}`} left={tooltipLeft - leftOffset} top={tooltipTop} unstyled={false}>
      {tooltipData}
    </Tooltip>
  );
};

export default memo(TimelineTooltip);
