// need offsets for labels for axes
export const LEFT_OFFSET = 150; // where the right side of main graph ends
export const RIGHT_OFFSET = 100; // where main graph starts

export const DATE_FORMATTER = (v: number, monthYear = false) => {
  const d = new Date(v);

  const year = d.getFullYear();
  const month = (1 + d.getMonth()).toString();
  const day = d.getDate().toString();

  if (monthYear) return month + '/' + day;
  return month + '/' + day + '/' + year;
};

export const ONE_DAY = 24 * 60 * 60 * 1000;
export const GET_NEXT_DATE_INTERVAL = (current: number, intervalInDays: number, previous?: boolean) => {
  if (previous) return current - intervalInDays * ONE_DAY;
  return current + intervalInDays * ONE_DAY;
};
export const GET_DAYS_BETWEEN = (firstDate: number, secondDate: number, inclusive = false): number => {
  if (firstDate === secondDate) return 0;
  if (secondDate < firstDate) {
    return GET_DAYS_BETWEEN(secondDate, firstDate, inclusive);
  }
  const diff = secondDate - firstDate;
  const diffDays = Math.ceil(diff / ONE_DAY);
  return inclusive ? diffDays + 1 : diffDays;
};

const NUMBER_ABBREVIATION = ['k', 'm', 'b']; // message me if it ever hits trillion xD
export const GET_ABBREVIATED_NUM = (value: number) => {
  let thousands = 0;
  while (value >= 1000) {
    value /= 1000;
    thousands++;
  }
  if (thousands === 0) return Math.round(value);

  if (thousands > NUMBER_ABBREVIATION.length) {
    console.warn('Abbreviation not defined, returning non-abbreviated value');
    return Math.round(value);
  }

  return `${Math.round(value)}${NUMBER_ABBREVIATION[thousands - 1]}`;
};
