import { useMemo, useState, memo, useCallback } from 'react';

// ----- Redux -----
import { useAppSelector } from '../../../../../hooks/useRedux';
import { grayzoneSelectors } from '../../../grayzoneSlice';

// ----- MUI -----
import { Accordion, AccordionDetails, AccordionSummary, Box, styled, Typography, useTheme } from '@mui/material';

// ----- PDS -----
import { HideOutlineIcon, ShowOutlineIcon } from '../../../../../PremiseDesign/Icons';

// ----- Ours -----
import TimelineConfig from './TimelineConfig';
import FeedConfig from './FeedConfig';
import useGrid from '../../../../../hooks/useGrid';

// ----- Types -----
import { Widget, WidgetType } from '../../../../../types/grayzone';

export const SectionLabel = styled(Typography)(({ theme }) => ({
  ...theme.typography['label-small'],
  color: theme.color['neutral-75']
}));
export const SectionedBox = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.color['neutral-25']}`,
  paddingBottom: theme.spacings['x-small'],
  marginBottom: theme.spacings['x-small']
}));

type WidgetAccordionProps = {
  widget: Widget;
  children?: React.ReactNode;
};
const WidgetAccordion = ({ widget, children }: WidgetAccordionProps) => {
  const theme = useTheme();
  const { hideItem, showItem, isItemHidden } = useGrid();

  const [isHover, setIsHover] = useState(false);
  const [hidden, setHidden] = useState(isItemHidden(widget.id));

  const onMouseEnter = useCallback(() => {
    setIsHover(true);
  }, [setIsHover, isHover]);
  const onMouseLeave = useCallback(() => {
    setIsHover(false);
  }, [setIsHover, isHover]);

  const onToggleEye = useCallback(
    (e) => {
      e.stopPropagation();
      // TODO: hide widget?
      if (!hidden) {
        hideItem(widget.id);
        setHidden(true);
      } else {
        showItem(widget.id, widget.type);
        setHidden(false);
      }
    },
    [hidden, hideItem, setHidden, showItem]
  );

  const Icon = hidden ? ShowOutlineIcon : HideOutlineIcon;
  const title = useMemo(() => {
    switch (widget.type) {
      case WidgetType.FEED: {
        return widget.name !== 'News Feed' ? `News Feed: ${widget.name}` : widget.name;
      }
      case WidgetType.TIMELINE: {
        return widget.name !== 'Timeline' ? `Timeline: ${widget.name}` : widget.name;
      }
      case WidgetType.MAP: {
        return widget.name !== 'Map' ? `Map: ${widget.name}` : widget.name;
      }
      case WidgetType.PHOTO: {
        return widget.name !== 'Photo' ? `Photo: ${widget.name}` : widget.name;
      }
    }
  }, [widget.name, widget.type]);

  return (
    <Accordion defaultExpanded={false} disableGutters>
      <AccordionSummary onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <Box display="flex" flexDirection="row" alignItems="center" width="100%">
          <Box flex={1}>
            <Typography noWrap maxWidth="220px">
              {title}
            </Typography>
          </Box>
          <Box
            sx={{
              opacity: isHover ? '1' : '0',
              transition: 'opacity 0.15s ease-in-out'
            }}
            onClick={onToggleEye}
          >
            <Icon size={theme.iconScale.small} color={theme.iconColor['neutral-75']} />
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

const WidgetConfigDrawerContent = () => {
  // ----- Selectors -----
  const primaryViewConfiguration = useAppSelector(grayzoneSelectors.viewConfigurations.selectPrimaryViewConfiguration);
  const primaryViewWidgets = useAppSelector((state) => grayzoneSelectors.widgets.selectAllWidgetsByGridLayoutId(state, primaryViewConfiguration?.gridLayoutId ?? ''));

  // per phase 1: only allow one of each widget
  // to keep code compatability for future iteration, just check for each widget
  // const widgets = useMemo(() => {
  //   const out: {
  //     [WidgetType.FEED]: undefined | Widget;
  //     [WidgetType.MAP]: undefined | Widget;
  //     [WidgetType.PHOTO]: undefined | Widget;
  //     [WidgetType.TIMELINE]: undefined | Widget;
  //   } = {
  //     [WidgetType.FEED]: undefined,
  //     [WidgetType.MAP]: undefined,
  //     [WidgetType.PHOTO]: undefined,
  //     [WidgetType.TIMELINE]: undefined
  //   };
  //   primaryViewWidgets.forEach((widget) => {
  //     if (out[widget.type] === undefined) {
  //       out[widget.type] = widget;
  //     }
  //   });
  //   return out;
  // }, [primaryViewWidgets]);

  return (
    <Accordion defaultExpanded={true} disableGutters>
      <AccordionSummary>Widgets</AccordionSummary>
      <AccordionDetails>
        {primaryViewWidgets.map((widget) => {
          switch (widget.type) {
            case WidgetType.FEED:
              return (
                <WidgetAccordion widget={widget}>
                  <FeedConfig />
                </WidgetAccordion>
              );
            case WidgetType.TIMELINE:
              return (
                <WidgetAccordion widget={widget}>
                  <TimelineConfig widget={widget} />
                </WidgetAccordion>
              );
              break;
            case WidgetType.MAP:
              return <WidgetAccordion widget={widget}></WidgetAccordion>;

            case WidgetType.PHOTO:
              return <WidgetAccordion widget={widget}></WidgetAccordion>;

            default:
              return <WidgetAccordion widget={widget}></WidgetAccordion>;
          }
        })}
      </AccordionDetails>
    </Accordion>
  );
};

export default memo(WidgetConfigDrawerContent);
