// ----- REACT -----
import { memo, useCallback, useEffect, useRef } from 'react';

// ----- REDUX -----
import { useAppDispatch } from '../../../hooks/useRedux';
import { grayzoneActions } from '../grayzoneSlice';
import { grayzoneEndpoints } from '../../../api/grayzone';
import { store } from '../../../store';

// ----- MODULES -----
import { Typography, Button, Box, Card, CircularProgress, useTheme } from '@mui/material';
import queryString from 'query-string';
import { v4 as uuid_v4 } from 'uuid';

// ----- OURS -----
import FileUploader from '../../../components/FileUploader';
import { PremiseLogo } from '../../../PremiseDesign/Icons';
import { ajv } from '../../../helpers/ajvValidator';
import { generateViewState, generateViewStateImport, viewAdapter } from '../../../helpers/grayzone';
import ViewConfig from './ViewConfig';
import amplitude from 'amplitude-js';

// ----- HOOKS -----
import useAppSnackbar from '../../../hooks/useAppSnackbar';

// ----- CONSTANTS -----
// const MAX_DROPDOWN_HEIGHT = '200px';

// ----- STYLES -----
import { FlexCentered } from '../../../styles';

// ----- TYPES -----
import { GrayzoneSchema, ViewConfiguration, ViewExport } from '../../../types/grayzone';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

// ----- COMPONENT -----

type Props = {
  fetchAccountViews?: boolean;
};
const ConfigureView = ({ fetchAccountViews = true }: Props) => {
  // Material
  const theme = useTheme();
  // ----- Redux -----
  const dispatch = useAppDispatch();
  const { enqueue } = useAppSnackbar();

  // ----- Queries -----
  const getViewsQueryResult = grayzoneEndpoints.getView.useQuery({});

  // ----- Mutations -----
  const [triggerPostViewAccessMutation, postViewAccessMutationResult] = grayzoneEndpoints.postViewAccess.useMutation();
  const [triggerPostViewMutation] = grayzoneEndpoints.postView.useMutation();

  // ----- Refs -----
  const submitRef = useRef<(() => void) | null>(null);

  // ----- Helpers -----

  const validateForm = useCallback((newView: ViewConfiguration) => {
    const validate = ajv.getSchema<ViewConfiguration>(GrayzoneSchema.VIEW_CONFIGURATION);
    if (!validate) {
      console.error('Could not fetch provided schema.');
      return false;
    }
    const valid = validate(newView);
    if (!valid) {
      enqueue(`${validate.errors?.[0]?.message?.slice(0, 1).toUpperCase()}${validate.errors?.[0]?.message?.slice(1)}`, { variant: 'warning' });
      console.warn('VALIDATION WARNING: ', validate.errors, 'JSON: ', newView);
      return false;
    }

    return true;
  }, []);

  const uploadView = async (viewId: string) => {
    const exportState = generateViewState(store.getState().grayzone, viewId);
    try {
      await triggerPostViewMutation({ body: { viewId: viewId, viewState: exportState } }).unwrap();
      enqueue('View successfully uploaded', { variant: 'success', preventDuplicate: false });
      enqueue('View configured', { variant: 'success' });
    } catch (e) {
      enqueue('Something went wrong and view was not uploaded', { variant: 'error' });
    }
  };

  // ----- Handlers -----

  const handleClickedConfigure = (newView: ViewConfiguration) => {
    if (!validateForm(newView)) {
      return;
    }
    const viewId = uuid_v4();
    dispatch(grayzoneActions.configureNewView({ config: newView, viewId: viewId }));
    uploadView(viewId);
    enqueue('View configured', { variant: 'success' });
  };

  const handleFileLoaded = (data: ViewExport) => {
    try {
      dispatch(grayzoneActions.importExternalViewState([generateViewStateImport(data)]));
      enqueue(`${data.viewConfiguration.name} successfuly added`, { variant: 'success' });
      amplitude.getInstance().logEvent('Imported View');
    } catch (error) {
      enqueue((error as Error).message, { variant: 'error' });
      console.error(error);
    }
  };

  const extractLinkedView = useCallback(async () => {
    if (window.location.search) {
      const { sid }: { sid?: string } = queryString.parse(window.location.search);

      if (sid) {
        try {
          await triggerPostViewAccessMutation({ body: { shareId: sid } }).unwrap();
          window.history.replaceState(undefined, 'Main Grayzone View', window.location.origin);
        } catch (e) {
          const error = e as FetchBaseQueryError;
          if (error.status === 401) {
            enqueue('You dont have the scope necessary to access the linked view');
          } else {
            enqueue('Could not fetch the linked view, please try again');
          }
          console.warn(e);
          window.history.replaceState(undefined, 'Main Grayzone View', window.location.origin);
        }
      }
    }
  }, []);

  // ----- Effects -----
  useEffect(() => {
    if (!getViewsQueryResult.isFetching && getViewsQueryResult.data) {
      const views = getViewsQueryResult.data;
      if (views.ids.length > 0) {
        dispatch(grayzoneActions.importExternalViewState(viewAdapter.defaultSelectors.selectAll(views)));
        enqueue('Views successfully loaded', { variant: 'success' });
      } else {
        enqueue('No saved views found, please configure one.', { variant: 'info' });
      }
    } else if (getViewsQueryResult.isError) {
      enqueue('No saved views found, please configure one.', { variant: 'info' });
    }
  }, [getViewsQueryResult]);

  useEffect(() => {
    extractLinkedView();
  }, []);

  // ----- Return Component -----

  if (fetchAccountViews && (getViewsQueryResult.isUninitialized || getViewsQueryResult.isFetching || postViewAccessMutationResult.isLoading)) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
        <Card variant="outlined">
          <Box padding={theme.spacing(4)}>
            <Typography variant="h2">Fetching your views</Typography>
            <FlexCentered style={{ margin: '20px' }}>
              <CircularProgress />
            </FlexCentered>
          </Box>
        </Card>
      </Box>
    );
  } else {
    return (
      <FlexCentered style={{ width: '100%', height: '100%', flexDirection: 'column' }} id="configure-view">
        <FlexCentered style={{ width: '30%', height: '70%', flexDirection: 'column', justifyContent: 'space-between', minWidth: 400 }}>
          <PremiseLogo />

          <Typography variant="display-4">Configure Grayzone</Typography>

          <Box height="70%" width="100%" display="flex" justifyContent="center">
            <ViewConfig
              formType="new"
              currConfiguration={undefined}
              submitRef={submitRef}
              onSubmit={handleClickedConfigure}
              style={{ height: '100%', width: '100%', gap: '20px', padding: '0px 12px' }}
            />
          </Box>
          <Box display="flex" flexDirection="row" gap="10px">
            <Button
              onClick={() => {
                submitRef.current?.();
              }}
              variant="outlined"
              size="large"
            >
              Configure
            </Button>
            <FileUploader accept="application/json" onLoad={handleFileLoaded} schema={GrayzoneSchema.VIEW_EXPORT}>
              <Button variant="outlined" size="large" component="span">
                Import View
              </Button>
            </FileUploader>
          </Box>
        </FlexCentered>
      </FlexCentered>
    );
  }
};

export default memo(ConfigureView);
