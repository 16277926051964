// ----- REACT -----
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

// ----- CSS -----
// react-grid-layout css, needs to be above index.css
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

// leaflet css
import 'leaflet/dist/leaflet.css';

import './index.css';
import * as serviceWorker from './serviceWorker';

// ----- REDUX -----
import { store } from './store';
import { Provider } from 'react-redux';

// ----- MODULES -----
import { SnackbarProvider } from 'notistack';
import { LicenseInfo } from '@mui/x-license-pro';

// ----- OURS -----
import { ThemeProvider } from '@mui/material/styles';
import theme from './PremiseDesign';
import App from './App';

// ----FULL STORY-----
import * as FullStory from '@fullstory/browser';
import SentryFullStory from '@sentry/fullstory';

// ----- Pau -----
LicenseInfo.setLicenseKey('fb297026f8bf46559abe90ceda453d88T1JERVI6NDIxMjYsRVhQSVJZPTE2ODIxMTA4OTgwMDAsS0VZVkVSU0lPTj0x');
FullStory.init({ orgId: 'o-1BCJWN-na1' });

Sentry.init({
  dsn: 'https://6187e87f85864941962aa497218f6f07@o1255165.ingest.sentry.io/6600022',
  integrations: [new BrowserTracing(), new SentryFullStory('premise')],
  environment: window.location.host === 'grayzone.premise.com' ? 'prod' : 'dev',

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // https://forum.sentry.io/t/resizeobserver-loop-limit-exceeded/8402/5
  ignoreErrors: ['ResizeObserver loop limit exceeded']
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }} preventDuplicate>
          <App />
        </SnackbarProvider>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
