/* eslint-disable max-len */
// BASEUI-GENERATED-REACT-ICON
// DO NOT EDIT THIS FILE DIRECTLY, SEE README.md
import * as React from 'react';
import type { IconProps } from '../Icon';
import Icon from '../Icon';

const BoundaryL1FillIcon = (props: IconProps): JSX.Element => {
  const {
    title = 'Boundary L1 Fill Icon',
    size,
    color,
    onClick,
    ...restProps
  } = props;
  return (
    <Icon
      title={title}
      size={size}
      color={color}
      onClick={onClick}
      {...restProps}
    >
      <rect x="4" y="4" width="16" height="16" rx="2"></rect>
    </Icon>
  );
};

export default BoundaryL1FillIcon;
/* eslint-enable max-len */
