import { ReactNode, useEffect, useMemo } from 'react';

// ----- MUI -----
import { useTheme } from '@mui/material';

// ----- Modules -----
import { Responsive as ResponsiveGridLayout } from 'react-grid-layout';
import { withSize } from 'react-sizeme';
import { getBreakPointFromWidth } from '../helpers/grayzone';

// ----- Ours -----
import useGrid from '../hooks/useGrid';
import DraggableCorner from '../components/widgets/DraggableCorner';
import { GridLayout } from '../types/grayzone';

type Props = {
  // react-sizeme
  size: {
    width: never;
    height: number | null;
  };
  children: ReactNode;
  gridLayout?: GridLayout;
};

const Grid = ({ size, children, gridLayout }: Props) => {
  const { columns, breakpoints, /* widgetSize ,*/ onLayoutChange, onBreakpointChange, /* onDrop ,*/ onResizeWidget } = useGrid();
  const theme = useTheme();

  // Dynamic rowheight based on container height used for ResponsiveGridLayout
  const rowHeight = useMemo(() => {
    const height = (size.height ?? 0) / 9 - 19;
    return height >= 100 ? height : 100;
  }, [size.height]);

  // ----- Effects -----
  // On mount, make sure initial breakpoint is accurate. RGL doesnt give us access to the current BP until after change, so we have to find it.
  useEffect(() => {
    const bp = getBreakPointFromWidth(breakpoints, size.width);
    onBreakpointChange(bp, columns[bp]);
  }, []);

  return (
    <ResponsiveGridLayout
      style={{ height: '100%' }}
      margin={[parseInt(theme.spacings.small), parseInt(theme.spacings.small)]}
      autoSize
      className="layout"
      layouts={gridLayout?.layouts}
      breakpoints={breakpoints}
      cols={columns}
      rowHeight={rowHeight}
      onLayoutChange={onLayoutChange}
      onBreakpointChange={onBreakpointChange}
      width={size.width ?? 0}
      isBounded={false}
      isDroppable={true}
      compactType="vertical"
      draggableHandle=".grid-item__title"
      // onDrop={onDrop}
      // droppingItem={{ i: '__dropping-item__', ...widgetSize }}
      resizeHandle={() => <DraggableCorner handleAxis={'sw'} />}
      onResizeStop={(a, b, x) => {
        onResizeWidget(x.i);
      }}
    >
      {children}
    </ResponsiveGridLayout>
  );
};

export default withSize({ refreshMode: 'debounce', refreshRate: 60, monitorHeight: true })(Grid);
