import { memo, useCallback, useEffect, useMemo, useState } from 'react';

// ----- Redux -----
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import { grayzoneActions, grayzoneSelectors } from '../../../../../features/grayzone/grayzoneSlice';
import { Update } from '@reduxjs/toolkit';

// ----- MUI -----
import { useTheme } from '@mui/material';

// ----- VISX -----
import { Group } from '@visx/group';
import { Bar } from '@visx/shape';

// ----- Ours -----
import useGrid from '../../../../../hooks/useGrid';

// ----- Modules -----
import { debounce } from 'lodash';

// ----- Types -----
import { ViewConfiguration } from '../../../../../types/grayzone';
import type { ScaleLinear } from 'd3-scale';

type elementIds = 's1' | 's2' | 'bar';
type Props = {
  left: number;
  width: number;
  height: number;
  numDays: number;
  getDateFromX: (x: number) => number;
  getXFromDate: ScaleLinear<number, number>;
};
const TimeScrubber = ({ left, width, height, numDays, getDateFromX, getXFromDate }: Props) => {
  const dispatch = useAppDispatch();
  const { gridLayout } = useGrid();
  const viewConfigurationSelectors = useMemo(() => grayzoneSelectors.viewConfigurations.getSelectors(), []);
  const parentView = useAppSelector((state) => viewConfigurationSelectors.selectById(state.grayzone.viewConfigurations, gridLayout.viewConfigId));
  // no error check needed for parentView, gauranteed

  // TODO: set initial values from dateFilter if exists
  const theme = useTheme();
  const [s1, setS1] = useState(0);
  const [s2, setS2] = useState<number>(width);
  const [draggingElement, setDraggingElement] = useState<elementIds | null>(null);
  // used to find (+/-) offset when dragging the bar to shift range
  const [barClickOrigin, setBarClickOrigin] = useState<{ x: number; s1: number; s2: number }>({ x: 0, s1: 0, s2: 0 });

  const snapSpacing = width / numDays;
  const getSnapValue = (value: number) => {
    const midPoint = snapSpacing / 2;
    if (value < midPoint) {
      return value - (value % snapSpacing);
    } else {
      return value - (value % snapSpacing) + snapSpacing;
    }
  };

  // if date filter is set to undefined (i.e. form/question has changed), reset time scrubber
  useEffect(() => {
    if (parentView?.dateFilter === undefined && s1 !== 0 && s2 !== width) {
      setS1(0);
      setS2(width);
    }
  }, [parentView?.dateFilter]);

  // handle width resize of widget
  useEffect(() => {
    const t1 = parentView?.dateFilter?.[0] ? getXFromDate(parentView.dateFilter[0]) - left : 0;
    const t2 = parentView?.dateFilter?.[1] ? getXFromDate(parentView.dateFilter[1]) - left : width;
    setS1(t1);
    setS2(t2);
  }, [getXFromDate, width]);

  // definitely don't want to render every frame, very heavy
  const debounceUpdateViewConfiguration = useCallback(
    debounce((update) => dispatch(grayzoneActions.updateViewConfiguration(update)), 100),
    []
  );
  useEffect(() => {
    if (s1 === 0 && s2 === width) {
      const update: Update<ViewConfiguration> = {
        id: parentView?.id ?? '',
        changes: {
          dateFilter: undefined
        }
      };
      debounceUpdateViewConfiguration(update);
    } else {
      const d1 = getDateFromX(s1);
      const d2 = getDateFromX(s2);
      const update: Update<ViewConfiguration> = {
        id: parentView?.id ?? '',
        changes: {
          dateFilter: [Math.min(d1, d2), Math.max(d1, d2)]
        }
      };
      debounceUpdateViewConfiguration(update);
    }
  }, [s1, s2]);

  const onMouseDown = (id: elementIds, e?: React.MouseEvent<SVGGElement, MouseEvent>) => {
    setDraggingElement(id);
    if (id === 'bar' && e) {
      setBarClickOrigin({ x: e.clientX ?? 0, s1, s2 });
    }
  };
  const onMouseOut = (e: React.MouseEvent<SVGGElement, MouseEvent>) => {
    e.preventDefault();
    setDraggingElement(null);
  };
  const onMouseDrag = (e: React.MouseEvent<SVGGElement, MouseEvent>) => {
    if (draggingElement === null) return;

    e.preventDefault();
    const CTM = e.currentTarget.getScreenCTM();
    const xWithOffset = getSnapValue(CTM ? (e.clientX - CTM.e) / CTM.a : e.clientX);

    if (xWithOffset < 0 || xWithOffset > width) return;

    if (draggingElement === 's1') {
      if (xWithOffset === s2) return;
      setS1(xWithOffset);
    } else if (draggingElement === 's2') {
      if (xWithOffset === s1) return;
      setS2(xWithOffset);
    } else if (draggingElement === 'bar') {
      const diff = getSnapValue(e.clientX - barClickOrigin.x);

      let newS1 = barClickOrigin.s1 + diff;
      let newS2 = barClickOrigin.s2 + diff;

      if (newS1 < 0 || newS1 > width || newS2 < 0 || newS2 > width) {
        if (newS2 < 0 || newS2 > width) [newS2, newS1] = [newS1, newS2];
        // snap
        if (newS1 < 0) {
          newS2 = newS2 - newS1;
          newS1 = 0;
        } else {
          newS2 = newS2 - (newS1 - width);
          newS1 = width;
        }
      }

      setS1(newS1);
      setS2(newS2);
    }
  };

  const barWidth = useMemo(() => {
    return Math.abs(s2 - s1);
  }, [s1, s2]);

  const cursor = useMemo(() => {
    if (draggingElement === 's1' || draggingElement === 's2') {
      return 'ew-resize';
    } else if (draggingElement === 'bar') {
      return 'move';
    }
  }, [draggingElement]);
  return (
    <Group left={left} width={width} onMouseLeave={(e) => onMouseOut(e)} onMouseUp={(e) => onMouseOut(e)} onMouseMove={onMouseDrag}>
      <Bar x={0} width={width} y1={1} height={height} fill={'white'} opacity={0} style={{ cursor: cursor ?? 'default' }} />
      <Bar
        x={Math.min(s1, s2)}
        width={barWidth}
        y={1}
        height={height}
        fill={theme.color['neutral-25']}
        opacity={theme.opacity.high}
        style={{ cursor: 'move' }}
        onMouseDown={(e) => onMouseDown('bar', e)}
      />

      {/* scrubber 1 */}
      <Group style={{ cursor: 'ew-resize' }} onMouseDown={() => onMouseDown('s1')}>
        <Bar x={s1 - 1} width={3} y={0} height={26} fill={'black'} rx={3} />
        <Bar x={s1 - 2.5} width={6} y={7} height={12} fill={'white'} rx={4} stroke={'black'} />
      </Group>
      {/* scrubber 2 */}
      <Group style={{ cursor: 'ew-resize' }} onMouseDown={() => onMouseDown('s2')}>
        <Bar x={s2 - 1} width={3} y={0} height={26} fill={'black'} rx={3} />
        <Bar x={s2 - 2.5} width={6} y={7} height={12} fill={'white'} rx={4} stroke={'black'} />
      </Group>
    </Group>
  );
};

export default memo(TimeScrubber);
