import { memo, useState, useCallback } from 'react';

// ----- Redux -----
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';
import { grayzoneActions, grayzoneSelectors } from '../grayzoneSlice';
import { authActions } from '../../auth/authSlice';

// ----- MUI -----
import { AppBar, Box, MenuItem, MenuList, styled, Toolbar, useTheme } from '@mui/material';

// ----- PDS -----
import { PremiseLogo, ProfileOutlineIcon, SettingsOutlineIcon } from '../../../PremiseDesign/Icons';
import IconButton from '../../../PremiseDesign/Components/IconButton';

// ----- Ours -----
import IconButtonWithMenu from '../../../components/IconButtonWithMenu';

export const NAV_BAR_HEIGHT = '52px';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.color['neutral-0'],
  height: NAV_BAR_HEIGHT,
  display: 'flex',
  justifyContent: 'center',
  zIndex: theme.zIndex.drawer + 1
}));

const NavBar = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const [accountMenuOpen, setAccountMenuOpen] = useState(false);

  const configDrawerOpen = useAppSelector(grayzoneSelectors.configDrawer.selectConfigDrawerOpen);

  const handleClickedLogout = useCallback(() => {
    window.localStorage.clear();
    dispatch(grayzoneActions.logout);
    dispatch(authActions.updateToken(null));
  }, []);

  const handleClickedConfig = useCallback(() => {
    dispatch(grayzoneActions.toggleDrawerState());
  }, []);

  return (
    <StyledAppBar
      position={'static'}
      onMouseLeave={() => {
        setAccountMenuOpen(false);
      }}
    >
      <Toolbar
        variant="dense"
        disableGutters={true}
        sx={{
          height: NAV_BAR_HEIGHT
        }}
      >
        <Box flex={1} marginLeft={theme.spacings.small}>
          <PremiseLogo />
        </Box>
        <Box marginRight={theme.spacings['xxx-small']}>
          <IconButton onClick={handleClickedConfig} selected={configDrawerOpen}>
            <SettingsOutlineIcon />
          </IconButton>
        </Box>
        <Box marginRight={theme.spacings['x-small']}>
          <IconButtonWithMenu Icon={ProfileOutlineIcon} variant="default" open={accountMenuOpen} onClick={() => setAccountMenuOpen((o) => !o)}>
            <MenuList disablePadding={true}>
              <MenuItem disableRipple onClick={handleClickedLogout}>
                Sign Out
              </MenuItem>
            </MenuList>
          </IconButtonWithMenu>
        </Box>
      </Toolbar>
    </StyledAppBar>
  );
};

export default memo(NavBar);
