import { memo } from 'react';

// ----- VISX -----
import { AxisLine, SMALL_TICK_LENGTH, TickLabel, TickLine, TICK_LENGTH } from '../styles';

import { GET_ABBREVIATED_NUM } from '../constants';

const TICK_LABEL_LEFT_PADDING = 2;

type Props = {
  left: number;
  height: number;
  scale: (value: number) => number; // pass in height to get submission number value
  numTicks?: number; // should be an odd number
};
const SubmissionsYAxis = ({ left, height, scale, numTicks = 5 }: Props) => {
  const generateTicks = () => {
    const ticks = [];

    // only need 5 ticks -> 0 = 0 , 2 = mid of scale, 4 = top of scale, rest are small ticks
    for (let i = 0; i < numTicks; i++) {
      const y = height - (height / (numTicks - 1)) * i;
      const value = GET_ABBREVIATED_NUM(scale(y));

      if (i % 2 === 0) {
        ticks.push(
          <>
            <TickLine key={`tick-line-${i}`} x1={left} x2={left + TICK_LENGTH} y1={y + 0.5} y2={y + 0.5} />
            <TickLabel key={`tick-label-${i}`} textAnchor="start" verticalAnchor="middle" x={left + TICK_LENGTH + TICK_LABEL_LEFT_PADDING} y={y}>
              {value}
            </TickLabel>
          </>
        );
      } else {
        ticks.push(<TickLine x1={left} x2={left + SMALL_TICK_LENGTH} y1={y} y2={y} />);
      }
    }

    return ticks;
  };

  return (
    <>
      {/* add the 1 to account for stroke width */}
      <AxisLine x1={left + 1} x2={left + 1} y1={0} y2={height} />
      {generateTicks()}
    </>
  );
};

export default memo(SubmissionsYAxis);
