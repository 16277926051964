import { MapboxStyle, FillLayer } from 'react-map-gl';
import { mapboxToken } from './constants';

const generateStyle = (callback: (r: MapboxStyle) => void, hascs: string[], level: number) => {
  return fetch(`https://api.mapbox.com/styles/v1/joeraii/ckrdksz4z0lr518paki3bz5ph/?access_token=${mapboxToken}`)
    .then((res) => res.json())
    .then((r: MapboxStyle) => {
      const index = r.layers.map((l) => l.id).indexOf(`gadm-premise-l${level}`);
      if (index !== -1 && hascs.length >= 2) {
        const layer = r.layers[index];
        (r.layers[index] as FillLayer).layout = {};
        (r.layers[index] as FillLayer).paint = {
          'fill-color': [
            'match',
            ['get', 'premise_id'],
            ...(hascs ?? []),
            'rgba(0,0,0,0)' // [!] anything that doesn't match goes transparent:
          ],
          'fill-outline-color': [
            'match',
            ['get', 'premise_id'],
            ...hascs.flatMap((item, index) => {
              if (index % 2 === 0) {
                return [item, 'rgba(0,0,67,0.4)'];
              } else {
                return [];
              }
            }),
            'rgba(0,0,0,0)'
          ]
        };
        // stick it into layer past 'mapbox-satellite', this ensures we have labels on top of our fill (based off from this specific mapbox style)
        const satelliteIndex = r.layers.map((l) => l.id).indexOf('mapbox-satellite');
        if (satelliteIndex !== -1) {
          r.layers.splice(index, 1);
          r.layers.splice(satelliteIndex, 0, layer);
        }
      }
      // eslint-disable-next-line promise/no-callback-in-promise
      return callback(r);
    });
};

export default generateStyle;
