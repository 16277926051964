/* eslint-disable max-len */
// BASEUI-GENERATED-REACT-ICON
// DO NOT EDIT THIS FILE DIRECTLY, SEE README.md
import * as React from 'react';
import type { IconProps } from '../Icon';
import Pictogram from '../Pictogram';

const BattlesPictogram = (props: IconProps): JSX.Element => {
  const { title = 'Battles Pictogram', ...restProps } = props;
  return (
    <Pictogram title={title} {...restProps}>
      <path
        d="M53.438 11.891C53.2532 13.6703 51.6609 14.9628 49.8817 14.778C48.1024 14.5932 46.8098 13.0009 46.9947 11.2217C47.1795 9.44238 48.7717 8.14984 50.551 8.33467C52.3303 8.51951 53.6228 10.1117 53.438 11.891Z"
        fill="black"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.8817 14.7778C51.6609 14.9626 53.2532 13.6701 53.438 11.8908C53.6228 10.1115 52.3303 8.51932 50.551 8.33449C48.7717 8.14965 47.1795 9.4422 46.9947 11.2215C46.9364 11.7821 47.0249 12.3242 47.229 12.8096L6.09937 53.9393L8.22069 56.0606L35.5821 28.6992L57.1472 35.3346L49.4059 14.8754L49.5543 14.727C49.6613 14.7492 49.7705 14.7663 49.8817 14.7778ZM52.1728 30.6652L37.9823 26.2989L47.0788 17.2025L52.1728 30.6652Z"
        fill="black"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1697 12.8882C16.3086 12.5803 16.4012 12.2452 16.438 11.8908C16.6229 10.1115 15.3303 8.51932 13.551 8.33449C11.7718 8.14965 10.1795 9.4422 9.99471 11.2215C9.80988 13.0007 11.1024 14.593 12.8817 14.7778C13.1904 14.8099 13.4935 14.7975 13.7846 14.7458L13.9142 14.8754L6.08008 35.5799L29.8626 26.5811L16.1697 12.8882ZM24.4576 25.4187L16.2413 17.2025L11.2401 30.4199L24.4576 25.4187ZM55.0994 56.0606L34.0994 35.0606L36.2207 32.9393L57.2207 53.9393L55.0994 56.0606Z"
        fill="black"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.2207 42.0608L8.22069 56.0608L6.09937 53.9395L20.0994 39.9395L22.2207 42.0608Z"
        fill="#7E3BFF"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2207 49.0608L8.22069 56.0608L6.09937 53.9395L13.0994 46.9395L15.2207 49.0608Z"
        fill="#FF5B4A"
      ></path>
    </Pictogram>
  );
};

export default BattlesPictogram;
/* eslint-enable max-len */
