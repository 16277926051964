// ----- REDUX -----
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// ----- OURS -----

// ----- Types -----
import { AuthState } from '../../features/auth/authSlice';
import { POISearchResult } from '../../types/grayzone';

type GetPOIsApiArg = {
  body: {
    filters: {
      hascs: string[];
      topics: string[];
    };
    start: number;
    stop: number;
  };
};
type GetPOIsResponse = POISearchResult[];

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_HOST,
  prepareHeaders: async (headers, { getState }) => {
    const state = (await getState()) as { auth: AuthState };
    const token = state.auth.token;

    // If we have a token set in state, let's assume that we should be passing it.
    if (token) {
      headers.set('X-Auth-Token', token);
      headers.set('Content-Type', 'application/json');
    }
    return headers;
  }
});

export const placesApi = createApi({
  reducerPath: 'placesApi',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getPOIs: builder.query<GetPOIsResponse, GetPOIsApiArg>({
      // after is a place_id
      query: (queryArg) => ({
        url: '/places/v0/places/pois', // temp for now?
        method: 'POST',
        body: queryArg.body
      })
    })
  })
});

export const placedEndpoints = { ...placesApi.endpoints };
