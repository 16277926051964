import { Box, Card, useTheme } from '@mui/material';
import { memo, useCallback, useEffect, useState } from 'react';
import IconButton from '../PremiseDesign/Components/IconButton';
import { IconProps } from '../PremiseDesign/Icons/Icon';

type Props = {
  Icon: (props: IconProps) => React.ReactElement;
  activeBadge?: boolean;
  variant?: 'mini' | 'default';
  children?: React.ReactNode;
  open?: boolean;
  onClick?: () => void;
};
const IconButtonWithMenu = ({ Icon, activeBadge = false, variant = 'mini', children, open, onClick }: Props) => {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(open ?? false);

  useEffect(() => {
    if (open !== undefined) {
      setIsOpen(open);
    }
  }, [open]);

  // ----- Callbacks -----
  const iconOnClick = useCallback(() => {
    setIsOpen((isOpen) => !isOpen);
    onClick?.();
  }, [isOpen]);

  return (
    <Box style={{ position: 'relative' }}>
      <IconButton variant={variant} onClick={iconOnClick} selected={isOpen}>
        <Icon />
      </IconButton>
      {activeBadge && (
        <Box
          height={'6px'}
          width={'6px'}
          bgcolor={theme.color['brand-primary']}
          borderRadius={'3px'}
          position={'absolute'}
          top={2}
          right={2}
          sx={{
            opacity: isOpen ? 0 : 1,
            transition: 'all ease-in-out 100ms'
          }}
        />
      )}
      {isOpen && (
        <Card variant="elevation" style={{ position: 'absolute', top: variant === 'default' ? 50 : 30, right: 0, zIndex: 9000 }}>
          {children}
        </Card>
      )}
    </Box>
  );
};

export default memo(IconButtonWithMenu);
