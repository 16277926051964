import { useCallback, memo, useMemo, useRef, useState } from 'react';

// ----- MUI -----
import { Typography, Box, useTheme, Button, Accordion, AccordionSummary, AccordionDetails, Menu, MenuItem, CircularProgress, Autocomplete, TextField } from '@mui/material';

// ----- PDS -----
import { CloseCircleOutlineIcon, SettingsOutlineIcon, EllipsesHorizontalOutlineIcon, AddOutlineIcon, ProfileOutlineIcon } from '../../../../PremiseDesign/Icons';

// ----- REDUX -----
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { grayzoneActions, grayzoneSelectors } from '../../grayzoneSlice';
import { store } from '../../../../store';
import { grayzoneEndpoints } from '../../../../api/grayzone';

// ----- MODULES -----
import { matchSorter } from 'match-sorter';
import amplitude from 'amplitude-js';
import { CloudOffOutlined, ShareOutlined } from '@mui/icons-material';

// ----- Ours -----
import ViewConfig from '../ViewConfig';
import { generateViewState, generateViewStateExport, viewAccessAdapter, viewAdapter } from '../../../../helpers/grayzone';
import useAppSnackbar from '../../../../hooks/useAppSnackbar';
import IconButton from '../../../../PremiseDesign/Components/IconButton';

// ----- Types -----
import { ViewConfiguration, ViewMap, ViewPermission } from '../../../../types/grayzone';
import { Update } from '@reduxjs/toolkit';

type Props = {
  onClose: () => void;
};
const ConfigDrawer = ({ onClose }: Props) => {
  // ----- Hooks -----
  const theme = useTheme();
  const { enqueue } = useAppSnackbar();

  // ----- Redux -----
  const dispatch = useAppDispatch();

  // ----- Selectors -----
  const viewMapSelectors = useMemo(() => grayzoneSelectors.viewMaps.getSelectors(), []);
  const viewConfigurationSelectors = useMemo(() => grayzoneSelectors.viewConfigurations.getSelectors(), []);

  const primaryView = useAppSelector(grayzoneSelectors.viewConfigurations.selectPrimaryView);
  const primaryViewConfiguration = useAppSelector(grayzoneSelectors.viewConfigurations.selectPrimaryViewConfiguration);
  const viewMaps = useAppSelector((state) => viewMapSelectors.selectAll(state.grayzone.viewMaps));
  const viewConfigurations = useAppSelector((state) => viewConfigurationSelectors.selectEntities(state.grayzone.viewConfigurations));

  if (!primaryViewConfiguration || !primaryView) {
    throw new Error('A primary view must be configured!');
  }

  // ----- Local State -----
  // const [viewIdToConfigure, setViewIdToConfigure] = useState<string>(primaryView.viewId);
  const [changesToApply, setChangesToApply] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  // ----- Refs -----
  const applyRef = useRef<(() => void) | null>(null);

  // ----- Queries -----
  const viewsQueryResult = grayzoneEndpoints.getView.useQuery({});
  const viewAccessesQueryResult = grayzoneEndpoints.getViewAccess.useQuery({});

  // ----- Mutations -----
  const [triggerPutViewMutation, putViewMutationResult] = grayzoneEndpoints.putViewByViewId.useMutation();
  const [triggerDeleteViewMutation, deleteViewMutationResult] = grayzoneEndpoints.deleteViewByViewId.useMutation();
  const [triggerDeleteViewAccessMutation, deleteViewAccessMutationResult] = grayzoneEndpoints.deleteViewAccess.useMutation();
  const [triggerPostShareMutation, postShareMutationResult] = grayzoneEndpoints.postShare.useMutation();

  // ----- Helpers -----
  const filterMyViews = useCallback(
    (options: ViewMap[], { inputValue }: { inputValue: string }) => {
      if (!inputValue || !inputValue.length) {
        return options;
      }
      const terms = inputValue.split(/(\s+)/g);
      if (!terms) {
        return options;
      }
      // reduceRight will mean sorting is done by score for the _first_ entered word.
      return terms.reduceRight((results, term) => matchSorter(results, term, { keys: [(option) => viewConfigurations[option.configId]?.name ?? ''] }), options);
    },
    [viewConfigurations]
  );

  const requestsInProgress =
    viewsQueryResult.isFetching ||
    viewAccessesQueryResult.isFetching ||
    // postViewMutationResult.isLoading ||
    putViewMutationResult.isLoading ||
    deleteViewMutationResult.isLoading ||
    postShareMutationResult.isLoading ||
    deleteViewAccessMutationResult.isLoading;

  const viewIsSaved = useMemo(() => {
    return viewsQueryResult.data && viewAdapter.defaultSelectors.selectById(viewsQueryResult.data, primaryView.viewId);
  }, [viewsQueryResult.data, primaryView.viewId]);

  const viewHasAnAccess = useMemo(() => {
    return viewAccessesQueryResult.data && viewAccessAdapter.defaultSelectors.selectById(viewAccessesQueryResult.data, primaryView.viewId);
  }, [viewAccessesQueryResult.data, primaryView.viewId]);

  const getViewSortPosition = useCallback(
    (viewId: string): number => {
      const data = viewAccessesQueryResult.data;
      if (data) {
        const access = viewAccessAdapter.defaultSelectors.selectById(data, viewId);
        if (!access) {
          return 2;
        } else if ((access.permissions & ViewPermission.OWNER) > 0) {
          return 0;
        } else {
          return 1;
        }
      } else {
        return 2;
      }
    },
    [viewAccessesQueryResult.data]
  );

  const getAutocompleteStartAdornment = useCallback(
    (viewId: string) => {
      const data = viewAccessesQueryResult.data;
      if (data) {
        const access = viewAccessAdapter.defaultSelectors.selectById(data, viewId);
        if (!access) {
          return <CloudOffOutlined style={{ fontSize: '14px' }} />;
        } else if ((access.permissions & ViewPermission.OWNER) > 0) {
          return <ProfileOutlineIcon size={theme.iconScale['x-small']} />;
        } else {
          return <ShareOutlined style={{ fontSize: '14px' }} />;
        }
      } else {
        return <CloudOffOutlined style={{ fontSize: '14px' }} />;
      }
    },
    [viewAccessesQueryResult.data]
  );

  const hasPermission = useCallback(
    (permission: ViewPermission) => {
      const data = viewAccessesQueryResult.data;
      if (data) {
        const access = viewAccessAdapter.defaultSelectors.selectById(data, primaryView.viewId);
        if (!access) {
          return false;
        }
        return (access.permissions & permission) > 0;
      } else {
        return false;
      }
    },
    [viewAccessesQueryResult.data, primaryView.viewId]
  );

  // const canRead = hasPermission(0b1000);
  const canWrite = hasPermission(ViewPermission.WRITE);
  // const canShare = hasPermission(ViewPermission.share); // If a user wants to share, they should just share the same link they used
  const isOwner = useMemo(() => {
    return hasPermission(ViewPermission.OWNER);
  }, [hasPermission]);

  // ----- Handlers -----
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (anchorEl !== event.currentTarget) {
        setAnchorEl(event.currentTarget);
      }
    },
    [anchorEl]
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleClickedApply = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      applyRef?.current?.();
    },
    [applyRef]
  );

  const onUpdate = useCallback((updates: Update<ViewConfiguration>) => {
    dispatch(grayzoneActions.updateViewConfiguration(updates));
    enqueue('Changes applied', { variant: 'success' });
    setChangesToApply(false);
  }, []);

  const handleSelectedView = useCallback((e: React.SyntheticEvent<Element, Event>, value: ViewMap | null) => {
    value && dispatch(grayzoneActions.switchPrimaryViewTo(value.viewId));
    // value && setViewIdToConfigure(value.viewId);
  }, []);

  const handleClickedPublishChanges = useCallback(async () => {
    const viewId = store.getState().grayzone.primaryViewId;
    if (viewId === undefined || viewId === null) {
      return enqueue('Something went wrong and view was not uploaded', { variant: 'error' });
    }

    const exportState = generateViewState(store.getState().grayzone, viewId);
    try {
      await triggerPutViewMutation({ viewId: viewId, body: exportState }).unwrap();
      enqueue('Changes published', { variant: 'success', preventDuplicate: false });
    } catch (e) {
      enqueue('Something went wrong and changes were not published', { variant: 'error' });
    }
  }, []);

  const handleClickedCreateLink = async () => {
    try {
      const result = await triggerPostShareMutation({ body: { viewId: primaryView.viewId, permissions: ViewPermission.READ, duration: null, isActive: true } }).unwrap();
      navigator.clipboard.writeText(`${window.location.origin}?sid=${result.shareId}`);
      enqueue('Link copied to clipboard', { variant: 'success' });
      amplitude.getInstance().logEvent('Created a link', result);
      handleClose();
    } catch (e) {
      enqueue('Something went wrong and a link was not generated', { variant: 'error' });
    }
  };

  const handleClickedDownload = () => {
    const exportState = generateViewStateExport(store.getState().grayzone, primaryView.viewId);

    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(JSON.stringify(exportState)));
    element.setAttribute('download', `${exportState.viewConfiguration.name.split(' ').join('_')}_${new Date().toLocaleDateString()}.json`);

    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const handleClickedRemoveFromAccount = async () => {
    try {
      await triggerDeleteViewAccessMutation({ viewId: primaryView.viewId }).unwrap();
      dispatch(grayzoneActions.deleteView(primaryView.viewId));
      enqueue('View removed and is no longer be saved to your account', { variant: 'success', preventDuplicate: false });
    } catch (e) {
      enqueue('Something went wrong and the view was not removed', { variant: 'error' });
    }
  };

  const handleClickedDeletePermanently = async () => {
    try {
      if (viewsQueryResult.data && viewAdapter.defaultSelectors.selectById(viewsQueryResult.data, primaryView.viewId)) {
        await triggerDeleteViewMutation({ viewId: primaryView.viewId }).unwrap();
      }
      dispatch(grayzoneActions.deleteView(primaryView.viewId));
      enqueue('View deleted', { variant: 'success', preventDuplicate: false });
    } catch (e) {
      enqueue('Something went wrong and the view was not deleted', { variant: 'error' });
    }
  };

  // ----- Component -----
  return (
    <>
      <Box display={'flex'} flexDirection={'row'} alignItems={'center'} width={'100%'} paddingBottom={theme.spacings['x-small']}>
        <SettingsOutlineIcon size={theme.iconScale.small} />
        <Typography variant="subhead-3" flex={1} paddingLeft={'6px'}>
          Dashboard Configurations
        </Typography>
        <Box sx={{ cursor: 'pointer' }} onClick={onClose}>
          <CloseCircleOutlineIcon size={theme.iconScale.small} color={theme.color['neutral-75']} />
        </Box>
      </Box>

      <Box>
        <Box style={{ display: 'flex', flexDirection: 'row', gap: '10px', width: '100%', alignItems: 'center' }}>
          <Autocomplete
            fullWidth
            disableClearable
            options={viewMaps.sort((a, b) => getViewSortPosition(a.viewId) - getViewSortPosition(b.viewId))}
            filterOptions={filterMyViews}
            groupBy={(option) => {
              if (getViewSortPosition(option.viewId) === 0) {
                return 'My Views';
              } else if (getViewSortPosition(option.viewId) === 1) {
                return 'Shared';
              } else if (getViewSortPosition(option.viewId) === 2) {
                return 'Unpublished';
              } else {
                return 'Unknown';
              }
            }}
            renderOption={(params, option) => {
              return (
                <li {...params} key={option.viewId} style={{ margin: 0, padding: '4px 8px 4px 32px', maxWidth: '100%' }}>
                  <Typography variant={params['aria-selected'] ? 'label-bold' : 'label'} whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                    {viewConfigurations?.[option.configId]?.name ?? 'Name Unavailable'}
                  </Typography>
                </li>
              );
            }}
            renderGroup={(params) => {
              if (params.group === 'My Views') {
                return (
                  <>
                    <Box display="flex" flexDirection="row" width="100%" height="20px" alignItems="center" padding="8px 0px">
                      <Box display="flex" alignItems="center" justifyContent="center" width="32px">
                        <ProfileOutlineIcon size={theme.iconScale['x-small']} />
                      </Box>
                      <Typography variant="label-small-bold">My Views</Typography>
                    </Box>
                    {params.children}
                  </>
                );
              } else if (params.group === 'Shared') {
                return (
                  <>
                    <Box display="flex" flexDirection="row" width="100%" height="20px" alignItems="center" padding="8px 0px">
                      <Box display="flex" alignItems="center" justifyContent="center" width="32px">
                        <ShareOutlined style={{ fontSize: '14px' }} />
                      </Box>
                      <Typography variant="label-small-bold">Shared</Typography>
                    </Box>
                    {params.children}
                  </>
                );
              } else if (params.group === 'Unpublished') {
                return (
                  <>
                    <Box display="flex" flexDirection="row" width="100%" height="20px" alignItems="center" padding="8px 0px">
                      <Box display="flex" alignItems="center" justifyContent="center" width="32px">
                        <CloudOffOutlined style={{ fontSize: '14px' }} />
                      </Box>
                      <Typography variant="label-small-bold">Unpublished</Typography>
                    </Box>
                    {params.children}
                  </>
                );
              }
            }}
            getOptionLabel={(map) => viewConfigurations?.[map.configId]?.name ?? 'Name Unavailable'}
            value={primaryView}
            onChange={handleSelectedView}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  variant={'standard'}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <Box paddingRight="9.5px" display="flex" justifyContent="center" alignItems="center">
                        {getAutocompleteStartAdornment(primaryView.viewId)}
                      </Box>
                    )
                  }}
                />
              );
            }}
          />
          <IconButton variant="mini" style={{ flex: 1 }} onClick={() => dispatch(grayzoneActions.updateViewConfigModalStateTo(true))}>
            <AddOutlineIcon size={theme.iconScale.small} />
          </IconButton>
          {[
            <>
              <IconButton variant="mini" onClick={handleClick} disabled={!viewsQueryResult.data || !viewAccessesQueryResult.data || !viewHasAnAccess || !viewIsSaved}>
                <EllipsesHorizontalOutlineIcon size={theme.iconScale.small} />
              </IconButton>
              <Menu
                style={{ zIndex: 4000, borderRadius: theme.borderRadius.borderRadiusSemiSharp, padding: 0, marginRight: 'auto' }}
                id="widget-header-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'icon-button-widget-header',
                  disablePadding: true
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
              >
                {[
                  canWrite ? (
                    <MenuItem key="publish-changes-button" onClick={handleClickedPublishChanges} disabled={requestsInProgress}>
                      {putViewMutationResult.isLoading && <CircularProgress size="20px" />}
                      {!putViewMutationResult.isLoading && <Typography variant="label">Publish changes</Typography>}
                    </MenuItem>
                  ) : null,
                  isOwner ? (
                    <MenuItem key="copy-link-button" onClick={handleClickedCreateLink} disabled={requestsInProgress}>
                      {postShareMutationResult.isLoading && <CircularProgress size="20px" />}
                      {!postShareMutationResult.isLoading && <Typography variant="label">Copy link</Typography>}
                    </MenuItem>
                  ) : null,
                  <MenuItem key="download-button" onClick={handleClickedDownload} disabled={requestsInProgress}>
                    <Typography variant="label">Download</Typography>
                  </MenuItem>,
                  <MenuItem key="remove-view-button" onClick={handleClickedRemoveFromAccount} disabled={requestsInProgress}>
                    {deleteViewAccessMutationResult.isLoading && <CircularProgress size="20px" />}
                    {!deleteViewAccessMutationResult.isLoading && <Typography variant="label">Remove</Typography>}
                  </MenuItem>,
                  isOwner ? (
                    <MenuItem key="delete-view-button" onClick={handleClickedDeletePermanently} disabled={requestsInProgress}>
                      {deleteViewMutationResult.isLoading && <CircularProgress size="20px" />}
                      {!deleteViewMutationResult.isLoading && <Typography variant="label">Delete permanently</Typography>}
                    </MenuItem>
                  ) : null
                ]}
              </Menu>
            </>
          ]}
        </Box>
        <Accordion defaultExpanded={true}>
          <AccordionSummary>
            <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
              Configurations
              {changesToApply && (
                <Button
                  // disabled={!changesToApply}
                  onClick={handleClickedApply}
                >
                  Apply
                </Button>
              )}
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <ViewConfig
              formType="update"
              onSubmit={onUpdate}
              submitRef={applyRef}
              currConfiguration={primaryViewConfiguration}
              style={{ height: 'auto', padding: '0px', width: '100%', overflow: 'hidden' }}
              onChange={() => {
                setChangesToApply(true);
              }}
            />
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
};

export default memo(ConfigDrawer);
