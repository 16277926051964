/* eslint-disable max-len */
// BASEUI-GENERATED-REACT-ICON
// DO NOT EDIT THIS FILE DIRECTLY, SEE README.md
import * as React from 'react';
import type { IconProps } from '../Icon';
import Icon from '../Icon';

const DragFillIcon = (props: IconProps): JSX.Element => {
  const {
    title = 'Drag Fill Icon',
    size,
    color,
    onClick,
    ...restProps
  } = props;
  return (
    <Icon
      title={title}
      size={size}
      color={color}
      onClick={onClick}
      {...restProps}
    >
      <circle cx="16" cy="20" r="2" transform="rotate(90 16 20)"></circle>
      <circle cx="16" cy="4" r="2" transform="rotate(90 16 4)"></circle>
      <circle cx="16" cy="12" r="2"></circle>
      <circle cx="8" cy="12" r="2"></circle>
      <circle cx="8" cy="4" r="2" transform="rotate(90 8 4)"></circle>
      <circle cx="8" cy="20" r="2" transform="rotate(90 8 20)"></circle>
    </Icon>
  );
};

export default DragFillIcon;
/* eslint-enable max-len */
