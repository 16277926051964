import { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';

// ----- REDUX -----
import { useAppSelector } from '../../../hooks/useRedux';
import { grayzoneSelectors } from '../../../features/grayzone/grayzoneSlice';

// ----- MUI -----
import { Box, styled } from '@mui/material';

// ----- Modules -----
import Slider from 'react-slick';
import { SizeMe } from 'react-sizeme';

// ----- Ours -----
import FeedItem from './FeedItem';
// import useSelectWidgetConfigurations from '../../../hooks/useSelectWidgetConfigurations';

// ----- Styles -----
import { Spinner } from '../../../styles';

// ----- Types -----
import { GDELTdataType, ACLEDdataType, FeedWidget, GDELTTopArticle } from '../../../types/grayzone';

const AVG_CELL_HEIGHT = 130;

const FeedContainer = styled(Box)(({ theme }) => ({
  height: '100%',
  boxSizing: 'border-box',
  paddingBottom: '16px',
  paddingLeft: '32px',
  paddingRight: theme.spacings['small'],
  width: '100%',
  display: 'flex',
  flexDirection: 'column'
}));
const FeedItems = styled(Box)(({ theme }) => ({
  height: '100%',
  overflowX: 'hidden',
  overflowY: 'scroll',
  // transition: 'opacity 1.0s ease',
  '&::-webkit-scrollbar': {
    width: '4px',
    marginLeft: theme.spacing(1),
    overflow: 'visible',
    borderRadius: theme.borderRadius.borderRadiusSemiSharp
  },
  '&::-webkit-scrollbar-thumb': {
    overflow: 'hidden',
    borderRadius: theme.borderRadius.borderRadiusSemiSharp,
    background: theme.color['neutral-50']
  },
  'mask-image': 'linear-gradient(to top, transparent, black),linear-gradient(to left, transparent 4px, black 4px)',
  'mask-size': '100% 20000px',
  'mask-position': 'left bottom',
  '-webkit-mask-image': 'linear-gradient(to top, transparent, black), linear-gradient(to left, transparent 4px, black 4px)',
  '-webkit-mask-size': '100% 20000px',
  '-webkit-mask-position': 'left bottom',
  transition: 'mask-position 0.2s, -webkit-mask-position 0.2s',
  '&:hover': { '-webkit-mask-position': 'left top' }
}));

const ItemRow = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  gap: '46px'
});
const Divider = styled(Box)(({ theme }) => ({
  display: 'inline-block',
  flex: 1,
  width: '100%',
  height: '1px',
  backgroundColor: theme.color['neutral-25'],
  marginBottom: theme.spacings.large
}));

type props = {
  data: (GDELTdataType | ACLEDdataType | GDELTTopArticle)[];
  isFetching: boolean;
  width: number; // number of cols in grid
  widget: FeedWidget;
  menuRef?: MutableRefObject<JSX.Element[]>;
};
const Feed = ({ data, isFetching, width, widget }: props) => {
  const [isSlideshow, setIsSlideshow] = useState(false);

  // ----- Selectors -----
  const idle = useAppSelector(grayzoneSelectors.selectIdleState);
  // const { [WidgetType.FEED]: feedConfigs } = useSelectWidgetConfigurations();

  // ----- Refs -----
  const containerRef = useRef<HTMLDivElement>(null);

  // ----- Helpers -----
  // Render rows of items based on the number of cols (adds a divider between rows too)
  // TO-DO: might have to use a virtualized list if data is huge
  const cols = useMemo(() => {
    if (width === 12) return 3;
    if (width > 7) return 2;
    return 1;
  }, [width]);
  const Items = useMemo(() => {
    const widgetId = widget.id;
    const rows = [];
    for (let count = 0; count < data.length; ) {
      const items = [];
      const dividers = [];
      for (let col = 0; col < cols; col++, count++) {
        if (count >= data.length) {
          items.push(<Box flex={1} width={'100%'} display={'flex'} key={`empty-space-${count}-${col}`} />); // push empty spaces
          dividers.push(<Box flex={1} display={'flex'} key={`divider-${count}-${col}`} />);
          continue;
        }
        const item = data[count];
        items.push(<FeedItem data={item} key={`news-item-${count}-${col}`} />);
        dividers.push(<Divider key={`divider-${count}-${col}`} />);
      }

      // if we aren't on idle and this is last row, don't include dividers
      if (count === data.length && !idle && !isSlideshow) {
        rows.push(
          <div key={`item-row-container-${widgetId}-${count}`}>
            <ItemRow key={`item-row-${widgetId}-${count}`}>{items}</ItemRow>
          </div>
        );
      } else {
        rows.push(
          <div key={`item-row-container-${widgetId}-${count}`}>
            <ItemRow key={`item-row-${widgetId}-${count}`}>{items}</ItemRow>
            <ItemRow key={`item-row-dividers-${widgetId}-${count}`}>{dividers}</ItemRow>
          </div>
        );
      }
    }
    return rows;
  }, [data, cols, idle, isSlideshow]); // use memoized cols instead of width to avoid re-renders
  const canFitSlideshow = (height: number | null) => {
    if (!height) return false;

    const totalHeight = AVG_CELL_HEIGHT * Items.length;
    setIsSlideshow(totalHeight >= height);
    return totalHeight >= height;
  };

  // ----- Effects -----
  useEffect(() => {
    if (!idle) setIsSlideshow(false);
  }, [idle]);

  if (isFetching) {
    return (
      <FeedContainer sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} ref={containerRef}>
        <Spinner color="primary" />
      </FeedContainer>
    );
  }

  return (
    <FeedContainer ref={containerRef} paddingTop="62px">
      {data.length === 0 && (
        <Box>
          <FeedItem key="null-feed-item" data={null} />
        </Box>
      )}
      {data.length > 0 && (
        <>
          <SizeMe monitorHeight monitorWidth={false}>
            {({ size }) => (
              <FeedItems>
                {idle && canFitSlideshow(size.height) ? (
                  <Slider
                    autoplay={true}
                    vertical={true}
                    adaptiveHeight={true}
                    speed={2000}
                    autoplaySpeed={4000}
                    dots={false}
                    arrows={false}
                    infinite={true}
                    pauseOnHover={false}
                    swipe={false}
                  >
                    {Items}
                  </Slider>
                ) : (
                  <>{Items}</>
                )}
              </FeedItems>
            )}
          </SizeMe>
        </>
      )}
    </FeedContainer>
  );
};

export default Feed;
