import { useEffect, useState, useCallback, useMemo } from 'react';

// ----- Redux -----
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import { grayzoneActions, grayzoneSelectors } from '../../../grayzoneSlice';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { grayzoneEndpoints } from '../../../../../api/grayzone';

// ----- MUI -----
import { Box, Typography, useTheme, Slider, TextField } from '@mui/material';

// ----- PDS -----
import IconButton from '../../../../../PremiseDesign/Components/IconButton';
import Checkbox from '../../../../../PremiseDesign/Components/Checkbox';
import { ArrowDownOutlineIcon, ArrowUpOutlineIcon } from '../../../../../PremiseDesign/Icons';

// ----- Ours -----
import VirtualizedAutocomplete from '../../../../../components/VirtualizedAutocomplete';
import useGrid from '../../../../../hooks/useGrid';

// ----- Modules -----
import amplitude from 'amplitude-js';

// ----- Types -----
import { ACLEDEventEnum, FeedDataType, FeedWidgetConfigurations, WidgetType } from '../../../../../types/grayzone';
import useSelectWidgetConfigurations from '../../../../../hooks/useSelectWidgetConfigurations';
import { SectionedBox, SectionLabel } from '.';

const FeedFilters = () => {
  const theme = useTheme();
  const { gridLayout } = useGrid();
  const dispatch = useAppDispatch();

  // ----- Selectors -----
  const viewConfigurationSelectors = useMemo(() => grayzoneSelectors.viewConfigurations.getSelectors(), []);
  const currentViewConfiguration = useAppSelector((state) => viewConfigurationSelectors.selectById(state.grayzone.viewConfigurations, gridLayout.viewConfigId));
  const { [WidgetType.FEED]: feedConfigs } = useSelectWidgetConfigurations();

  // ----- Queries -----
  // TODO: make this reusable - (GridView.tsx)
  const min_date_alert =
    currentViewConfiguration && currentViewConfiguration?.dateRange[0] ? new Date(Number(currentViewConfiguration.dateRange[0])).toISOString().split('T')[0] : undefined;
  const max_date_alert =
    currentViewConfiguration && currentViewConfiguration?.dateRange[1] ? new Date(Number(currentViewConfiguration.dateRange[1])).toISOString().split('T')[0] : undefined;

  const timeseriesParams = {
    form_id: currentViewConfiguration?.forms[0] ?? skipToken,
    question_name: currentViewConfiguration?.questions[0] ?? skipToken,
    hasc_code: currentViewConfiguration?.countries[0] ?? skipToken
  };
  const feedQueryParams = {
    ...timeseriesParams,
    min_date_alert: min_date_alert ?? skipToken,
    max_date_alert: max_date_alert ?? skipToken
  };
  const revereQuery = grayzoneEndpoints.getRevere.useQuery(feedQueryParams);

  const [sortAlpha, setSortAlpha] = useState<'ascend' | 'descend' | null>(null);
  const [sortDate, setSortDate] = useState<'ascend' | 'descend' | null>(null);
  const [relevanceOn, setRelevanceOn] = useState(feedConfigs.relevanceFilter !== null ?? false);
  const [relevance, setRelevance] = useState<[number, number]>([0, 1]);

  useEffect(() => {
    const sort = feedConfigs.sort;
    if (sort) {
      switch (sort) {
        case 'alphaAscending': {
          setSortAlpha('ascend');
          setSortDate(null);
          break;
        }
        case 'alphaDescending': {
          setSortAlpha('descend');
          setSortDate(null);
          break;
        }
        case 'dateAscending': {
          setSortDate('ascend');
          setSortAlpha(null);
          break;
        }
        case 'dateDescending': {
          setSortDate('descend');
          setSortAlpha(null);
          break;
        }
        default: {
          setSortAlpha(null);
          setSortDate(null);
        }
      }
    }

    const relevanceFilter = feedConfigs.relevanceFilter;
    if (relevanceFilter === null) {
      setRelevanceOn(false);
      setRelevance([0, 1]);
    } else {
      setRelevanceOn(true);
      setRelevance(relevanceFilter);
    }
  }, [feedConfigs]);

  // main function to update feedConfigs for redux and amplitude
  const updateFeedFilters = useCallback(
    (changes: Partial<FeedWidgetConfigurations>) => {
      const feedChanges = { ...feedConfigs, ...changes };
      dispatch(
        grayzoneActions.updateWidgetConfigurations({
          id: gridLayout.widgetConfigurationsId,
          changes: {
            [WidgetType.FEED]: feedChanges
          }
        })
      );
      amplitude.getInstance().logEvent('News Feed Updated', changes);
    },
    [feedConfigs]
  );

  // ----- Callbacks -----
  const setSort = useCallback(
    (sort: 'alphaAscending' | 'alphaDescending' | 'dateAscending' | 'dateDescending') => {
      updateFeedFilters({ sort });
    },
    [updateFeedFilters]
  );
  const toggleACLEDEvents = useCallback(
    (eventType: ACLEDEventEnum) => {
      const excludeACLEDEvents = { ...feedConfigs.excludeACLEDEvents };
      excludeACLEDEvents[eventType] = !excludeACLEDEvents[eventType];
      updateFeedFilters({ excludeACLEDEvents });
    },
    [feedConfigs, updateFeedFilters]
  );
  const addFilterGDELTSource = useCallback(
    (_e, value: string[] | string | null) => {
      const GDELTSourceFilters = value ? (Array.isArray(value) ? value : [value]) : [];
      updateFeedFilters({ GDELTSourceFilters });
    },
    [updateFeedFilters]
  );
  const setSourcesFilter = useCallback(
    (source: FeedDataType) => {
      const sourcesFilter = { ...feedConfigs.sourcesFilter };
      if (source === FeedDataType.ACLED) {
        sourcesFilter.ACLED = !sourcesFilter.ACLED;
      } else {
        sourcesFilter.GDELT = !sourcesFilter.GDELT;
      }
      updateFeedFilters({ sourcesFilter });
    },
    [feedConfigs, updateFeedFilters]
  );
  const toggleRelevanceFilter = useCallback(() => {
    const relevanceFilter = relevanceOn ? null : ([0, 1] as [number, number]);
    updateFeedFilters({ relevanceFilter });
  }, [relevanceOn, updateFeedFilters]);
  const updateRelevanceFilterValues = useCallback(
    (_e, newValue) => {
      const relevanceFilter = newValue as [number, number];
      updateFeedFilters({ relevanceFilter });
    },
    [updateFeedFilters]
  );

  // ----- Memo -----
  const newsSources = useMemo(() => {
    if (revereQuery.isSuccess && revereQuery.data) {
      const gdeltSources = Array.from(new Set(revereQuery.data.gdelt.map((gdelt) => gdelt.domain_text)));
      if (feedConfigs.GDELTSourceFilters) {
        gdeltSources.concat(feedConfigs.GDELTSourceFilters);
      }
      return gdeltSources;
    } else return [];
  }, [revereQuery.isSuccess, revereQuery.data]);

  return (
    <Box>
      <SectionedBox>
        <SectionLabel>Sort</SectionLabel>
        <Box>
          {/* Alphabetical */}
          <Box display="flex" flexDirection="row" alignItems={'center'}>
            <IconButton variant="mini" selected={sortAlpha === 'ascend'} onClick={() => setSort('alphaAscending')}>
              <ArrowUpOutlineIcon />
            </IconButton>
            <IconButton variant="mini" selected={sortAlpha === 'descend'} onClick={() => setSort('alphaDescending')}>
              <ArrowDownOutlineIcon />
            </IconButton>
            <Typography variant="label-small" color={theme.color['neutral-75']} flex={1}>
              Alphabetical
            </Typography>
          </Box>
          {/* Date */}
          <Box display="flex" flexDirection="row" alignItems={'center'}>
            <IconButton variant="mini" selected={sortDate === 'ascend'} onClick={() => setSort('dateAscending')}>
              <ArrowUpOutlineIcon />
            </IconButton>
            <IconButton variant="mini" selected={sortDate === 'descend'} onClick={() => setSort('dateDescending')}>
              <ArrowDownOutlineIcon />
            </IconButton>
            <Typography variant="label-small" color={theme.color['neutral-75']} flex={1}>
              Date
            </Typography>
          </Box>
        </Box>
      </SectionedBox>

      <SectionedBox>
        <SectionLabel>Feed sources</SectionLabel>
        <Checkbox label="GDELT" variant="dash" checked={feedConfigs.sourcesFilter.GDELT} onChange={() => setSourcesFilter(FeedDataType.GDELT)} />
        <Checkbox label="ACLED" variant="dash" checked={feedConfigs.sourcesFilter.ACLED} onChange={() => setSourcesFilter(FeedDataType.ACLED)} />
      </SectionedBox>

      <SectionedBox>
        <Box>
          <Typography variant="label-small" color={theme.color['neutral-75']} display={'block'} marginBottom={theme.spacings.small}>
            GDELT sources:
          </Typography>
          <VirtualizedAutocomplete
            id="GDELT-sources-dropdown"
            fullWidth
            disableListWrap
            multiple={true}
            options={newsSources}
            onChange={addFilterGDELTSource}
            value={feedConfigs.GDELTSourceFilters}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  label="Select a source"
                  variant="standard"
                  InputProps={{
                    ...params.InputProps
                  }}
                />
              );
            }}
            renderOption={(props, source) => [
              props,
              <Typography key={source} variant="label-small">
                {source}
              </Typography>
            ]}
          />
        </Box>
      </SectionedBox>

      <SectionedBox>
        <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
          <Checkbox checked={relevanceOn} onChange={toggleRelevanceFilter} />
          <SectionLabel display="span">Relevance</SectionLabel>
        </Box>

        <Box padding={'8px 16px'}>
          <Slider
            valueLabelDisplay="auto"
            disabled={!relevanceOn}
            max={1}
            min={0}
            step={0.1}
            value={relevance}
            marks={[
              { value: 0, label: 'Less' },
              { value: 1, label: 'More' }
            ]}
            onChange={(_e, newValue) => setRelevance(newValue as [number, number])}
            onChangeCommitted={updateRelevanceFilterValues}
          />
        </Box>
      </SectionedBox>

      <Box>
        <SectionLabel>ACLED Events</SectionLabel>
        {(Object.keys(ACLEDEventEnum) as Array<keyof typeof ACLEDEventEnum>).map((ACLEDEvent) => (
          <Checkbox
            key={`ACLEDEvent-${ACLEDEvent}`}
            label={ACLEDEventEnum[ACLEDEvent]}
            checked={!feedConfigs.excludeACLEDEvents[ACLEDEventEnum[ACLEDEvent]]}
            variant="dash"
            onChange={() => toggleACLEDEvents(ACLEDEventEnum[ACLEDEvent])}
          />
        ))}
      </Box>
    </Box>
  );
};

export default FeedFilters;
