/* eslint-disable max-len */
// BASEUI-GENERATED-REACT-ICON
// DO NOT EDIT THIS FILE DIRECTLY, SEE README.md
import * as React from 'react';
import type { IconProps } from '../Icon';
import Pictogram from '../Pictogram';

const ProtestPictogram = (props: IconProps): JSX.Element => {
  const { title = 'Protest Pictogram', ...restProps } = props;
  return (
    <Pictogram title={title} {...restProps}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42 23.5V34H39V23.5H42Z"
        fill="#FF5B4A"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42 33.5V44.5H39V33.5H42Z"
        fill="#7E3BFF"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 6H58V23.5H36V28H24V40H21V28H6V14H23V6ZM55 20.5H36V14H26V9H55V20.5ZM9 17V25H33V17H9Z"
        fill="black"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.5 38C32.6046 38 33.5 37.1046 33.5 36C33.5 34.8954 32.6046 34 31.5 34C30.3954 34 29.5 34.8954 29.5 36C29.5 37.1046 30.3954 38 31.5 38ZM31.5 41C34.2614 41 36.5 38.7614 36.5 36C36.5 33.2386 34.2614 31 31.5 31C28.7386 31 26.5 33.2386 26.5 36C26.5 38.7614 28.7386 41 31.5 41Z"
        fill="black"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 37.5C16.5 38.8807 15.3807 40 14 40C12.6193 40 11.5 38.8807 11.5 37.5C11.5 36.1193 12.6193 35 14 35C15.3807 35 16.5 36.1193 16.5 37.5ZM18.0115 41.2627C18.9346 40.279 19.5 38.9555 19.5 37.5C19.5 34.4624 17.0376 32 14 32C10.9624 32 8.5 34.4624 8.5 37.5C8.5 38.9555 9.06538 40.279 9.98851 41.2627C8.1815 42.5285 7 44.6263 7 47V58H10V47C10 44.7909 11.7909 43 14 43C16.2091 43 18 44.7909 18 47V58H21V47C21 44.6263 19.8185 42.5285 18.0115 41.2627Z"
        fill="black"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53 37.5C53 38.8807 51.8807 40 50.5 40C49.1193 40 48 38.8807 48 37.5C48 36.1193 49.1193 35 50.5 35C51.8807 35 53 36.1193 53 37.5ZM54.57 41.1994C55.4585 40.2225 56 38.9245 56 37.5C56 34.4624 53.5376 32 50.5 32C47.4624 32 45 34.4624 45 37.5C45 38.9245 45.5415 40.2225 46.43 41.1994C44.3659 42.5354 43 44.8582 43 47.5V58H46V47.5C46 45.0147 48.0147 43 50.5 43C52.9853 43 55 45.0147 55 47.5V58H58V47.5C58 44.8582 56.6341 42.5354 54.57 41.1994Z"
        fill="black"
      ></path>
    </Pictogram>
  );
};

export default ProtestPictogram;
/* eslint-enable max-len */
