// ----- REACT -----

import React, { useEffect } from 'react';
// ----- REDUX -----

// ----- API -----

// ----- MODULES -----
import { TransformComponent, TransformWrapper, useTransformContext } from 'react-zoom-pan-pinch';
import { Box, Skeleton, styled, useTheme } from '@mui/material';

// ----- OURS -----

// ----- CONSTANTS -----

// ----- STYLES -----

const ImageBox = styled(Box)<{ $style?: React.CSSProperties; $source: string }>(({ $style, $source, theme }) => ({
  backgroundImage: `url(${$source})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundColor: theme.color['neutral-10'],
  width: '100%',
  height: 'auto',
  ...$style
}));

// ----- TYPES -----

// ----- COMPONENT -----

type TransformableImageProps = {
  source: string;
};
const TransformableImage = ({ source }: TransformableImageProps) => {
  const transformContext = useTransformContext();
  const theme = useTheme();

  // ----- Effects -----
  useEffect(() => {
    transformContext.centerView(1);
  }, [source]);

  // ----- Component -----
  return (
    <TransformComponent contentStyle={{ height: '100%', cursor: 'grab' }} wrapperStyle={{ height: '100%', width: '100%', backgroundColor: theme.color['neutral-10'] }}>
      <img src={source} alt="test" style={{ height: '100%', width: 'auto' }} />
    </TransformComponent>
  );
};

type Props = {
  source?: string;
  style?: React.CSSProperties;
  interactable?: boolean;
};
const ImageContainer = ({ source, style, interactable }: Props) => {
  // ----- Effects -----

  // ----- Return Component -----
  if (source) {
    if (interactable) {
      return (
        <TransformWrapper limitToBounds centerOnInit panning={{ velocityDisabled: true }}>
          <TransformableImage source={source} />
        </TransformWrapper>
      );
    } else {
      return <ImageBox $source={source} $style={style} />;
    }
  } else {
    return <Skeleton variant="rectangular" style={{ width: '100%', height: '100%', ...style }} />;
  }
};

export default ImageContainer;
