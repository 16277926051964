import { useState, useMemo, memo, useCallback } from 'react';

// ----- MUI -----
import { Typography, useTheme } from '@mui/material';

// ----- VISX -----
import { Group } from '@visx/group';
import { scaleLinear } from '@visx/scale';
import { Bar } from '@visx/shape';
import type { ScaleLinear } from 'd3-scale';

// ----- Ours -----
import { DATE_FORMATTER, GET_NEXT_DATE_INTERVAL, ONE_DAY } from '../constants';
import SubmissionsYAxis from './SubmissionsYAxis';
import { RectClipPath } from '@visx/clip-path';
import DateXAxis from './DateXAxis';

// ----- Types -----
import { GenericMap, TimeseriesPoint } from '../../../../../types/grayzone';

// ----- Constants -----
const MIN_TICK_WIDTH = 54; // defined by design

type AggregatedSubmission = {
  start: number;
  value: number;
  x: number;
};
type Props = {
  left: number;
  height: number;
  width: number;
  xScale: ScaleLinear<number, number>;
  data: TimeseriesPoint[] | GenericMap<TimeseriesPoint[]>;
  clipKey: string;
  setTooltip: (data: string | React.ReactNode, left: number, top: number) => void;
  hideTooltip: () => void;
  setHighlightedComponent: (component: React.ReactNode, offset?: boolean) => void;
  hideData?: boolean;
};
const SubmissionsBarChart = ({ left, height, width, xScale, data, clipKey, setTooltip, hideTooltip, setHighlightedComponent, hideData = false }: Props) => {
  const theme = useTheme();
  const [highlightedBar, setHighlightedBar] = useState<React.ReactNode | null>(null);

  const firstAndLastDate = useMemo(() => {
    if (Array.isArray(data)) {
      return [data[0].date, data[data.length - 1].date];
    } else {
      const firstData = data[Object.keys(data)[0]];
      return [firstData[0].date, firstData[firstData.length - 1].date];
    }
  }, [data]);

  const barSpacing = useMemo(() => {
    let spacing = 1;
    // compare the width between the first and second tick
    // if the labels overlap, increase the spacing and try again
    // (spacing unit === 1 day)
    const spaced = false;
    while (!spaced) {
      const firstX = xScale(firstAndLastDate[0]);
      const second = GET_NEXT_DATE_INTERVAL(firstAndLastDate[0], spacing);
      if (second > firstAndLastDate[1]) {
        // idk just return 1
        return 1;
      }
      const secondX = xScale(second);

      // we found our good spacing
      if (secondX - firstX >= MIN_TICK_WIDTH) break;

      // spacing must be odd
      spacing += 2;
    }

    return spacing;
  }, [width, data, xScale]);

  // Averages data based on barSpacing and assigns to buckets
  const aggregatedSubmissions = useMemo(() => {
    // if categorical, each key's num_samples will be the same across other key's dates
    const points = Array.isArray(data) ? data : data[Object.keys(data)[0]];

    if (barSpacing === 1) {
      // no aggregation needed
      return points.map((point) => {
        const x = xScale(point.date);
        return {
          start: point.date,
          value: point.num_samples,
          x
        };
      });
    }

    const aggregatedSub: Array<AggregatedSubmission> = [];
    let nextDate = GET_NEXT_DATE_INTERVAL(firstAndLastDate[0], barSpacing / 2);

    let bucket: Array<number> = [];
    for (let i = 0; i < points.length; i++) {
      const entry = points[i];
      if (entry.date < nextDate) {
        bucket.push(entry.num_samples);
        if (i === points.length - 1) {
          // last one
          const avg = bucket.reduce((a, b) => a + b) / bucket.length;
          const start = GET_NEXT_DATE_INTERVAL(nextDate, barSpacing, true);
          const x = xScale(start);
          aggregatedSub.push({ start, value: avg, x });
        }
      } else {
        const avg = bucket.reduce((a, b) => a + b) / bucket.length;
        const start = GET_NEXT_DATE_INTERVAL(nextDate, barSpacing, true);
        const x = xScale(start);
        aggregatedSub.push({ start, value: avg, x });
        bucket = [entry.num_samples];
        nextDate = GET_NEXT_DATE_INTERVAL(nextDate, barSpacing);
        i--;
      }
    }

    return aggregatedSub;
  }, [data, xScale, barSpacing]);

  // ----- Scales -----
  const barScale = useMemo(() => {
    if (Array.isArray(data)) {
      return scaleLinear({
        domain: [0, Math.max(...data.map((d) => d.num_samples)), 0],
        range: [height, 0]
      });
    } else {
      return scaleLinear({
        domain: [0, Math.max(...data[Object.keys(data)[0]].map((d) => d.num_samples)), 0],
        range: [height, 0]
      });
    }
  }, [data, height]);
  const getInvertScaleValue = useCallback(
    (value: number) => {
      return barScale.invert(value);
    },
    [barScale, height]
  );
  const memoBars = useMemo(() => {
    const bars: Array<{ height: number; width: number; y: number; x: number }> = [];

    aggregatedSubmissions.forEach((bar) => {
      // we add 10 here because we need a border radius, visx only supports rx on all corners so we clip the bottom with a mask
      const bheight = height - barScale(bar.value) + 10;
      const bwidth = xScale(GET_NEXT_DATE_INTERVAL(bar.start, barSpacing)) - xScale(bar.start);
      if (bheight <= 0 || bwidth <= 0) return; // skip dat
      const y = height - bheight + 10;
      bars.push({ x: bar.x, y, height: bheight, width: bwidth - 1 });
    });

    return bars;
  }, [aggregatedSubmissions, height, width, xScale, barSpacing]);

  const barMouseMove = useCallback(
    (e) => {
      const el = e.target as SVGElement;
      const x = Number(el.getAttribute('x'));
      const y = Number(el.getAttribute('y'));
      const width = Number(el.getAttribute('width'));
      const height = Number(el.getAttribute('height'));

      // find out component
      let meta: AggregatedSubmission | undefined = undefined;
      let first = false;
      let last = false;
      for (let i = 0; i < aggregatedSubmissions.length; i++) {
        if (aggregatedSubmissions[i].x === x) {
          meta = aggregatedSubmissions[i];
          if (i === 0) first = true;
          else if (i === aggregatedSubmissions.length - 1) last = true;
          break;
        }
      }
      // calculate what dates to display
      let date = null;
      if (meta) {
        if (barSpacing === 1) {
          date = (
            <Typography variant="label-small-tight" display="block" color={theme.color['neutral-75']}>
              Date: {DATE_FORMATTER(meta.start)}
            </Typography>
          );
        } else {
          let dateString = `Date: ${DATE_FORMATTER(meta.start)} - ${DATE_FORMATTER(GET_NEXT_DATE_INTERVAL(meta.start, barSpacing - 1))}`;
          if (first) {
            // first bar starts in the middle, so grab the mid date
            const mid = GET_NEXT_DATE_INTERVAL(meta.start, barSpacing / 2);
            if (mid === aggregatedSubmissions[1].start) {
              // just display a single date
              dateString = `Date: ${DATE_FORMATTER(mid)}`;
            } else {
              dateString = `Date: ${DATE_FORMATTER(mid)} - ${DATE_FORMATTER(GET_NEXT_DATE_INTERVAL(meta.start, barSpacing - 1))}`;
            }
          } else if (last) {
            const end = firstAndLastDate[1];
            if (end === meta.start) {
              dateString = `Date: ${DATE_FORMATTER(end)}`;
            } else {
              dateString = `Date: ${DATE_FORMATTER(meta.start)} - ${DATE_FORMATTER(end)}`;
            }
          }
          date = (
            <Typography variant="label-small-tight" display="block" color={theme.color['neutral-75']}>
              {dateString}
            </Typography>
          );
        }
      }
      // this is the one case where we want a component highlighted under other the rest of the data
      // components. so we just fake the overlay and render the extra component here
      setHighlightedComponent(<></>);
      setHighlightedBar(
        <Bar
          key={`bar-highlighted`}
          x={x}
          y={y}
          width={width}
          height={height}
          fill={theme.color['neutral-100']}
          clipPath={`url(#${clipKey})`}
          rx={theme.borderRadius.borderRadiusSemiSharp}
          style={{
            pointerEvents: 'none'
          }}
        />
      );
      setTooltip(
        <div>
          <Typography variant="label-small-tight" display="block" color={theme.color['neutral-75']}>
            {`Submissions${barSpacing > 1 ? ' (averaged)' : ''}: ${String(Math.round(getInvertScaleValue(y)))}`}
          </Typography>
          {date}
        </div>,
        e.clientX,
        y - 20
      );
    },
    [aggregatedSubmissions, barSpacing, barScale, getInvertScaleValue, setTooltip]
  );
  const barMouseLeave = useCallback(() => {
    hideTooltip();
    setHighlightedComponent(null);
    setHighlightedBar(null);
  }, []);

  const numTicks = useMemo(() => {
    // Grabs the amount of days between the dates
    const firstDate = new Date(firstAndLastDate[0]);
    const lastDate = new Date(firstAndLastDate[1]);
    lastDate.setHours(0, 0, 0);
    firstDate.setHours(0, 0, 0);

    const diffDays = Math.round(Math.abs((lastDate.getTime() - firstDate.getTime()) / ONE_DAY)) + 1;
    // TO-DO: Dynamic ticks / spacing
    return Math.ceil(diffDays / barSpacing);
  }, [data, barSpacing]);

  return (
    <>
      <RectClipPath id={clipKey} height={height} width={width} x={0} y={0} />
      <Group clipPath={clipKey}>
        {!hideData &&
          memoBars.map((bar, idx) => {
            return (
              <Bar
                key={`bar-${idx}`}
                x={bar.x}
                y={bar.y}
                width={bar.width}
                height={bar.height}
                fill={theme.color['neutral-25']}
                opacity={0.4}
                clipPath={`url(#${clipKey})`}
                rx={theme.borderRadius.borderRadiusSemiSharp}
                onMouseMove={barMouseMove}
                onMouseLeave={barMouseLeave}
              />
            );
          })}
        {/* inverse the opacity transition from mainTimeline to fake */}
        <Group style={{ opacity: highlightedBar ? 1 : 0.3, transition: 'linear 0.3s' }}>{highlightedBar}</Group>
      </Group>
      <SubmissionsYAxis left={width + left} height={height} scale={getInvertScaleValue} />
      <DateXAxis
        left={left}
        height={height}
        width={width}
        numTicks={numTicks}
        data={Array.isArray(data) ? data : data[Object.keys(data)[0]]}
        xScale={xScale}
        barSpacing={barSpacing}
      />
    </>
  );
};

export default memo(SubmissionsBarChart);
