import { memo, useCallback, useMemo } from 'react';

// ----- MUI -----
import { styled, Typography, useTheme } from '@mui/material';

// ----- PDS -----
import { ArrowDownOutlineIcon, ArrowUpOutlineIcon } from '../../../../../PremiseDesign/Icons';

// ----- VISX -----
import { Group } from '@visx/group';
import { Bar, Circle } from '@visx/shape';
import { scaleLog } from '@visx/scale';

// ----- Ours -----
import { DATE_FORMATTER } from '../constants';

// ----- Types -----
import { Alert } from '../../../../../types/grayzone';
import type { ScaleLinear } from 'd3-scale';

const Dropshadow = styled('svg')({
  WebkitFilter: 'drop-shadow(0px 3px 10px #0000005c)'
});

type Props = {
  data: Alert[];
  xScale: ScaleLinear<number, number>;
  getYonCurve: (date: number, rawValue?: boolean) => number | undefined;
  setTooltip: (data: string | React.ReactNode, left: number, top: number) => void;
  hideTooltip: () => void;
  setHighlightedComponent: (component: React.ReactNode, offset?: boolean) => void;
  height: number;
  clipKey: string;
};
const Alerts = ({ data, xScale, getYonCurve, setTooltip, hideTooltip, setHighlightedComponent, height, clipKey }: Props) => {
  const theme = useTheme();

  const width = 12;

  const circleInnerRadius = width / 2 - 1.5;

  // map x to each alert so we can grab on mousemove
  const xToDate = useMemo(() => {
    const map = new Map<number, number>();
    data.forEach((alert) => {
      const x = xScale(alert.date) - width / 2;
      map.set(x, alert.date);
    });

    return map;
  }, [data, width, xScale]);

  const alertScale = useMemo(() => {
    // get extent of data
    let min = -1;
    let max = 0;
    data.forEach((alert) => {
      const val = Math.abs(alert.probability);
      if (min === -1 || val < min) {
        min = val;
      }
      if (val > max) max = val;
    });

    return scaleLog({
      domain: [min, max],
      range: [height * 0.1, height * 0.25]
    });
  }, [data, height]);

  const alertMouseMove = useCallback(
    (e) => {
      const el = e.target as SVGElement;
      const rect = e.currentTarget.getBoundingClientRect();
      const x = Number(el.getAttribute('x'));
      const y = Number(el.getAttribute('y'));
      const width = Number(el.getAttribute('width'));
      const height = Number(el.getAttribute('height'));
      const fill = el.getAttribute('fill');

      const pos = fill === theme.color['brand-secondary-4-100'];
      const ArrowComponent = pos ? ArrowUpOutlineIcon : ArrowDownOutlineIcon;
      const circleInnerRadius = width - 3;

      const dropshadows = theme['box-shadow'].overlay.split('),');
      let dropshadow = '';
      dropshadows.forEach((ds) => (dropshadow += `drop-shadow(${ds})) `));

      setHighlightedComponent(
        <Group>
          <Dropshadow>
            <Bar key={'highlighted-alert'} x={x} width={width} y={y} height={height} fill={fill ?? ''} rx={width / 2} opacity={0.8} pointerEvents={'none'} />
            <Group pointerEvents={'none'}>
              <Circle cx={x + width / 2} cy={y + height / 2} fill={pos ? theme.color['brand-secondary-4-100'] : theme.color['brand-secondary-3-100']} r={width} />
              <Circle cx={x + width / 2} cy={y + height / 2} fill={pos ? theme.color['brand-secondary-4-10'] : theme.color['brand-secondary-3-10']} r={circleInnerRadius} />
              <Group transform={`translate(${x - width / 2}, ${y + height / 2 - width} )`}>
                <ArrowComponent size={(width * 2) / 24} color={pos ? theme.color['brand-secondary-4-100'] : theme.color['brand-secondary-3-100']} />
              </Group>
            </Group>
          </Dropshadow>
        </Group>,
        true
      );

      const date = xToDate.get(x);
      const sentiment = date ? getYonCurve(date, true) : null;
      const alert = alertScale.invert(height / 2); // height is magnitude * 2

      setTooltip(
        <div>
          {sentiment && (
            <Typography variant="label-small-tight" display="block" color={theme.color['neutral-75']}>
              {`Sentiment: ${Math.round(sentiment * 100)}%`}
              {', '}
              <Typography variant="label-small-tight" color={pos ? theme.color['brand-secondary-4-100'] : theme.color['brand-secondary-3-100']}>
                {pos ? '+' : '-'}
                {Math.round(alert * 100)}%
              </Typography>
            </Typography>
          )}

          {date && <Typography variant="label-small-tight" display="block" color={theme.color['neutral-75']}>{`Date: ${DATE_FORMATTER(date)}`}</Typography>}
        </div>,
        rect.left + width,
        y + height / 2 - 25
      );
    },
    [xToDate, getYonCurve]
  );
  const alertMouseLeave = useCallback(() => {
    setHighlightedComponent(null);
    hideTooltip();
  }, []);

  return (
    <Group clipPath={`url(#${clipKey})`}>
      {data.map((alert) => {
        const x = xScale(alert.date);
        const y = getYonCurve(alert.date);
        if (y === undefined) return;

        const ArrowComponent = alert.probability > 0 ? ArrowUpOutlineIcon : ArrowDownOutlineIcon;

        const magnitude = alertScale(Math.abs(alert.probability));

        // icon width = 24 * x;
        return (
          <>
            <Bar
              key={alert.id}
              x={x - width / 2}
              width={width}
              y={y - magnitude}
              height={magnitude * 2}
              fill={alert.probability > 0 ? theme.color['brand-secondary-4-100'] : theme.color['brand-secondary-3-100']}
              rx={width / 2}
              opacity={0.8}
              onMouseMove={alertMouseMove}
              onMouseLeave={alertMouseLeave}
            />
            <Group pointerEvents={'none'} key={`${alert.id}-group`}>
              <Circle cx={x} cy={y} fill={alert.probability > 0 ? theme.color['brand-secondary-4-100'] : theme.color['brand-secondary-3-100']} r={width / 4} />
              <Circle cx={x} cy={y} fill={alert.probability > 0 ? theme.color['brand-secondary-4-10'] : theme.color['brand-secondary-3-10']} r={circleInnerRadius} />
              <Group transform={`translate(${x - width / 2}, ${y - width / 2})`}>
                <ArrowComponent size={width / 24} color={alert.probability > 0 ? theme.color['brand-secondary-4-100'] : theme.color['brand-secondary-3-100']} />
              </Group>
            </Group>
          </>
        );
        // return <Line key={alert.id} x1={x} x2={x} y1={y - magnitude} y2={y + magnitude} strokeWidth={8} />;
      })}
    </Group>
  );
};

export default memo(Alerts);
