import { memo, useCallback } from 'react';

// ----- Redux -----
import { Update } from '@reduxjs/toolkit';
import { useAppDispatch } from '../../../hooks/useRedux';
import { grayzoneActions } from '../../../features/grayzone/grayzoneSlice';

// ----- MUI -----
import { styled } from '@mui/material';

// ----- PDS -----
import { AddOutlineIcon, BoundaryL0FillIcon, BoundaryL1FillIcon, BoundaryL2FillIcon, HomeFillIcon, MinusOutlineIcon } from '../../../PremiseDesign/Icons';
import ButtonGroup from '../../../PremiseDesign/Components/ButtonGroup';
import IconButton from '../../../PremiseDesign/Components/IconButton';

// ----- Modules -----
import { MapRef } from 'react-map-gl';

// ----- Types -----
import type { MapWidget } from '../../../types/grayzone';

const ControlContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: `calc(${theme.spacings.small} + 40px)`,
  right: theme.spacings.small,
  zIndex: 400,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacings['xx-small']
}));

type Props = {
  mapRef: React.MutableRefObject<MapRef | null>;
  widget: MapWidget;
  flyHome: () => void;
};
const MapControls = ({ mapRef, widget, flyHome }: Props) => {
  const dispatch = useAppDispatch();

  const zoomIn = useCallback(() => {
    mapRef.current?.zoomIn();
  }, []);
  const zoomOut = useCallback(() => {
    mapRef.current?.zoomOut();
  }, []);
  // TODO: this is bad. make better
  const setL0 = useCallback(() => {
    const updates: Update<MapWidget> = {
      id: widget.id,
      changes: { choroplethLevel: 0 }
    };
    dispatch(grayzoneActions.updateWidget(updates));
  }, []);
  const setL1 = useCallback(() => {
    const updates: Update<MapWidget> = {
      id: widget.id,
      changes: { choroplethLevel: 1 }
    };
    dispatch(grayzoneActions.updateWidget(updates));
  }, []);
  const setL2 = useCallback(() => {
    const updates: Update<MapWidget> = {
      id: widget.id,
      changes: { choroplethLevel: 2 }
    };
    dispatch(grayzoneActions.updateWidget(updates));
  }, []);

  return (
    <ControlContainer>
      {/* TODO: button groups are style only, make them functional */}
      <ButtonGroup>
        <IconButton onClick={zoomIn} shadow={true} variant="mini">
          <AddOutlineIcon />
        </IconButton>
        <IconButton onClick={zoomOut} shadow={true} variant="mini">
          <MinusOutlineIcon />
        </IconButton>
      </ButtonGroup>

      {/* TODO: this is so bad lol. change later */}
      <ButtonGroup>
        {/* TODO: L0 not working, endpoint not hitting */}
        <IconButton onClick={setL0} shadow={true} variant="mini" selected={widget.choroplethLevel === 0}>
          <BoundaryL0FillIcon />
        </IconButton>
        <IconButton onClick={setL1} shadow={true} variant="mini" selected={widget.choroplethLevel === 1}>
          <BoundaryL1FillIcon />
        </IconButton>
        <IconButton onClick={setL2} shadow={true} variant="mini" selected={widget.choroplethLevel === 2}>
          <BoundaryL2FillIcon />
        </IconButton>
      </ButtonGroup>

      <IconButton onClick={flyHome} shadow={true} variant="mini">
        <HomeFillIcon />
      </IconButton>
    </ControlContainer>
  );
};

export default memo(MapControls);
