// ----- REACT -----
import { ChangeEvent, useEffect, useState, CSSProperties, useCallback, MutableRefObject, useRef } from 'react';

// ----- API -----
import { sentimentEndpoints } from '../../../api/sentiment';

// ----- MODULES -----
import { CircularProgress, TextField, Typography, Box, useTheme } from '@mui/material';
import moment from 'moment';
import { v4 as uuid_v4 } from 'uuid';
import amplitude from 'amplitude-js';

// ----- OURS -----
import { countryAdapter, formAdapter, formQuestionAdapter } from '../../../helpers/grayzone';
import DebouncedTextfield from '../../../components/DebouncedTextfield';
import VirtualizedAutocomplete from '../../../components/VirtualizedAutocomplete';
import useAppSnackbar from '../../../hooks/useAppSnackbar';

// ----- STYLES -----
import { FlexScrollableList } from '../../../styles';

// ----- TYPES -----
import { Country, Form, FormQuestion, ViewConfiguration } from '../../../types/grayzone';
import { Update } from '@reduxjs/toolkit';

// ----- COMPONENT -----
type UpdateViewProps = {
  submitRef?: MutableRefObject<null | (() => void)>;
  onSubmit: (value: Update<ViewConfiguration>) => void;
  onChange?: () => void;
  currConfiguration: ViewConfiguration;
  style?: CSSProperties;
  formType: 'update';
  submitOnComplete?: boolean;
};

type NewViewProps = {
  submitRef?: MutableRefObject<null | (() => void)>;
  onSubmit: (value: ViewConfiguration) => void;
  onChange?: () => void;
  currConfiguration: undefined;
  style?: CSSProperties;
  formType: 'new';
  submitOnComplete?: boolean;
};
type Props = NewViewProps | UpdateViewProps;
const ViewConfig = ({ submitRef, onSubmit, onChange, currConfiguration, style, formType, submitOnComplete = false }: Props) => {
  const theme = useTheme();
  const { enqueue } = useAppSnackbar();

  // ----- Local State -----
  const [selectedName, setSelectedName] = useState<string>('');
  const [selectedForms, setSelectedForms] = useState<Form[]>([]);
  const [selectedQuestions, setSelectedQuestions] = useState<FormQuestion[]>([]);
  const [selectedCountries, setSelectedCountries] = useState<Country[]>([]);
  const [selectedHomeBounds, setSelectedHomeBounds] = useState<[[number, number], [number, number]] | undefined>(currConfiguration?.homeBounds);

  // ----- Queries -----
  const [triggerFormsQuery, formsQueryResult] = sentimentEndpoints.getAvailableForms.useLazyQuery();
  const [triggerQuestionsQuery, questionsQueryResult] = sentimentEndpoints.getQuestionsFromForm.useLazyQuery();
  const [triggerCountriesQuery, countriesQueryResult] = sentimentEndpoints.getAvailableGeographies.useLazyQuery();
  const [triggerHascBoundsQuery] = sentimentEndpoints.getHascBounds.useLazyQuery();
  const queryTriggerPromises = useRef<ReturnType<typeof triggerFormsQuery | typeof triggerQuestionsQuery | typeof triggerCountriesQuery | typeof triggerHascBoundsQuery>[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const hascBoundsQuery = getHascBounds.useQuery({ hasc: selectedCountries[0]?.hasc }, { skip: selectedCountries.length === 0 });

  // ----- Helpers -----
  const validate = (): boolean => {
    if (selectedName === undefined || selectedName.length === 0) {
      console.warn('Name is malformed');
      return false;
    } else if (selectedForms === undefined || selectedForms.length === 0) {
      console.warn('Forms is malformed');
      return false;
    } else if (selectedQuestions === undefined || selectedQuestions.length === 0) {
      console.warn('Questions is malformed');
      return false;
    } else if (selectedCountries === undefined || selectedCountries.length === 0) {
      console.warn('Countries is malformed');
      return false;
    }
    return true;
  };

  // ----- Handlers -----
  const handleClickedSubmit = () => {
    if (!validate()) {
      enqueue('Please complete all sections', { variant: 'warning' });
      return;
    }
    switch (formType) {
      case 'new': {
        const newView: ViewConfiguration = {
          id: uuid_v4(),
          name: selectedName,
          forms: selectedForms.map((form) => form.form_id),
          questions: selectedQuestions.map((question) => question.question_name),
          countries: selectedCountries.map((country) => country.hasc),
          dateRange: [
            selectedForms?.[0]?.data_starts ? selectedForms?.[0]?.data_starts.toString() : new Date().getTime().toString(),
            selectedForms?.[0]?.data_ends ? selectedForms?.[0]?.data_ends.toString() : new Date().getTime().toString()
          ],
          dateFilter: [null, null],
          topics: [],
          homeBounds: selectedHomeBounds ?? [
            [20, 20],
            [0, 0]
          ],
          selectedCategoricalResponse: null
        };
        onSubmit(newView);
        amplitude.getInstance().logEvent('Added View', newView);

        break;
      }

      case 'update': {
        const updates: Update<ViewConfiguration> = {
          id: currConfiguration.id,
          changes: {
            name: selectedName,
            forms: selectedForms.map((form) => form.form_id),
            questions: selectedQuestions.map((question) => question.question_name),
            countries: selectedCountries.map((country) => country.hasc),
            dateRange: [
              selectedForms?.[0]?.data_starts ? selectedForms?.[0]?.data_starts.toString() : new Date().getTime().toString(),
              selectedForms?.[0]?.data_ends ? selectedForms?.[0]?.data_ends.toString() : new Date().getTime().toString()
            ],
            dateFilter: [null, null],
            topics: [],
            homeBounds: selectedHomeBounds ?? [
              [20, 20],
              [0, 0]
            ],
            selectedCategoricalResponse: null
          }
        };
        onSubmit(updates);
        break;
      }
      default:
        break;
    }
  };

  const handleViewConfigurationChanged = useCallback(async (configuration: ViewConfiguration) => {
    // Set name
    setSelectedName(configuration.name);

    // Check that forms data is available
    const formsPromise = triggerFormsQuery(undefined, true);
    queryTriggerPromises.current.push(formsPromise);
    const formsResult = await formsPromise;
    const formsData = formsResult.data;
    if (formsResult.error || !formsData) {
      throw new Error('Forms data should be set before calling this function');
    } else if (formAdapter.defaultSelectors.selectTotal(formsData) < 1) {
      enqueue('No forms are available right now', { variant: 'info' });
      return;
    }
    setSelectedForms(configuration.forms.flatMap((form_id) => formAdapter.defaultSelectors.selectById(formsData, form_id) ?? []));

    // Get the questions
    const questionsPromise = triggerQuestionsQuery({ selectedForms: configuration.forms }, true);
    queryTriggerPromises.current.push(questionsPromise);
    const questionsResult = await questionsPromise;
    const questionsData = questionsResult.data;

    if (questionsResult.error || !questionsData) {
      enqueue('Could not fetch questions', { variant: 'error' });
      return;
    } else if (formQuestionAdapter.defaultSelectors.selectTotal(questionsData) < 1) {
      enqueue('No questions are available for this form', { variant: 'info' });
      return;
    }
    setSelectedQuestions(configuration.questions.flatMap((questionName) => formQuestionAdapter.defaultSelectors.selectById(questionsData, questionName) ?? []));

    // Get the countries
    const countriesPromise = triggerCountriesQuery(
      {
        form_id: configuration.forms[0],
        question_names: configuration.questions
      },
      true
    );
    queryTriggerPromises.current.push(countriesPromise);
    const countriesResult = await countriesPromise;
    const countriesData = countriesResult.data;

    if (countriesResult.error || !countriesData) {
      enqueue('Could not fetch countries', { variant: 'error' });
      return;
    } else if (countryAdapter.defaultSelectors.selectTotal(countriesData) < 1) {
      enqueue('No countries are available for this question', { variant: 'info' });
      return;
    }

    setSelectedCountries(configuration.countries.flatMap((hasc) => countryAdapter.defaultSelectors.selectById(countriesData, hasc) ?? []));
    setSelectedHomeBounds(configuration.homeBounds);
    return [questionsPromise, countriesPromise];
  }, []);

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange?.();
    setSelectedName(e.target.value);
  };

  const handleFormsChange = (event: React.SyntheticEvent<Element, Event>, value: Form[] | Form | null) => {
    onChange?.();
    const updatedForms = value ? (Array.isArray(value) ? value : [value]) : [];
    setSelectedForms(updatedForms);
    setSelectedQuestions([]);
    setSelectedCountries([]);
    setSelectedHomeBounds(undefined);

    if (updatedForms.length > 0) {
      const promise = triggerQuestionsQuery({ selectedForms: updatedForms.map((form) => form.form_id) }, true);
      queryTriggerPromises.current.push(promise);
    }
  };

  const handleQuestionsChange = (event: React.SyntheticEvent<Element, Event>, value: FormQuestion[] | FormQuestion | null) => {
    onChange?.();
    const updatedQuestions = value ? (Array.isArray(value) ? value : [value]) : [];
    setSelectedQuestions(updatedQuestions);
    setSelectedCountries([]);
    setSelectedHomeBounds(undefined);

    if (updatedQuestions.length > 0) {
      const promise = triggerCountriesQuery({ form_id: selectedForms[0]?.form_id, question_names: updatedQuestions.map((question) => question.question_name) }, true);
      queryTriggerPromises.current.push(promise);
    }
  };

  const handleCountriesChange = async (event: React.SyntheticEvent<Element, Event>, value: Country[] | Country | null) => {
    onChange?.();
    const updatedCountries = value ? (Array.isArray(value) ? value : [value]) : [];

    const hascBoundsPromise = updatedCountries[0]?.hasc ? triggerHascBoundsQuery({ hasc: updatedCountries[0].hasc }) : null;
    let homeBounds: [[number, number], [number, number]] | undefined = undefined;
    if (hascBoundsPromise !== null) {
      queryTriggerPromises.current.push(hascBoundsPromise);
      const hascBoundsResult = await hascBoundsPromise;
      if (!hascBoundsResult.error && hascBoundsResult.data && hascBoundsResult.data.length === 4) {
        homeBounds = [
          [hascBoundsResult.data[1], hascBoundsResult.data[0]],
          [hascBoundsResult.data[3], hascBoundsResult.data[2]]
        ];
      } else {
        enqueue('Home bounds could not be fetched for select country', { variant: 'error' });
      }
    }
    setSelectedHomeBounds(homeBounds);
    setSelectedCountries(updatedCountries);
  };

  // ----- Effects -----
  useEffect(() => {
    // entry point for submitOnComplete
    if (submitOnComplete && selectedCountries.length > 0) handleClickedSubmit();
  }, [selectedCountries]);

  useEffect(() => {
    switch (formType) {
      case 'new':
        break;
      case 'update':
        setSelectedName('');
        setSelectedForms([]);
        setSelectedQuestions([]);
        setSelectedCountries([]);
        handleViewConfigurationChanged(currConfiguration).catch(console.log);
        break;
      default:
        break;
    }
  }, [currConfiguration?.id]);

  useEffect(() => {
    triggerFormsQuery(undefined, true);
  }, []);

  useEffect(() => {
    if (submitRef) {
      submitRef.current = handleClickedSubmit;
    }
  }, [handleClickedSubmit, submitRef]);

  // Ondismount
  useEffect(() => {
    return () => {
      queryTriggerPromises.current.forEach((p) => {
        p.abort();
      });
    };
  }, []);

  // ----- Helper Components -----
  const customFormOption = (form: Form, forms: Form[]) => {
    const idLabelColor = forms.some((someForm) => someForm.form_id === form.form_id) ? theme.color['dataviz-1-75'] : theme.color['brand-primary-75'];
    const detailsColor = forms.some((someForm) => someForm.form_id === form.form_id) ? theme.color['neutral-100'] : theme.color['neutral-50'];

    return (
      <Box style={{ display: 'flex', flexDirection: 'column' }}>
        <Typography component="div" variant="subhead-3">
          {form.form_name}
        </Typography>
        <Typography component="div" variant="label-small" color={idLabelColor}>
          {form.form_id}
        </Typography>
        <Typography component="div" variant="label-tight" color={detailsColor}>{`${form.project_name} (${form.project_id})`}</Typography>
        <Typography component="div" variant="label-tight" color={detailsColor}>{`${moment(form.data_starts).format('YYYY-MM-DD')} \u2194 ${moment(form.data_ends).format(
          'YYYY-MM-DD'
        )}`}</Typography>
        <Typography component="div" variant="label-tight" color={detailsColor}>{`${form.completed_questions} of ${form.available_questions} Questions Active`}</Typography>
      </Box>
    );
  };

  // ----- Return Component -----

  return (
    <FlexScrollableList style={{ flex: 1, gap: '20px', padding: '20px 20px', ...style }}>
      {/* // ----- NAME ----- */}
      <DebouncedTextfield fullWidth id="input-name" onChangeDebounced={handleNameChange} value={selectedName} delay={600} label="Name" margin="none" variant="standard" />
      {/* ----- FORMS ----- */}
      <VirtualizedAutocomplete
        loading={formsQueryResult.isFetching}
        // limitTags={5}
        // disableCloseOnSelect
        rowHeight={120}
        onChange={handleFormsChange}
        id="select-a-form"
        fullWidth
        disableListWrap
        getOptionLabel={(form) => form.form_name}
        value={selectedForms.length > 0 ? selectedForms[0] : null}
        options={formsQueryResult.data ? formAdapter.defaultSelectors.selectAll(formsQueryResult.data) : []}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              label="Select a Form"
              variant={'standard'}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {formsQueryResult.isFetching ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          );
        }}
        renderOption={(props, form) => [props, customFormOption(form, selectedForms)]}
      />
      {/* ----- QUESTIONS ----- */}
      <VirtualizedAutocomplete
        // limitTags={5}
        // disableCloseOnSelect
        rowHeight={80}
        loading={questionsQueryResult.isFetching}
        onChange={handleQuestionsChange}
        id="select-questions-of-interest"
        fullWidth
        disableListWrap
        getOptionLabel={(question) => question.question_proper}
        options={questionsQueryResult.data ? formQuestionAdapter.defaultSelectors.selectAll(questionsQueryResult.data) : []}
        value={selectedQuestions.length > 0 ? selectedQuestions[0] : null}
        renderOption={(props, question) => [
          props,
          <Typography key={question.question_name} variant="label-small">
            {question.question_proper}
          </Typography>
        ]}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              label="Select Questions of Interest"
              variant={'standard'}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {questionsQueryResult.isFetching ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          );
        }}
        disabled={selectedForms.length === 0 || questionsQueryResult.isFetching || !questionsQueryResult.data}
      />
      {/* ----- COUNTRIES ----- */}
      <VirtualizedAutocomplete
        // limitTags={5}
        // disableCloseOnSelect
        loading={countriesQueryResult.isFetching}
        onChange={handleCountriesChange}
        id="select-countries-of-interest"
        fullWidth
        disableListWrap
        getOptionLabel={(country) => country.l0_name}
        options={countriesQueryResult.data ? countryAdapter.defaultSelectors.selectAll(countriesQueryResult.data) : []}
        value={selectedCountries.length > 0 ? selectedCountries[0] : null}
        renderOption={(props, country) => [
          props,
          <Typography key={country.hasc} variant="label">
            {country.l0_name}
          </Typography>
        ]}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              label="Select Countries of Interest"
              variant={'standard'}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {countriesQueryResult.isFetching ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          );
        }}
        disabled={selectedQuestions.length === 0 || countriesQueryResult.isFetching || !countriesQueryResult.data}
      />
      {/* ----- DATES ----- */}
      {/* <Box width="100%" display="flex" alignItems="center" flexDirection="column">
        <Typography>Select Date Range</Typography>
        <br></br>
        <CustomDateRange
          // eslint-disable-line
          ariaLabels={{ dateInput: { selection: { startDate: 'start date input' as unknown as Date, endDate: 'end date input' as unknown as Date } } }}
          startDatePlaceholder="Start Date"
          endDatePlaceholder="End Date"
          minDate={selectedForms?.[0]?.data_starts ? new Date(selectedForms?.[0]?.data_starts) : undefined}
          maxDate={selectedForms?.[0]?.data_ends ? new Date(selectedForms?.[0]?.data_ends) : undefined}
          onChange={(item) => handleDateChange(item.selection)}
          ranges={[selectedDateRange]}
        /> */}
      {/* Keep for Mui X integration */}
      {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateRangePicker
                minDate={new Date(selectedForms?.[0]?.data_starts)}
                maxDate={new Date(selectedForms?.[0]?.data_ends)}
                defaultCalendarMonth={new Date(selectedForms?.[0]?.data_starts)}
                startText="Start Date"
                endText="End Date"
                value={selectedDateRange}
                onChange={handleDateChange}
                renderInput={(startProps: TextFieldProps, endProps: TextFieldProps) => (
                  <Box width="100%" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: '10px' }}>
                    <TextField {...startProps} fullWidth />
                    <Typography variant="label">to</Typography>
                    <TextField {...endProps} fullWidth />
                  </Box>
                )}
                disabled={selectedForms.length === 0}
              />
            </LocalizationProvider> */}
      {/* </Box> */}
      {/* ----- TOPICS ----- */}
      {/* <VirtualizedAutocomplete
        multiple
        disableCloseOnSelect
        disabled
        limitTags={5}
        onChange={handleTopicsChange}
        id="select-topics-of-interest"
        fullWidth
        disableListWrap
        getOptionLabel={(topic) => topic}
        options={[] as string[]}
        value={selectedTopics}
        renderOption={(props, topic) => [
          props,
          <Typography key={topic} variant="label">
            {topic}
          </Typography>
        ]}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              label="Select Topics of Interest"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {countriesQueryResult.isFetching ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          );
        }}
      /> */}
    </FlexScrollableList>
  );
};

export default ViewConfig;
