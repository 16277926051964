/* eslint-disable @typescript-eslint/no-empty-interface */
import { createTheme } from '@mui/material/styles';
import React from 'react';
import premiseTheme from './premiseTheme';
import premiseOverrides from './overrides';

const theme = createTheme({
  components: premiseOverrides,
  ...premiseTheme
});

interface CustomTheme {
  color: {
    'brand-primary-150': string;
    'brand-primary-125': string;
    'brand-primary': string;
    'brand-primary-75': string;
    'brand-primary-50': string;
    'brand-primary-25': string;
    'brand-primary-10': string;
    'neutral-100': string;
    'neutral-75': string;
    'neutral-50': string;
    'neutral-25': string;
    'neutral-10': string;
    'neutral-0': string;
    'status-success-100': string;
    'status-success-25': string;
    'status-warning-100': string;
    'status-danger-100': string;
    'status-danger-25': string;
    'brand-secondary-1-125': string;
    'brand-secondary-1-100': string;
    'brand-secondary-1-75': string;
    'brand-secondary-1-50': string;
    'brand-secondary-1-25': string;
    'brand-secondary-1-10': string;
    'brand-secondary-2-125': string;
    'brand-secondary-2-100': string;
    'brand-secondary-2-75': string;
    'brand-secondary-2-50': string;
    'brand-secondary-2-25': string;
    'brand-secondary-2-10': string;
    'brand-secondary-3-125': string;
    'brand-secondary-3-100': string;
    'brand-secondary-3-75': string;
    'brand-secondary-3-50': string;
    'brand-secondary-3-25': string;
    'brand-secondary-3-10': string;
    'brand-secondary-4-125': string;
    'brand-secondary-4-100': string;
    'brand-secondary-4-75': string;
    'brand-secondary-4-50': string;
    'brand-secondary-4-25': string;
    'brand-secondary-4-10': string;
    'dataviz-1-100': string;
    'dataviz-1-75': string;
    'dataviz-1-50': string;
    'dataviz-1-25': string;
    'dataviz-1-10': string;
    'neutral-100-opacity-low': string;
  };
  opacity: {
    disabled: number;
    high: number;
    medium: number;
    low: number;
  };
  fontFamily: {
    heading: string;
    text: string;
  };
  borderRadius: {
    borderRadiusSharp: number;
    borderRadiusSemiSharp: number;
    borderRadius: number;
    borderRadiusSmooth: number;
    borderRadiusPill: string;
  };
  iconScale: {
    'x-small': string;
    small: string;
    regular: string;
    large: string;
    'object-selected': string;
  };
  iconColor: {
    'neutral-100': string;
    'brand-primary': string;
    'brand-primary-125': string;
    'neutral-75': string;
    'neutral-50': string;
    'status-success': string;
  };
  spacings: {
    'xxxx-small': string;
    'xxx-small': string;
    'xx-small': string;
    'x-small': string;
    small: string;
    large: string;
    'x-large': string;
    'xx-large': string;
    'xxx-large': string;
  };
  'box-shadow': {
    card: string;
    'card-light': string;
    layout: string;
    overlay: string;
    focus: string;
    'card-hover': string;
    'map-object': string;
    'map-object-selected': string;
    'dataviz-hover': string;
  };
}

// Typescript augments for MUI down here
declare module '@mui/material/styles' {
  interface Theme extends CustomTheme {}
  interface ThemeOptions extends CustomTheme {}
  interface TypographyVariants {
    body: React.CSSProperties;
    'body-bold': React.CSSProperties;
    'body-small': React.CSSProperties;
    'body-small-bold': React.CSSProperties;
    'subhead-1': React.CSSProperties;
    'subhead-2': React.CSSProperties;
    'subhead-3': React.CSSProperties;
    'subhead-4': React.CSSProperties;
    'display-4': React.CSSProperties;
    'button-tertiary': React.CSSProperties;
    hyperlink: React.CSSProperties;
    'hyperlink-small': React.CSSProperties;
    label: React.CSSProperties;
    'label-bold': React.CSSProperties;
    'label-heavy': React.CSSProperties;
    'label-small': React.CSSProperties;
    'label-small-tight': React.CSSProperties;
    'label-small-bold': React.CSSProperties;
    'label-small-bold-tight': React.CSSProperties;
    'label-small-heavy': React.CSSProperties;
    'label-large': React.CSSProperties;
    'label-large-bold': React.CSSProperties;
    'label-large-heavy': React.CSSProperties;
    'button-tertiary-mini': React.CSSProperties;
    'label-bold-tight': React.CSSProperties;
    'label-tight': React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    // ---------- disable variants
    body1: false;
    body2: false;
    subtitle1: false;
    subtitle2: false;
    button: false;
    h5: false;
    h6: false;
    inherit: false;
    overline: false;
    caption: false;
    // ---------- add variants
    body: true;
    'body-bold': true;
    'body-small': true;
    'body-small-bold': true;
    'subhead-1': true;
    'subhead-2': true;
    'subhead-3': true;
    'subhead-4': true;
    'display-4': true;
    'button-tertiary': true;
    hyperlink: true;
    'hyperlink-small': true;
    label: true;
    'label-bold': true;
    'label-heavy': true;
    'label-small': true;
    'label-small-bold': true;
    'label-small-bold-tight': true;
    'label-small-heavy': true;
    'label-large': true;
    'label-large-bold': true;
    'label-large-heavy': true;
    'button-tertiary-mini': true;
    'label-bold-tight': true;
    'label-tight': true;
    'label-small-tight': true;
  }
}

export default theme;
