/* eslint-disable max-len */
// BASEUI-GENERATED-REACT-ICON
// DO NOT EDIT THIS FILE DIRECTLY, SEE README.md
import * as React from 'react';
import type { IconProps } from '../Icon';
import Icon from '../Icon';

const CautionOutlineIcon = (props: IconProps): JSX.Element => {
  const {
    title = 'Caution Outline Icon',
    size,
    color,
    onClick,
    ...restProps
  } = props;
  return (
    <Icon
      title={title}
      size={size}
      color={color}
      onClick={onClick}
      {...restProps}
    >
      <path d="M12 7C12.5523 7 13 7.44772 13 8V12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12V8C11 7.44772 11.4477 7 12 7Z"></path>
      <path d="M12 16C12.5523 16 13 15.5523 13 15C13 14.4477 12.5523 14 12 14C11.4477 14 11 14.4477 11 15C11 15.5523 11.4477 16 12 16Z"></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5981 3.5L21.5263 15.5C22.681 17.5 21.2376 20 18.9282 20H5.0718C2.7624 20 1.31902 17.5 2.47372 15.5L9.40192 3.5C10.5566 1.5 13.4434 1.5 14.5981 3.5ZM12.866 4.5C12.4811 3.83333 11.5189 3.83333 11.134 4.5L4.20577 16.5C3.82087 17.1667 4.302 18 5.0718 18H18.9282C19.698 18 20.1791 17.1667 19.7942 16.5L12.866 4.5Z"
      ></path>
    </Icon>
  );
};

export default CautionOutlineIcon;
/* eslint-enable max-len */
