// ----- REACT -----
import { ChangeEvent, memo, MutableRefObject, useCallback, useEffect, useState } from 'react';

// ----- MUI -----
import { TextField, TextFieldProps } from '@mui/material';

// ----- MODULES -----
import { debounce, DebouncedFunc } from 'lodash';

type Props = {
  delay: number;
  flushRef?: MutableRefObject<null | (() => string | undefined)>;
  onChangeDebounced?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
};
const DebouncedTextfield = (props: Props & TextFieldProps) => {
  const { delay, onChangeDebounced, flushRef, ...textFieldProps } = props;
  // ----- Local State -----
  const [localValue, setLocalValue] = useState<string | undefined>(textFieldProps.value !== undefined ? (textFieldProps.value as string) : undefined);
  // ----- Handlers -----
  const handleDebouncedChange: DebouncedFunc<(e: ChangeEvent<HTMLInputElement>) => string> = useCallback(
    debounce((e: ChangeEvent<HTMLInputElement>) => {
      onChangeDebounced && onChangeDebounced(e);
      return e.target.value;
    }, delay),
    [onChangeDebounced]
  );

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    textFieldProps.value !== undefined && setLocalValue(e.target.value);
    textFieldProps.onChange && textFieldProps.onChange(e);
    handleDebouncedChange(e);
  };

  // ----- Effects -----

  useEffect(() => {
    return function () {
      handleDebouncedChange.flush();
    };
  }, []);

  useEffect(() => {
    if (flushRef) {
      flushRef.current = handleDebouncedChange.flush;
    }
  }, [handleDebouncedChange, flushRef]);

  useEffect(() => {
    if (props.value !== undefined && (props.value as string) !== localValue) {
      setLocalValue(props.value as string);
    }
  }, [props.value]);

  return <TextField {...(textFieldProps as TextFieldProps)} onChange={handleChange} value={localValue} />;
};

export default memo(DebouncedTextfield);
