import { useMemo, useState, useEffect, useCallback, useRef } from 'react';

// ----- MUI -----
import { Box, useTheme } from '@mui/material';

// ----- Map -----
import Map, { AttributionControl, MapboxStyle, MapRef } from 'react-map-gl';

// ----- Redux -----
import { useAppSelector } from '../../../hooks/useRedux';
import { sentimentEndpoints } from '../../../api/sentiment';

// ----- Ours -----
import useGrid from '../../../hooks/useGrid';
import { getColorScheme } from './utils/colors';
import { grayzoneSelectors } from '../../../features/grayzone/grayzoneSlice';
import { withSize } from 'react-sizeme';
import generateStyle from './utils/generateStyle';
import CustomMarker from './CustomMarker';
import MapControls from './MapControls';
import MapLegend from './MapLegend';

// ----- Modules -----
import { isNumber } from 'lodash';
import { load } from 'protobufjs';
import { interpolateRgbBasis, color } from 'd3';
import mapboxgl from 'mapbox-gl';

// ----- Types -----
import { FeedDataType, GenericMap } from '../../../types/grayzone';
import type { MapProps } from '.';

// token
import { mapboxToken } from './utils/constants';

/* eslint-disable @typescript-eslint/no-var-requires */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

load('static/iris.proto', (e, root) => {
  if (e || root === undefined) {
    return console.error('failed to load protobuf defs');
  }

  window.irisProto = {
    ThinResponse: root.lookupType('iris.ThinResponse')
  };
});

type Props = {
  size: {
    width: number;
    height: number;
  };
} & MapProps;
const DeckMap = ({ widget, size, feedData, categoricalColorScheme }: Props) => {
  const theme = useTheme();
  const { gridLayout } = useGrid();

  // ----- Local State -----
  const [selectedMarkerIndex, setSelectedMarkerIndex] = useState<number | null>(null);
  const [ordering, setOrdering] = useState<{ label: string; order: string; responses: string[] }[]>([]);
  const [mapStyle, setMapStyle] = useState<MapboxStyle | null>(null);

  const mapRef = useRef<MapRef | null>(null);

  // ----- Selectors -----
  const viewConfigurationSelectors = useMemo(() => grayzoneSelectors.viewConfigurations.getSelectors(), []);
  const parentView = useAppSelector((state) => viewConfigurationSelectors.selectById(state.grayzone.viewConfigurations, gridLayout.viewConfigId));
  const activeCategorical = parentView?.selectedCategoricalResponse ?? null;
  const highlightedNewsEvent = useAppSelector(grayzoneSelectors.hoverState.selectHighlightedNewsEvent);

  if (!parentView) {
    throw new Error(`No parent view found inside widget with id: ${widget.id}. A parent view MUST be configured for any widget to belong to it.`);
  }

  const colorScheme = useMemo(() => {
    if (ordering.length === 0) return [];

    if (categoricalColorScheme) {
      const colors: GenericMap<string> = {};
      ordering.forEach((item) => {
        // parseFloat to remove trailing 0's, 0.9090 !== 0.909 in map
        colors[Math.trunc(parseFloat(item.order) * 100) / 100] = categoricalColorScheme[item.label];
      });
      return colors;
    }
    // filter ordering values that aren't numbers
    const length = ordering.filter((o) => o !== null).length;
    return getColorScheme(length, theme);
  }, [ordering, categoricalColorScheme]);

  const legendItems = useMemo(() => {
    if (activeCategorical) {
      const ramp = interpolateRgbBasis(['#fcdff5', '#7E1B65']);
      return Array.from(Array(5).keys())
        .reverse()
        .map((value) => ({ color: color(ramp(value / 5))?.formatRgb() ?? 'rgba(0,0,0,0)', label: `${(100 * value) / 4}%` }));
    } else if (colorScheme) {
      return ordering.map((o) => ({
        color: colorScheme[(Math.trunc(parseFloat(o.order) * 100) / 100) as never] ?? 'rgba(0,0,0,0)',
        label: o.label
      }));
    } else {
      return [];
    }
  }, [ordering, colorScheme, activeCategorical]);

  // ----- Queries -----
  const [fireThinGeoQuery, thinGeoQuery] = sentimentEndpoints.getThinGeo.useLazyQuery();
  const orderingQuery = sentimentEndpoints.getOrdering.useQuery({ questionName: parentView.questions[0] ?? '', formId: parentView.forms[0] ?? '' });

  useEffect(() => {
    if (orderingQuery.isError) {
      console.log(orderingQuery.error);
    } else {
      if (orderingQuery.data === undefined) return;
      setOrdering(
        Object.entries(orderingQuery.data)
          .filter((o) => o[1] !== null)
          .sort((a, b) => {
            if (a[1] === b[1]) return 0;
            if (+a[1] > +b[1]) return 1;
            else return -1;
          })
          .map((d) => ({ label: d[0], order: d[1], responses: [d[0]] }))
      );
    }
  }, [orderingQuery]);
  useEffect(() => {
    if (ordering.length !== 0) {
      let l = '';
      if (parentView.dateFilter && parentView.dateFilter[0] !== null) {
        l = new Date(parentView.dateFilter[0]).toISOString().split('T')[0];
      } else {
        l = '1980-01-01';
      }
      let r = '';
      if (parentView.dateFilter && parentView.dateFilter[1] !== null) {
        r = new Date(parentView.dateFilter[1]).toISOString().split('T')[0];
      } else {
        r = '2030-01-01';
      }
      const time_break = [l, r] as [string, string];

      fireThinGeoQuery({
        formId: parentView.forms[0],
        hasc: parentView.countries[0],
        level: widget.choroplethLevel,
        ordering,
        question: parentView.questions[0],
        time_break,
        focusGroup: activeCategorical ?? undefined
      });
    }
  }, [ordering, widget.choroplethLevel, parentView.forms, parentView.countries, parentView.questions, parentView.dateFilter, activeCategorical]);

  // call mapbox api, modify layers with our data and setMapStyle via callback
  useEffect(() => {
    if (thinGeoQuery.isSuccess) {
      const hascs = thinGeoQuery.data?.map((d) => {
        if (isNumber(d)) {
          if (activeCategorical) {
            const ramp = interpolateRgbBasis(['#fff', '#7E1B65']);
            return color(ramp(d))?.formatRgb() ?? 'rgba(0,0,0,0)';
          } else {
            const truncD = (Math.trunc(parseFloat(d) * 100) / 100) as never;
            if (colorScheme === undefined || Object.keys(colorScheme).length === 0 || colorScheme[truncD] === undefined) {
              // console.warn('color scheme was not able to generate color', d, colorScheme);
              return 'rgba(0,0,0,0)';
            }
            return colorScheme[truncD];
          }
        } else {
          return d;
        }
      });

      if (hascs.length > 0) generateStyle((r) => setMapStyle(r), hascs, widget.choroplethLevel);
    }
  }, [activeCategorical, thinGeoQuery, thinGeoQuery.isSuccess, ordering, widget.choroplethLevel]);

  const flyHome = useCallback(() => {
    if (parentView && parentView.homeBounds) {
      const bounds = parentView.homeBounds;
      const s: [[number, number], [number, number]] = [
        [bounds[0][1], bounds[1][0]],
        [bounds[1][1], bounds[0][0]]
      ];
      mapRef.current?.fitBounds(s);
    }
  }, [parentView]);

  useEffect(() => {
    if (parentView.homeBounds) {
      flyHome();
    }
  }, [parentView]);

  useEffect(() => {
    mapRef.current?.resize();
  }, [size]);

  // ----- Controls -----
  const eventMarkers = useMemo(() => {
    if (highlightedNewsEvent) {
      const item = feedData.find((item) => item.id === highlightedNewsEvent);
      if (item) {
        return (
          <CustomMarker
            key={'marker-highlighted'}
            index={0}
            selectedMarkerIndex={0}
            latitude={item.lat}
            longitude={item.lon}
            description={item.type === FeedDataType.GDELT ? item.title : item.event_description}
            anchor={'bottom'}
          />
        );
      }
    }

    return feedData.map((item, idx) => {
      const desc = item.type === FeedDataType.GDELT ? item.title : item.event_description;

      return (
        <CustomMarker
          key={`marker-${idx}`}
          index={idx}
          selectedMarkerIndex={selectedMarkerIndex}
          latitude={item.lat}
          longitude={item.lon}
          description={desc}
          anchor={'bottom'}
          onClick={(e) => {
            e.originalEvent.stopPropagation();
            e.originalEvent.stopImmediatePropagation();
            setSelectedMarkerIndex(idx);
            // e.target.flyTo({ center: [item.lat, item.lon] });
          }}
        />
      );
    });
  }, [feedData, selectedMarkerIndex, highlightedNewsEvent]);

  const legendTitle = categoricalColorScheme ? (activeCategorical ? activeCategorical : 'Category Responses') : 'Sentiment';
  return (
    <Box height={'100%'} width={'100%'}>
      <Map
        onClick={() => {
          setSelectedMarkerIndex(null);
        }}
        initialViewState={{
          longitude: widget.centerLongitude,
          latitude: widget.centerLatitude,
          zoom: widget.zoomLevel
        }}
        style={{
          width: size.width,
          height: size.height
        }}
        mapStyle={mapStyle ?? `https://api.mapbox.com/styles/v1/joeraii/ckrdksz4z0lr518paki3bz5ph/?access_token=${mapboxToken}`}
        mapboxAccessToken={mapboxToken}
        ref={mapRef}
        trackResize={true}
        attributionControl={false}
      >
        <AttributionControl position={'bottom-left'} />
        {eventMarkers}
      </Map>

      <MapControls mapRef={mapRef} widget={widget} flyHome={flyHome} />
      <MapLegend legendItems={legendItems} title={legendTitle} />
    </Box>
  );
};

export default withSize({ monitorHeight: true })(DeckMap);
