import { memo, useEffect, useMemo, useState } from 'react';
import { MapRef, Marker, MarkerProps, Popup } from 'react-map-gl';
import { MarkerState } from '../../../types/grayzone';

type Props = {
  mapRef?: React.MutableRefObject<MapRef | null>;
  selectedMarkerIndex: number | null;
  index: number;
  description: string;
};
const CustomMarker = (props: Props & MarkerProps) => {
  const { description, selectedMarkerIndex, index, ...markerProps } = props;
  // ----- Local State -----
  const [markerState, setMarkerState] = useState<MarkerState>(MarkerState.DEFAULT);

  const popup = useMemo(() => {
    return (
      <Popup longitude={markerProps.longitude} latitude={markerProps.latitude} anchor="bottom" offset={[0, -25]} closeButton={false}>
        {description}
      </Popup>
    );
  }, [description]);

  // ----- Effects -----
  useEffect(() => {
    if (index === selectedMarkerIndex) {
      setMarkerState(MarkerState.SELECTED);
    } else {
      setMarkerState(MarkerState.DEFAULT);
    }
  }, [index, selectedMarkerIndex]);

  //   useEffect(() => {
  //     mapRef?.current?.on('click')
  //   })

  return (
    <Marker {...markerProps} anchor={'bottom'} style={{ opacity: markerState === MarkerState.DEFAULT ? 0.6 : 1 }}>
      <div
        className="marker"
        style={{ cursor: 'pointer' }}
        onMouseOver={() => {
          if (markerState !== MarkerState.SELECTED) {
            setMarkerState(MarkerState.HOVER);
          }
        }}
        onMouseLeave={() => {
          if (markerState !== MarkerState.SELECTED) {
            setMarkerState(MarkerState.DEFAULT);
          }
        }}
      >
        {markerState === MarkerState.DEFAULT && <img src="/images/pin.svg" />}
        {markerState === MarkerState.HOVER && <img src="/images/pin_hover.svg" />}
        {markerState === MarkerState.SELECTED && <img src="/images/pin_selected.svg" />}
      </div>
      {markerState === MarkerState.SELECTED && popup}
    </Marker>
  );
};

export default memo(CustomMarker);
