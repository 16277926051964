/* eslint-disable max-len */
// BASEUI-GENERATED-REACT-ICON
// DO NOT EDIT THIS FILE DIRECTLY, SEE README.md
import * as React from 'react';
import type { IconProps } from '../Icon';
import Pictogram from '../Pictogram';

const ExplosionPictogram = (props: IconProps): JSX.Element => {
  const { title = 'Explosion Pictogram', ...restProps } = props;
  return (
    <Pictogram title={title} {...restProps}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.1506 6.54146C26.8447 6.37512 27.56 6.72104 27.8606 7.3685L33.2762 19.0329L41.7795 7.12831C42.1995 6.54025 42.9792 6.33529 43.6341 6.64075C44.2891 6.94621 44.6331 7.67524 44.4525 8.37499L41.0145 21.6972L49.288 20.5152C49.9183 20.4252 50.5368 20.7429 50.8307 21.3078C51.1246 21.8727 51.0299 22.5615 50.5944 23.0261L44.5583 29.4646L56.591 34.6215C57.2366 34.8982 57.6038 35.5859 57.4744 36.2764C57.3451 36.9669 56.7538 37.4751 56.0518 37.4993L44.1576 37.9094L53.77 53.2019L51.2301 54.7984L38.8426 35.0909L49.2345 34.7326L39.4419 30.5357L45.4858 24.0889L36.9857 25.3032L39.532 15.4362L32.724 24.9674L28.2639 15.3612L28.5836 24.3108L20.8868 21.0121L25.7521 27.7487L13.5091 28.8965L22.5036 32.3559L17.374 38.4474L28.7611 33.3428L25.4806 45.6447L31.0518 39.7253L35.3833 51.1446L36.5175 43.7721L39.4826 44.2283L37.4826 57.2283C37.3791 57.9016 36.8347 58.4206 36.1572 58.4919C35.4797 58.5633 34.8392 58.1691 34.5976 57.5322L29.9483 45.275L23.5924 52.0282C23.1215 52.5285 22.371 52.645 21.7706 52.3109C21.1703 51.9767 20.8737 51.2775 21.0507 50.6137L24.239 38.6576L12.6137 43.8689C11.9752 44.1551 11.2238 43.9619 10.8025 43.4034C10.3812 42.8448 10.4021 42.0691 10.8527 41.534L17.4966 33.6444L6.46162 29.4002C5.81154 29.1502 5.41909 28.4855 5.51411 27.7955C5.60913 27.1055 6.16661 26.5717 6.86008 26.5067L20.248 25.2516L15.2841 18.3784C14.8893 17.8318 14.9077 17.089 15.3291 16.5627C15.7505 16.0364 16.4713 15.8559 17.091 16.1215L25.4166 19.6896L25.001 8.05371C24.9756 7.34032 25.4564 6.7078 26.1506 6.54146Z"
        fill="black"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.2701 38.0889L53.7701 53.2025L51.2302 54.799L41.7302 39.6854L44.2701 38.0889Z"
        fill="#FF5B4A"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.2701 44.4521L53.7701 53.2021L51.2302 54.7987L45.7302 46.0487L48.2701 44.4521Z"
        fill="#7E3BFF"
      ></path>
    </Pictogram>
  );
};

export default ExplosionPictogram;
/* eslint-enable max-len */
