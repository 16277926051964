import { useCallback, useMemo, useState } from 'react';

// ----- MUI -----
import { Box, Card, styled, Typography, useTheme } from '@mui/material';

// ----- PDS -----
import IconButton from '../../../PremiseDesign/Components/IconButton';
import { CollapseOutlineIcon, ListOutlineIcon } from '../../../PremiseDesign/Icons';

const LegendButton = styled('div')(({ theme }) => ({
  position: 'absolute',
  zIndex: 400,
  right: theme.spacings.small,
  bottom: theme.spacings.small
}));
const Legend = styled(Card)(({ theme }) => ({
  position: 'absolute',
  zIndex: 400,
  right: `calc(${theme.spacings.small} + 28px + ${theme.spacings['xx-small']})`,
  bottom: theme.spacings.small,
  cursor: 'default',
  maxWidth: '250px'
}));
const LegendContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacings['x-small'],
  padding: `0px ${theme.spacings.small}`,
  paddingBottom: theme.spacings.small
}));

type Props = {
  legendItems: Array<{
    color: string;
    label: string;
  }>;
  title: string;
};
const MapLegend = ({ legendItems, title }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const theme = useTheme();

  const openLegend = useCallback(() => setIsOpen(true), []);
  const closeLegend = useCallback(() => setIsOpen(false), []);

  const LegendItems = useMemo(() => {
    return legendItems.map((item) => {
      return (
        <Box key={`legend-${item.label}`} display={'flex'} flexDirection={'row'}>
          <Box
            sx={{
              height: '16px',
              width: '16px',
              borderRadius: '2px',
              marginRight: theme.spacings['xx-small'],
              backgroundColor: item.color
            }}
          />
          <Typography variant="label-small" noWrap>
            {item.label}
          </Typography>
        </Box>
      );
    });
  }, [legendItems]);

  return (
    <>
      {!isOpen && (
        <LegendButton>
          <IconButton onClick={openLegend} shadow={true} variant="mini">
            <ListOutlineIcon />
          </IconButton>
        </LegendButton>
      )}
      {isOpen && (
        <Legend>
          <Box sx={{ cursor: 'pointer', height: '20px', width: '20px' }}>
            <CollapseOutlineIcon onClick={closeLegend} />
          </Box>

          <LegendContent>
            <Typography variant="label-small-bold" noWrap>
              {title}
            </Typography>
            {LegendItems}
          </LegendContent>
        </Legend>
      )}
    </>
  );
};

export default MapLegend;
