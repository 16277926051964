// ----- REDUX -----
import { createSelector, createSlice, Dictionary, EntityState, PayloadAction, Update } from '@reduxjs/toolkit';

// ----- MODULES
import { v4 as uuid_v4 } from 'uuid';

// ----- OURS -----
import {
  gridLayoutAdapter,
  viewConfigurationAdapter,
  widgetAdapter,
  generateDefaultGrid,
  viewMapAdapter,
  widgetConfigurationsAdapter,
  _widgetConfigurationsAdapter,
  GDELTEventQueryResultAdapter
} from '../../helpers/grayzone';
import { RootState } from '../../store';

// ----- TYPES ----------
import { View, GridLayout, ViewConfiguration, ViewMap, Widget, WidgetConfigurations, CustomEvent, GDELTEventQueryResult, GDELTKeywordQueryDataItem } from '../../types/grayzone';

// ----- DEFINE STATE TYPE -----
export type GrayzoneState = {
  // ----- Views -----
  viewMaps: EntityState<ViewMap>;
  // ----- Grid -----
  gridLayouts: EntityState<GridLayout>;
  // ----- View Configurations -----
  viewConfigModalOpen: boolean;
  primaryViewId?: string | null;
  secondaryViewId?: string | null;
  viewConfigurations: EntityState<ViewConfiguration>;
  // ----- Widgets -----
  widgets: EntityState<Widget>;
  highlightedWidget?: string | null;
  widgetToConfigureId?: string | null;
  widgetConfigModalOpen: boolean;
  addEventModalOpen: boolean;
  // ----- Config Drawer -----
  configDrawerOpen: boolean;
  // ----- Timeline -----
  // TODO: move to ViewConfiguration
  hideFeedData: boolean;
  hideAverageSentiment: boolean;
  hideSentimentAlerts: boolean;
  hideSubmissions: boolean;
  eventToQuery: (CustomEvent & { eventSetId: string }) | null;
  eventQueryResults: EntityState<GDELTEventQueryResult>;
  eventQueryTooltipData: { dataItem: GDELTKeywordQueryDataItem; x: number; y: number } | null;
  // ----- Idle -----
  idle: boolean;
  // ----- Filters -----
  widgetConfigurations: EntityState<WidgetConfigurations>;
  // ----- Hover State -----
  highlightedNewsEvent: string | null;
  highlightedNewsDate: { date: string; x: number; y: number } | null;
};

// ----- DEFINE INITIAL STATE -----
const initialState: GrayzoneState = {
  // ----- Views -----
  viewMaps: viewMapAdapter.getInitialState(),
  // ----- Grid -----
  gridLayouts: gridLayoutAdapter.getInitialState(),
  // ----- View Configurations -----
  viewConfigModalOpen: false,
  primaryViewId: undefined,
  secondaryViewId: undefined,
  viewConfigurations: viewConfigurationAdapter.getInitialState(),
  // ----- Widgets -----
  widgets: widgetAdapter.getInitialState(),
  highlightedWidget: undefined,
  widgetToConfigureId: undefined,
  widgetConfigModalOpen: false,
  addEventModalOpen: false,
  // ----- Config Drawer -----
  configDrawerOpen: false,
  // ----- Timeline -----
  hideFeedData: false,
  hideAverageSentiment: false,
  hideSentimentAlerts: false,
  hideSubmissions: false,
  eventToQuery: null,
  eventQueryTooltipData: null,
  eventQueryResults: GDELTEventQueryResultAdapter.getInitialState(),
  // ----- Idle -----
  idle: false,
  // ----- Filters -----
  widgetConfigurations: widgetConfigurationsAdapter.getInitialState(),
  // ----- Hover Events -----
  highlightedNewsEvent: null,
  highlightedNewsDate: null
};

// ----- EXPORT SLICE -----
export const grayzoneSlice = createSlice({
  name: 'grayzone',
  initialState,
  reducers: {
    // ----- App -----
    logout: (state) => {
      Object.assign(state, { ...initialState });
    },
    // ----- Grid -----
    updateGridLayout: (state, action: PayloadAction<Update<GridLayout>>) => {
      state.gridLayouts = gridLayoutAdapter.updateOne(state.gridLayouts, action.payload);
    },
    // ----- Views -----
    updateViewConfiguration: (state, action: PayloadAction<Update<ViewConfiguration>>) => {
      state.viewConfigurations = viewConfigurationAdapter.updateOne(state.viewConfigurations, action.payload);
    },
    updateViewConfigModalStateTo: (state, action: PayloadAction<boolean>) => {
      state.viewConfigModalOpen = action.payload;
    },
    deleteView: (state, action: PayloadAction<string>) => {
      const viewId = action.payload;
      const viewMap = viewMapAdapter.defaultSelectors.selectById(state.viewMaps, viewId);
      if (!viewMap) {
        return console.error('View map for view with provided id was not found; aborting delete');
      }
      const viewConfig = viewConfigurationAdapter.defaultSelectors.selectById(state.viewConfigurations, viewMap.configId);
      if (!viewConfig) {
        return console.error('View configuration for view with provided id was not found; aborting delete');
      }
      if (!viewConfig.gridLayoutId) {
        return console.error('View configuration for view with provided id had no grid layout; aborting delete');
      }
      const gridLayout = gridLayoutAdapter.defaultSelectors.selectById(state.gridLayouts, viewConfig.gridLayoutId);
      if (!gridLayout) {
        return console.error('Grid layout for view with provided id was not found; aborting delete');
      }
      const widgetConfigurations = widgetConfigurationsAdapter.defaultSelectors.selectById(state.widgetConfigurations, gridLayout.widgetConfigurationsId);
      if (!widgetConfigurations) {
        return console.error('Widget configurations for view with provided id was not found; aborting delete');
      }
      state.viewMaps = viewMapAdapter.removeOne(state.viewMaps, viewId);
      state.viewConfigurations = viewConfigurationAdapter.removeOne(state.viewConfigurations, viewConfig.id);
      state.gridLayouts = gridLayoutAdapter.removeOne(state.gridLayouts, gridLayout.id);
      state.widgets = widgetAdapter.removeMany(state.widgets, gridLayout.widgetIds);
      state.widgetConfigurations = widgetConfigurationsAdapter.removeOne(state.widgetConfigurations, gridLayout.widgetConfigurationsId);

      if (state.viewMaps.ids.length === 0) {
        state.primaryViewId = null;
        state.secondaryViewId = null;
      } else if (state.primaryViewId === viewId) {
        if (state.secondaryViewId !== null && state.secondaryViewId !== undefined) {
          state.primaryViewId = state.secondaryViewId;
          state.secondaryViewId = null;
        } else {
          state.primaryViewId = state.viewMaps.ids[0] as string;
        }
      } else if (state.secondaryViewId === viewId) {
        state.secondaryViewId = null;
      }
    },
    configureNewView: (state, action: PayloadAction<{ config: ViewConfiguration; viewId?: string }>) => {
      // If no gridLayoutId was provided, assume that there are no widgets for the provided configuration and generate the defaults.

      if (action.payload.config.gridLayoutId === undefined) {
        const defaults = generateDefaultGrid(action.payload.config.id);
        state.gridLayouts = gridLayoutAdapter.addOne(state.gridLayouts, defaults.gridLayout);
        state.widgets = widgetAdapter.addMany(state.widgets, defaults.widgets);
        state.viewConfigurations = viewConfigurationAdapter.addOne(state.viewConfigurations, { ...action.payload.config, gridLayoutId: defaults.gridLayout.id });
        state.widgetConfigurations = _widgetConfigurationsAdapter.addOne(state.widgetConfigurations, defaults.widgetConfigurations);
      } else {
        state.viewConfigurations = viewConfigurationAdapter.addOne(state.viewConfigurations, action.payload.config);
      }
      const viewMap = { viewId: action.payload.viewId ?? uuid_v4(), configId: action.payload.config.id };
      state.viewMaps = viewMapAdapter.addOne(state.viewMaps, viewMap);
      state.primaryViewId = viewMap.viewId;
    },
    switchPrimaryViewTo: (state, action: PayloadAction<string>) => {
      state.primaryViewId = action.payload;
    },
    switchSecondaryViewTo: (state, action: PayloadAction<string | null | undefined>) => {
      state.secondaryViewId = action.payload;
    },
    importExternalViewState: (state, action: PayloadAction<View[]>) => {
      if (action.payload.length < 1) {
        return console.warn('You shouldnt be importing an array of views of length 0');
      }
      const viewMaps: ViewMap[] = action.payload.map((view) => ({ viewId: view.id, configId: view.viewConfiguration.id }));
      state.viewMaps = viewMapAdapter.addMany(state.viewMaps, viewMaps);
      state.viewConfigurations = viewConfigurationAdapter.addMany(
        state.viewConfigurations,
        action.payload.map((view) => view.viewConfiguration)
      );
      state.gridLayouts = gridLayoutAdapter.addMany(
        state.gridLayouts,
        action.payload.map((view) => view.gridLayout)
      );
      state.widgets = widgetAdapter.addMany(
        state.widgets,
        action.payload.flatMap((view) => view.widgets)
      );
      state.widgetConfigurations = widgetConfigurationsAdapter.addMany(
        state.widgetConfigurations,
        action.payload.map((view) => view.widgetConfigurations)
      );

      if (!state.primaryViewId || state.viewConfigurations.ids.length === 0) {
        state.primaryViewId = viewMaps[0].viewId;
      }
    },
    importExternalViewStateAndReplace: (state, action: PayloadAction<View[]>) => {
      if (action.payload.length < 1) {
        return console.warn('You shouldnt be importing an array of views of length 0');
      }
      const viewMaps: ViewMap[] = action.payload.map((view) => ({ viewId: view.id, configId: view.viewConfiguration.id }));
      state.viewMaps = viewMapAdapter.setMany(state.viewMaps, viewMaps);
      state.viewConfigurations = viewConfigurationAdapter.setMany(
        state.viewConfigurations,
        action.payload.map((view) => view.viewConfiguration)
      );
      state.gridLayouts = gridLayoutAdapter.setMany(
        state.gridLayouts,
        action.payload.map((view) => view.gridLayout)
      );
      state.widgets = widgetAdapter.setMany(
        state.widgets,
        action.payload.flatMap((view) => view.widgets)
      );
      state.widgetConfigurations = widgetConfigurationsAdapter.setMany(
        state.widgetConfigurations,
        action.payload.map((view) => view.widgetConfigurations)
      );

      if (!state.primaryViewId || state.viewConfigurations.ids.length === 0) {
        state.primaryViewId = viewMaps[0].viewId;
      }
    },
    // ----- Widgets -----
    highlightWidget: (state, action: PayloadAction<string | null>) => {
      state.highlightedWidget = action.payload;
    },
    createNewWidgets: (state, action: PayloadAction<Widget[]>) => {
      state.widgets = widgetAdapter.addMany(state.widgets, action.payload);
    },
    updateWidget: (state, action: PayloadAction<Update<Widget>>) => {
      state.widgets = widgetAdapter.updateOne(state.widgets, action.payload);
    },
    deleteWidget: (state, action: PayloadAction<string>) => {
      const widget = widgetAdapter.defaultSelectors.selectById(state.widgets, action.payload);
      if (!widget) {
        console.warn('Widget with provided ID not found, so it cannot be deleted');
        return;
      }
      state.widgets = widgetAdapter.removeOne(state.widgets, action.payload);

      // // @Pau I think this is redundant code from removeItem() ?
      // const gridLayout = gridLayoutAdapter.defaultSelectors.selectById(state.gridLayouts, widget.gridId);
      // if (!gridLayout) {
      //   console.warn('GridLayout with provided gridId not found, so it cannot be modified');
      //   return;
      // }
      // const filterOutWidget = (item: ReactGridLayout.Layout): boolean => {
      //   return item.i !== action.payload;
      // };
      // const [layoutLG, layoutMD, layoutSM] = [
      //   gridLayout.layouts['lg'].filter(filterOutWidget),
      //   gridLayout.layouts['md'].filter(filterOutWidget),
      //   gridLayout.layouts['sm'].filter(filterOutWidget)
      // ];

      // state.gridLayouts = gridLayoutAdapter.updateOne(state.gridLayouts, { id: gridLayout.id, changes: { layouts: { lg: layoutLG, md: layoutMD, sm: layoutSM } } });
    },
    updateAddEventModalStateTo: (state, action: PayloadAction<boolean>) => {
      state.addEventModalOpen = action.payload;
    },
    startConfiguringWidget: (state, action: PayloadAction<string>) => {
      state.widgetConfigModalOpen = true;
      state.widgetToConfigureId = action.payload;
    },
    finishConfiguringWidget: (state) => {
      state.widgetConfigModalOpen = false;
      state.widgetToConfigureId = null;
    },
    setHomeBoundsForView: (state, action: PayloadAction<{ id: string; bounds: [[number, number], [number, number]] }>) => {
      viewConfigurationAdapter.updateOne(state.viewConfigurations, { id: action.payload.id, changes: { homeBounds: action.payload.bounds } });
    },
    // ----- UI -----
    toggleDrawerState: (state) => {
      state.configDrawerOpen = !state.configDrawerOpen;
    },
    // ----- Idle -----
    setIdle: (state, action: PayloadAction<boolean>) => {
      state.idle = action.payload;
    },
    // ----- Custom Events -----
    // setEnabledCustomEvents: (state, action: PayloadAction<GenericMap<boolean>>) => {
    //   state.enabledCustomEvents = action.payload;
    // },
    // updateEnabledCustomEvents: (state, action: PayloadAction<{ id: string; value: null | boolean }>) => {
    //   if (action.payload.value !== null) {
    //     state.enabledCustomEvents[action.payload.id] = action.payload.value;
    //   } else if (state.enabledCustomEvents[action.payload.id] !== undefined) {
    //     delete state.enabledCustomEvents[action.payload.id];
    //   }
    // },
    // ----- Timeline -----
    toggleHideFeedData: (state) => {
      state.hideFeedData = !state.hideFeedData;
    },
    setAllMainTimelineFilters: (state, action: PayloadAction<boolean>) => {
      state.hideAverageSentiment = !action.payload;
      state.hideSentimentAlerts = !action.payload;
      state.hideSubmissions = !action.payload;
    },
    toggleHideAverageSentiment: (state) => {
      state.hideAverageSentiment = !state.hideAverageSentiment;
    },
    toggleHideSentimentAlerts: (state) => {
      state.hideSentimentAlerts = !state.hideSentimentAlerts;
    },
    toggleHideSubmissions: (state) => {
      state.hideSubmissions = !state.hideSubmissions;
    },
    updateEventToQuery: (state, action: PayloadAction<(CustomEvent & { eventSetId: string }) | null>) => {
      state.eventToQuery = action.payload;
    },
    addEventQueryResult: (state, action: PayloadAction<GDELTEventQueryResult>) => {
      state.eventQueryResults = GDELTEventQueryResultAdapter.addOne(state.eventQueryResults, action.payload);
    },
    updateEventQueryResult: (state, action: PayloadAction<Update<GDELTEventQueryResult>>) => {
      state.eventQueryResults = GDELTEventQueryResultAdapter.updateOne(state.eventQueryResults, action.payload);
    },
    deleteEventQueryResult: (state, action: PayloadAction<string>) => {
      state.eventQueryResults = GDELTEventQueryResultAdapter.removeOne(state.eventQueryResults, action.payload);
    },
    updateEventQueryTooltipData: (state, action: PayloadAction<{ dataItem: GDELTKeywordQueryDataItem; x: number; y: number } | null>) => {
      state.eventQueryTooltipData = action.payload;
    },
    // ----- Filters -----
    // TODO: Use generics
    // updateWidget: (state, action: PayloadAction<Update<Widget>>) => {
    //   state.widgets = widgetAdapter.updateOne(state.widgets, action.payload);
    // },
    updateWidgetConfigurations: (state: GrayzoneState, action: PayloadAction<Update<WidgetConfigurations>>) => {
      state.widgetConfigurations = widgetConfigurationsAdapter.updateOne(state.widgetConfigurations, action.payload);
    },
    // ----- Hover State -----
    setHighlightedNewsEvent: (state, action: PayloadAction<string | null>) => {
      state.highlightedNewsEvent = action.payload;
    },
    setHighlightedNewsDate: (state, action: PayloadAction<{ date: string; x: number; y: number } | null>) => {
      state.highlightedNewsDate = action.payload;
    }
  }
});

// ----- ADAPTER SELECTORS -----
const viewMapSelectors = viewMapAdapter.rootSelectors;
const viewConfigurationSelectors = viewConfigurationAdapter.rootSelectors;
const gridLayoutSelectors = gridLayoutAdapter.rootSelectors;
const widgetSelectors = widgetAdapter.rootSelectors;
const widgetConfigurationsSelectors = widgetConfigurationsAdapter.rootSelectors;

// ----- ADDITIONAL SELECTORS -----

// ----- Grid -----
const selectGridLayoutByViewId = createSelector(
  [(state: RootState, viewConfigId: string) => viewConfigId, (state: RootState) => viewConfigurationSelectors.selectEntities(state), gridLayoutSelectors.selectEntities],
  (viewConfigId, viewConfigMap, layouts) => {
    const viewConfig = viewConfigMap[viewConfigId];
    if (!viewConfig) {
      console.warn('View configuration with provided id could not be found.');
      return undefined;
    } else if (!viewConfig.gridLayoutId) {
      console.warn('View configuration has no grid layout id.');
      return undefined;
    }
    return layouts[viewConfig.gridLayoutId];
  },
  {
    memoizeOptions: {
      maxSize: 4
    }
  }
);

// ----- View Configurations -----
const selectPrimaryViewId = (state: RootState) => state.grayzone.primaryViewId;
// TODO: Switch the configs to views
const selectPrimaryView = (state: RootState) => viewMapSelectors.selectById(state, selectPrimaryViewId(state) ?? '');
const selectPrimaryViewConfiguration = (state: RootState) => viewConfigurationSelectors.selectById(state, selectPrimaryView(state)?.configId ?? '');
const selectSecondaryViewId = (state: RootState) => state.grayzone.secondaryViewId;
const selectSecondaryView = (state: RootState) => viewMapSelectors.selectById(state, selectSecondaryViewId(state) ?? '');
const selectSecondaryViewConfiguration = (state: RootState) => viewConfigurationSelectors.selectById(state, selectSecondaryView(state)?.configId ?? '');

const selectViewConfigModalOpen = (state: RootState) => state.grayzone.viewConfigModalOpen;

const selectViewConfigurationByGridLayoutId = createSelector(
  [(state: RootState, gridLayoutId: string) => gridLayoutSelectors.selectById(state, gridLayoutId), (state: RootState) => viewConfigurationSelectors.selectEntities(state)],
  (grid, viewConfigurationEntities) => {
    if (!grid) {
      console.warn('No grid found with the id provided');
      return undefined;
    }

    const viewConfig = viewConfigurationEntities[grid.viewConfigId];
    if (!viewConfig) {
      console.warn('No view config found with the id provided');
      return undefined;
    }

    return viewConfig;
  }
);

// ----- Widgets -----
const selectHighlightedWidget = (state: RootState) => state.grayzone.highlightedWidget;
const selectWidgetToConfigureId = (state: RootState) => state.grayzone.widgetToConfigureId;
const selectWidgetToConfigure = (state: RootState) => widgetSelectors.selectById(state, selectWidgetToConfigureId(state) ?? '');
const selectWidgetConfigModalOpen = (state: RootState) => state.grayzone.widgetConfigModalOpen;
const selectAddEventModalOpen = (state: RootState) => state.grayzone.addEventModalOpen;
const selectAllWidgetsByGridLayoutId = createSelector(
  [(state: RootState, gridlayoutId: string) => gridlayoutId, (state: RootState) => gridLayoutSelectors.selectEntities(state), widgetSelectors.selectEntities],
  (gridLayoutId, gridLayoutMap, widgetMap) => {
    const layout = gridLayoutMap[gridLayoutId];
    return layout
      ? layout.widgetIds.reduce((arr: Widget[], widgetId) => {
          const widget = widgetMap[widgetId];
          widget && arr.push(widget);
          return arr;
        }, [])
      : [];
  },
  {
    memoizeOptions: {
      maxSize: 4
    }
  }
);
const selectAllWidgetsByGridLayoutIdMapped = createSelector(
  [(state: RootState, gridlayoutId: string) => gridlayoutId, (state: RootState) => gridLayoutSelectors.selectEntities(state), widgetSelectors.selectEntities],
  (gridLayoutId, gridLayoutMap, widgetMap) => {
    const layout = gridLayoutMap[gridLayoutId];
    return layout
      ? layout.widgetIds.reduce((obj: Dictionary<Widget>, widgetId) => {
          const widget = widgetMap[widgetId];
          if (widget) {
            obj[widgetId] = widget;
          }
          return obj;
        }, {})
      : {};
  },
  {
    memoizeOptions: {
      maxSize: 4
    }
  }
);

// ----- Idle -----
const selectIdleState = (state: RootState) => state.grayzone.idle;

// ----- Widget Configurations -----
const selectWidgetConfigurationsByGridLayoutId = createSelector(
  [(state: RootState, gridlayoutId: string) => gridlayoutId, (state: RootState) => gridLayoutSelectors.selectEntities(state), widgetConfigurationsSelectors.selectEntities],
  (gridLayoutId, gridLayoutMap, widgetConfigurationsMap) => {
    const layout = gridLayoutMap[gridLayoutId];
    if (!layout) {
      console.warn('No layout was found with grid layout id');
      return undefined;
    }

    const configs = widgetConfigurationsMap[layout.widgetConfigurationsId];
    if (!configs) {
      console.warn('No widget configurations were found with widget configuration id');
      return undefined;
    }
    return configs;
  },
  {
    memoizeOptions: {
      maxSize: 4
    }
  }
);

// ----- Config Drawer -----
const selectConfigDrawerOpen = (state: RootState) => state.grayzone.configDrawerOpen;

// ----- Hover State -----
const selectHighlightedNewsEvent = (state: RootState) => state.grayzone.highlightedNewsEvent;
const selectHighlightedNewsDate = (state: RootState) => state.grayzone.highlightedNewsDate;

// ----- Timeline -----
const selectHideFeedDataState = (state: RootState) => state.grayzone.hideFeedData;
const selectHideAverageSentimentState = (state: RootState) => state.grayzone.hideAverageSentiment;
const selectHideSentimentAlertsState = (state: RootState) => state.grayzone.hideSentimentAlerts;
const selectHideSubmissionsState = (state: RootState) => state.grayzone.hideSubmissions;
const selectEventToQuery = (state: RootState) => state.grayzone.eventToQuery;
const selectEventQueryResults = (state: RootState) => state.grayzone.eventQueryResults;
const selectEventQueryTooltipData = (state: RootState) => state.grayzone.eventQueryTooltipData;
// ----- Filters -----
// const selectFeedFilters = (state: RootState) => state.grayzone.widgetConfigurations[WidgetType.FEED];
// const selectMapFilters = (state: RootState) => state.grayzone.widgetConfigurations[WidgetType.MAP];
// const selectPhotoFilters = (state: RootState) => state.grayzone.widgetConfigurations[WidgetType.PHOTO];
// const selectTimelineFilters = (state: RootState) => state.grayzone.widgetConfigurations[WidgetType.TIMELINE];

// ----- EXPORT SELECTORS -----
export const grayzoneSelectors = {
  viewMaps: {
    getSelectors: viewMapAdapter.getSelectors
  },
  viewConfigurations: {
    selectPrimaryViewId,
    selectPrimaryView,
    selectPrimaryViewConfiguration,
    selectSecondaryViewId,
    selectSecondaryView,
    selectSecondaryViewConfiguration,
    selectViewConfigModalOpen,
    selectViewConfigurationByGridLayoutId,
    getSelectors: viewConfigurationAdapter.getSelectors
  },
  gridLayouts: {
    selectGridLayoutByViewId,
    getSelectors: gridLayoutAdapter.getSelectors
  },
  widgets: {
    selectHighlightedWidget,
    selectWidgetToConfigureId,
    selectWidgetToConfigure,
    selectWidgetConfigModalOpen,
    selectAddEventModalOpen,
    selectAllWidgetsByGridLayoutId,
    selectAllWidgetsByGridLayoutIdMapped,
    getSelectors: widgetAdapter.getSelectors
  },
  configDrawer: {
    selectConfigDrawerOpen
  },
  // customEvents: {
  //   selectEnabledCustomEvents
  // },
  timeline: {
    selectHideFeedDataState,
    selectHideAverageSentimentState,
    selectHideSentimentAlertsState,
    selectHideSubmissionsState,
    selectEventToQuery,
    selectEventQueryResults,
    selectEventQueryTooltipData
  },
  widgetConfigurations: {
    selectWidgetConfigurationsByGridLayoutId
  },
  hoverState: {
    selectHighlightedNewsEvent,
    selectHighlightedNewsDate
  },
  selectIdleState
};

// ----- EXPORT ACTIONS -----
export const grayzoneActions = grayzoneSlice.actions;

// ----- EXPORT REDUCER -----
export default grayzoneSlice.reducer;
