// ----- REACT -----
import { ChangeEvent, useState } from 'react';

// ----- REDUX -----
import { Update } from '@reduxjs/toolkit';

// ----- MODULES -----
import { Box } from '@mui/material';

// ----- OURS -----
import DebouncedTextfield from '../../../components/DebouncedTextfield';

// ----- TYPES -----
import { TimelineWidget } from '../../../types/grayzone';

// ----- COMPONENT -----
type Props = {
  publishChanges: (updates: Update<TimelineWidget>) => void;
  widget: TimelineWidget;
};
const TimelineConfig = ({ publishChanges, widget }: Props) => {
  // ----- Local State -----
  const [updates, setUpdates] = useState<Update<TimelineWidget>>({ id: widget.id, changes: {} });

  // ----- Helpers -----
  const generateUpdates = (newChanges: Partial<TimelineWidget>): Update<TimelineWidget> => {
    const newUpdates = { ...updates, changes: { ...updates.changes, ...newChanges } };
    setUpdates(newUpdates);
    return newUpdates;
  };

  // ----- Handlers -----
  const handleNameChanged = (e: ChangeEvent<HTMLInputElement>) => {
    publishChanges(generateUpdates({ name: e.target.value }));
  };

  // ----- Return Component -----
  return (
    <Box display="flex" flexDirection="column">
      <DebouncedTextfield id="input-name" onChangeDebounced={handleNameChanged} defaultValue={widget.name} delay={700} label="Name" margin="normal" size="small" />
    </Box>
  );
};

export default TimelineConfig;
