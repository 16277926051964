/* eslint-disable max-len */
// BASEUI-GENERATED-REACT-ICON
// DO NOT EDIT THIS FILE DIRECTLY, SEE README.md
import * as React from 'react';
import type { IconProps } from '../Icon';
import Icon from '../Icon';

const CheckboxDashOutlineIcon = (props: IconProps): JSX.Element => {
  const {
    title = 'Checkbox Dash Outline Icon',
    size,
    color,
    onClick,
    ...restProps
  } = props;
  return (
    <Icon
      title={title}
      size={size}
      color={color}
      onClick={onClick}
      {...restProps}
    >
      <path d="M6 3C4.34315 3 3 4.34315 3 6V18C3 19.6569 4.34315 21 6 21H18C19.6569 21 21 19.6569 21 18V6C21 4.34315 19.6569 3 18 3H6Z"></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.00003 12C7.00003 11.4477 7.44775 11 8.00003 11H16C16.5523 11 17 11.4477 17 12C17 12.5523 16.5523 13 16 13H8.00003C7.44775 13 7.00003 12.5523 7.00003 12Z"
        fill="white"
      ></path>
    </Icon>
  );
};

export default CheckboxDashOutlineIcon;
/* eslint-enable max-len */
