/* eslint-disable max-len */
// BASEUI-GENERATED-REACT-ICON
// DO NOT EDIT THIS FILE DIRECTLY, SEE README.md
import * as React from 'react';
import type { IconProps } from '../Icon';
import Pictogram from '../Pictogram';

const StrategyPictogram = (props: IconProps): JSX.Element => {
  const { title = 'Strategy Pictogram', ...restProps } = props;
  return (
    <Pictogram title={title} {...restProps}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46 12V14.5H53C53.5115 14.5 53.9878 14.7607 54.2635 15.1916C54.5392 15.6225 54.5763 16.1641 54.3619 16.6286L51.8982 21.9667C51.3663 23.1192 50.2565 23.8863 49.006 23.9884C48.9926 25.0268 49.0014 26.0299 49.0314 27H52.5V30H49.1984C49.8958 38.5239 52.3737 44.3487 55.7104 49.5794C57.0772 50.3516 58 51.8181 58 53.5C58 55.9853 55.9853 58 53.5 58H35.5C33.0147 58 31 55.9853 31 53.5C31 51.784 31.9605 50.2924 33.3732 49.5333C36.695 44.3145 39.1604 38.4989 39.8558 30H36.5V27H40.0228C40.0527 26.0311 40.0616 25.0294 40.0482 23.9924C38.7757 23.9083 37.6414 23.1358 37.1018 21.9667L34.6381 16.6286C34.4237 16.1641 34.4608 15.6225 34.7365 15.1916C35.0122 14.7607 35.4885 14.5 36 14.5H43V12H39.5V9H43V6H46V9H49.5V12H46ZM43.0485 24C43.0611 25.0312 43.0526 26.0305 43.024 27H46.0302C46.0016 26.0305 45.9931 25.0312 46.0057 24H43.0485ZM42.865 30C42.2447 37.9935 40.1422 43.8521 37.2 49H51.8542C48.912 43.8521 46.8095 37.9935 46.1892 30H42.865ZM35.5 52C34.6716 52 34 52.6716 34 53.5C34 54.3284 34.6716 55 35.5 55H53.5C54.3284 55 55 54.3284 55 53.5C55 52.6716 54.3284 52 53.5 52H35.5ZM38.3444 17.5L39.8257 20.7095C39.9074 20.8866 40.0846 21 40.2797 21H48.7203C48.9154 21 49.0926 20.8866 49.1743 20.7095L50.6556 17.5H38.3444ZM15 18H29.5V21H15V18ZM30 43H13V46H30V43Z"
        fill="black"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 27H33V30H6V27Z"
        fill="#7E3BFF"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 27H21V30H6V27Z"
        fill="#FF5B4A"
      ></path>
    </Pictogram>
  );
};

export default StrategyPictogram;
/* eslint-enable max-len */
