import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { AuthState } from '../../features/auth/authSlice';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_HOST,
  prepareHeaders: async (headers, { getState }) => {
    const state = (await getState()) as { auth: AuthState };
    const token = state.auth.token;

    // If we have a token set in state, let's assume that we should be passing it.
    if (token) {
      headers.set('X-Auth-Token', token);
      headers.set('Content-Type', 'application/json');
    }
    return headers;
  }
});

export const irisApi = createApi({
  reducerPath: 'irisApi',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    authenticateToken: builder.query<string, { token: string }>({
      query: ({ token }) => ({
        url: '/iris/v0/profile',
        method: 'GET',
        headers: {
          // token is not set yet in store, so use passed var
          'X-Auth-Token': token,
          'Content-Type': 'application/json'
        }
      })
    })
  })
});

export const irisEndpoints = { ...irisApi.endpoints };
