/* eslint-disable max-len */
// BASEUI-GENERATED-REACT-ICON
// DO NOT EDIT THIS FILE DIRECTLY, SEE README.md
import * as React from 'react';
import type { IconProps } from '../Icon';
import Icon from '../Icon';

const DoubleCheckmarkOutlineIcon = (props: IconProps): JSX.Element => {
  const {
    title = 'Double Checkmark Outline Icon',
    size,
    color,
    onClick,
    ...restProps
  } = props;
  return (
    <Icon
      title={title}
      size={size}
      color={color}
      onClick={onClick}
      {...restProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.28761 11.4841C3.67108 11.1006 4.29282 11.1006 4.6763 11.4841L7.76755 14.5753L15.4054 6.93751C15.7888 6.55403 16.4106 6.55403 16.794 6.93751C17.1775 7.32099 17.1775 7.94272 16.794 8.3262L8.46189 16.6583C8.07842 17.0418 7.45668 17.0418 7.0732 16.6583L3.28761 12.8728C2.90413 12.4893 2.90413 11.8675 3.28761 11.4841Z"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.71006 10.9932C7.09354 10.6097 7.71527 10.6097 8.09875 10.9932L9.1156 12.01L7.72691 13.3987L6.71006 12.3818C6.32658 11.9984 6.32658 11.3766 6.71006 10.9932Z"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1688 15.049L11.6951 14.5753L10.3064 15.964L11.4745 17.132C11.858 17.5155 12.4797 17.5155 12.8632 17.132L20.7622 9.23299C21.1457 8.84951 21.1457 8.22777 20.7622 7.8443C20.3788 7.46082 19.757 7.46082 19.3736 7.8443L12.1688 15.049Z"
      ></path>
    </Icon>
  );
};

export default DoubleCheckmarkOutlineIcon;
/* eslint-enable max-len */
