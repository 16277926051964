/* eslint-disable max-len */
// BASEUI-GENERATED-REACT-ICON
// DO NOT EDIT THIS FILE DIRECTLY, SEE README.md
import * as React from 'react';
import type { IconProps } from '../Icon';
import Icon from '../Icon';

const ResizeOutlineIcon = (props: IconProps): JSX.Element => {
  const {
    title = 'Resize Outline Icon',
    size,
    color,
    onClick,
    ...restProps
  } = props;
  return (
    <Icon
      title={title}
      size={size}
      color={color}
      onClick={onClick}
      {...restProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.22835 17.7599C5.7957 17.3273 5.7957 16.6258 6.22835 16.1932L16.2049 6.2166C16.6376 5.78396 17.339 5.78396 17.7717 6.2166C18.2043 6.64925 18.2043 7.3507 17.7717 7.78334L7.79509 17.7599C7.36244 18.1926 6.66099 18.1926 6.22835 17.7599Z"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4065 17.1042C12.9739 16.6715 12.9739 15.9701 13.4065 15.5374L16.0144 12.9295C16.4471 12.4969 17.1485 12.4969 17.5812 12.9295C18.0138 13.3622 18.0138 14.0636 17.5812 14.4963L14.9733 17.1042C14.5406 17.5368 13.8392 17.5368 13.4065 17.1042Z"
      ></path>
    </Icon>
  );
};

export default ResizeOutlineIcon;
/* eslint-enable max-len */
