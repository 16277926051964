import React from 'react';
import { Box, Skeleton, useTheme } from '@mui/material';

const SkeletonPlaceholder = () => {
  const theme = useTheme();

  return (
    <Box height="100%" width="100%" padding={theme.spacing(4)}>
      <Skeleton
        variant="rectangular"
        height={`calc(100% - ${theme.spacing(4)} * 2)`}
        width={`calc(100% - ${theme.spacing(4)} * 2)`}
        sx={{ borderRadius: theme.borderRadius.borderRadiusSemiSharp }}
      />
    </Box>
  );
};

export default React.memo(SkeletonPlaceholder);
