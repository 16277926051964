/* eslint-disable max-len */
// BASEUI-GENERATED-REACT-ICON
// DO NOT EDIT THIS FILE DIRECTLY, SEE README.md
import * as React from 'react';
import type { IconProps } from '../Icon';
import Icon from '../Icon';

const CheckboxCheckedOutlineIcon = (props: IconProps): JSX.Element => {
  const {
    title = 'Checkbox Checked Outline Icon',
    size,
    color,
    onClick,
    ...restProps
  } = props;
  return (
    <Icon
      title={title}
      size={size}
      color={color}
      onClick={onClick}
      {...restProps}
    >
      <path d="M6 3C4.34315 3 3 4.34315 3 6V18C3 19.6569 4.34315 21 6 21H18C19.6569 21 21 19.6569 21 18V6C21 4.34315 19.6569 3 18 3H6Z"></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.29289 12.2929C6.68342 11.9024 7.31658 11.9024 7.70711 12.2929L9.77271 14.3585L16.1702 7.96098C16.5608 7.57045 17.1939 7.57045 17.5844 7.96098C17.975 8.3515 17.975 8.98467 17.5844 9.37519L10.4798 16.4798C10.2923 16.6674 10.0379 16.7727 9.77271 16.7727C9.50749 16.7727 9.25314 16.6674 9.0656 16.4798L6.29289 13.7071C5.90237 13.3166 5.90237 12.6834 6.29289 12.2929Z"
        fill="white"
      ></path>
    </Icon>
  );
};

export default CheckboxCheckedOutlineIcon;
/* eslint-enable max-len */
