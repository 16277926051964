import { EntityState } from '@reduxjs/toolkit';
import { viewAccessAdapter, viewAdapter } from '../../helpers/grayzone';
import { View, Widget, GridLayout, ViewConfiguration, ViewState, ViewAccess } from '../../types/grayzone';
import { grayzoneApi } from './grayzone';

export type HttpErrorDetail = {
  code?: string;
  message: string;
};
export type HttpError = {
  status: number;
  error?: {
    message: string;
    details: HttpErrorDetail[];
  };
};

export type PartialView = {
  viewConfiguration?: ViewConfiguration;
  gridLayout?: GridLayout;
  widgets?: Widget[];
};

export type PostViewApiResponse = /** status 200 View successfully saved. Response includes the id corresponding to the saved view */ {
  viewId: string;
};
export type PostViewApiArg = {
  /** The view being posted and the optional userId indicating which user this view will be associated with */
  body: {
    viewState: ViewState;
    ownerId?: string;
    viewId?: string;
  };
};
// export type GetViewApiResponse = /** status 200 Any views found within the provided parameters are returned in an array */ View[];
export type GetViewApiResponse = /** status 200 Any views found within the provided parameters are returned in an array */ EntityState<View>;

export type GetViewApiArg = {
  /** Id of the view being retrieved */
  viewId?: string;
  /** Id of the user to whom the view belongs */
  userId?: string;
  /** The maximum number of views to be retrieved by this request */
  maxResults?: number;
  ownerId?: string;
};
export type GetViewByViewIdApiResponse = /** status 200 View found. The requested view is returned in the response body. */ View;
export type GetViewByViewIdApiArg = {
  viewId: string;
};
export type DeleteViewByViewIdApiResponse = /** status 200 View successfully deleted. The deleted view is returned in the response body. */ View;
export type DeleteViewByViewIdApiArg = {
  viewId: string;
};
export type PatchViewByViewIdApiResponse = /** status 200 The specified view was successfully updated. The new version of the view is returned in the response body. */ View;
export type PatchViewByViewIdApiArg = {
  viewId: string;
  /** An object containing a subset of the properties present on the View model */
  body: PartialView;
};
export type PutViewByViewIdApiResponse = /** status 200 The view was successfully replaced. The old view is returned in the response body. */ View;
export type PutViewByViewIdApiArg = {
  viewId: string;
  /** An object containing a subset of the properties present on the View model */
  body: ViewState;
};

export type PostViewAccessApiArg = {
  body: { shareId: string };
};

export type PostViewAccessApiResponse = {
  accessId: string;
};

export type GetViewAccessApiArg = {
  id?: string;
  userId?: string;
  viewId?: string;
  permissions?: string;
  isActive?: string;
  maxResults?: string;
};

export type GetViewAccessApiResponse = EntityState<ViewAccess>;

export type DeleteViewAccessApiArg = {
  viewId: string;
};

export type DeleteViewAccessApiResponse = {
  accessId: string;
};

const injectedRtkApi = grayzoneApi.injectEndpoints({
  endpoints: (build) => ({
    postView: build.mutation<PostViewApiResponse, PostViewApiArg>({
      query: (queryArg) => ({ url: `/view`, method: 'POST', body: queryArg.body }),
      invalidatesTags: (result) => {
        return result ? [{ type: 'View', id: result.viewId }, { type: 'View', id: 'LIST' }, 'ViewAccess'] : [];
      }
    }),
    getView: build.query<GetViewApiResponse, GetViewApiArg>({
      query: (queryArg) => ({ url: `/view`, params: queryArg }),
      transformResponse: (views: View[]) => viewAdapter.addMany(viewAdapter.getInitialState(), views),
      providesTags: (result) => {
        return result ? [...result.ids.map((viewId) => ({ type: 'View' as const, id: viewId })), 'View', { type: 'View', id: 'LIST' }] : ['View', { type: 'View', id: 'LIST' }];
      }
    }),
    getViewByViewId: build.query<GetViewByViewIdApiResponse, GetViewByViewIdApiArg>({
      query: (queryArg) => ({ url: `/view/${queryArg.viewId}` }),
      providesTags: (result) => {
        return result ? [{ type: 'View', id: result.id }, 'View'] : ['View'];
      }
    }),
    deleteViewByViewId: build.mutation<DeleteViewByViewIdApiResponse, DeleteViewByViewIdApiArg>({
      query: (queryArg) => ({ url: `/view/${queryArg.viewId}`, method: 'DELETE' }),
      invalidatesTags: (result, error, arg) => [{ type: 'View', id: arg.viewId }]
    }),
    patchViewByViewId: build.mutation<PatchViewByViewIdApiResponse, PatchViewByViewIdApiArg>({
      query: (queryArg) => ({ url: `/view/${queryArg.viewId}`, method: 'PATCH', body: queryArg.body }),
      invalidatesTags: (result, error, arg) => [{ type: 'View', id: arg.viewId }]
    }),
    putViewByViewId: build.mutation<PutViewByViewIdApiResponse, PutViewByViewIdApiArg>({
      query: (queryArg) => ({ url: `/view/${queryArg.viewId}`, method: 'PUT', body: queryArg.body }),
      invalidatesTags: (result) => {
        return result ? [{ type: 'View', id: result.id }] : [];
      }
    }),
    postViewAccess: build.mutation<PostViewAccessApiResponse, PostViewAccessApiArg>({
      query: (queryArg) => ({ url: `/view/access`, method: 'POST', body: queryArg.body }),
      invalidatesTags: (result) => {
        return result ? [{ type: 'ViewAccess', id: result.accessId }, 'ViewAccess', 'View'] : [];
      }
    }),
    getViewAccess: build.query<GetViewAccessApiResponse, GetViewAccessApiArg>({
      query: (queryArg) => ({ url: `/view/access`, params: queryArg }),
      transformResponse: (accesses: ViewAccess[]) => viewAccessAdapter.addMany(viewAccessAdapter.getInitialState(), accesses),
      providesTags: (result) => {
        return result ? [...result.ids.map((accessId) => ({ type: 'ViewAccess' as const, id: accessId })), 'ViewAccess'] : ['ViewAccess'];
      }
    }),
    deleteViewAccess: build.mutation<DeleteViewAccessApiResponse, DeleteViewAccessApiArg>({
      query: (queryArg) => ({ url: `/view/access/${queryArg.viewId}`, method: 'DELETE' }),
      invalidatesTags: (result) => {
        return result ? [{ type: 'ViewAccess', id: result.accessId }, 'ViewAccess', 'View'] : [];
      }
    })
  }),
  overrideExisting: false
});

export const viewEndpoints = injectedRtkApi.endpoints;
