/* eslint-disable max-len */
// BASEUI-GENERATED-REACT-ICON
// DO NOT EDIT THIS FILE DIRECTLY, SEE README.md
import * as React from 'react';
import type { IconProps } from '../Icon';
import Icon from '../Icon';

const TrendOutlineIcon = (props: IconProps): JSX.Element => {
  const {
    title = 'Trend Outline Icon',
    size,
    color,
    onClick,
    ...restProps
  } = props;
  return (
    <Icon
      title={title}
      size={size}
      color={color}
      onClick={onClick}
      {...restProps}
    >
      <path
        d="M4 15L9 10L14 15L20 9"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      ></path>
      <circle cx="4" cy="15" r="2"></circle>
      <circle cx="9" cy="10" r="2"></circle>
      <circle cx="14" cy="15" r="2"></circle>
      <path d="M21.6561 7.00959C21.8591 6.9552 22.0448 7.14094 21.9904 7.34392L20.5291 12.7975C20.4747 13.0005 20.221 13.0685 20.0724 12.9199L16.0801 8.92758C15.9315 8.77899 15.9995 8.52526 16.2025 8.47087L21.6561 7.00959Z"></path>
    </Icon>
  );
};

export default TrendOutlineIcon;
/* eslint-enable max-len */
