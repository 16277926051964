import React from 'react';
import PremiseLogoColor from './svg/premise-logo-web.svg';
import PremiseLogoGray from './svg/premise-logo-gray.svg';

interface Props {
  active?: boolean,
}

export function PremiseLogo(props: Props): JSX.Element {
  const { active } = props;
  return (
    <img
      alt="a full color logo with text representing Premise Data"
      src={active ? PremiseLogoColor : PremiseLogoGray}
      style={{
        height: '20px',
        width: '110.69px',
      }}
    />
  );
}
