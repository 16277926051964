import Ajv from 'ajv';
import { GrayzoneSchema } from '../types/grayzone';
import * as view from './schemas/view.json';
import * as view_export from './schemas/viewExport.json';
import * as view_configuration from './schemas/viewConfiguration.json';
import * as view_configuration_dto from './schemas/viewConfigurationDTO.json';
import * as custom_event_set from './schemas/customEventSet.json';
export const ajv = new Ajv();
ajv.addSchema(view, GrayzoneSchema.VIEW);
ajv.addSchema(view_export, GrayzoneSchema.VIEW_EXPORT);
ajv.addSchema(view_configuration, GrayzoneSchema.VIEW_CONFIGURATION);
ajv.addSchema(view_configuration_dto, GrayzoneSchema.VIEW_CONFIGURATION_DTO);
ajv.addSchema(custom_event_set, GrayzoneSchema.CUSTOM_EVENT_SET);
