import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../store';

// ----- DEFINE STATE TYPE -----
export type AuthState = {
  token: string | null;
};

// ----- DEFINE INITIAL STATE -----
const initialState: AuthState = {
  token: null
};

// ----- EXPORT SLICE -----
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateToken: (state, action: PayloadAction<string | null>) => {
      state.token = action.payload;
    }
  }
});

// ----- EXPORT ACTIONS -----
export const authActions = authSlice.actions;

// ----- DEFINE SELECTORS -----
const selectToken = (state: RootState) => state.auth.token;

// ----- EXPORT SELECTORS -----
export const authSelectors = {
  selectToken
};

// ----- EXPORT REDUCER -----
export default authSlice.reducer;
