import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import grayzoneReducer from '../features/grayzone/grayzoneSlice';
import authReducer from '../features/auth/authSlice';
import { irisApi } from '../api/iris';
import { grayzoneApi } from '../api/grayzone';
import { sentimentApi } from '../api/sentiment';
import { placesApi } from '../api/places';
import { gdeltApi } from '../api/gdelt';
import { rtkQueryErrorLogger } from './errorLogger';

export const store = configureStore({
  reducer: {
    grayzone: grayzoneReducer,
    auth: authReducer,
    [irisApi.reducerPath]: irisApi.reducer, // This slice positioning is what redux recommends (a top level slice for the api).,
    [grayzoneApi.reducerPath]: grayzoneApi.reducer,
    [sentimentApi.reducerPath]: sentimentApi.reducer,
    [placesApi.reducerPath]: placesApi.reducer,
    [gdeltApi.reducerPath]: gdeltApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([irisApi.middleware, grayzoneApi.middleware, sentimentApi.middleware, placesApi.middleware, gdeltApi.middleware, rtkQueryErrorLogger])
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
