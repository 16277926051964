import { useCallback, useMemo } from 'react';

// ----- MUI -----
import { useTheme } from '@mui/material';

// ----- VISX -----
import { scaleLinear } from '@visx/scale';
import { LinePath } from '@visx/shape';
import { curveCatmullRom } from '@visx/curve';
import { Group } from '@visx/group';

// ----- Types -----
import { GenericMap, TimeseriesPoint } from '../../../../../types/grayzone';

// ----- Ours -----
import TimeScrubber from './TimeScrubber';
import { LEFT_OFFSET, DATE_FORMATTER, RIGHT_OFFSET, GET_DAYS_BETWEEN, ONE_DAY } from '../constants';
import { TickLabel } from '../styles';

const CHART_HEIGHT = 24;

type Props = {
  parentWidth: number;
  parentHeight: number;
  data: TimeseriesPoint[] | GenericMap<TimeseriesPoint[]>;
  categoricalColorScheme?: GenericMap<string>; // if we have this, then we are categorical
};
const MiniTimeline = ({ parentHeight, parentWidth, data, categoricalColorScheme }: Props) => {
  const theme = useTheme();

  const width = parentWidth - RIGHT_OFFSET - LEFT_OFFSET;
  const xStart = LEFT_OFFSET;

  const firstAndLastDate = useMemo(() => {
    if (Array.isArray(data)) {
      if (data.length === 0) return [0, 0];
      return [data[0].date, data[data.length - 1].date];
    } else {
      const firstData = data[Object.keys(data)[0]];
      if (!firstData) return [0, 0];
      return [firstData[0].date, firstData[firstData.length - 1].date];
    }
  }, [data]);

  const yScale = useMemo(
    () =>
      scaleLinear({
        domain: [0, 1],
        range: [CHART_HEIGHT, 0]
      }),
    []
  );
  const xScale = useMemo(
    () =>
      scaleLinear({
        domain: firstAndLastDate,
        range: [xStart, xStart + width]
      }),
    [data, width]
  );
  const getDateFromX = useCallback(
    (x: number) => {
      const value = Math.round(xScale.invert(x + xStart));
      return value - (value % ONE_DAY);
    },
    [xScale]
  );

  const Line = useMemo(() => {
    if (Array.isArray(data)) {
      return <LinePath curve={curveCatmullRom} data={data} x={(d) => xScale(d.date)} y={(d) => yScale(d.smoothed_mean)} stroke={theme.color['neutral-75']} strokeWidth={1} />;
    } else {
      return (
        <>
          {Object.keys(data).map((key) => {
            return (
              <LinePath
                key={`mini-line-${key}`}
                curve={curveCatmullRom}
                data={data[key]}
                x={(d) => xScale(d.date)}
                y={(d) => yScale(d.smoothed_mean)}
                stroke={categoricalColorScheme?.[key] ?? theme.color['neutral-75']}
                strokeWidth={1}
              />
            );
          })}
        </>
      );
    }
  }, [data, categoricalColorScheme, xScale, yScale]);

  return (
    <svg height={parentHeight} width={parentWidth}>
      <Group top={1}>
        {/* border of chart */}
        <line x1={xStart} y1={0} x2={xStart + width} y2={0} stroke={theme.color['neutral-25']} />
        <line x1={xStart} y1={0} x2={xStart} y2={CHART_HEIGHT} stroke={theme.color['neutral-25']} />
        <line x1={xStart + width} y1={0} x2={xStart + width} y2={CHART_HEIGHT} stroke={theme.color['neutral-25']} />
        {/* horizontal line through middle of chart */}
        <line x1={xStart} y1={CHART_HEIGHT / 2} x2={xStart + width} y2={CHART_HEIGHT / 2} stroke={theme.color['neutral-10']} />
        {/* x axis */}
        <line x1={xStart} y1={CHART_HEIGHT} x2={xStart + width} y2={CHART_HEIGHT} stroke={theme.color['neutral-25']} />
        {/* ticks */}
        <line x1={xStart} y1={CHART_HEIGHT} x2={xStart} y2={CHART_HEIGHT + 4} stroke={theme.color['neutral-25']} />
        <line x1={xStart + width} y1={CHART_HEIGHT} x2={xStart + width} y2={CHART_HEIGHT + 4} stroke={theme.color['neutral-25']} />
        {/* tick labels */}
        <TickLabel x={xStart} y={CHART_HEIGHT + 4 + parseInt(theme.spacings['xxxx-small'])} verticalAnchor="start">
          {DATE_FORMATTER(firstAndLastDate[0])}
        </TickLabel>
        <TickLabel x={xStart + width} y={CHART_HEIGHT + 4 + parseInt(theme.spacings['xxxx-small'])} verticalAnchor="start" textAnchor="end">
          {DATE_FORMATTER(firstAndLastDate[1])}
        </TickLabel>
        {/* line curve */}
        {Line}
      </Group>

      {/* Time scrub */}
      <TimeScrubber
        left={xStart}
        width={width}
        height={CHART_HEIGHT}
        numDays={GET_DAYS_BETWEEN(firstAndLastDate[0], firstAndLastDate[1])}
        getDateFromX={getDateFromX}
        getXFromDate={xScale}
      />
    </svg>
  );
};

export default MiniTimeline;
