/* eslint-disable max-len */
// BASEUI-GENERATED-REACT-ICON
// DO NOT EDIT THIS FILE DIRECTLY, SEE README.md
import * as React from 'react';
import type { IconProps } from '../Icon';
import Icon from '../Icon';

const HideOutlineIcon = (props: IconProps): JSX.Element => {
  const {
    title = 'Hide Outline Icon',
    size,
    color,
    onClick,
    ...restProps
  } = props;
  return (
    <Icon
      title={title}
      size={size}
      color={color}
      onClick={onClick}
      {...restProps}
    >
      <path d="M19.7071 5.70711C20.0976 5.31658 20.0976 4.68342 19.7071 4.29289C19.3166 3.90237 18.6834 3.90237 18.2929 4.29289L4.29289 18.2929C3.90237 18.6834 3.90237 19.3166 4.29289 19.7071C4.68342 20.0976 5.31658 20.0976 5.70711 19.7071L19.7071 5.70711Z"></path>
      <path d="M12 6C13.0186 6 14.0661 6.1884 15.0819 6.50386L13.4384 8.14737C12.9481 8.05183 12.4649 8 12 8C10.1649 8 8.04314 8.80761 6.33381 9.92434C5.49382 10.4731 4.83488 11.0446 4.4115 11.5313C4.22442 11.7464 4.11953 11.9023 4.06159 12C4.11953 12.0977 4.22442 12.2536 4.4115 12.4687C4.83488 12.9554 5.49382 13.5269 6.33381 14.0757C6.57042 14.2302 6.81493 14.3789 7.06548 14.5203L5.60485 15.9809C3.49738 14.6892 2 13.0624 2 12C2 10 7.30648 6 12 6Z"></path>
      <path d="M18.3952 8.01906L16.9345 9.4797C17.1851 9.62111 17.4296 9.76976 17.6662 9.92434C18.5062 10.4731 19.1651 11.0446 19.5885 11.5313C19.7756 11.7464 19.8805 11.9023 19.9384 12C19.8805 12.0977 19.7756 12.2536 19.5885 12.4687C19.1651 12.9554 18.5062 13.5269 17.6662 14.0757C15.9569 15.1924 13.8351 16 12 16C11.5351 16 11.0519 15.9482 10.5616 15.8526L8.91808 17.4961C9.93392 17.8116 10.9814 18 12 18C16.6935 18 22 14 22 12C22 10.9376 20.5026 9.31082 18.3952 8.01906Z"></path>
      <path d="M12.5377 9.04806C12.3633 9.01649 12.1836 9 12 9C10.3431 9 9 10.3431 9 12C9 12.1836 9.01649 12.3633 9.04806 12.5377L12.5377 9.04806Z"></path>
      <path d="M14.9519 11.4623L11.4623 14.9519C11.6367 14.9835 11.8164 15 12 15C13.6569 15 15 13.6569 15 12C15 11.8164 14.9835 11.6367 14.9519 11.4623Z"></path>
    </Icon>
  );
};

export default HideOutlineIcon;
/* eslint-enable max-len */
