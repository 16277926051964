// ----- REACT -----
import { ChangeEvent, MutableRefObject, ReactNode } from 'react';

// ----- MODULES -----
import { ajv } from '../helpers/ajvValidator';

// ----- TYPES -----
import { GrayzoneSchema } from '../types/grayzone';

// ----- OURS -----
import useAppSnackbar from '../hooks/useAppSnackbar';

// ----- Amplitude -----
import amplitude from 'amplitude-js';

// ----- COMPONENT -----

type Props<T> = {
  onLoad: (data: T) => void;
  accept: string;
  schema: GrayzoneSchema;
  children: ReactNode;
  inputRef?: MutableRefObject<null | HTMLInputElement>;
  customParser?: (csv: string) => object;
};

const FileUploader = <T,>({ onLoad, accept, schema, children, customParser, inputRef }: Props<T>) => {
  const { enqueue } = useAppSnackbar();

  // ----- Handlers -----
  const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target || !e.target.files) {
      return;
    }

    e.preventDefault();
    const reader = new FileReader();
    reader.onload = (e) => {
      const text = e.target?.result as string;

      let json: T;

      try {
        json = customParser ? (customParser(text) as unknown as T) : (JSON.parse(text) as T);
      } catch (error) {
        enqueue('File could not be parsed as JSON', { variant: 'error' });
        return console.log(error);
      }

      const validate = ajv.getSchema<T>(schema);
      if (!validate) {
        enqueue('Could not fetch provided schema', { variant: 'error' });
        return console.warn('Could not fetch provided schema.');
      }
      const valid = validate(json);
      if (!valid) {
        enqueue("Provided JSON doesn't have the correct shape", { variant: 'error' });
        return console.warn('ERROR: ', validate.errors, 'JSON: ', json);
      }
      onLoad && onLoad(json);
    };
    reader.readAsText(e.target?.files?.[0]);
  };

  const handleClick: React.MouseEventHandler<HTMLInputElement> = (e) => {
    e.currentTarget.value = '';
    amplitude.getInstance().logEvent('Uploaded View');
  };
  // ----- Effects -----

  // ----- Return Component -----
  return (
    <label htmlFor="contained-button-file">
      <input
        accept={accept}
        id="contained-button-file"
        multiple={false}
        type="file"
        style={{ display: 'none' }}
        onChange={(e) => {
          handleUpload(e);
        }}
        onClick={handleClick}
        ref={inputRef}
      />
      {children}
    </label>
  );
};

export default FileUploader;
