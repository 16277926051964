import React, { memo, ReactNode, useMemo } from 'react';
import styled from 'styled-components';

const ButtonContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column'
}));

type Props = {
  children: React.ReactNode | React.ReactNode[];
};
const ButtonGroup = ({ children }: Props) => {
  // quick button group, FOR STYLE ONLY
  // TODO: make into actual button group with state management

  const startStyle = useMemo((): React.CSSProperties => {
    return {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0
    };
  }, []);
  const midStyle = useMemo((): React.CSSProperties => {
    return {
      borderRadius: 0
    };
  }, []);
  const endStyle = useMemo((): React.CSSProperties => {
    return {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0
    };
  }, []);
  return (
    <ButtonContainer>
      {React.Children.map<ReactNode, ReactNode>(children, (child, idx) => {
        if (React.isValidElement(child)) {
          let style: React.CSSProperties;
          if (idx === 0) {
            style = startStyle;
          } else if (idx === React.Children.count(children) - 1) {
            style = endStyle;
          } else {
            style = midStyle;
          }
          return React.cloneElement(child, { style });
        }
      })}
    </ButtonContainer>
  );
};

export default memo(ButtonGroup);
