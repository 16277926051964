/* eslint-disable max-len */
// BASEUI-GENERATED-REACT-ICON
// DO NOT EDIT THIS FILE DIRECTLY, SEE README.md
import * as React from 'react';
import type { IconProps } from '../Icon';
import Icon from '../Icon';

const AddOutlineIcon = (props: IconProps): JSX.Element => {
  const {
    title = 'Add Outline Icon',
    size,
    color,
    onClick,
    ...restProps
  } = props;
  return (
    <Icon
      title={title}
      size={size}
      color={color}
      onClick={onClick}
      {...restProps}
    >
      <path d="M3.5145 11.0001C2.96221 11.0001 2.5145 11.4478 2.5145 12.0001C2.5145 12.5524 2.96221 13.0001 3.5145 13.0001H11L11 20.4856C11 21.0379 11.4477 21.4856 12 21.4856C12.5523 21.4856 13 21.0379 13 20.4856V13.0001H20.4854C21.0377 13.0001 21.4854 12.5524 21.4854 12.0001C21.4854 11.4478 21.0377 11.0001 20.4854 11.0001L13 11.0001V3.51465C13 2.96236 12.5523 2.51465 12 2.51465C11.4477 2.51465 11 2.96236 11 3.51465L11 11.0001H3.5145Z"></path>
    </Icon>
  );
};

export default AddOutlineIcon;
/* eslint-enable max-len */
