// ----- REACT -----
import { useCallback, useRef } from 'react';

// ----- Redux -----
import { useAppSelector, useAppDispatch } from '../../../hooks/useRedux';
import { grayzoneActions, grayzoneSelectors } from '../grayzoneSlice';
import { store } from '../../../store';
import { grayzoneEndpoints } from '../../../api/grayzone';

// ----- MUI -----
import { Close as CloseIcon } from '@mui/icons-material';
import { AppBar, Box, Button, Dialog, DialogContent, IconButton, Toolbar, Typography } from '@mui/material';

// ----- MODULES -----
import { v4 as uuid_v4 } from 'uuid';
// ----- PDS -----
import { PremiseLogo } from '../../../PremiseDesign/Icons';

// ----- Ours -----
import ViewConfig from './ViewConfig';
import { ajv } from '../../../helpers/ajvValidator';
import useAppSnackbar from '../../../hooks/useAppSnackbar';
import { generateViewState } from '../../../helpers/grayzone';

// ----- Types -----
import { ViewConfiguration, GrayzoneSchema } from '../../../types/grayzone';

// ----- Styles -----
import { FlexCentered } from '../../../styles';

// ----- COMPONENT -----
const ViewConfigModal = () => {
  const { enqueue } = useAppSnackbar();
  // ----- Redux -----
  const dispatch = useAppDispatch();

  // ----- Refs -----
  const submitRef = useRef<(() => void) | null>(null);

  // ----- Selectors -----
  const viewConfigModalOpen = useAppSelector(grayzoneSelectors.viewConfigurations.selectViewConfigModalOpen);

  // ----- Queries -----
  const [triggerPostViewMutation] = grayzoneEndpoints.postView.useMutation();

  // ----- Handlers -----
  const onViewConfigModalClose = useCallback(() => {
    dispatch(grayzoneActions.updateViewConfigModalStateTo(false));
  }, []);

  const validateForm = useCallback((newView: ViewConfiguration) => {
    const validate = ajv.getSchema<ViewConfiguration>(GrayzoneSchema.VIEW_CONFIGURATION);
    if (!validate) {
      console.error('Could not fetch provided schema.');
      return false;
    }
    const valid = validate(newView);
    if (!valid) {
      if (validate.errors?.[0]?.message) {
        enqueue(`${validate.errors[0].message.slice(0, 1).toUpperCase()}${validate.errors[0].message.slice(1)}`, { variant: 'error' });
      } else {
        enqueue('Provided JSON did not successfylly validate', { variant: 'error' });
      }
      console.warn('VALIDATION WARNING: ', validate.errors, 'JSON: ', newView);
      return false;
    }

    return true;
  }, []);

  const uploadView = async (viewId: string) => {
    const exportState = generateViewState(store.getState().grayzone, viewId);
    try {
      await triggerPostViewMutation({ body: { viewId: viewId, viewState: exportState } }).unwrap();
      enqueue('View successfully uploaded', { variant: 'success', preventDuplicate: false });
      onViewConfigModalClose();
      enqueue('View configured', { variant: 'success' });
    } catch (e) {
      enqueue('Something went wrong and view was not uploaded', { variant: 'error' });
    }
  };

  const handleClickedConfigure = (newView: ViewConfiguration) => {
    if (!validateForm(newView)) {
      return;
    }

    const viewId = uuid_v4();
    dispatch(grayzoneActions.configureNewView({ config: newView, viewId: viewId }));
    uploadView(viewId);
  };

  // ----- Return Component -----
  return (
    <Dialog fullScreen open={viewConfigModalOpen} fullWidth maxWidth="sm" onClose={onViewConfigModalClose}>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar variant="dense">
          <IconButton
            edge="start"
            color="inherit"
            sx={{ mr: 2, bgcolor: 'primary.main', ':hover': { bgcolor: 'primary.dark' } }}
            onClick={onViewConfigModalClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <FlexCentered style={{ width: '100%', height: '100%', flexDirection: 'column' }} id="configure-view">
          <FlexCentered style={{ width: '30%', height: '70%', flexDirection: 'column', justifyContent: 'space-between', minWidth: 400 }}>
            <PremiseLogo />

            <Typography variant="display-4">Configure Grayzone</Typography>

            <Box height="70%" width="100%" display="flex" justifyContent="center">
              <ViewConfig
                formType="new"
                currConfiguration={undefined}
                submitRef={submitRef}
                onSubmit={handleClickedConfigure}
                style={{ height: '100%', width: '100%', gap: '20px', padding: '0px 12px' }}
              />
            </Box>
            <Box display="flex" flexDirection="row" gap="10px">
              <Button
                onClick={() => {
                  submitRef.current && submitRef.current();
                }}
                variant="outlined"
                size="large"
              >
                Configure
              </Button>
            </Box>
          </FlexCentered>
        </FlexCentered>
      </DialogContent>
    </Dialog>
  );
};

export default ViewConfigModal;
