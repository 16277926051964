/* eslint-disable max-len */
// BASEUI-GENERATED-REACT-ICON
// DO NOT EDIT THIS FILE DIRECTLY, SEE README.md
import * as React from 'react';
import type { IconProps } from '../Icon';
import Icon from '../Icon';

const SplitScreenOutlineIcon = (props: IconProps): JSX.Element => {
  const {
    title = 'Split Screen Outline Icon',
    size,
    color,
    onClick,
    ...restProps
  } = props;
  return (
    <Icon
      title={title}
      size={size}
      color={color}
      onClick={onClick}
      {...restProps}
    >
      <path
        d="M4 7C4 5.89543 4.89543 5 6 5H10V19H6C4.89543 19 4 18.1046 4 17V7Z"
        stroke={color}
        strokeWidth="2"
        fill="none"
      ></path>
      <path
        d="M14 5H18C19.1046 5 20 5.89543 20 7V17C20 18.1046 19.1046 19 18 19H14"
        stroke={color}
        strokeWidth="2"
        fill="none"
      ></path>
      <path d="M10 2V21.5" stroke={color} strokeWidth="2" fill="none"></path>
    </Icon>
  );
};

export default SplitScreenOutlineIcon;
/* eslint-enable max-len */
