import { memo, useCallback, useEffect, useMemo, useState } from 'react';

// ----- Redux -----
import { grayzoneActions, grayzoneSelectors } from '../../../../../features/grayzone/grayzoneSlice';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';

// ----- API -----
import { eventEndpoints } from '../../../../../api/grayzone/event';

// ----- MODULES -----
import { useTheme, styled, Box, Typography } from '@mui/material';

import { Group } from '@visx/group';
import { Line, Circle, Bar } from '@visx/shape';
import { HtmlLabel } from '@visx/annotation';
import { scaleLinear } from '@visx/scale';

import { extent } from 'd3';

// ----- OURS -----
import useGrid from '../../../../../hooks/useGrid';
import { customEventSetAdapter, GDELTEventQueryResultAdapter } from '../../../../../helpers/grayzone';
import { DATE_FORMATTER } from '../constants';

// ----- TYPES -----
import {
  ACLEDdataType,
  FeedDataType,
  GDELTdataType,
  FeedWidget,
  CustomEventSet,
  GenericMap,
  GDELTEventQueryResult,
  GDELTKeywordQueryDataItem
} from '../../../../../types/grayzone';
import type { ScaleLinear } from 'd3-scale';

// ----- Styles -----
import { TruncatedTypography } from '../styles';

const Point = styled(Circle)({
  WebkitFilter: 'drop-shadow(0px 0px 1px #7d7d7d2e)'
});

type FeedLine = {
  points: {
    date: string;
    x: number;
    id: string;
  }[];
  name: string;
  id: string;
};

// ----- CONSTANTS -----
const LINE_PADDING = 4;
const LINE_HEIGHT = 28;

type Props = {
  data: (GDELTdataType | ACLEDdataType)[];
  left: number;
  width: number;
  setHeight: React.Dispatch<React.SetStateAction<number>>;
  xScale: ScaleLinear<number, number>;
  setTooltip: (data: string | React.ReactNode, left: number, top: number, offsetOff?: boolean) => void;
  hideTooltip: () => void;
  setHighlightedComponent: (component: React.ReactNode, offset?: number) => void;
  clipKey: string;
  enabledEventSets: GenericMap<boolean>;
};

const SubTimelines = ({ data, left, width, setHeight, xScale, hideTooltip, setTooltip, setHighlightedComponent, clipKey, enabledEventSets }: Props) => {
  const dispatch = useAppDispatch();

  // ----- Selectors -----
  const hideFeedData = useAppSelector(grayzoneSelectors.timeline.selectHideFeedDataState);
  const highlightedNewsEvent = useAppSelector(grayzoneSelectors.hoverState.selectHighlightedNewsEvent);
  const eventQueryResults = useAppSelector(grayzoneSelectors.timeline.selectEventQueryResults);
  const eventQueryTooltipData = useAppSelector(grayzoneSelectors.timeline.selectEventQueryTooltipData);

  const [feedLine, setFeedLine] = useState<FeedLine | undefined>(undefined);
  const [eventLines, setEventLines] = useState<CustomEventSet[]>([]);

  const GDELTEventQueryResultSelectors = useMemo(() => {
    return GDELTEventQueryResultAdapter.getSelectors();
  }, []);

  const customEventSetSelectors = useMemo(() => {
    return customEventSetAdapter.getSelectors();
  }, []);
  const highlightedNewsDate = useAppSelector(grayzoneSelectors.hoverState.selectHighlightedNewsDate);

  const primaryViewId = useAppSelector(grayzoneSelectors.viewConfigurations.selectPrimaryViewId);
  if (!primaryViewId) {
    throw new Error('No primary view id found. A primary view must be defined!');
  }

  // ----- Queries -----
  const { data: eventSetData } = eventEndpoints.getEventSets.useQuery({ viewId: primaryViewId });

  useEffect(() => {
    if (eventSetData === undefined) return;

    // setEventLines(customEventSetSelectors.selectAll(eventSetData).filter((eventSet) => enabledEventSets[eventSet.id] === true) ?? []);
    setEventLines(customEventSetSelectors.selectAll(eventSetData).filter((eventSet) => enabledEventSets?.[eventSet.id] === true) ?? []);
  }, [eventSetData, enabledEventSets]);

  // ----- Helpers -----
  const numActiveQueryResultLines = useMemo(
    () =>
      Object.keys(
        GDELTEventQueryResultSelectors.selectAll(eventQueryResults)
          .filter((result) => enabledEventSets[result.eventSetId] && !result.hidden)
          .reduce((runningObj: GenericMap<boolean>, currResult) => {
            if (!runningObj[currResult.eventSetId]) {
              runningObj[currResult.eventSetId] = true;
            }
            return runningObj;
          }, {})
      ).length,
    [eventQueryResults, enabledEventSets]
  );

  const theme = useTheme();
  const { gridLayout } = useGrid();

  const widgets = useAppSelector((state) => grayzoneSelectors.widgets.selectAllWidgetsByGridLayoutId(state, gridLayout.id));

  useEffect(() => {
    const feedWidgets = widgets.filter((widget) => widget.type === 'feed') as FeedWidget[];
    if (feedWidgets.length === 0) {
      setFeedLine(undefined);
      setHeight(eventLines.length * LINE_HEIGHT + numActiveQueryResultLines * LINE_HEIGHT);
    } else {
      const widget = feedWidgets[0];
      const points = data.map((item: GDELTdataType | ACLEDdataType) => {
        if (item.type === FeedDataType.ACLED) {
          return {
            date: item.date_event,
            x: xScale(Math.floor(new Date(item.date_event).getTime())),
            id: item.id
          };
        } else {
          return {
            date: item.date_news,
            x: xScale(Math.floor(new Date(item.date_news).getTime())),
            id: item.id
          };
        }
      });
      setFeedLine({
        // filter out points that aren't in range for rendering
        points: points.filter((point) => point.x >= left && point.x <= left + width),
        name: widget.name,
        id: widget.id
      });

      setHeight(LINE_HEIGHT + eventLines.length * LINE_HEIGHT + numActiveQueryResultLines * LINE_HEIGHT);
    }
  }, [widgets, xScale, data, hideFeedData, eventLines, numActiveQueryResultLines]);

  useEffect(() => {
    if (highlightedNewsEvent && feedLine) {
      dispatch(grayzoneActions.setHighlightedNewsDate(null));
      const point = feedLine.points.find((point) => point.id === highlightedNewsEvent);
      if (point) {
        const y = eventLines.length * LINE_HEIGHT + 6 + numActiveQueryResultLines * LINE_HEIGHT;
        setHighlightedComponent(<Point fill={theme.color['neutral-50']} cx={point.x} cy={y} r={8} strokeWidth={2} stroke={'white'} />);
      }
    } else {
      setHighlightedComponent(null);
    }
  }, [highlightedNewsEvent, feedLine, eventLines, highlightedNewsDate, numActiveQueryResultLines]);

  // TODO: custom events
  const onEventMouseOver = useCallback(
    (e, text: React.ReactNode) => {
      const el = e.target as SVGRectElement;
      const x = Number(el.getAttribute('x'));
      const y = Number(el.getAttribute('y'));
      const w = Number(el.getAttribute('width'));

      setHighlightedComponent(
        <Bar key={`hover-over-bar`} x={x} width={w} y={y - 2} height={16} fill={theme.color['neutral-10']} rx={'2px'} stroke={theme.color['neutral-50']} pointerEvents={'none'} />
      );
      setTooltip(text, e.clientX, y + 15, true);
    },
    [clipKey]
  );
  const onPointMouseOver = useCallback(
    (e) => {
      const el = e.target as SVGCircleElement;
      const cx = Number(el.getAttribute('cx'));
      // const cy = Number(el.getAttribute('cy')) + eventLines.length * LINE_HEIGHT;
      const cy = Number(el.getAttribute('cy')) + (eventLines.length + numActiveQueryResultLines) * LINE_HEIGHT;

      setHighlightedComponent(<Point fill={theme.color['neutral-50']} cx={cx} cy={cy} r={8} strokeWidth={2} stroke={'white'} pointerEvents="none" />);
    },
    [eventLines, numActiveQueryResultLines]
  );

  const onEnhancedPointMouseOver = useCallback(
    (e) => {
      const el = e.target as SVGCircleElement;
      const r = Number(el.getAttribute('r'));
      const cx = Number(el.getAttribute('cx'));
      const cy = Number(el.getAttribute('cy')) + eventLines.length * LINE_HEIGHT;

      setHighlightedComponent(<Point fill={theme.color['neutral-50']} cx={cx} cy={cy} r={r - 0.5} strokeWidth={1.5} stroke={'white'} pointerEvents="none" />);
    },
    [eventLines]
  );
  const onMouseLeave = useCallback(() => {
    hideTooltip();
    setHighlightedComponent(null);
  }, []);

  const onPointClick = useCallback(
    (date: string) => {
      if (highlightedNewsDate?.date === date) {
        dispatch(grayzoneActions.setHighlightedNewsDate(null));
      } else {
        dispatch(
          grayzoneActions.setHighlightedNewsDate({
            date,
            x: xScale(Math.floor(new Date(date).getTime())),
            y: eventLines.length * LINE_HEIGHT + LINE_HEIGHT
          })
        );
      }
    },
    [highlightedNewsDate, xScale, eventLines]
  );

  const onEnhancedPointClick = useCallback(
    (eventQueryResultDataItem: GDELTKeywordQueryDataItem, x: number, y: number) => {
      if (eventQueryResultDataItem.id === eventQueryTooltipData?.dataItem.id) {
        dispatch(grayzoneActions.updateEventQueryTooltipData(null));
      } else {
        dispatch(
          grayzoneActions.updateEventQueryTooltipData({
            dataItem: eventQueryResultDataItem,
            x: x,
            y: y
          })
        );
      }
      setHighlightedComponent(null);
    },
    [eventQueryTooltipData]
  );

  const memoEventLines = useMemo(() => {
    return eventLines.map((eventLine, eventLineIndex) => {
      const y = eventLineIndex * LINE_HEIGHT - 2;
      return (
        <Group key={`${eventLine.id}-event-${eventLine.name}-${eventLineIndex}`}>
          <HtmlLabel
            horizontalAnchor="end"
            verticalAnchor="middle"
            showAnchorLine={false}
            containerStyle={{ display: 'block', width: `${left - LINE_HEIGHT}px`, pointerEvents: 'all' }}
            x={left}
            y={y + 8}
          >
            <TruncatedTypography variant="label-small-tight" onMouseMove={() => setTooltip(eventLine.name, left + 8, y - 12, true)} onMouseOut={hideTooltip}>
              {eventLine.name}
            </TruncatedTypography>
          </HtmlLabel>
          <Line y1={y + 8} y2={y + 8} x1={left + LINE_PADDING} x2={left + width} stroke={theme.color['neutral-25']} />
          {eventLine.events.map((event, eventIndex) => {
            let x = xScale(event.startDate);
            if (x < left) x = left;
            let w = xScale(event.endDate) - x;
            if (x + w > left + width) w = left + width - x;
            const y = eventLineIndex * LINE_HEIGHT;
            const height = 12;
            if (x > left + width) return null;

            return (
              <Bar
                key={`event-${eventLine.name}-bar-${eventIndex}`}
                x={x}
                width={w}
                y={y}
                height={height}
                fill={theme.color['neutral-10']}
                rx={'2px'}
                stroke={theme.color['neutral-50']}
                cursor="pointer"
                onMouseMove={(e) =>
                  onEventMouseOver(
                    e,
                    <Box>
                      <Typography>{event.name}</Typography>
                      <Typography>
                        {DATE_FORMATTER(event.startDate)} - {DATE_FORMATTER(event.endDate)}
                      </Typography>
                    </Box>
                  )
                }
                onClick={() => {
                  dispatch(grayzoneActions.updateEventToQuery({ ...event, eventSetId: eventLine.id }));
                }}
                onMouseOut={onMouseLeave}
              />
            );
          })}
        </Group>
      );
    });
  }, [eventLines, left, width, xScale, onEventMouseOver, onMouseLeave]);

  const memoFeedLines = useMemo(() => {
    if (!feedLine) return null;
    const seenX = new Set<number>();
    const y = 6;
    return (
      <Group key={`${feedLine.id}-feedline`}>
        <HtmlLabel
          horizontalAnchor="end"
          verticalAnchor="middle"
          showAnchorLine={false}
          containerStyle={{ display: 'block', width: `${left}px`, pointerEvents: 'all' }}
          x={left}
          y={y}
        >
          <TruncatedTypography
            variant="label-small-tight"
            onMouseMove={() => setTooltip(feedLine.name, left + 8, eventLines.length * LINE_HEIGHT - 14, true)}
            onMouseOut={hideTooltip}
          >
            {feedLine.name}
          </TruncatedTypography>
        </HtmlLabel>
        <Line y1={y} y2={y} x1={left + LINE_PADDING} x2={left + width} stroke={theme.color['neutral-25']} />
        {feedLine.points
          .filter((v, i, a) => a.findIndex((v2) => v2.x === v.x) === i) // https://stackoverflow.com/a/56757215/13738080
          .map((point, idx) => {
            seenX.add(point.x);
            return (
              <Point
                key={`${feedLine.id}-point${idx}`}
                fill={highlightedNewsDate?.date === point.date ? theme.color['brand-primary'] : theme.color['neutral-50']}
                cx={point.x}
                cy={y}
                r={highlightedNewsDate?.date === point.date ? 8 : 4}
                strokeWidth={1}
                stroke={'white'}
                onMouseOver={(e) => {
                  if (highlightedNewsDate?.date !== point.date) onPointMouseOver(e);
                }}
                onMouseLeave={onMouseLeave}
                onMouseDown={() => onPointClick(point.date)}
                cursor={'pointer'}
              />
            );
          })}
      </Group>
    );
  }, [feedLine, onPointMouseOver, onPointClick, left, width, xScale, eventLines, highlightedNewsDate]);

  const memoEnhancedFeedLines = useMemo(() => {
    return Object.entries(
      GDELTEventQueryResultSelectors.selectAll(eventQueryResults)
        .filter((result) => !result.hidden && enabledEventSets[result.eventSetId])
        .reduce((runningObj: GenericMap<GDELTEventQueryResult[]>, currResult) => {
          if (!runningObj[currResult.eventSetId]) {
            runningObj[currResult.eventSetId] = [currResult];
          } else {
            runningObj[currResult.eventSetId] = [...runningObj[currResult.eventSetId], currResult];
          }
          return runningObj;
        }, {})
    ).map(([eventSetId, queryResults], eventSetIndex) => {
      const eventSetActive = enabledEventSets[eventSetId];
      if (!eventSetActive || !eventSetData) return null;

      const eventSet = customEventSetSelectors.selectById(eventSetData, eventSetId);
      if (!eventSet) return null;

      const seenX = new Set<number>();
      // const y = 6;
      const y = eventSetIndex * LINE_HEIGHT + 6;
      return (
        <Group key={`event-query-feedline-${eventSetIndex}`}>
          <HtmlLabel
            horizontalAnchor="end"
            verticalAnchor="middle"
            showAnchorLine={false}
            containerStyle={{ display: 'block', width: `${left}px`, pointerEvents: 'all' }}
            x={left}
            y={y}
          >
            <TruncatedTypography
              variant="label-small-tight"
              onMouseMove={() => setTooltip('Event Queries', left + 8, eventLines.length * LINE_HEIGHT - 14, true)}
              onMouseOut={hideTooltip}
            >
              {`${eventSet.name} Queries`}
            </TruncatedTypography>
          </HtmlLabel>
          <Line y1={y} y2={y} x1={left + LINE_PADDING} x2={left + width} stroke={theme.color['neutral-25']} />
          {/* {queryResult.data
            .filter((v, i, a) => {
              const year = +v.date.substring(0, 4);
              const month = +v.date.substring(4, 6);
              const day = +v.date.substring(6, 8);
              const itemX = xScale(new Date(year, month - 1, day).getTime());
              return a.findIndex((v2) => v2.date === v.date) === i && itemX >= left && itemX <= left + width;
            }) // https://stackoverflow.com/a/56757215/13738080 */}
          {queryResults.map((result) => {
            const domain = extent(result.data.map((item) => item.value));
            if (domain[0] === undefined || domain[1] === undefined) return null;
            const rScale = scaleLinear({ domain: domain, range: [4, 10] });

            return result.data
              .filter((v) => {
                const itemX = xScale(Math.floor(new Date(v.date).getTime()));
                return itemX >= left && itemX <= left + width;
              })
              .map((item, idx) => {
                const x = xScale(Math.floor(new Date(item.date).getTime()));
                seenX.add(x);
                const r = rScale(item.value);
                return (
                  <Point
                    key={`event-query-feedline-${eventSetIndex}-point${idx}`}
                    fill={eventQueryTooltipData?.dataItem.id === item.id ? theme.color['brand-primary'] : theme.color['neutral-50']}
                    cx={x}
                    cy={y}
                    r={r}
                    strokeWidth={1}
                    stroke="white"
                    onMouseOver={(e) => {
                      if (eventQueryTooltipData?.dataItem.id !== item.id) onEnhancedPointMouseOver(e);
                    }}
                    onMouseLeave={onMouseLeave}
                    onMouseDown={() => {
                      onEnhancedPointClick(item, x, y + eventLines.length * LINE_HEIGHT + 6 + LINE_PADDING);
                    }}
                    cursor="pointer"
                  />
                );
              });
          })}
          ;
        </Group>
      );
    });
  }, [onPointMouseOver, left, width, xScale, eventQueryResults, highlightedNewsDate, enabledEventSets, eventSetData, eventLines, eventQueryTooltipData]);

  return (
    <>
      {memoEventLines}
      {/* {memoEnhancedFeedLines} */}
      {/* <Group top={eventLines.length * LINE_HEIGHT}>{memoEnhancedFeedLines}</Group> */}
      {numActiveQueryResultLines > 0 && <Group top={eventLines.length * LINE_HEIGHT}>{memoEnhancedFeedLines}</Group>}
      {!hideFeedData && <Group top={eventLines.length * LINE_HEIGHT + numActiveQueryResultLines * LINE_HEIGHT}>{memoFeedLines}</Group>}
    </>
  );
};

export default memo(SubTimelines);
