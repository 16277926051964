// ----- REACT -----
import { Box, InputAdornment, Menu, MenuItem, Typography } from '@mui/material';
import { ChangeEvent, MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTheme } from '@mui/material';
import { grayzoneActions, grayzoneSelectors } from '../../features/grayzone/grayzoneSlice';
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux';

import { CheckmarkOutlineIcon, ChevronLeftOutlineIcon, CloseCircleOutlineIcon, EllipsesHorizontalOutlineIcon } from '../../PremiseDesign/Icons';
import IconButton from '../../PremiseDesign/Components/IconButton';
import React from 'react';
import DebouncedTextfield from '../DebouncedTextfield';
import useAppSnackbar from '../../hooks/useAppSnackbar';

// ----- REDUX -----

// ----- API -----

// ----- MODULES -----

// ----- OURS -----

// ----- CONSTANTS -----

// ----- STYLES -----
import { DisappearingBox } from '../../styles';
// ----- TYPES -----

// ----- COMPONENT -----
type Props = {
  widgetId: string;
  style: React.CSSProperties;
  menuRef?: MutableRefObject<JSX.Element[]>;
  isActive: boolean;
};
const WidgetHeader = ({ widgetId, style, menuRef, isActive }: Props) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { enqueue } = useAppSnackbar();
  // ----- Redux -----

  // ----- Local State -----
  const widgetSelectors = useMemo(() => grayzoneSelectors.widgets.getSelectors(), []);
  const [editMode, setEditMode] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  // ----- Refs -----
  const flushRef = useRef<(() => string | undefined) | null>(null);

  // ----- Selectors -----
  const widget = useAppSelector((state) => widgetSelectors.selectById(state.grayzone.widgets, widgetId));

  if (!widget) {
    throw new Error('Widget with provided widgetId could not be found');
  }

  const [selectedName, setSelectedName] = useState<string>(widget.name);

  // ----- Queries -----

  // ----- Helpers -----

  // ----- Handlers -----
  const handleNameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSelectedName(e.target.value);
  }, []);

  const handleClickedSubmit = useCallback(() => {
    const res = flushRef.current?.();
    const name = res && res !== selectedName ? res : selectedName;
    if (name === widget.name) {
      setEditMode(false);
    } else if (name.length < 1) {
      enqueue('Widget names must be longer than 1 character', { variant: 'warning' });
    } else {
      dispatch(grayzoneActions.updateWidget({ id: widgetId, changes: { name: name } }));
      setEditMode(false);
    }
  }, [selectedName, widget.name, flushRef, widgetId]);

  // const handleClickedRemove = useCallback(() => {
  //   // dispatch(grayzoneActions.deleteWidget(widgetId));
  //   hideItem(widgetId);
  // }, [widgetId, hideItem]);

  const handleClickedDismiss = useCallback(() => {
    setEditMode(false);
    setSelectedName(widget.name);
  }, [widget.name]);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (anchorEl !== event.currentTarget) {
        setAnchorEl(event.currentTarget);
      }
    },
    [anchorEl]
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        handleClickedSubmit();
      } else if (e.key === 'Escape') {
        handleClickedDismiss();
      }
    },
    [handleClickedSubmit, handleClickedSubmit]
  );

  // ----- Effects -----
  useEffect(() => {
    setSelectedName(widget.name);
  }, [widget.name]);

  // ----- Return Component -----
  return (
    <Box display="flex" flexDirection="row" boxSizing="border-box" padding="16px 16px 0px 32px" width="100%" style={style} alignItems="center" gap={editMode ? '4px' : undefined}>
      {editMode && (
        <>
          <IconButton style={{ padding: '4px', borderRadius: theme.borderRadius.borderRadiusSemiSharp }} variant="mini" onClick={handleClickedDismiss}>
            <ChevronLeftOutlineIcon size={theme.iconScale.small} />
          </IconButton>
          <DebouncedTextfield
            flushRef={flushRef}
            onKeyDown={handleKeyDown}
            delay={600}
            onChangeDebounced={handleNameChange}
            value={selectedName}
            size="small"
            style={{ flex: '1' }}
            margin="none"
            multiline={false}
            variant="standard"
            placeholder="Enter a widget name"
            InputProps={{
              style: {
                backgroundColor: theme.color['neutral-10'],
                paddingLeft: theme.spacings['xxx-small']
              },
              endAdornment: (
                <InputAdornment position="end">
                  <Box style={{ cursor: 'pointer', paddingRight: theme.spacings['xxx-small'], display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CloseCircleOutlineIcon
                      color={theme.color['neutral-75']}
                      onClick={() => {
                        setSelectedName('');
                      }}
                      size={theme.iconScale.small}
                    />
                  </Box>
                </InputAdornment>
              )
            }}
          />
          <IconButton style={{ padding: '4px' }} variant="mini" onClick={handleClickedSubmit}>
            <CheckmarkOutlineIcon size={theme.iconScale.small} />
          </IconButton>
        </>
      )}
      {!editMode && (
        <>
          <Box flex={1} paddingRight={theme.spacings.small} display={'inline-flex'} overflow={'hidden'}>
            <Typography variant="label-bold" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden" bgcolor={'white'}>
              {widget.name}
            </Typography>
          </Box>

          <DisappearingBox id="icon-button-widget-header" style={{ cursor: 'pointer' }} onClick={handleClick} $active={isActive}>
            <IconButton
              // id="icon-button-widget-header"
              variant="mini"
              onClick={handleClick}
              aria-controls={open ? 'widget-header-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              style={{ padding: '4px', borderRadius: theme.borderRadius.borderRadiusSemiSharp }}
            >
              <EllipsesHorizontalOutlineIcon size={theme.iconScale['x-small']} color={theme.color['neutral-75']} />
            </IconButton>
          </DisappearingBox>

          <Menu
            style={{ zIndex: 4000, borderRadius: theme.borderRadius.borderRadiusSemiSharp, padding: 0 }}
            id="widget-header-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'icon-button-widget-header',
              disablePadding: true
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <MenuItem
              onClick={() => {
                handleClose();
                setEditMode(true);
              }}
              style={{ justifyContent: 'left', alignItems: 'center' }}
            >
              <Typography variant="label">Rename</Typography>
            </MenuItem>
            {/* <MenuItem onClick={handleClickedRemove}>
              <Typography variant="label">Remove</Typography>
            </MenuItem> */}
            {menuRef?.current}
          </Menu>
        </>
      )}
    </Box>
  );
};

export default WidgetHeader;
