// ----- REACT -----
import { memo } from 'react';
// ----- REDUX -----

// ----- API -----

// ----- MODULES -----
import { Box, styled } from '@mui/material';

// ----- OURS -----
import ImageContainer from './ImageContainer';

// ----- CONSTANTS -----

// ----- STYLES -----
const GridPhotoContainer = styled(Box)<{ $selected: boolean; $order: number }>(({ theme, $selected, $order }) => ({
  borderRadius: theme.borderRadius.borderRadiusSemiSharp,
  width: `93px`,
  height: `93px`,
  border: $selected ? `${theme.color['brand-primary-50']} solid 2px` : undefined,
  boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.24), 0px 0px 12px rgba(79, 79, 79, 0.08)',
  filter: $selected ? 'drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.16)) drop-shadow(0px 20px 36px rgba(243, 176, 168, 0.24))' : undefined,
  boxSizing: 'border-box',
  overflow: 'hidden',
  cursor: !$selected ? 'pointer' : undefined,
  order: $order
}));

const PhotoGrid = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: '1 1 0%',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
  width: '100%',
  maxHeight: '50%',
  minHeight: '0px',
  transition: 'all 0.4s ease'
}));

// ----- TYPES -----
import { POISearchResult } from '../../../types/grayzone';
import { useAppSelector } from '../../../hooks/useRedux';
import { grayzoneSelectors } from '../../../features/grayzone/grayzoneSlice';

// ----- COMPONENT -----
type Props = {
  POIs: POISearchResult[];
  selectedIndex: number;
  updateSelectedIndex: (index: number) => void;
};
const POIGrid = ({ POIs, selectedIndex, updateSelectedIndex }: Props) => {
  // ----- Redux -----

  // ----- Local State -----

  // ----- Selectors -----
  const idle = useAppSelector(grayzoneSelectors.selectIdleState);

  // ----- Queries -----

  // ----- Helpers -----

  // ----- Handlers -----

  // ----- Effects -----

  // ----- Return Component -----

  return (
    <PhotoGrid>
      {POIs.map((info, index) => (
        <GridPhotoContainer
          $order={idle ? (index - selectedIndex >= 0 ? index - selectedIndex : POIs.length + (index - selectedIndex)) : index}
          // $order={index}
          $selected={index === selectedIndex}
          key={`photo-${index}`}
          onClick={() => {
            if (index !== selectedIndex) {
              updateSelectedIndex(index);
            }
          }}
        >
          <ImageContainer
            style={
              {
                // borderRadius: '4px',
                width: `100%`,
                height: `100%`,
                opacity: index === selectedIndex ? 1 : 0.2,
                transition: 'all 0.4s ease',
                '&:hover': {
                  opacity: 1,
                  boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.24), 0px 0px 12px rgba(79, 79, 79, 0.08)',
                  filter: 'drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.16)) drop-shadow(0px 20px 36px rgba(243, 176, 168, 0.24))'
                }
              } as React.CSSProperties
            }
            source={info.photo}
          />
        </GridPhotoContainer>
      ))}
    </PhotoGrid>
  );
};

export default memo(POIGrid);
