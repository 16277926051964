import { EntityState } from '@reduxjs/toolkit';
import { shareAdapter } from '../../helpers/grayzone';
import { Share, ShareCreationDto } from '../../types/grayzone';
import { grayzoneApi } from './grayzone';

export type PostShareApiResponse = {
  shareId: string;
};

export type PostShareApiArg = {
  body: ShareCreationDto;
};

export type GetShareApiResponse = EntityState<Share>;

export type GetShareApiArg = {
  id?: string;
  viewId?: string;
  permissions?: number;
  maxResults?: number;
};

const injectedRtkApi = grayzoneApi.injectEndpoints({
  endpoints: (build) => ({
    postShare: build.mutation<PostShareApiResponse, PostShareApiArg>({
      query: (queryArg) => ({ url: `/share`, method: 'POST', body: queryArg.body }),
      invalidatesTags: (result) => {
        return result ? [{ type: 'Share', id: result.shareId }] : [];
      }
    }),
    getShare: build.query<GetShareApiResponse, GetShareApiArg>({
      query: (queryArg) => ({ url: `/share`, params: queryArg }),
      transformResponse: (shares: Share[]) => shareAdapter.addMany(shareAdapter.getInitialState(), shares),
      providesTags: (result) => {
        return result ? [...result.ids.map((shareId) => ({ type: 'Share' as const, id: shareId })), 'Share'] : ['Share'];
      }
    })
  }),
  overrideExisting: false
});

export const shareEndpoints = injectedRtkApi.endpoints;
