import { grayzoneSelectors } from '../features/grayzone/grayzoneSlice';
import useGrid from './useGrid';
import { useAppSelector } from './useRedux';

const useSelectWidgetConfigurations = () => {
  const { gridLayout } = useGrid();

  const filters = useAppSelector((state) => grayzoneSelectors.widgetConfigurations.selectWidgetConfigurationsByGridLayoutId(state, gridLayout.id));
  if (!filters) {
    throw new Error('No widget configurations found! Widget configurations must exist for all views');
  }

  return filters;
};

export default useSelectWidgetConfigurations;
