/* eslint-disable max-len */
// BASEUI-GENERATED-REACT-ICON
// DO NOT EDIT THIS FILE DIRECTLY, SEE README.md
import * as React from 'react';
import type { IconProps } from '../Icon';
import Icon from '../Icon';

const ProfileOutlineIcon = (props: IconProps): JSX.Element => {
  const {
    title = 'Profile Outline Icon',
    size,
    color,
    onClick,
    ...restProps
  } = props;
  return (
    <Icon
      title={title}
      size={size}
      color={color}
      onClick={onClick}
      {...restProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 7C17 9.76142 14.7614 12 12 12C9.23858 12 7 9.76142 7 7C7 4.23858 9.23858 2 12 2C14.7614 2 17 4.23858 17 7ZM15 7C15 8.65685 13.6569 10 12 10C10.3431 10 9 8.65685 9 7C9 5.34315 10.3431 4 12 4C13.6569 4 15 5.34315 15 7Z"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 13H10C7.79086 13 6 14.7909 6 17V19C6 20.1046 6.89543 21 8 21H16C17.1046 21 18 20.1046 18 19V17C18 14.7909 16.2091 13 14 13ZM8 17C8 15.8954 8.89543 15 10 15H14C15.1046 15 16 15.8954 16 17V19H8V17Z"
      ></path>
    </Icon>
  );
};

export default ProfileOutlineIcon;
/* eslint-enable max-len */
