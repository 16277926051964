import { ACLEDdataType, FeedData, FeedDataType, GDELTdataType } from '../../types/grayzone';
import { grayzoneApi } from './grayzone';
import { v4 as uuid_v4 } from 'uuid';

export type GetRevereApiArg = {
  form_id: string | symbol;
  question_name: string | symbol;
  hasc_code: string | symbol;
  date_alert?: string | symbol;
  min_date_alert?: string | symbol;
  max_date_alert?: string | symbol;
};

const injectedRtkApi = grayzoneApi.injectEndpoints({
  endpoints: (build) => ({
    getRevere: build.query<FeedData, GetRevereApiArg>({
      transformResponse: (data: { gdelt: Omit<GDELTdataType, 'type'>[]; acled: Omit<ACLEDdataType, 'type'>[] }) => {
        // responses from api-grayzone don't come with type, set here for use in grayzone
        return {
          gdelt: data.gdelt
            .filter((v, i, a) => a.findIndex((v2) => v2.title === v.title) === i)
            .map((g) => ({
              ...g,
              id: uuid_v4(),
              type: FeedDataType.GDELT
            })) as GDELTdataType[],
          acled: data.acled
            .filter((v, i, a) => a.findIndex((v2) => v2.event_description === v.event_description) === i)
            .map((a) => ({
              ...a,
              id: uuid_v4(),
              type: FeedDataType.ACLED
            })) as ACLEDdataType[]
        };
      },
      query: (queryArg) => ({
        url: '/revere',
        method: 'GET',
        params: queryArg
      })
    })
  }),
  overrideExisting: false
});

export const revereNewsEndpoints = injectedRtkApi.endpoints;
