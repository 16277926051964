import { memo, useCallback, useEffect, useMemo, useState } from 'react';

// ----- Redux -----
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';
import { grayzoneActions, grayzoneSelectors } from '../grayzoneSlice';
import { eventEndpoints } from '../../../api/grayzone/event';
import { customEventSetAdapter } from '../../../helpers/grayzone';

// ----- MUI -----
import { Autocomplete, Box, Button, Dialog, styled, TextField, Typography, useTheme } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// ----- PDS -----
import IconButton from '../../../PremiseDesign/Components/IconButton';
import { AddOutlineIcon } from '../../../PremiseDesign/Icons';

// ----- Ours -----
import useAppSnackbar from '../../../hooks/useAppSnackbar';
import { v4 as uuid_v4 } from 'uuid';
import { Spinner } from '../../../styles';

// ----- Types -----
import { CustomEventSet } from '../../../types/grayzone';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  borderRadius: theme.borderRadius.borderRadius
}));
const StyledInput = styled(TextField)(({ theme }) => ({
  marginTop: theme.spacings['x-small'],
  marginBottom: theme.spacings['x-small']
}));
const ModalContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacings.small,
  width: '500px'
}));
const AddEventModal = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { enqueue } = useAppSnackbar();

  // ----- Selectors -----
  const addEventModalOpen = useAppSelector(grayzoneSelectors.widgets.selectAddEventModalOpen);
  const customEventSetSelectors = useMemo(() => {
    return customEventSetAdapter.getSelectors();
  }, []);
  const primaryViewConfiguration = useAppSelector(grayzoneSelectors.viewConfigurations.selectPrimaryViewConfiguration);
  const primaryViewId = useAppSelector(grayzoneSelectors.viewConfigurations.selectPrimaryViewId);
  if (!primaryViewConfiguration || !primaryViewId) {
    throw new Error('No current view found! A view must be configured.');
  }
  const { data: eventSetData, isFetching: eventSetsIsFetching, isError: eventSetsIsError } = eventEndpoints.getEventSets.useQuery({});

  // ----- State -----
  const [eventName, setEventName] = useState('');
  const [eventCategoryDropdown, setEventCategoryDropdown] = useState<CustomEventSet | null>(null);
  const [newEventCategory, setNewEventCategory] = useState('');
  const [description, setDescription] = useState('');
  const [dateOne, setDateOne] = useState<Date | null>(null);
  const [dateTwo, setDateTwo] = useState<Date | null>(null);
  const [addCategoryMode, setAddCategoryMode] = useState(false);

  // ----- Queries -----
  const [triggerPatchAddEventToEventSet, patchResult] = eventEndpoints.addEventToEventSet.useMutation();
  const [triggerPostEventSetMutation, postResult] = eventEndpoints.postEventSet.useMutation();

  useEffect(() => {
    if (eventSetsIsError) enqueue('Unable to fetch event sets');
  }, [eventSetsIsError]);

  const onClose = useCallback(() => {
    dispatch(grayzoneActions.updateAddEventModalStateTo(false));
  }, []);

  const onSubmit = () => {
    // these optionals should never be null
    const newEvent = {
      id: uuid_v4(),
      name: eventName,
      country: 'TODO: //delete this?',
      startDate: dateOne?.valueOf() ?? 0,
      endDate: dateTwo?.valueOf() ?? dateOne?.valueOf() ?? 0
    };

    if (addCategoryMode) {
      // create a new category
      triggerPostEventSetMutation({
        body: {
          eventSetState: {
            name: newEventCategory,
            events: [newEvent]
          },
          viewId: primaryViewId
        }
      });
    } else {
      // add event to lists of events
      triggerPatchAddEventToEventSet({
        eventSetId: eventCategoryDropdown?.id ?? '',
        event: newEvent
      });
    }
  };

  useEffect(() => {
    if (patchResult.isError || postResult.isError) {
      return enqueue('Error uploading event', { variant: 'error' });
    }

    if (patchResult.isSuccess || postResult.isSuccess) {
      enqueue('Event successfully added', { variant: 'success' });
      onClose();
    }
  }, [patchResult, postResult]);

  const canSubmit = useMemo(() => {
    if (eventName === '') return false;
    if (addCategoryMode) {
      if (newEventCategory === '') return false;
    } else {
      if (eventCategoryDropdown === null) return false;
    }
    if (dateOne === null) return false;

    return true;
  }, [eventName, addCategoryMode, newEventCategory, eventCategoryDropdown, dateOne]);

  return (
    <StyledDialog open={addEventModalOpen} onClose={onClose}>
      <ModalContainer>
        <Typography variant="h1">New Event</Typography>

        <StyledInput fullWidth label="Event Name" placeholder="Enter name" value={eventName} variant="standard" onChange={(e) => setEventName(e.target.value)} />

        <Box marginBottom={theme.spacings.small} marginTop={theme.spacings.small} display="flex" flexDirection="row">
          <Autocomplete
            disabled={addCategoryMode || eventSetsIsFetching}
            options={(eventSetData && customEventSetSelectors.selectAll(eventSetData)) ?? []}
            getOptionLabel={(opt) => opt.name}
            loading={eventSetsIsFetching}
            clearOnEscape
            renderInput={(params) => {
              return <TextField {...params} placeholder="Select an event set" variant={'standard'} label="Event Set" />;
            }}
            multiple={false}
            value={eventCategoryDropdown}
            onChange={(e, value) => setEventCategoryDropdown(value)}
            style={{ flex: 1 }}
          />
          <IconButton variant="mini" selected={addCategoryMode} onClick={() => setAddCategoryMode((s) => !s)}>
            <AddOutlineIcon />
          </IconButton>
        </Box>

        {addCategoryMode && (
          <StyledInput
            fullWidth
            label="New Event Set Name"
            placeholder="Enter new event set name"
            variant="standard"
            value={newEventCategory}
            onChange={(e) => setNewEventCategory(e.target.value)}
          />
        )}

        <StyledInput
          fullWidth
          label="Event description (optional)"
          placeholder="Enter event description"
          variant="standard"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Box display="flex" flexDirection="row" justifyContent={'space-between'}>
            <DesktopDatePicker
              label="Start Date"
              inputFormat="MM/dd/yyyy"
              value={dateOne}
              onChange={(date) => {
                setDateOne(date);
                if (date === null) setDateTwo(null);
              }}
              renderInput={(params) => <TextField {...params} variant="standard" color="primary" />}
            />
            <DesktopDatePicker
              label="End Date"
              inputFormat="MM/dd/yyyy"
              minDate={dateOne}
              value={dateTwo}
              onChange={(date) => setDateTwo(date)}
              renderInput={(params) => <TextField {...params} variant="standard" color="primary" />}
            />
          </Box>
        </LocalizationProvider>

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          paddingTop={theme.spacings.small}
          paddingBottom={theme.spacings.small}
          alignItems="center"
          gap={theme.spacings['x-small']}
        >
          <Button onClick={onClose}>Cancel</Button>
          {(patchResult.isLoading || postResult.isLoading) && (
            <Box width={'64px'} display="flex" justifyContent="center" alignItems={'center'}>
              <Spinner size={'12px'} color="primary" />
            </Box>
          )}
          {!patchResult.isLoading && !postResult.isLoading && (
            <Button variant="contained" onClick={onSubmit} disabled={!canSubmit}>
              Add
            </Button>
          )}
        </Box>
      </ModalContainer>
    </StyledDialog>
  );
};

export default memo(AddEventModal);
