/* eslint-disable max-len */
// BASEUI-GENERATED-REACT-ICON
// DO NOT EDIT THIS FILE DIRECTLY, SEE README.md
import * as React from 'react';
import type { IconProps } from '../Icon';
import Icon from '../Icon';

const CaretUpOutlineIcon = (props: IconProps): JSX.Element => {
  const {
    title = 'Caret Up Outline Icon',
    size,
    color,
    onClick,
    ...restProps
  } = props;
  return (
    <Icon
      title={title}
      size={size}
      color={color}
      onClick={onClick}
      {...restProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.7071 16.7071C21.0976 16.3166 21.0976 15.6834 20.7071 15.2929L12.7071 7.29289C12.3166 6.90237 11.6834 6.90237 11.2929 7.29289L3.29289 15.2929C2.90237 15.6834 2.90237 16.3166 3.29289 16.7071C3.68342 17.0976 4.31658 17.0976 4.70711 16.7071L12 9.41421L19.2929 16.7071C19.6834 17.0976 20.3166 17.0976 20.7071 16.7071Z"
      ></path>
    </Icon>
  );
};

export default CaretUpOutlineIcon;
/* eslint-enable max-len */
