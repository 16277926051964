import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { AuthState } from '../../features/auth/authSlice';

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_API_HOST}/grayzone`,
  prepareHeaders: async (headers, { getState }) => {
    const state = (await getState()) as { auth: AuthState };
    const token = state.auth.token;

    // If we have a token set in state, let's assume that we should be passing it.
    if (token) {
      if (!headers.get('X-Auth-Token')) {
        headers.set('X-Auth-Token', token);
      }
      headers.set('Content-Type', 'application/json');
    }
    return headers;
  }
});

export const grayzoneApi = createApi({
  tagTypes: ['View', 'Share', 'ViewAccess', 'EventSet', 'EventSetBinding'],
  reducerPath: 'grayzoneApi',
  baseQuery: baseQuery,
  endpoints: () => ({})
});
