// ----- REACT -----
import { ChangeEvent, memo, useState } from 'react';

// ----- REDUX -----
import { Update } from '@reduxjs/toolkit';

// ----- MODULES -----
import { Box } from '@mui/material';

// ----- OURS -----
import DebouncedTextfield from '../../../components/DebouncedTextfield';

// ----- TYPES -----
import { FeedWidget } from '../../../types/grayzone';

// ----- COMPONENT -----
type Props = {
  publishChanges: (updates: Update<FeedWidget>) => void;
  widget: FeedWidget;
};
const FeedConfig = ({ publishChanges, widget }: Props) => {
  // ----- Local State -----
  const [updates, setUpdates] = useState<Update<FeedWidget>>({ id: widget.id, changes: {} });

  // ----- Helpers -----
  const generateUpdates = (newChanges: Partial<FeedWidget>): Update<FeedWidget> => {
    const newUpdates = { ...updates, changes: { ...updates.changes, ...newChanges } };
    setUpdates(newUpdates);
    return newUpdates;
  };

  // ----- Handlers -----
  const handleNameChanged = (e: ChangeEvent<HTMLInputElement>) => {
    publishChanges(generateUpdates({ name: e.target.value }));
  };

  // ----- Return Component -----
  return (
    <Box display="flex" flexDirection="column">
      <DebouncedTextfield id="input-name" onChange={handleNameChanged} defaultValue={widget.name} delay={700} label="Name" margin="normal" size="small" />
    </Box>
  );
};

export default memo(FeedConfig);
