import { ReactNode, useCallback, useState, memo, forwardRef, useMemo, MutableRefObject, useRef } from 'react';

// ----- MUI -----
import { Box, Card, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

// ----- Redux -----
import { useAppSelector } from '../../hooks/useRedux';
import { grayzoneSelectors } from '../../features/grayzone/grayzoneSlice';
import { DragFillIcon, ResizeOutlineIcon } from '../../PremiseDesign/Icons';
import WidgetHeader from './WidgetHeader';

const WidgetCard = styled(Card)<{ $highlighted: boolean; $idle: boolean }>(({ theme, $highlighted, $idle }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: theme.borderRadius.borderRadiusSemiSharp,
  outline: $highlighted ? `${theme.palette.primary.main} solid 2px` : undefined,
  pointerEvents: $idle ? 'none' : 'auto', // disables default on hover box shadow
  '&:hover': {
    boxShadow: theme['box-shadow']['card-hover']
  }
}));
const CornerDragContainer = styled(Box)<{ $active: boolean }>(({ theme, $active }) => ({
  height: '14px',
  width: '14px',
  position: 'absolute',
  right: '2px',
  bottom: '2px',
  padding: theme.spacings['xxxx-small'],
  cursor: 'nwse-resize',
  opacity: $active ? 1 : 0,
  transition: 'ease-in opacity 0.2s',
  zIndex: 1100
}));

const MoveDragContainer = styled(Box)<{ $active: boolean }>(({ $active }) => ({
  width: '28px',
  height: '28px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  cursor: 'grab',
  opacity: $active ? 1 : 0,
  transition: 'ease-in opacity 0.2s',
  zIndex: 1100,
  '&:active': {
    cursor: 'grabbing'
  }
}));

const CornerDrag = memo(({ isActive }: { isActive: boolean }) => {
  const theme = useTheme();
  return (
    <CornerDragContainer $active={isActive}>
      <ResizeOutlineIcon size={theme.iconScale['x-small']} color={theme.iconColor['neutral-75']} />
    </CornerDragContainer>
  );
});
CornerDrag.displayName = 'CornerDrag';

const MoveDrag = memo(({ isActive }: { isActive: boolean }) => {
  const theme = useTheme();

  return (
    <Box position={'absolute'} height={'100%'} width={'28px'} display={'flex'} alignItems={'center'}>
      {/* div needs the class name here for rgl to recognize its a drag handle */}
      <MoveDragContainer $active={isActive} className="grid-item__title">
        <DragFillIcon size={theme.iconScale.small} color={theme.iconColor['neutral-75']} />
      </MoveDragContainer>
    </Box>
  );
});
MoveDrag.displayName = 'MoveDrag';

type Props = {
  renderChildren?: (menuRef: MutableRefObject<JSX.Element[]>) => ReactNode;
  children?: ReactNode;
  style?: React.CSSProperties;
  className?: string;
  widgetId: string;
};

const WidgetContainer = forwardRef<HTMLDivElement, Props>(({ widgetId, children, renderChildren, style, className, ...rest }: Props, ref) => {
  // ----- Selectors -----
  const highlightedWidget = useAppSelector(grayzoneSelectors.widgets.selectHighlightedWidget);
  const idle = useAppSelector(grayzoneSelectors.selectIdleState);

  // ----- Local State -----
  const [isActive, setIsActive] = useState(false);
  const setIsActiveTrue = useCallback(() => {
    // do not set active to true if we are idle
    setIsActive(!idle);
  }, [idle]);
  const setIsActiveFalse = useCallback(() => setIsActive(false), []);

  // ----- Refs -----
  const menuRef = useRef<JSX.Element[]>([]);

  // ----- Helpers -----
  const headerStyle: React.CSSProperties = useMemo(
    () => ({
      position: 'absolute',
      left: 0,
      top: 0,
      zIndex: 4000
    }),
    []
  );

  const content: ReactNode = useMemo(() => {
    return renderChildren?.(menuRef);
  }, [renderChildren, menuRef]);

  return (
    <Box
      height={'100%'}
      width={'100%'}
      className={`react-grid-item ${className ?? ''}`}
      onMouseOver={setIsActiveTrue}
      onMouseLeave={setIsActiveFalse}
      position="relative"
      style={style}
      {...rest}
      ref={ref}
    >
      {/* ↑ need an outer div tof handle eventhandlers for card ↑ */}
      <WidgetCard variant="elevation" $highlighted={widgetId === highlightedWidget} $idle={idle}>
        {widgetId !== '__dropping-item__' && <WidgetHeader widgetId={widgetId} style={headerStyle} menuRef={menuRef} isActive={isActive} />}
        <Box width={'100%'} height={'100%'}>
          {children}
          {content}
        </Box>
        <MoveDrag isActive={isActive} />
        <CornerDrag isActive={isActive} />
      </WidgetCard>
    </Box>
  );
});

WidgetContainer.displayName = 'WidgetContainer';

export default memo(WidgetContainer);
