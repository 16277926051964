import { createContext, ReactNode, useCallback, useMemo, useState } from 'react';

// ----- VISX -----
import { useTooltip } from '@visx/tooltip';

type TooltipData = string | React.ReactNode;
export type TimelineTooltipContextInterface = {
  tooltipOpen: boolean;
  tooltipLeft: number;
  tooltipTop: number;
  tooltipData: TooltipData | undefined;
  setAndShowTooltip: (data: string | React.ReactNode, left: number, top: number) => void;
  hideTooltip: () => void;
  highlightedComponent: ReactNode | null;
  setHighlightedComponent: React.Dispatch<React.SetStateAction<ReactNode>>;
  setOffset: React.Dispatch<React.SetStateAction<number>>;
};
export const TimelineTooltipContext = createContext<TimelineTooltipContextInterface>({} as TimelineTooltipContextInterface);

const TimelineTooltipProvider = ({ children }: { children: ReactNode }) => {
  const {
    showTooltip,
    hideTooltip,
    tooltipOpen,
    tooltipData,
    tooltipLeft = 0,
    tooltipTop = 0
  } = useTooltip<TooltipData>({
    // initial tooltip state
    tooltipOpen: false
  });

  // tooltip enabler, use this to draw on top of our chart to enable z index
  const [highlightedComponent, setHighlightedComponent] = useState<ReactNode>(null);
  const [offset, setOffset] = useState(0);

  const setAndShowTooltip = useCallback(
    (data: string | React.ReactNode, left: number, top: number, offsetOff?: boolean) => {
      showTooltip({
        tooltipLeft: left,
        tooltipTop: top + (offsetOff ? 0 : offset),
        tooltipData: data
      });
    },
    [showTooltip, offset]
  );

  const values = useMemo((): TimelineTooltipContextInterface => {
    return {
      tooltipOpen,
      tooltipLeft,
      tooltipTop,
      tooltipData,
      setAndShowTooltip,
      hideTooltip,
      highlightedComponent,
      setHighlightedComponent,
      setOffset
    };
  }, [tooltipOpen, tooltipLeft, tooltipTop, tooltipData, setAndShowTooltip, hideTooltip, highlightedComponent, setHighlightedComponent]);

  return <TimelineTooltipContext.Provider value={values}>{children} </TimelineTooltipContext.Provider>;
};

export default TimelineTooltipProvider;
