import { ACLEDdataType, ACLEDEventEnum, FeedDataType, FeedWidgetConfigurations, GDELTdataType, GDELTTopArticle } from '../../../types/grayzone';

// TODO: create test
export const filterItems = (items: (GDELTdataType | ACLEDdataType | GDELTTopArticle)[], filters: FeedWidgetConfigurations, dateFilter: (number | null)[] | undefined) => {
  const sourcesFilter = filters.sourcesFilter;
  if (sourcesFilter !== undefined) {
    if (sourcesFilter.ACLED === false) {
      items = items.filter((item) => item.type !== FeedDataType.ACLED);
    }
    if (sourcesFilter.GDELT === false) {
      items = items.filter((item) => item.type !== FeedDataType.GDELT);
    }

    // TODO: Filter out event query responses? Nah, maybe just toggle with the 'shown events' toggle
  }

  if (dateFilter) {
    if (dateFilter[0] !== null) {
      const dateToFilter = dateFilter[0] as number;
      items = items.filter((item) => {
        switch (item.type) {
          case FeedDataType.ACLED:
            return new Date(item.date_event).getTime() >= dateToFilter;
          case FeedDataType.GDELT:
            return new Date(item.date_news).getTime() >= dateToFilter;
          case FeedDataType.EventQueryArticle:
            return new Date(item.date).getTime() >= dateToFilter;
          default:
            throw new Error('Invalid FeedDataType');
            break;
        }
      });
    }
    if (dateFilter[1] !== null) {
      const dateToFilter = dateFilter[1] as number;
      items = items.filter((item) => {
        switch (item.type) {
          case FeedDataType.ACLED: {
            return new Date(item.date_event).getTime() <= dateToFilter;
          }
          case FeedDataType.GDELT: {
            return new Date(item.date_news).getTime() <= dateToFilter;
          }
          case FeedDataType.EventQueryArticle: {
            return new Date(item.date).getTime() <= dateToFilter;
          }
          default: {
            throw new Error('Invalid FeedDataType');
          }
        }
      });
    }
  }

  const sort = filters.sort;
  if (sort !== undefined) {
    switch (sort) {
      case 'alphaAscending': {
        items.sort((a, b) => {
          let aString;
          let bString;

          switch (a.type) {
            case FeedDataType.ACLED: {
              aString = a.event_description;
              break;
            }
            case FeedDataType.GDELT: {
              aString = a.title;
              break;
            }
            case FeedDataType.EventQueryArticle: {
              aString = a.title;
              break;
            }
            default: {
              throw new Error('Invalid FeedDataType');
            }
          }

          switch (b.type) {
            case FeedDataType.ACLED: {
              bString = b.event_description;
              break;
            }
            case FeedDataType.GDELT: {
              bString = b.title;
              break;
            }
            case FeedDataType.EventQueryArticle: {
              bString = b.title;
              break;
            }
            default: {
              throw new Error('Invalid FeedDataType');
            }
          }

          return aString.localeCompare(bString) * -1;
        });
        break;
      }
      case 'alphaDescending': {
        items.sort((a, b) => {
          let aString;
          let bString;

          switch (a.type) {
            case FeedDataType.ACLED: {
              aString = a.event_description;
              break;
            }
            case FeedDataType.GDELT: {
              aString = a.title;
              break;
            }
            case FeedDataType.EventQueryArticle: {
              aString = a.title;
              break;
            }
            default: {
              throw new Error('Invalid FeedDataType');
            }
          }

          switch (b.type) {
            case FeedDataType.ACLED: {
              bString = b.event_description;
              break;
            }
            case FeedDataType.GDELT: {
              bString = b.title;
              break;
            }
            case FeedDataType.EventQueryArticle: {
              bString = b.title;
              break;
            }
            default: {
              throw new Error('Invalid FeedDataType');
            }
          }

          return aString.localeCompare(bString);
        });
        break;
      }
      case 'dateAscending': {
        items.sort((a, b) => {
          let aDate;
          let bDate;

          switch (a.type) {
            case FeedDataType.ACLED: {
              aDate = a.date_event;
              break;
            }
            case FeedDataType.GDELT: {
              aDate = a.date_news;
              break;
            }
            case FeedDataType.EventQueryArticle: {
              aDate = a.date;
              break;
            }
            default: {
              throw new Error('Invalid FeedDataType');
            }
          }

          switch (b.type) {
            case FeedDataType.ACLED: {
              bDate = b.date_event;
              break;
            }
            case FeedDataType.GDELT: {
              bDate = b.date_news;
              break;
            }
            case FeedDataType.EventQueryArticle: {
              bDate = b.date;
              break;
            }
            default: {
              throw new Error('Invalid FeedDataType');
            }
          }

          return Date.parse(bDate) - Date.parse(aDate);
        });
        break;
      }
      case 'dateDescending': {
        items.sort((a, b) => {
          let aDate;
          let bDate;

          switch (a.type) {
            case FeedDataType.ACLED: {
              aDate = a.date_event;
              break;
            }
            case FeedDataType.GDELT: {
              aDate = a.date_news;
              break;
            }
            case FeedDataType.EventQueryArticle: {
              aDate = a.date;
              break;
            }
            default: {
              throw new Error('Invalid FeedDataType');
            }
          }

          switch (b.type) {
            case FeedDataType.ACLED: {
              bDate = b.date_event;
              break;
            }
            case FeedDataType.GDELT: {
              bDate = b.date_news;
              break;
            }
            case FeedDataType.EventQueryArticle: {
              bDate = b.date;
              break;
            }
            default: {
              throw new Error('Invalid FeedDataType');
            }
          }

          return Date.parse(aDate) - Date.parse(bDate);
        });
        break;
      }
    }
  }

  const relevance = filters.relevanceFilter;
  if (relevance !== undefined) {
    if (relevance !== null) {
      items = items.filter((a) => {
        if (a.type === FeedDataType.GDELT) {
          return a.relevancy_score >= relevance[0] && a.relevancy_score <= relevance[1];
        }
        return true;
      });
    }
  }

  const excludeACLEDEvents = filters.excludeACLEDEvents;
  if (excludeACLEDEvents !== undefined) {
    const set = new Set<ACLEDEventEnum>();
    for (const event in excludeACLEDEvents) {
      if (excludeACLEDEvents[event as ACLEDEventEnum] === false) {
        set.add(event as ACLEDEventEnum);
      }
    }
    items = items.filter((a) => {
      if (a.type === FeedDataType.ACLED) {
        return set.has(a.event_type);
      }
      return true;
    });
  }

  const GDELTSourceFilters = filters.GDELTSourceFilters;
  if (GDELTSourceFilters !== undefined && GDELTSourceFilters.length !== 0) {
    const set = new Set<string>(GDELTSourceFilters);
    items = items.filter((a) => {
      if (a.type === FeedDataType.GDELT) {
        return set.has(a.domain_text);
      }
      return true;
    });
  }

  return items;
};

export const SENTIMENT_CATEGORICAL_COLORS = ['#B63225', '#B82C80', '#7030EC', '#042E8B', '#0F7468'];
export const SENTIMENT_CATEGORICAL_HOVER_COLORS = ['#FADFDE', '#F8EAF2', '#E2D9FB', '#D0D7E6', '#DBEAE8'];
export const SENTIMENT_CATEGORICAL_HOVER_COLORS_MAPPED = {
  [SENTIMENT_CATEGORICAL_COLORS[0]]: SENTIMENT_CATEGORICAL_HOVER_COLORS[0],
  [SENTIMENT_CATEGORICAL_COLORS[1]]: SENTIMENT_CATEGORICAL_HOVER_COLORS[1],
  [SENTIMENT_CATEGORICAL_COLORS[2]]: SENTIMENT_CATEGORICAL_HOVER_COLORS[2],
  [SENTIMENT_CATEGORICAL_COLORS[3]]: SENTIMENT_CATEGORICAL_HOVER_COLORS[3],
  [SENTIMENT_CATEGORICAL_COLORS[4]]: SENTIMENT_CATEGORICAL_HOVER_COLORS[4]
};
