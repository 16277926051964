/* eslint-disable max-len */
// BASEUI-GENERATED-REACT-ICON
// DO NOT EDIT THIS FILE DIRECTLY, SEE README.md
import * as React from 'react';
import type { IconProps } from '../Icon';
import Icon from '../Icon';

const ChevronLeftOutlineIcon = (props: IconProps): JSX.Element => {
  const {
    title = 'Chevron Left Outline Icon',
    size,
    color,
    onClick,
    ...restProps
  } = props;
  return (
    <Icon
      title={title}
      size={size}
      color={color}
      onClick={onClick}
      {...restProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7071 3.29289C15.3166 2.90237 14.6834 2.90237 14.2929 3.29289L6.29289 11.2929C5.90237 11.6834 5.90237 12.3166 6.29289 12.7071L14.2929 20.7071C14.6834 21.0976 15.3166 21.0976 15.7071 20.7071C16.0976 20.3166 16.0976 19.6834 15.7071 19.2929L8.41421 12L15.7071 4.70711C16.0976 4.31658 16.0976 3.68342 15.7071 3.29289Z"
      ></path>
    </Icon>
  );
};

export default ChevronLeftOutlineIcon;
/* eslint-enable max-len */
