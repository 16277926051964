import { memo } from 'react';

// ----- Ours -----
import { DATE_FORMATTER, GET_NEXT_DATE_INTERVAL } from '../constants';
import { AxisLine, TickLabel, TickLine, TICK_LENGTH } from '../styles';

// ----- Types -----
import { TimeseriesPoint } from '../../../../../types/grayzone';
import type { ScaleLinear } from 'd3-scale';

const TICK_LABEL_TOP_PADDING = 4;

type Props = {
  left: number;
  height: number;
  width: number;
  numTicks: number;
  data: TimeseriesPoint[];
  xScale: ScaleLinear<number, number>;
  barSpacing: number;
};
const DateXAxis = ({ left, height, width, numTicks, data, xScale, barSpacing }: Props) => {
  // no memo, relies on all props changing to memoize anyway, no perf gain
  const generateTicks = () => {
    const ticks = [];
    const firstDate = data[0].date;

    for (let i = 0; i < numTicks; i++) {
      const date = GET_NEXT_DATE_INTERVAL(firstDate, barSpacing * i);
      const x = xScale(date);

      ticks.push(
        <>
          <TickLine x1={x - 0.5} x2={x - 0.5} y1={height} y2={height + TICK_LENGTH} />
          <TickLabel textAnchor="middle" x={x} y={height + TICK_LENGTH + TICK_LABEL_TOP_PADDING} verticalAnchor="start">
            {DATE_FORMATTER(date, true)}
          </TickLabel>
        </>
      );
    }

    return ticks;
  };

  return (
    <>
      {/* add 2 to the left and right to account for y axis stroke width and add 1 to y for y axis stroke width */}
      <AxisLine x1={left - 2} x2={left + width + 2} y1={height + 1} y2={height + 1} />
      {generateTicks()}
    </>
  );
};
export default memo(DateXAxis);
