import { memo, useEffect, useRef } from 'react';

// ----- VISX -----
import { HtmlLabel } from '@visx/annotation';

// ----- Ours -----
import { AxisLine, TickLabel, TickLine, TruncatedTypography } from '../styles';

const MINIMUM_SPACING = 36;

type Props = {
  left: number;
  height: number;
  questionOrdering?: { [x: string]: string };
  setTooltip: (data: string, left: number, top: number) => void;
  hideTooltip: () => void;
  isOffset: boolean;
  setOffset: React.Dispatch<React.SetStateAction<boolean>>;
};
const SentimentYAxis = ({ left, height, questionOrdering, setTooltip, hideTooltip, isOffset, setOffset }: Props) => {
  const ref = useRef<HTMLSpanElement>(null);

  /* we add a ref to the top 100% tick, if it is more than 1 line (14px) we set offset to true which will
    re-render the chart but shift the it down and make the chart height smaller by 8px, this is so that 
    the labels don't interfere with the 'Sentiment' label above it */
  useEffect(() => {
    if (!ref.current) return;

    const height = ref.current.getBoundingClientRect().height;
    if (height === 14) {
      if (isOffset) setOffset(false);
    } else {
      if (!isOffset) setOffset(true);
    }
  }, [ref.current]);

  const generateTicks = () => {
    const ticks = [];

    if (!questionOrdering) {
      // just generate a default 0% -> 50% -> 100%
      for (let i = 0; i < 5; i++) {
        const y = height - (height / 4) * i;

        if (i % 2 === 0) {
          const text = `${(i / 4) * 100}%`;
          ticks.push(
            <>
              <TickLine x1={left - 6} x2={left} y1={y + 0.5} y2={y + 0.5} />
              <TickLabel textAnchor="end" verticalAnchor="middle" x={left - 8} y={y}>
                {text}
              </TickLabel>
            </>
          );
        } else {
          ticks.push(<TickLine x1={left - 4} x2={left} y1={y} y2={y} />);
        }
      }
    } else {
      let ticksWithY = [];
      for (const [label, value] of Object.entries(questionOrdering)) {
        if (value === null) continue; // sometimes there are null values? error with data assumed so just skip

        // Step 1: add all the possible ticks with labels
        const valueParsed = Number(value);
        const y = height - height * valueParsed;
        const text = `${Math.round(valueParsed * 100)}%`;
        ticksWithY.push({
          y,
          tick: (
            <>
              {/* unsure why I have to add 0.5 to make it flush with the X axis*/}
              <TickLine x1={left - 6} x2={left} y1={y + 0.5} y2={y + 0.5} />
              <TickLabel textAnchor="end" verticalAnchor="middle" x={left - 8} y={y}>
                {text}
              </TickLabel>

              {/* Use HTML label here so we can use line truncation, svg text is not good for this.
            container style must be overriden to be block to fix centering bug for 1 line labels */}
              <HtmlLabel
                horizontalAnchor="end"
                verticalAnchor="middle"
                x={left - 6 - 28 - 8} // forgot where i got this number from lol
                y={y}
                showAnchorLine={false}
                containerStyle={{ display: 'block', width: '72px', pointerEvents: 'all' }}
              >
                <TruncatedTypography variant="label-small-tight" onMouseMove={(e) => setTooltip(label, e.clientX, y)} onMouseLeave={hideTooltip} ref={y === 0 ? ref : null}>
                  {label}
                </TruncatedTypography>
              </HtmlLabel>
            </>
          )
        });
      }

      // Step 2: omit some labels

      /* -- Rules for spacing label omitting: -- 
        - if spacing between two ticks is below 36px we have to omit labels
        - odd number of ticks: Ez, just remove the offending tick and since they are evenly spaced, 
          they will be evenly ommitted
        - even number of ticks: Hard, we CAN NOT evenly omit ticks. Solution (by design): if we 
          offend the spacing, remove all ticks between last and first and stick a 50% tick in the middle
      */

      // sort first
      ticksWithY.sort((a, b) => b.y - a.y);

      // if we only have two ticks, stick a 50% label in there and ship
      if (ticksWithY.length === 2) {
        const midY = (ticksWithY[0].y + ticksWithY[1].y) / 2;
        return ticksWithY
          .map((tick) => tick.tick)
          .concat([
            <>
              <TickLine x1={left - 6} x2={left} y1={midY + 0.5} y2={midY + 0.5} />
              <TickLabel textAnchor="end" verticalAnchor="middle" x={left - 8} y={midY}>
                50%
              </TickLabel>
            </>
          ]);
      }

      if (ticksWithY.length % 2 === 0) {
        // even
        const spacing = Math.abs(ticksWithY[0].y - ticksWithY[1].y);
        if (spacing < MINIMUM_SPACING) {
          // return only first last and 50% tick
          const midY = (ticksWithY[0].y + ticksWithY[ticksWithY.length - 1].y) / 2;
          return [ticksWithY[0].tick, ticksWithY[ticksWithY.length - 1].tick].concat([
            <>
              <TickLine x1={left - 6} x2={left} y1={midY + 0.5} y2={midY + 0.5} />
              <TickLabel textAnchor="end" verticalAnchor="middle" x={left - 8} y={midY}>
                50%
              </TickLabel>
            </>
          ]);
        } else {
          return ticksWithY.map((tick) => tick.tick);
        }
      } else {
        // odd

        // reduce as much as we can until we hit an even label count (unreducable)
        while (Math.abs(ticksWithY[1].y - ticksWithY[0].y) < MINIMUM_SPACING) {
          const tickCount = ticksWithY.length;
          const center = Math.floor(tickCount / 2);
          const ticksBetween = center - 1;

          // can't reduce, just go [start, middle, end] like even
          if (ticksBetween % 2 === 0 || tickCount === 3) {
            return [ticksWithY[0].tick, ticksWithY[center].tick, ticksWithY[ticksWithY.length - 1].tick];
          }

          // we have an odd number of ticks between, filter out every other element
          ticksWithY = ticksWithY.filter((_, idx) => idx % 2 === 0);
        }
        return ticksWithY.map((tick) => tick.tick);
      }
    }

    return ticks;
  };

  return (
    <>
      {/* subtract 1 to account for stroke width */}
      <AxisLine x1={left - 1} x2={left - 1} y1={0} y2={height} />
      {generateTicks()}
    </>
  );
};

export default memo(SentimentYAxis);
