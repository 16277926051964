import { useState, memo, useCallback, useRef, useEffect } from 'react';
import styled from 'styled-components';
import Theme from '../premiseTheme';
import { CheckboxCheckedOutlineIcon, CheckboxDashOutlineIcon } from '../Icons';

const CheckboxContainer = styled('label')<{ $disabled: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: row;
  cursor: ${(props) => (props.$disabled ? 'not-allowed' : 'pointer')};
  opacity: ${(props) => (props.$disabled ? 0.2 : 1)};
`;
const InputContainer = styled('div')`
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  margin-right: 9px;
  position: relative;
`;
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;
const StyledCheckbox = styled(CheckboxCheckedOutlineIcon)<{ $checked: boolean }>`
  position: absolute;
  border-radius: 3px;
  opacity: ${(props) => (props.$checked ? 1 : 0)};
  transition: all 100ms;
`;
const StyledDashbox = styled(CheckboxDashOutlineIcon)<{ $checked: boolean }>`
  position: absolute;
  border-radius: 3px;
  opacity: ${(props) => (props.$checked ? 1 : 0)};
  transition: all 100ms;
`;
const CheckboxBox = styled('div')<{ $checked: boolean; $isHover: boolean }>`
  height: 14px;
  width: 14px;
  position: absolute;
  border: 2px solid ${(props) => (props.$checked ? (props.$isHover ? Theme.color['brand-primary-150'] : Theme.color['brand-primary']) : Theme.color['neutral-50'])};
  background-color: ${(props) => (props.$isHover ? Theme.color['neutral-25'] : 'none')};
  border-radius: 3px;
  transition: all 100ms;
`;

type Props = {
  checked?: boolean;
  disabled?: boolean;
  label?: string;
  variant?: 'default' | 'dash';
  onChange?: (val?: boolean) => void;
};
const Checkbox = ({ checked, disabled = false, label, variant = 'default', onChange }: Props) => {
  // TODO: mini size variant
  // TODO: focus effect (pain in the ass)
  // TODO: RTL and LTR management
  const [isChecked, setIsChecked] = useState(checked === undefined ? false : checked);
  const [isHover, setIsHover] = useState(false);
  const ref = useRef(null);

  const handleChange = () => {
    if (checked === undefined) {
      // no managed external state, just toggle
      setIsChecked((c) => {
        onChange?.(!c);
        return !c;
      });
    } else {
      onChange?.();
    }

    // callback
    // onChange?.();
  };

  useEffect(() => {
    if (checked !== undefined) setIsChecked(checked);
  }, [checked]);

  const mouseOver = useCallback(() => {
    if (!disabled) setIsHover(true);
  }, []);
  const mouseOut = useCallback(() => {
    if (!disabled) setIsHover(false);
  }, []);

  const font = isChecked ? Theme.typography['label-small-bold-tight'] : Theme.typography['label-small-tight'];
  const focused = document.activeElement === ref.current;

  /* Hide the original checkbox input, put our custom checkbox on top */
  return (
    <CheckboxContainer $disabled={disabled} onMouseOver={mouseOver} onMouseLeave={mouseOut} ref={ref}>
      <InputContainer>
        <HiddenCheckbox checked={isChecked} onChange={handleChange} disabled={disabled} />
        <CheckboxBox $checked={isChecked} $isHover={isHover} />
        {variant === 'default' && <StyledCheckbox $checked={isChecked} color={isHover ? Theme.color['brand-primary-125'] : Theme.color['brand-primary']} size={22 / 24} />}
        {variant === 'dash' && <StyledDashbox $checked={isChecked} color={isHover ? Theme.color['brand-primary-125'] : Theme.color['brand-primary']} size={22 / 24} />}
      </InputContainer>

      <span style={{ ...font, color: Theme.color['neutral-100'], userSelect: 'none' }}>{label}</span>
    </CheckboxContainer>
  );
};

export default memo(Checkbox);
