/* eslint-disable max-len */
// BASEUI-GENERATED-REACT-ICON
// DO NOT EDIT THIS FILE DIRECTLY, SEE README.md
import * as React from 'react';
import type { IconProps } from '../Icon';
import Icon from '../Icon';

const TrendNeutralOutlineIcon = (props: IconProps): JSX.Element => {
  const {
    title = 'Trend Neutral Outline Icon',
    size,
    color,
    onClick,
    ...restProps
  } = props;
  return (
    <Icon
      title={title}
      size={size}
      color={color}
      onClick={onClick}
      {...restProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.31113 12.0745C2.31113 11.5528 2.75283 11.1299 3.2977 11.1299H17.4254C17.9702 11.1299 18.4119 11.5528 18.4119 12.0745C18.4119 12.5962 17.9702 13.0192 17.4254 13.0192H3.2977C2.75283 13.0192 2.31113 12.5962 2.31113 12.0745Z"
      ></path>
      <path d="M21.8636 11.7854C22.0454 11.8859 22.0454 12.1372 21.8636 12.2377L16.9791 14.9379C16.7973 15.0384 16.5701 14.9128 16.5701 14.7118V9.31131C16.5701 9.1103 16.7973 8.98467 16.9791 9.08518L21.8636 11.7854Z"></path>
      <path d="M5.94901 12.0081C5.94901 13.0985 5.06499 13.9826 3.9745 13.9826C2.88402 13.9826 2 13.0985 2 12.0081C2 10.9176 2.88402 10.0336 3.9745 10.0336C5.06499 10.0336 5.94901 10.9176 5.94901 12.0081Z"></path>
    </Icon>
  );
};

export default TrendNeutralOutlineIcon;
/* eslint-enable max-len */
