import React, { useCallback, useEffect, useMemo, useState } from 'react';

// ----- MUI -----
import { useTheme } from '@mui/material';
import { RectClipPath } from '@visx/clip-path';

// ----- VISX -----
import { scaleLinear } from '@visx/scale';
import { Line, LinePath, Bar } from '@visx/shape';
import { curveCatmullRom } from '@visx/curve';
import { Group } from '@visx/group';
import { Threshold } from '@visx/threshold';

// ----- Ours -----
import { LEFT_OFFSET, RIGHT_OFFSET } from '../constants';
import SubmissionsBarChart from './SubmissionsBarChart';
import SentimentYAxis from './SentimentYAxis';
import useTimelineTooltip from '../../../../../hooks/useTimelineTooltip';
import Alerts from './Alerts';
import SubTimelines from './SubTimelines';

// ----- Modules -----
import { v4 as uuid_v4 } from 'uuid';

// ----- Types -----
import { TimeseriesPoint, Alert, ACLEDdataType, GDELTdataType, GenericMap } from '../../../../../types/grayzone';

// ----- Styles -----
import { CHART_TOP_OFFSET, TickLabelBold } from '../styles';
import { grayzoneSelectors } from '../../../../../features/grayzone/grayzoneSlice';
import { useAppSelector } from '../../../../../hooks/useRedux';

type Props = {
  parentWidth: number;
  parentHeight: number;
  data: TimeseriesPoint[] | GenericMap<TimeseriesPoint[]>;
  categoricalColorScheme?: GenericMap<string>; // if we have this, then we are categorical
  hoverCategorical: string | null;
  activeCategorical: string | null;
  alertData?: Alert[];
  feedData?: (GDELTdataType | ACLEDdataType)[];
  questionOrdering?: { [x: string]: string };
  enabledEventSets: GenericMap<boolean>;
};

const MainTimeline = ({
  parentHeight,
  parentWidth,
  data,
  categoricalColorScheme,
  hoverCategorical,
  activeCategorical,
  alertData,
  feedData,
  questionOrdering,
  enabledEventSets
}: Props) => {
  // values of our time scrubber if any
  const [scrubX, setScrubX] = useState<number | null>(null);
  // if our top label in sentiment Y axis is a two liner, need to lower the height of total graph slightly
  const [twoLineLabelOffset, setTwoLineLabelOffset] = useState(false);
  // we can have multiple sub timelines - need to calculate height
  const [subTimelineHeight, setSubTimelineHeight] = useState(0);

  const theme = useTheme();

  // ----- Selectors -----
  const hideAverageSentiment = useAppSelector(grayzoneSelectors.timeline.selectHideAverageSentimentState);
  const hideSentimentAlerts = useAppSelector(grayzoneSelectors.timeline.selectHideSentimentAlertsState);
  const hideSubmissions = useAppSelector(grayzoneSelectors.timeline.selectHideSubmissionsState);

  // ----- Tooltip -----
  const { highlightedComponent, setAndShowTooltip, hideTooltip, setHighlightedComponent, setOffset } = useTimelineTooltip();

  // ----- Constants -----
  const xStart = LEFT_OFFSET;
  // 50 being the space left for tick labels on x axis
  const height = parentHeight - 50 - (twoLineLabelOffset ? 8 : 0) - subTimelineHeight;
  const width = parentWidth - xStart - RIGHT_OFFSET;
  const chartOffset = CHART_TOP_OFFSET + (twoLineLabelOffset ? 8 : 0);
  // There needs to be a separate unique clip key for individual timeline widgets or else they will use the same clip and will be bugged
  const clipKey = useMemo(() => `clip-${uuid_v4()}`, []);

  // ----- Scales & Helpers -----
  const firstAndLastDate = useMemo(() => {
    if (Array.isArray(data)) {
      if (data.length === 0) return [0, 0];
      return [data[0].date, data[data.length - 1].date];
    } else {
      const firstData = data[Object.keys(data)[0]];
      if (!firstData) return [0, 0];
      return [firstData[0].date, firstData[firstData.length - 1].date];
    }
  }, [data]);
  const yScale = useMemo(
    () =>
      scaleLinear({
        domain: [0, 1],
        range: [height, 0]
      }),
    [height]
  );
  const xScale = useMemo(
    () =>
      scaleLinear({
        domain: firstAndLastDate,
        range: [xStart, width + xStart]
      }),
    [data, width]
  );

  const processedAlerts = useMemo(() => {
    if (Array.isArray(data)) {
      // non-categorical
      return alertData;
    } else {
      if (activeCategorical) {
        return alertData?.filter((point) => point.response === activeCategorical) ?? [];
      } else return undefined;
    }
  }, [data, alertData, activeCategorical]);

  // for alerts
  const getYonCurve = useCallback(
    (date: number, rawValue = false) => {
      if (Array.isArray(data)) {
        const timelinePoint = data.find((point) => point.date === date);
        if (!timelinePoint) return;

        if (rawValue) return timelinePoint.smoothed_mean;
        return yScale(timelinePoint.smoothed_mean);
      } else if (activeCategorical) {
        const timelinePoint = data[activeCategorical].find((point) => point.date === date);
        if (!timelinePoint) return 0;

        if (rawValue) return timelinePoint.smoothed_mean;
        return yScale(timelinePoint.smoothed_mean);
      } else return 0;
    },
    [data, yScale, activeCategorical]
  );
  const setScrubPosition = useCallback(
    (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
      const x = e.clientX;
      const y = e.clientY;
      const rect = (e.currentTarget as Element).getBoundingClientRect();

      const xWithOffset = x - rect.x;
      const yWithOffset = y - rect.y;
      // check if within bounds of chart
      if (xWithOffset >= xStart && xWithOffset <= width + xStart && yWithOffset <= chartOffset + subTimelineHeight + height) {
        setScrubX(x - rect.x);
        return;
      }
      // out of bounds, no scrub
      setScrubX(null);
    },
    [parentHeight, parentWidth]
  );
  const removeScrubPosition = useCallback(() => setScrubX(null), []);

  const setHoverComponent = useCallback(
    (component: React.ReactNode, offset = false) => {
      if (offset) {
        setHighlightedComponent(<Group top={subTimelineHeight + chartOffset}>{component}</Group>);
      } else {
        setHighlightedComponent(component);
      }
    },
    [height, subTimelineHeight, chartOffset]
  );

  useEffect(() => {
    setOffset(subTimelineHeight + (twoLineLabelOffset ? 8 : 0));
  }, [subTimelineHeight, twoLineLabelOffset]);

  // ----- Memoized components -----
  const memoThreshold = useMemo(() => {
    if (Array.isArray(data)) {
      return (
        <Threshold
          id={`threshold-${clipKey}`} // Thresholds uses this for clip path, needs to be unique
          data={data}
          x={(d) => xScale(d.date)}
          y0={(d) => yScale(d.upper_bound)}
          y1={(d) => yScale(d.lower_bound)}
          clipAboveTo={0}
          clipBelowTo={height}
          belowAreaProps={{
            fill: theme.color['neutral-25'],
            fillOpacity: 0.4
          }}
          aboveAreaProps={{
            fill: theme.color['neutral-25'],
            fillOpacity: 0.4
          }}
        />
      );
    } else if (activeCategorical) {
      return (
        <Threshold
          id={`threshold-${clipKey}`} // Thresholds uses this for clip path, needs to be unique
          data={data[activeCategorical] ?? []}
          x={(d) => xScale(d.date)}
          y0={(d) => yScale(d.upper_bound)}
          y1={(d) => yScale(d.lower_bound)}
          clipAboveTo={0}
          clipBelowTo={height}
          belowAreaProps={{
            fill: categoricalColorScheme?.[activeCategorical] ?? theme.color['neutral-25'],
            fillOpacity: 0.4
          }}
          aboveAreaProps={{
            fill: categoricalColorScheme?.[activeCategorical] ?? theme.color['neutral-25'],
            fillOpacity: 0.4
          }}
        />
      );
    } else return null;
  }, [data, activeCategorical, categoricalColorScheme, xScale, yScale]);
  const Curve = useMemo(() => {
    return (
      <Group style={{ pointerEvents: 'none' }} clipPath={`url(#${clipKey})`}>
        {/*  */}
        {Array.isArray(data) ? (
          <>
            {memoThreshold}
            <LinePath
              data={data}
              x={(d) => xScale(d.date)}
              y={(d) => yScale(d.smoothed_mean)}
              stroke={theme.color['neutral-75']}
              strokeWidth={3}
              curve={curveCatmullRom}
              opacity={1}
            />
          </>
        ) : (
          <>
            {Object.keys(data).map((key) => {
              return (
                <>
                  {activeCategorical === key && memoThreshold}
                  <LinePath
                    key={`categorical-line-${key}`}
                    curve={curveCatmullRom}
                    data={data[key]}
                    x={(d) => xScale(d.date)}
                    y={(d) => yScale(d.smoothed_mean)}
                    stroke={categoricalColorScheme?.[key] ?? theme.color['neutral-75']}
                    strokeWidth={3}
                  />
                </>
              );
            })}
          </>
        )}
      </Group>
    );
  }, [data, xScale, yScale, height, activeCategorical]);
  const Titles = useMemo(() => {
    return (
      <Group>
        <TickLabelBold x={xStart} y={0} verticalAnchor="start" textAnchor="end">
          Sentiment
        </TickLabelBold>
        <TickLabelBold x={xStart + width} y={0} verticalAnchor="start" textAnchor="start">
          Submissions
        </TickLabelBold>
      </Group>
    );
  }, [width]);
  const Clips = useMemo(() => {
    return (
      <>
        <RectClipPath id={clipKey} height={height} width={width} x={xStart} y={0} />
      </>
    );
  }, [height, width, xStart]);

  // ----- Effect -----
  useEffect(() => {
    if (hoverCategorical) {
      setHoverComponent(
        <>
          {hoverCategorical === activeCategorical && memoThreshold}
          <LinePath
            key={`hover-categorical-${hoverCategorical}`}
            curve={curveCatmullRom}
            data={(data as GenericMap<TimeseriesPoint[]>)[hoverCategorical]}
            x={(d) => xScale(d.date)}
            y={(d) => yScale(d.smoothed_mean)}
            stroke={categoricalColorScheme?.[hoverCategorical] ?? theme.color['neutral-75']}
            strokeWidth={3}
          />
        </>,
        true
      );
    } else {
      setHoverComponent(null);
    }
  }, [hoverCategorical]);

  return (
    <div style={{ height: parentHeight, width: parentWidth, position: 'relative' }}>
      <svg height={parentHeight} width={parentWidth} onMouseMove={setScrubPosition} onMouseLeave={removeScrubPosition}>
        <Group top={5}>
          {feedData && (
            <SubTimelines
              data={feedData}
              left={xStart}
              width={width}
              setHeight={setSubTimelineHeight}
              xScale={xScale}
              hideTooltip={hideTooltip}
              setHighlightedComponent={setHoverComponent}
              setTooltip={setAndShowTooltip}
              clipKey={clipKey}
              enabledEventSets={enabledEventSets}
            />
          )}
          <Group top={subTimelineHeight}>
            {Titles}
            {/* we need this padding so svg doesn't cut off exactly at top of chart */}
            <Group top={chartOffset}>
              {Clips}
              {/* 50% line */}
              <Line x1={xStart} x2={xStart + width} y1={height / 2 + 0.5} y2={height / 2 + 0.5} stroke={theme.color['neutral-25']} />
              {/* submissions + submission y axis */}
              <SubmissionsBarChart
                data={data}
                xScale={xScale}
                left={xStart}
                height={height}
                width={width}
                clipKey={clipKey}
                setTooltip={setAndShowTooltip}
                hideTooltip={hideTooltip}
                setHighlightedComponent={setHoverComponent}
                hideData={hideSubmissions}
              />
              {/* curves */}
              {!hideAverageSentiment && Curve}
              {/* alerts */}
              {!hideSentimentAlerts && processedAlerts && (
                <Alerts
                  data={processedAlerts}
                  xScale={xScale}
                  getYonCurve={getYonCurve}
                  setTooltip={setAndShowTooltip}
                  hideTooltip={hideTooltip}
                  setHighlightedComponent={setHoverComponent}
                  height={height}
                  clipKey={clipKey}
                />
              )}
              {/* curve axis */}
              <SentimentYAxis
                left={xStart}
                height={height}
                questionOrdering={questionOrdering}
                setTooltip={setAndShowTooltip}
                hideTooltip={hideTooltip}
                isOffset={twoLineLabelOffset}
                setOffset={setTwoLineLabelOffset}
              />
              {/* opacity overlay (used over opacity params for optimzation ) */}
              <Bar
                x={xStart}
                y={0}
                style={{ opacity: highlightedComponent !== null ? 0.5 : 0, transition: 'linear 0.3s' }}
                fill={'white'}
                height={height}
                width={width}
                pointerEvents="none"
                clipPath={`url(#${clipKey})`}
              />
            </Group>
          </Group>
          {scrubX && (
            <Line
              from={{ x: scrubX, y: 0 }}
              to={{ x: scrubX, y: chartOffset + subTimelineHeight + height }}
              stroke={'black'}
              strokeWidth={1}
              pointerEvents="none"
              strokeDasharray={'5,3'}
            />
          )}
          <Group>{highlightedComponent}</Group>
        </Group>
      </svg>
    </div>
  );
};

export default MainTimeline;
