import { Action, AnyAction, Dispatch, isRejectedWithValue, Middleware } from '@reduxjs/toolkit';

export const rtkQueryErrorLogger: Middleware = () => (next: Dispatch<AnyAction>) => (action: Action<AnyAction>) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
  if (isRejectedWithValue(action)) {
    console.warn('ASYNC ERROR!: ', action);
  }

  return next(action);
};
