// ----- MODULES -----
import { createEntityAdapter } from '@reduxjs/toolkit';
import { assign, omit } from 'lodash';
import { v4 as uuid_v4 } from 'uuid';
import { GrayzoneState } from '../features/grayzone/grayzoneSlice';
import { RootState } from '../store';

import {
  Country,
  Form,
  FormQuestion,
  GridLayout,
  MapWidget,
  FeedWidget,
  PhotoWidget,
  TimelineWidget,
  // PlaceholderWidget,
  ViewConfiguration,
  Widget,
  WidgetType,
  ViewMap,
  ViewExport,
  GridLayoutDTO,
  View,
  Layouts,
  Breakpoint,
  Breakpoints,
  Share,
  ACLEDEventEnum,
  ViewState,
  ViewAccess,
  CustomEventSet,
  CustomEventSetBinding,
  ACLEDdataType,
  GDELTdataType,
  FeedDataType,
  FeedWidgetConfigurations,
  WidgetConfigurations,
  GDELTEventQueryResult
} from '../types/grayzone';

// ----- EXPORT VIEW -----

export const generateViewState = (state: GrayzoneState, viewId: string): ViewState => {
  const view = state.viewMaps.entities[viewId];
  if (!view) {
    throw new Error('Could not find view with provided viewId. Cannot generate an exportable view.');
  }
  const viewConfiguration = state.viewConfigurations.entities[view?.configId];
  if (!viewConfiguration) {
    throw new Error('Could not find view configuraiton with provided configId. Cannot generate an exportable view.');
  } else if (!viewConfiguration.gridLayoutId) {
    throw new Error('The provided view does not have a matching grid layout. Cannot generate and exportable view.');
  }

  const gridLayout = state.gridLayouts.entities[viewConfiguration.gridLayoutId];
  if (!gridLayout) {
    throw new Error("No grid matched the provided view's layout id. Cannot generate an exportable view");
  }
  const widgets =
    gridLayout.widgetIds.reduce((arr: Widget[], widgetId: string) => {
      const widget = state.widgets.entities[widgetId];
      if (widget) {
        arr.push(widget);
      }
      return arr;
    }, []) ?? [];

  const widgetConfigurations = state.widgetConfigurations.entities[gridLayout.widgetConfigurationsId];
  if (!widgetConfigurations) {
    throw new Error("No widget configurations matched the provided view's grid widget configuration id. Cannot generate an exportable view");
  }

  const exportState: ViewState = {
    viewConfiguration: viewConfiguration,
    gridLayout: gridLayout,
    widgets: widgets,
    widgetConfigurations
  };

  return exportState;
};

export const generateViewStateExport = (state: GrayzoneState, viewId: string): ViewExport => {
  const view = state.viewMaps.entities[viewId];
  if (!view) {
    throw new Error('Could not find view with provided viewId. Cannot generate an exportable view.');
  }
  const viewConfiguration = state.viewConfigurations.entities[view?.configId];
  if (!viewConfiguration) {
    throw new Error('Could not find view config with provided view config id. Cannot generate an exportable view.');
  } else if (!viewConfiguration.gridLayoutId) {
    throw new Error('The provided view does not have a matching grid layout. Cannot generate and exportable view.');
  }

  const gridLayout = state.gridLayouts.entities[viewConfiguration.gridLayoutId];
  if (!gridLayout) {
    throw new Error("No grid matched the provided view's layout id. Cannot generate an exportable view");
  }
  const widgets =
    gridLayout.widgetIds.reduce((arr: Widget[], widgetId: string) => {
      const widget = state.widgets.entities[widgetId];
      if (widget) {
        arr.push(widget);
      }
      return arr;
    }, []) ?? [];

  const layouts: GridLayoutDTO['layouts'] = (Object.keys(gridLayout.layouts) as Array<Breakpoint>).reduce((obj: Partial<GridLayoutDTO['layouts']>, bp) => {
    obj[bp] = gridLayout.layouts[bp].map((item) => omit(item, 'i'));
    return obj;
  }, {}) as GridLayoutDTO['layouts'];

  const widgetConfigurations = state.widgetConfigurations.entities[gridLayout.widgetConfigurationsId];
  if (!widgetConfigurations) {
    throw new Error('Could not find widget configurations');
  }

  const exportState: ViewExport = {
    viewConfiguration: omit(viewConfiguration, ['id', 'gridLayoutId']),
    gridLayout: omit({ ...gridLayout, layouts }, ['id', 'widgetIds', 'viewConfigId']),
    widgets: widgets.map((widget) => {
      return omit(widget, ['id', 'gridId']);
    }),
    widgetConfigurations: omit(widgetConfigurations, ['id'])
  };

  return exportState;
};

export const generateViewStateImport = (state: ViewExport): View => {
  if (!state.gridLayout || !state.viewConfiguration || !state.widgets || !state.widgetConfigurations) {
    throw new Error('Provided file cannot be interpreted as a View');
  }
  const id = uuid_v4();
  const viewConfigurationId = uuid_v4();
  const gridLayoutId = uuid_v4();
  const widgetIds = state.widgets.map(() => uuid_v4());
  const widgetConfigurationsId = uuid_v4();

  const viewConfiguration: ViewConfiguration = assign(state.viewConfiguration, { id: viewConfigurationId, gridLayoutId: gridLayoutId });
  const populatedLayout = (Object.keys(state.gridLayout.layouts) as Array<Breakpoint>).reduce((obj: Partial<Layouts>, bp) => {
    obj[bp] = state.gridLayout.layouts[bp].map((item, index) => assign(item, { i: widgetIds[index] }));
    return obj;
  }, {}) as Layouts;

  const gridLayout: GridLayout = assign(state.gridLayout, {
    id: gridLayoutId,
    widgetIds: widgetIds,
    layouts: populatedLayout,
    viewConfigId: viewConfigurationId,
    widgetConfigurationsId
  });

  const widgets = state.widgets.map((widget, index) => assign(widget, { id: widgetIds[index], gridId: gridLayoutId })) as Widget[];
  const widgetConfigurations = { ...state.widgetConfigurations, id: widgetConfigurationsId };

  return {
    id,
    viewConfiguration,
    gridLayout,
    widgets,
    widgetConfigurations
  };
};

export const generateGridLayout = (layouts: Layouts, widgetIds: string[], viewConfigId: string, widgetConfigurationsId: string): GridLayout => ({
  id: uuid_v4(),
  layouts,
  widgetIds,
  viewConfigId,
  widgetConfigurationsId
});

const generateDefaultMapWidget = (id: string, gridId: string): MapWidget => {
  return {
    id,
    type: WidgetType.MAP,
    gridId,
    name: 'Map',
    zoomLevel: 8,
    choroplethLevel: 1,
    centerLatitude: 0,
    centerLongitude: 0
  };
};

const generateDefaultTimelineWidget = (id: string, gridId: string): TimelineWidget => {
  return {
    id,
    type: WidgetType.TIMELINE,
    gridId,
    name: 'Timeline',
    enabledCustomEvents: {}
  };
};

const generateDefaultFeedWidget = (id: string, gridId: string): FeedWidget => {
  return {
    id,
    type: WidgetType.FEED,
    gridId,
    name: 'News Feed'
  };
};

const generateDefaultPhotoWidget = (id: string, gridId: string): PhotoWidget => {
  return {
    id,
    type: WidgetType.PHOTO,
    gridId,
    name: 'Photo'
  };
};

// const generateDefaultPlaceholderWidget = (id: string, gridId: string): PlaceholderWidget => {
//   return {
//     id,
//     type: WidgetType.PLACEHOLDER,
//     gridId,
//     name: 'New Placeholder'
//   };
// };

// ----- GRID LAYOUT -----
export const getBreakPointFromWidth = (breakpoints: Breakpoints, width: number): Breakpoint => {
  const entries = Object.entries(breakpoints) as [Breakpoint, number][];
  if (entries.length === 0) {
    throw new Error('There must be at least one breakpoint in order for RGL to function.');
  }
  return entries.filter(([, br]) => width >= br).sort(([, valA], [, valB]) => valB - valA)?.[0]?.[0];
};

export const generateDefaultWidgets = (items: Pick<Widget, 'id' | 'type' | 'gridId'>[]): Widget[] => {
  return items.map((item: Pick<Widget, 'id' | 'type' | 'gridId'>) => {
    switch (item.type) {
      case WidgetType.MAP:
        return generateDefaultMapWidget(item.id, item.gridId);
        break;
      case WidgetType.TIMELINE:
        return generateDefaultTimelineWidget(item.id, item.gridId);
        break;
      case WidgetType.FEED:
        return generateDefaultFeedWidget(item.id, item.gridId);
        break;
      case WidgetType.PHOTO:
        return generateDefaultPhotoWidget(item.id, item.gridId);
        break;
      // case WidgetType.PLACEHOLDER:
      //   return generateDefaultPlaceholderWidget(item.id, item.gridId);
      //   break;
      default:
        throw new Error('Unknown widget type! Cannot generate default configuration!');
    }
  });
};

export const generateDefaultWidgetConfigurations = (id: string): WidgetConfigurations => {
  return {
    id,
    [WidgetType.FEED]: {
      sort: 'dateAscending',
      sourcesFilter: {
        GDELT: true,
        ACLED: true
      },
      GDELTSourceFilters: [],
      relevanceFilter: null,
      excludeACLEDEvents: {
        Battles: false,
        'Violence against civilians': false,
        'Explosion/Remote violence': false,
        Protests: false,
        Riots: false,
        'Strategic developments': false
      }
    },
    [WidgetType.TIMELINE]: {
      alertPercentChange: 0.01,
      alertSampleSize: 25,
      alertSignificance: 99
    }
  };
};

export const MIN_DIM_FEED = { w: 4, h: 3, minH: 3, minW: 4 };
export const MIN_DIM_MAP = { w: 4, h: 3, minH: 3, minW: 4 };
export const MIN_DIM_PHOTO = { w: 4, h: 3, minH: 3, minW: 4 };
export const MIN_DIM_TIMELINE = { w: 6, h: 3, minH: 3, minW: 6 };
export const getMinDim = (widgetType: WidgetType) => {
  switch (widgetType) {
    case WidgetType.FEED: {
      return MIN_DIM_FEED;
    }
    case WidgetType.MAP: {
      return MIN_DIM_MAP;
    }
    case WidgetType.PHOTO: {
      return MIN_DIM_PHOTO;
    }
    case WidgetType.TIMELINE: {
      return MIN_DIM_TIMELINE;
    }
  }
};

export const generateDefaultGrid = (viewConfigId: string): { gridLayout: GridLayout; widgets: Widget[]; widgetConfigurations: WidgetConfigurations } => {
  const ids = Array.from(Array(4)).map(() => uuid_v4());

  const layouts: Layouts = {
    lg: [
      { i: ids[0], x: 0, y: 0, ...MIN_DIM_FEED }, // FEED
      { i: ids[1], x: 4, y: 0, ...MIN_DIM_MAP }, // MAP
      { i: ids[2], x: 8, y: 0, ...MIN_DIM_PHOTO }, // PHOTO
      { i: ids[3], x: 0, y: 1, ...MIN_DIM_TIMELINE, w: 12 } // TIMELINE
    ],
    md: [
      { i: ids[0], x: 0, y: 0, ...MIN_DIM_FEED }, // FEED
      { i: ids[1], x: 4, y: 0, ...MIN_DIM_MAP }, // MAP
      { i: ids[2], x: 8, y: 0, ...MIN_DIM_PHOTO }, // PHOTO
      { i: ids[3], x: 4, y: 1, ...MIN_DIM_TIMELINE } // TIMELINE
    ],
    sm: [
      { i: ids[0], x: 0, y: 1, ...MIN_DIM_FEED }, // FEED
      { i: ids[1], x: 4, y: 0, ...MIN_DIM_MAP }, // MAP
      { i: ids[2], x: 8, y: 0, ...MIN_DIM_PHOTO }, // PHOTO
      { i: ids[3], x: 0, y: 2, ...MIN_DIM_TIMELINE } // TIMELINE
    ]
  };

  const widgetConfigurationsId = uuid_v4();
  const newGridLayout = generateGridLayout(layouts, ids, viewConfigId, widgetConfigurationsId);
  const partialWidgets: Pick<Widget, 'id' | 'type' | 'gridId'>[] = [
    { id: layouts[Breakpoint.LG][0].i, type: WidgetType.FEED, gridId: newGridLayout.id },
    { id: layouts[Breakpoint.LG][1].i, type: WidgetType.MAP, gridId: newGridLayout.id },
    { id: layouts[Breakpoint.LG][2].i, type: WidgetType.PHOTO, gridId: newGridLayout.id },
    { id: layouts[Breakpoint.LG][3].i, type: WidgetType.TIMELINE, gridId: newGridLayout.id }
  ];
  return {
    gridLayout: newGridLayout,
    widgets: generateDefaultWidgets(partialWidgets),
    widgetConfigurations: generateDefaultWidgetConfigurations(widgetConfigurationsId)
  };
};

// ----- ADAPTERS -----
const _shareAdapter = createEntityAdapter<Share>();
export const shareAdapter = {
  ..._shareAdapter,
  defaultSelectors: _shareAdapter.getSelectors()
};

const _viewAdapter = createEntityAdapter<View>();
export const viewAdapter = {
  ..._viewAdapter,
  defaultSelectors: _viewAdapter.getSelectors()
};

const _viewAccessAdapter = createEntityAdapter<ViewAccess>({
  selectId: (access) => access.viewId
});
export const viewAccessAdapter = {
  ..._viewAccessAdapter,
  defaultSelectors: _viewAccessAdapter.getSelectors()
};

const _viewMapAdapter = createEntityAdapter<ViewMap>({
  selectId: (viewMap) => viewMap.viewId
});
export const viewMapAdapter = {
  ..._viewMapAdapter,
  defaultSelectors: _viewMapAdapter.getSelectors(),
  rootSelectors: _viewMapAdapter.getSelectors<RootState>((state) => state.grayzone.viewMaps)
};
const _viewConfigurationAdapter = createEntityAdapter<ViewConfiguration>({
  sortComparer: (a: ViewConfiguration, b: ViewConfiguration) => a.name.localeCompare(b.name)
});

export const viewConfigurationAdapter = {
  ..._viewConfigurationAdapter,
  defaultSelectors: _viewConfigurationAdapter.getSelectors(),
  rootSelectors: _viewConfigurationAdapter.getSelectors<RootState>((state) => state.grayzone.viewConfigurations)
};

const _widgetAdapter = createEntityAdapter<Widget>({
  sortComparer: (a: Widget, b: Widget) => a.id.localeCompare(b.id)
});

export const widgetAdapter = {
  ..._widgetAdapter,
  defaultSelectors: _widgetAdapter.getSelectors(),
  rootSelectors: _widgetAdapter.getSelectors<RootState>((state) => state.grayzone.widgets)
};

export const _widgetConfigurationsAdapter = createEntityAdapter<WidgetConfigurations>();

export const widgetConfigurationsAdapter = {
  ..._widgetConfigurationsAdapter,
  defaultSelectors: _widgetConfigurationsAdapter.getSelectors(),
  rootSelectors: _widgetConfigurationsAdapter.getSelectors<RootState>((state) => state.grayzone.widgetConfigurations)
};

const _gridLayoutAdapter = createEntityAdapter<GridLayout>({
  sortComparer: (a: GridLayout, b: GridLayout) => a.id.localeCompare(b.id)
});

export const gridLayoutAdapter = {
  ..._gridLayoutAdapter,
  defaultSelectors: _gridLayoutAdapter.getSelectors(),
  rootSelectors: _gridLayoutAdapter.getSelectors<RootState>((state) => state.grayzone.gridLayouts)
};

const _formAdapter = createEntityAdapter<Form>({
  sortComparer: (a: Form, b: Form) => a.form_id.localeCompare(b.form_id),
  selectId: (form: Form) => form.form_id
});

export const formAdapter = {
  ..._formAdapter,
  defaultSelectors: _formAdapter.getSelectors()
};

const _formQuestionAdapter = createEntityAdapter<FormQuestion>({
  sortComparer: (a: FormQuestion, b: FormQuestion) => a.question_name.localeCompare(b.question_name),
  selectId: (question: FormQuestion) => question.question_name
});

export const formQuestionAdapter = {
  ..._formQuestionAdapter,
  defaultSelectors: _formQuestionAdapter.getSelectors()
};

const _countryAdapter = createEntityAdapter<Country>({
  sortComparer: (a: Country, b: Country) => a.l0_name.localeCompare(b.l0_name),
  selectId: (country: Country) => country.hasc
});

export const countryAdapter = {
  ..._countryAdapter,
  defaultSelectors: _countryAdapter.getSelectors()
};

const _customEventSetAdapter = createEntityAdapter<CustomEventSet>({
  sortComparer: (a, b) => a.name.localeCompare(b.name)
});

export const customEventSetAdapter = {
  ..._customEventSetAdapter,
  defaultSelectors: _customEventSetAdapter.getSelectors()
};

const _GDELTEventQueryResultAdapter = createEntityAdapter<GDELTEventQueryResult>();

export const GDELTEventQueryResultAdapter = {
  ..._GDELTEventQueryResultAdapter,
  defaultSelectors: _GDELTEventQueryResultAdapter.getSelectors()
};

const _customEventSetBindingAdapter = createEntityAdapter<CustomEventSetBinding>({
  selectId: (binding) => binding.eventSetId
});

export const customEventSetBindingAdapter = {
  ..._customEventSetBindingAdapter,
  defaultSelectors: _customEventSetBindingAdapter.getSelectors()
};

const _customEventAdapter = createEntityAdapter<CustomEventSet>({
  sortComparer: (a, b) => a.name.localeCompare(b.name)
});

export const customEventAdapter = {
  ..._customEventAdapter,
  defaultSelectors: _customEventAdapter.getSelectors()
};

export const filterFeedData = (data: (ACLEDdataType | GDELTdataType)[], filters: FeedWidgetConfigurations) => {
  let items = data;
  const sourcesFilter = filters.sourcesFilter;
  if (sourcesFilter !== undefined) {
    if (sourcesFilter.ACLED === false) {
      items = items.filter((item) => item.type !== FeedDataType.ACLED);
    }
    if (sourcesFilter.GDELT === false) {
      items = items.filter((item) => item.type !== FeedDataType.GDELT);
    }
  }

  const sort = filters.sort;
  if (sort !== undefined) {
    switch (sort) {
      case 'alphaAscending': {
        items.sort((a, b) => {
          const astring = a.type === FeedDataType.ACLED ? a.event_description : a.title;
          const bstring = b.type === FeedDataType.ACLED ? b.event_description : b.title;
          return astring.localeCompare(bstring) * -1;
        });
        break;
      }
      case 'alphaDescending': {
        items.sort((a, b) => {
          const astring = a.type === FeedDataType.ACLED ? a.event_description : a.title;
          const bstring = b.type === FeedDataType.ACLED ? b.event_description : b.title;
          return astring.localeCompare(bstring);
        });
        break;
      }
      case 'dateAscending': {
        items.sort((a, b) => {
          const adate = Date.parse(a.type === FeedDataType.ACLED ? a.date_event : a.date_news);
          const bdate = Date.parse(b.type === FeedDataType.ACLED ? b.date_event : b.date_news);
          return bdate - adate;
        });
        break;
      }
      case 'dateDescending': {
        items.sort((a, b) => {
          const adate = Date.parse(a.type === FeedDataType.ACLED ? a.date_event : a.date_news);
          const bdate = Date.parse(b.type === FeedDataType.ACLED ? b.date_event : b.date_news);
          return adate - bdate;
        });
        break;
      }
    }
  }

  const relevance = filters.relevanceFilter;
  if (relevance !== undefined) {
    if (relevance !== null) {
      items = items.filter((a) => {
        if (a.type === FeedDataType.GDELT) {
          return a.relevancy_score >= relevance[0] && a.relevancy_score <= relevance[1];
        }
        return true;
      });
    }
  }

  const excludeACLEDEvents = filters.excludeACLEDEvents;
  if (excludeACLEDEvents !== undefined) {
    const set = new Set<ACLEDEventEnum>();
    for (const event in excludeACLEDEvents) {
      if (excludeACLEDEvents[event as ACLEDEventEnum] === false) {
        set.add(event as ACLEDEventEnum);
      }
    }
    items = items.filter((a) => {
      if (a.type === FeedDataType.ACLED) {
        return set.has(a.event_type);
      }
      return true;
    });
  }

  const GDELTSourceFilters = filters.GDELTSourceFilters;
  if (GDELTSourceFilters !== undefined && GDELTSourceFilters.length !== 0) {
    const set = new Set<string>(GDELTSourceFilters);
    items = items.filter((a) => {
      if (a.type === FeedDataType.GDELT) {
        return set.has(a.domain_text);
      }
      return true;
    });
  }
  return items;
};
