import DeckMap from './DeckMap';
import { ACLEDdataType, FormQuestion, GDELTdataType, GenericMap, MapWidget } from '../../../types/grayzone';

export type MapProps = {
  feedData: (GDELTdataType | ACLEDdataType)[];
  widget: MapWidget;
  questionData?: FormQuestion;
  categoricalColorScheme?: GenericMap<string>;
};
const Map = (props: MapProps) => {
  // TODO: server side implementation (LeafletMap)
  return <DeckMap {...props} />;
};

export default Map;
