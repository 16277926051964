/* eslint-disable max-len */
// BASEUI-GENERATED-REACT-ICON
// DO NOT EDIT THIS FILE DIRECTLY, SEE README.md
import * as React from 'react';
import type { IconProps } from '../Icon';
import Icon from '../Icon';

const ArrowDownOutlineIcon = (props: IconProps): JSX.Element => {
  const {
    title = 'Arrow Down Outline Icon',
    size,
    color,
    onClick,
    ...restProps
  } = props;
  return (
    <Icon
      title={title}
      size={size}
      color={color}
      onClick={onClick}
      {...restProps}
    >
      <path d="M18.7019 13.7334C19.0994 13.3369 19.0994 12.6939 18.7019 12.2974C18.3045 11.9009 17.6602 11.9009 17.2628 12.2974L13 16.5508L13 5.07143C13 4.4797 12.5523 4 12 4C11.4477 4 11 4.4797 11 5.07143L11 16.5508L6.73721 12.2974C6.3398 11.9009 5.69547 11.9009 5.29806 12.2974C4.90065 12.6939 4.90065 13.3369 5.29806 13.7334L11.2804 19.7026C11.6778 20.0991 12.3222 20.0991 12.7196 19.7026L18.7019 13.7334Z"></path>
    </Icon>
  );
};

export default ArrowDownOutlineIcon;
/* eslint-enable max-len */
