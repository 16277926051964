// ----- REACT -----
import { useCallback, useEffect, useState } from 'react';

// ----- MUI -----
import { Card, styled, Button, Box, TextField, FormControl, Typography, useTheme, CircularProgress } from '@mui/material';

// ----- REDUX -----
import { useAppDispatch } from '../../hooks/useRedux';
import { irisEndpoints } from '../../api/iris';
import { authActions } from './authSlice';

// ----- MODULES -----
import { useForm, SubmitHandler } from 'react-hook-form';

// ----- HOOKS -----
import useAppSnackbar from '../../hooks/useAppSnackbar';

// ----- STYLES -----
import { FlexCentered } from '../../styles';

const TokenWindow = styled('div')({
  width: '100vw',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
});

const FormContainer = styled('form')({
  width: '500px',
  padding: '20px'
});

const ButtonRow = styled('div')({
  display: 'flex',
  flexDirection: 'row-reverse',
  paddingTop: '30px'
});

const ButtonTextContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '80px',
  height: '20px'
});

const TokenInputFormControl = styled(FormControl)({
  height: '70px'
});

const AuthView = () => {
  const [loadingStoredToken, setLoadingStoredToken] = useState(false);
  const { enqueue } = useAppSnackbar();

  const theme = useTheme();

  // Forms
  type FormValues = {
    token: string;
  };

  const {
    formState: { errors },
    handleSubmit,
    register,
    setError
  } = useForm<FormValues>();
  const tokenForm = register('token', { required: 'A token is required' });

  // Redux
  const dispatch = useAppDispatch();

  // Query
  const [authorizeQuery, authorizeQueryResults] = irisEndpoints.authenticateToken.useLazyQuery();
  useEffect(() => {
    if (authorizeQueryResults.isError) {
      if (loadingStoredToken) {
        window.localStorage.clear();
        setLoadingStoredToken(false);
      }
      setError('token', { type: 'message', message: 'Token not valid' });
      return;
    } else if (authorizeQueryResults.isSuccess) {
      // store our token for persistent session
      if (!loadingStoredToken) window.localStorage.token = authorizeQueryResults?.originalArgs?.token;
      // setting this to true will take us out of auth view and into main view

      if (!authorizeQueryResults?.originalArgs?.token) {
        throw new Error('Token is undefined!');
      }

      dispatch(authActions.updateToken(authorizeQueryResults.originalArgs.token));
      enqueue('Token Authenticated', { variant: 'success' });
    }
  }, [authorizeQueryResults.isSuccess, authorizeQueryResults.isError]);

  /* Before we do anything, check to see if we have a token stored already in local storage.
  If we do, run the query to verify our token is still valid and works */
  useEffect(() => {
    // window.localStorage.clear(); // DEBUG: logout
    const storedToken = window.localStorage.token as string;
    if (storedToken == null) return;

    setLoadingStoredToken(true);
    authorizeQuery({ token: storedToken }).catch((error) => console.log(error));
  }, []);

  // Callbacks
  const handleFormSubmit: SubmitHandler<FormValues> = useCallback((data) => {
    return authorizeQuery({ token: data.token });
  }, []);

  return (
    <TokenWindow>
      <Card variant="outlined">
        {loadingStoredToken && (
          <Box padding={theme.spacing(4)}>
            <Typography variant="h2">Verifying authentication token</Typography>
            <FlexCentered style={{ margin: '20px' }}>
              <CircularProgress />
            </FlexCentered>
          </Box>
        )}
        {!loadingStoredToken && (
          <FormContainer
            onSubmit={(event) => {
              handleSubmit(handleFormSubmit)(event).catch((error) => console.log(error));
            }}
          >
            <TokenInputFormControl error={!!errors?.token?.message} fullWidth>
              <TextField variant="filled" helperText={errors?.token?.message} fullWidth label="token" {...tokenForm} />
            </TokenInputFormControl>
            <ButtonRow>
              <Button type="submit" variant="contained">
                <ButtonTextContainer>{authorizeQueryResults.isFetching ? <CircularProgress size={16} /> : 'Authorize'}</ButtonTextContainer>
              </Button>
            </ButtonRow>
          </FormContainer>
        )}
      </Card>
    </TokenWindow>
  );
};

export default AuthView;
