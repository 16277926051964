/* eslint-disable max-len */
// BASEUI-GENERATED-REACT-ICON
// DO NOT EDIT THIS FILE DIRECTLY, SEE README.md
import * as React from 'react';
import type { IconProps } from '../Icon';
import Icon from '../Icon';

const CloseCircleOutlineIcon = (props: IconProps): JSX.Element => {
  const {
    title = 'Close Circle Outline Icon',
    size,
    color,
    onClick,
    ...restProps
  } = props;
  return (
    <Icon
      title={title}
      size={size}
      color={color}
      onClick={onClick}
      {...restProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12Z"
      ></path>
      <path d="M14.1213 8.46449C14.5118 8.07396 15.145 8.07396 15.5355 8.46449C15.9261 8.85501 15.9261 9.48818 15.5355 9.8787L13.4142 12L15.5355 14.1213C15.9261 14.5119 15.9261 15.145 15.5355 15.5356C15.145 15.9261 14.5118 15.9261 14.1213 15.5356L12 13.4142L9.87868 15.5356C9.48816 15.9261 8.85499 15.9261 8.46447 15.5356C8.07394 15.145 8.07394 14.5119 8.46447 14.1213L10.5858 12L8.46447 9.8787C8.07394 9.48818 8.07394 8.85501 8.46447 8.46449C8.85499 8.07396 9.48816 8.07396 9.87868 8.46449L12 10.5858L14.1213 8.46449Z"></path>
    </Icon>
  );
};

export default CloseCircleOutlineIcon;
/* eslint-enable max-len */
