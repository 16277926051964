/* eslint-disable max-len */
// BASEUI-GENERATED-REACT-ICON
// DO NOT EDIT THIS FILE DIRECTLY, SEE README.md
import * as React from 'react';
import type { IconProps } from '../Icon';
import Icon from '../Icon';

const ListOutlineIcon = (props: IconProps): JSX.Element => {
  const {
    title = 'List Outline Icon',
    size,
    color,
    onClick,
    ...restProps
  } = props;
  return (
    <Icon
      title={title}
      size={size}
      color={color}
      onClick={onClick}
      {...restProps}
    >
      <path d="M3 6C2.44772 6 2 6.44772 2 7C2 7.55229 2.44772 8 3 8L4 8C4.55228 8 5 7.55229 5 7C5 6.44772 4.55228 6 4 6H3Z"></path>
      <path d="M8 6C7.44772 6 7 6.44772 7 7C7 7.55229 7.44772 8 8 8L21 8C21.5523 8 22 7.55228 22 7C22 6.44772 21.5523 6 21 6H8Z"></path>
      <path d="M8 11C7.44772 11 7 11.4477 7 12C7 12.5523 7.44772 13 8 13L21 13C21.5523 13 22 12.5523 22 12C22 11.4477 21.5523 11 21 11L8 11Z"></path>
      <path d="M7 17C7 16.4477 7.44772 16 8 16L21 16C21.5523 16 22 16.4477 22 17C22 17.5523 21.5523 18 21 18L8 18C7.44772 18 7 17.5523 7 17Z"></path>
      <path d="M2 12C2 11.4477 2.44772 11 3 11H4C4.55228 11 5 11.4477 5 12C5 12.5523 4.55228 13 4 13H3C2.44772 13 2 12.5523 2 12Z"></path>
      <path d="M3 16C2.44772 16 2 16.4477 2 17C2 17.5523 2.44772 18 3 18H4C4.55228 18 5 17.5523 5 17C5 16.4477 4.55228 16 4 16H3Z"></path>
    </Icon>
  );
};

export default ListOutlineIcon;
/* eslint-enable max-len */
