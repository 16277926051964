import { useEffect, useRef, useCallback, memo, useMemo, useState } from 'react';

// ----- MUI -----
import { Box, useTheme } from '@mui/material';

// ----- Redux -----
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { sentimentEndpoints } from '../../../api/sentiment';
import { grayzoneActions, grayzoneSelectors } from '../../../features/grayzone/grayzoneSlice';
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';
import { Update } from '@reduxjs/toolkit';

// ----- Hooks -----
import useGrid from '../../../hooks/useGrid';
import useSelectWidgetConfigurations from '../../../hooks/useSelectWidgetConfigurations';
import useAppSnackbar from '../../../hooks/useAppSnackbar';

// ----- VISX -----
import { ParentSize } from '@visx/responsive';

// ----- OURS -----
import MiniTimeline from './visx/MiniTimeline';
import MainTimeline from './visx/MainTimeline';
import TimelineTooltip from './visx/TimelineTooltip';
import TimelineTooltipContext from '../../../contexts/TimelineTooltipContext';
import Badge from '../../../PremiseDesign/Components/Badge';
import { RIGHT_OFFSET } from './visx/constants';
import { SENTIMENT_CATEGORICAL_HOVER_COLORS_MAPPED } from '../../../features/grayzone/helpers';
import FeedItemTooltip from './visx/FeedItemsTooltip';
import EventQueryItemTooltip from './visx/EventQueryItemTooltip';

// ----- STYLES -----
import { Spinner } from '../../../styles';

// ----- TYPES -----
import { ACLEDdataType, GDELTdataType, GenericMap, TimelineWidget, TimeseriesPoint, ViewConfiguration, WidgetType } from '../../../types/grayzone';

type Props = {
  id: string;
  questionOrdering?: { [x: string]: string };
  widget: TimelineWidget;
  isLoading: boolean;
  timelineData: TimeseriesPoint[] | GenericMap<TimeseriesPoint[]>;
  categoricalColorScheme?: GenericMap<string>; // if we have this, then we are categorical
  feedData: (GDELTdataType | ACLEDdataType)[];
};
const Timeline = ({ id, questionOrdering, isLoading, timelineData, categoricalColorScheme, feedData, widget }: Props) => {
  const { gridLayout } = useGrid();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { enqueue } = useAppSnackbar();

  // ----- Selectors -----
  const viewConfigurationSelectors = useMemo(() => grayzoneSelectors.viewConfigurations.getSelectors(), []);
  const parentView = useAppSelector((state) => viewConfigurationSelectors.selectById(state.grayzone.viewConfigurations, gridLayout.viewConfigId));
  const activeCategorical = parentView?.selectedCategoricalResponse ?? null;
  const { [WidgetType.TIMELINE]: timelineConfigs } = useSelectWidgetConfigurations();
  const highlightedNewsDate = useAppSelector(grayzoneSelectors.hoverState.selectHighlightedNewsDate);
  const eventQueryTooltipData = useAppSelector(grayzoneSelectors.timeline.selectEventQueryTooltipData);

  // ----- Local State -----
  const [hoverCategorical, setHoverCategorical] = useState<string | null>(null);

  // ----- Refs -----
  const containerRef = useRef<HTMLDivElement | null>(null);
  const widgetContainerRef = useRef<HTMLDivElement | null>(null);

  if (!parentView) {
    throw new Error(`No parent view found inside widget with id: ${id}. A parent view MUST be configured for any widget to belong to it.`);
  }

  // ----- Queries -----
  const getAlerts = sentimentEndpoints.getTimeseriesAlerts.useQuery({
    form_id: parentView?.forms[0] ?? skipToken,
    hasc_code: parentView?.countries[0] ?? skipToken,
    question_name: parentView?.questions[0] ?? skipToken,
    alertPercentChange: timelineConfigs.alertPercentChange ?? 0.01,
    alertSampleSize: timelineConfigs.alertSampleSize ?? 25,
    alertSignificance: timelineConfigs.alertSignificance ?? 99
  });

  useEffect(() => {
    if (getAlerts.isError) {
      return enqueue('Unable to fetch alerts for timeline', { variant: 'error' });
    }
  }, [getAlerts.isError, getAlerts.isSuccess]);

  useEffect(() => {
    // if feedData or date range changes, reset highlighted news Date
    dispatch(grayzoneActions.setHighlightedNewsDate(null));
  }, [feedData, parentView.dateFilter]);

  // We need to pass the screen left offset of our timeline widget to timeline tool tip so it can place it correctly
  const grabLeft = useCallback(() => {
    const rect = containerRef.current?.getBoundingClientRect();
    return rect?.x ?? 0;
  }, []);

  const handleMouseLeftTimeline = useCallback(() => {
    dispatch(grayzoneActions.setHighlightedNewsDate(null));
    dispatch(grayzoneActions.updateEventQueryTooltipData(null));
  }, []);

  // Flex with parent size is causing an infinite grow bug, so use calcs
  const MINI_TIMELINE_HEIGHT = '60px';
  // 18px is padding for the bottom drag, 16px is padding from design
  const MAIN_TIMELINE_HEIGHT = `calc(100% - ${MINI_TIMELINE_HEIGHT} - 18px - 16px)`;

  const mainTimelineData = useMemo(() => {
    if (parentView?.dateFilter !== undefined) {
      const left = parentView?.dateFilter?.[0];
      const right = parentView?.dateFilter?.[1];

      if (!left || !right) return timelineData;

      if (Array.isArray(timelineData)) {
        return timelineData.filter((point) => point.date >= left && point.date <= right);
      } else {
        const timelineCopy = { ...timelineData };
        Object.keys(timelineData).forEach((key) => {
          timelineCopy[key] = timelineData[key].filter((point) => point.date >= left && point.date <= right);
        });
        return timelineCopy;
      }
    }
    return timelineData;
  }, [timelineData, parentView?.dateFilter]);

  const shouldRenderData = useMemo(() => {
    if (Array.isArray(timelineData)) {
      return timelineData.length > 0;
    } else {
      const firstData = Object.keys(timelineData)[0];
      if (firstData) {
        return firstData.length > 0;
      } else return false;
    }
  }, [timelineData]);

  const badgeSetActiveCategorical = useCallback(
    (key) => {
      const updates: Update<ViewConfiguration> = {
        id: parentView.id,
        changes: {
          selectedCategoricalResponse: activeCategorical === key ? null : key
        }
      };
      dispatch(grayzoneActions.updateViewConfiguration(updates));
    },
    [activeCategorical]
  );

  const headerTop = `${parseInt(theme.spacings.small) * (categoricalColorScheme ? 5 : 2)}px`;
  const Badges = useMemo(() => {
    return (
      <Box height={headerTop} display="flex" gap={theme.spacings['xx-small']} width={`calc(100% - ${RIGHT_OFFSET}px)`} justifyContent={'end'} alignItems={'end'}>
        {categoricalColorScheme && (
          <>
            {Object.keys(categoricalColorScheme).map((key) => {
              return (
                <Badge
                  key={`categorical-badge-${key}`}
                  label={key}
                  color={categoricalColorScheme[key]}
                  hoverColor={SENTIMENT_CATEGORICAL_HOVER_COLORS_MAPPED[categoricalColorScheme[key]]}
                  maxWidth={'100px'}
                  onHover={() => setHoverCategorical(key)}
                  onMouseLeave={() => setHoverCategorical(null)}
                  onClick={() => badgeSetActiveCategorical(key)}
                  active={activeCategorical === key}
                />
              );
            })}
          </>
        )}
      </Box>
    );
  }, [categoricalColorScheme, activeCategorical, badgeSetActiveCategorical]);

  if (isLoading) {
    return (
      <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
        <Spinner color="primary" />
      </Box>
    );
  }

  /* TODO: replace context? we end up passing props and funcs to avoid re-renders anyway */
  return (
    <TimelineTooltipContext>
      {Badges}
      <Box
        width={'100%'}
        height={`calc(100% - ${headerTop})`}
        display={'flex'}
        position={'absolute'}
        flexDirection={'column'}
        ref={widgetContainerRef}
        onMouseLeave={handleMouseLeftTimeline}
      >
        {shouldRenderData && (
          <Box flex={1} width={'100%'}>
            <Box width={'100%'} height={MAIN_TIMELINE_HEIGHT} marginTop={theme.spacings.small} ref={containerRef} position={'relative'}>
              <ParentSize ignoreDimensions={['left', 'top']} debounceTime={15}>
                {(parent) => (
                  <MainTimeline
                    parentWidth={parent.width}
                    parentHeight={parent.height}
                    data={mainTimelineData}
                    categoricalColorScheme={categoricalColorScheme}
                    hoverCategorical={hoverCategorical}
                    activeCategorical={activeCategorical}
                    alertData={getAlerts.data?.alerts}
                    feedData={feedData}
                    questionOrdering={questionOrdering}
                    enabledEventSets={widget.enabledCustomEvents}
                  />
                )}
              </ParentSize>
              <TimelineTooltip grabLeftOffset={grabLeft} />
            </Box>
            <Box width={'100%'} height={MINI_TIMELINE_HEIGHT}>
              <ParentSize ignoreDimensions={['left', 'top']} debounceTime={15}>
                {(parent) => <MiniTimeline parentWidth={parent.width} parentHeight={parent.height} data={timelineData} categoricalColorScheme={categoricalColorScheme} />}
              </ParentSize>
            </Box>
          </Box>
        )}
        {eventQueryTooltipData !== null && (
          <EventQueryItemTooltip
            dataItem={eventQueryTooltipData.dataItem}
            containerWidth={widgetContainerRef.current?.getBoundingClientRect().width ?? 0}
            containerLeft={widgetContainerRef.current?.getBoundingClientRect().left ?? 0}
            containerX={eventQueryTooltipData.x}
            containerY={eventQueryTooltipData.y}
          />
        )}
        {highlightedNewsDate && (
          <FeedItemTooltip
            feedData={feedData}
            containerWidth={widgetContainerRef.current?.getBoundingClientRect().width ?? 0}
            containerLeft={widgetContainerRef.current?.getBoundingClientRect().left ?? 0}
            highlightedNewsDate={highlightedNewsDate}
          />
        )}
      </Box>
    </TimelineTooltipContext>
  );
};

export default memo(Timeline);
