const fontFamily = {
  heading: 'proxima-nova, Helvetica, San-Serif',
  text: 'P22-mackinac-pro, Georgia, Serif'
};

const fontSize = {
  'xxxx-large': 72,
  'xxx-large': 48,
  'xx-large': 36,
  'x-large': 28,
  large: 24,
  medium: 20,
  root: 16,
  small: 12,
  'x-small': 10
};

const lineHeight = {
  'display-4': '44px',
  h1: '36px',
  h2: '28px',
  h3: '26px',
  h4: '20px',
  'subhead-1': '28px',
  'subhead-2': '24px',
  'subhead-3': '20px',
  'subhead-4': '16px',
  body: '24px',
  'body-small': '20px',
  'label-large': '24px',
  label: '20px',
  'label-small': '16px',
  'label-small-tight': '12px',
  'label-tight': '16px'
};

const color = {
  'brand-primary-150': '#B63225',
  'brand-primary-125': '#D14334',
  'brand-primary': '#FF5B4A',
  'brand-primary-75': '#EE7562',
  'brand-primary-50': '#F09286',
  'brand-primary-25': '#F3B0A8',
  'brand-primary-10': '#FADFDE',
  'neutral-100': '#000000',
  'neutral-75': '#4F4F4F',
  'neutral-50': '#767676',
  'neutral-25': '#E0E0E0',
  'neutral-10': '#F9F9F9',
  'neutral-0': '#FFFFFF',
  'status-success-100': '#138810',
  'status-success-25': '#E6F2ED',
  'status-warning-100': '#FFCA62',
  'status-danger-100': '#BB5500',
  'status-danger-25': '#F3DDCB',
  'brand-secondary-1-125': '#2F63D7',
  'brand-secondary-1-100': '#1669FF',
  'brand-secondary-1-75': '#4179F6',
  'brand-secondary-1-50': '#6997F7',
  'brand-secondary-1-25': '#92B4F6',
  'brand-secondary-1-10': '#D5E1FC',
  'brand-secondary-2-125': '#7030EC',
  'brand-secondary-2-100': '#7E3BFF',
  'brand-secondary-2-75': '#8259F6',
  'brand-secondary-2-50': '#9C7CF7',
  'brand-secondary-2-25': '#B9A0F8',
  'brand-secondary-2-10': '#E2D9FB',
  'brand-secondary-3-125': '#E49100',
  'brand-secondary-3-100': '#FFA200',
  'brand-secondary-3-75': '#F2AE43',
  'brand-secondary-3-50': '#F5BF63',
  'brand-secondary-3-25': '#F8D18B',
  'brand-secondary-3-10': '#FCECD0',
  'brand-secondary-4-125': '#028C65',
  'brand-secondary-4-100': '#00A576',
  'brand-secondary-4-75': '#51AB87',
  'brand-secondary-4-50': '#6DBDA1',
  'brand-secondary-4-25': '#93D0BC',
  'brand-secondary-4-10': '#D1EDE4',
  'dataviz-1-100': '#042E8B',
  'dataviz-1-75': '#2B59BF',
  'dataviz-1-50': '#7290CF',
  'dataviz-1-25': '#A4B7DA',
  'dataviz-1-10': '#D0D7E6',
  'neutral-100-opacity-low': 'rgba(0, 0, 0, 0.8)'
};

const fontWeight = {
  regular: 500,
  bold: 700,
  heavy: 800
};

export default {
  borderRadius: {
    borderRadiusSharp: 0,
    borderRadiusSemiSharp: 4,
    borderRadius: 8,
    borderRadiusSmooth: 24,
    borderRadiusPill: '20rem'
  },
  spacings: {
    'xxxx-small': '2px',
    'xxx-small': '4px',
    'xx-small': '8px',
    'x-small': '12px',
    small: '16px',
    large: '24px',
    'x-large': '32px',
    'xx-large': '48px',
    'xxx-large': '96px'
  },
  opacity: {
    disabled: 0.2,
    high: 0.4,
    medium: 0.6,
    low: 0.8
  },
  color,
  palette: {
    primary: {
      main: color['brand-primary'],
      light: color['brand-primary-75'],
      dark: color['brand-primary-125'],
      contrastText: color['neutral-10']
    },
    secondary: {
      main: color['brand-secondary-1-100'],
      light: color['brand-secondary-1-75'],
      dark: color['brand-secondary-1-125'],
      contrastText: color['neutral-10']
    },
    error: {
      main: color['brand-secondary-3-100'],
      light: color['brand-secondary-3-75'],
      dark: color['brand-secondary-3-125'],
      contrastText: color['neutral-10']
    },
    warning: {
      main: color['brand-secondary-2-100'],
      light: color['brand-secondary-2-75'],
      dark: color['brand-secondary-2-125'],
      contrastText: color['neutral-10']
    },
    info: {
      main: color['brand-secondary-1-100'],
      light: color['brand-secondary-1-75'],
      dark: color['brand-secondary-1-125'],
      contrastText: color['neutral-10']
    },
    success: {
      main: color['brand-secondary-4-100'],
      light: color['brand-secondary-4-75'],
      dark: color['brand-secondary-4-125'],
      contrastText: color['neutral-10']
    },
    'secondary-2': {
      '125': '#7030EC',
      '100': '#7E3BFF',
      '75': '#8259F6',
      '50': '#9C7CF7',
      '25': '#B9A0F8',
      '10': '#E2D9FB'
    },
    'secondary-3': {
      '125': '#E49100',
      '100': '#FFA200',
      '75': '#F2AE43',
      '50': '#F5BF63',
      '25': '#F8D18B',
      '10': '#FCECD0'
    },
    neutral: {
      '100': '#000000',
      '75': '#4F4F4F',
      '50': '#767676',
      '25': '#E0E0E0',
      '10': '#F9F9F9',
      '0': '#FFFFFF'
    }
    // TO-DO: fill the rest with colors here, not sure what matches
    // ERROR, WARNING, INFO, SUCCESS https://mui.com/customization/palette/
    // https://premisedata.github.io/portal-design-system/?path=/story/design-system-tokens--colors
  },
  fontFamily,
  typography: {
    fontFamily: fontFamily.heading,
    fontSize: fontSize.root,
    fontWeightRegular: fontWeight.regular,
    fontWeightBold: fontWeight.bold,
    fontWeightHeavy: fontWeight.heavy,
    body1: undefined, // disable
    body2: undefined, // disable
    subtitle1: undefined,
    subtitle2: undefined,
    button: undefined,
    h5: undefined,
    h6: undefined,
    inherit: undefined,
    overline: undefined,
    caption: undefined,
    body: {
      fontFamily: fontFamily.text,
      fontSize: fontSize.root,
      fontWeight: fontWeight.regular,
      lineHeight: lineHeight.body,
      color: color['neutral-75']
    },
    'body-bold': {
      fontFamily: fontFamily.text,
      fontSize: fontSize.root,
      fontWeight: fontWeight.bold,
      lineHeight: lineHeight.body,
      color: color['neutral-75']
    },
    'body-small': {
      fontFamily: fontFamily.text,
      fontSize: fontSize.small,
      fontWeight: fontWeight.regular,
      lineHeight: lineHeight.body,
      color: color['neutral-75']
    },
    'body-small-bold': {
      fontFamily: fontFamily.text,
      fontSize: fontSize.small,
      fontWeight: fontWeight.bold,
      lineHeight: lineHeight.body,
      color: color['neutral-75']
    },
    h1: {
      fontFamily: fontFamily.heading,
      fontSize: fontSize['x-large'],
      fontWeight: fontWeight.heavy,
      lineHeight: lineHeight.h1,
      color: color['neutral-100']
    },
    h2: {
      fontFamily: fontFamily.heading,
      fontSize: fontSize.large,
      fontWeight: fontWeight.heavy,
      lineHeight: lineHeight.h2,
      color: color['neutral-100']
    },
    h3: {
      fontFamily: fontFamily.heading,
      fontSize: fontSize.medium,
      fontWeight: fontWeight.heavy,
      lineHeight: lineHeight.h3,
      color: color['neutral-100']
    },
    h4: {
      fontFamily: fontFamily.heading,
      fontSize: fontSize.root,
      fontWeight: fontWeight.heavy,
      lineHeight: lineHeight.h4,
      color: color['neutral-100']
    },
    'subhead-1': {
      fontFamily: fontFamily.heading,
      fontSize: fontSize.large,
      fontWeight: fontWeight.bold,
      lineHeight: lineHeight['subhead-1'],
      color: color['neutral-100']
    },
    'subhead-2': {
      fontFamily: fontFamily.heading,
      fontSize: fontSize.medium,
      fontWeight: fontWeight.bold,
      lineHeight: lineHeight['subhead-2'],
      color: color['neutral-100']
    },
    'subhead-3': {
      fontFamily: fontFamily.heading,
      fontSize: fontSize.root,
      fontWeight: fontWeight.bold,
      lineHeight: lineHeight['subhead-3'],
      color: color['neutral-100']
    },
    'subhead-4': {
      fontFamily: fontFamily.heading,
      fontSize: fontSize.small,
      fontWeight: fontWeight.bold,
      lineHeight: lineHeight['subhead-4'],
      color: color['neutral-100']
    },
    'display-4': {
      fontFamily: fontFamily.heading,
      fontSize: fontSize['xx-large'],
      fontWeight: fontWeight.bold,
      lineHeight: lineHeight['display-4'],
      color: color['neutral-100']
    },
    'button-tertiary': {
      fontFamily: fontFamily.heading,
      fontSize: fontSize.root,
      fontWeight: fontWeight.regular,
      lineHeight: lineHeight.label,
      color: color['brand-primary-125'],
      textDecoration: 'underline'
    },
    hyperlink: {
      fontFamily: fontFamily.text,
      fontSize: fontSize.root,
      fontWeight: fontWeight.regular,
      lineHeight: lineHeight.body,
      color: color['brand-primary-125'],
      textDecoration: 'underline'
    },
    'hyperlink-small': {
      fontFamily: fontFamily.text,
      fontSize: fontSize.small,
      fontWeight: fontWeight.regular,
      lineHeight: lineHeight['body-small'],
      color: color['brand-primary-125'],
      textDecoration: 'underline'
    },
    label: {
      fontFamily: fontFamily.heading,
      fontSize: fontSize.root,
      fontWeight: fontWeight.regular,
      lineHeight: lineHeight.label,
      color: color['neutral-100']
    },
    'label-bold': {
      fontFamily: fontFamily.heading,
      fontSize: fontSize.root,
      fontWeight: fontWeight.bold,
      lineHeight: lineHeight.label,
      color: color['neutral-100']
    },
    'label-heavy': {
      fontFamily: fontFamily.heading,
      fontSize: fontSize.root,
      fontWeight: fontWeight.heavy,
      lineHeight: lineHeight.label,
      color: color['neutral-100']
    },
    'label-small': {
      fontFamily: fontFamily.heading,
      fontSize: fontSize.small,
      fontWeight: fontWeight.regular,
      lineHeight: lineHeight['label-small'],
      color: color['neutral-100']
    },
    'label-small-bold': {
      fontFamily: fontFamily.heading,
      fontSize: fontSize.small,
      fontWeight: fontWeight.bold,
      lineHeight: lineHeight['label-small'],
      color: color['neutral-100']
    },
    'label-small-heavy': {
      fontFamily: fontFamily.heading,
      fontSize: fontSize.small,
      fontWeight: fontWeight.heavy,
      lineHeight: lineHeight['label-small'],
      color: color['neutral-100']
    },
    'label-large': {
      fontFamily: fontFamily.heading,
      fontSize: fontSize.medium,
      fontWeight: fontWeight.regular,
      lineHeight: lineHeight['label-large'],
      color: color['neutral-100']
    },
    'label-large-bold': {
      fontFamily: fontFamily.heading,
      fontSize: fontSize.medium,
      fontWeight: fontWeight.bold,
      lineHeight: lineHeight['label-large'],
      color: color['neutral-100']
    },
    'label-large-heavy': {
      fontFamily: fontFamily.heading,
      fontSize: fontSize.medium,
      fontWeight: fontWeight.heavy,
      lineHeight: lineHeight['label-large'],
      color: color['neutral-100']
    },
    'button-tertiary-mini': {
      fontFamily: fontFamily.heading,
      fontSize: fontSize.small,
      fontWeight: fontWeight.regular,
      lineHeight: lineHeight['label-small'],
      color: color['brand-primary-125']
    },
    'label-small-tight': {
      fontFamily: fontFamily.heading,
      fontSize: fontSize.small,
      fontWeight: fontWeight.regular,
      lineHeight: lineHeight['label-small-tight'],
      color: color['neutral-100']
    },
    'label-small-bold-tight': {
      fontFamily: fontFamily.heading,
      fontSize: fontSize.small,
      fontWeight: fontWeight.bold,
      lineHeight: lineHeight['label-small-tight'],
      color: color['neutral-100']
    },
    'label-bold-tight': {
      fontFamily: fontFamily.heading,
      fontSize: fontSize.root,
      fontWeight: fontWeight.bold,
      lineHeight: lineHeight['label-tight'],
      color: color['neutral-100']
    },
    'label-tight': {
      fontFamily: fontFamily.heading,
      fontSize: fontSize.root,
      fontWeight: fontWeight.regular,
      lineHeight: lineHeight['label-tight'],
      color: color['neutral-100']
    }
  },
  // TO-DO: all the stuff down here lmao
  iconScale: {
    'x-small': 'scale(0.575)',
    small: 'scale(0.835)',
    regular: 'scale(1.0)',
    large: 'scale(1.67)',
    'object-selected': 'scale(1.5)'
  },
  iconColor: {
    'neutral-100': color['neutral-100'],
    'brand-primary': color['brand-primary'],
    'brand-primary-125': color['brand-primary-125'],
    'neutral-75': color['neutral-75'],
    'neutral-50': color['neutral-50'],
    'status-success': color['status-success-100']
  },
  'box-shadow': {
    card: '0.0px 1.0px 10.0px 0px rgba(79, 79, 79, 0.12),0.0px 4.0px 12.0px 0px rgba(79, 79, 79, 0.12),0.0px 0.0px 2.0px 0px rgba(0, 0, 0, 0.12)',
    'card-light': '0px 0px 46px rgba(79, 79, 79, 0.12);',
    layout: '0px 0px 2px rgba(0, 0, 0, 0.24);',
    overlay: '0px 32px 56px rgba(0, 0, 0, 0.04), 0px 4px 24px rgba(0, 0, 0, 0.1), 0px 11px 32px rgba(0, 0, 0, 0.06)',
    focus: '0px 0px 0px 2px #F09286, 0px 4px 12px rgba(0, 0, 0, 0.16), 0px 20px 36px rgba(243, 176, 168, 0.24)',
    'card-hover': '0px 4px 12px rgba(0, 0, 0, 0.16),0px 20px 36px rgba(232, 230, 230, 0.24),0px 8px 32px rgba(79, 79, 79, 0.12)',
    'map-object': '0px 4px 20px rgba(0, 0, 0, 0.16)',
    'map-object-selected': '0px 4px 20px rgba(74, 74, 74, 0.25), 0px 4px 10px rgba(74, 74, 74, 0.25)',
    'dataviz-hover': '0px 10px 20px rgba(74, 74, 74, 0.25), 0px 5px 10px rgba(74, 74, 74, 0.25)'
  }
};
