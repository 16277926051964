// ----- REACT -----
import { createContext, useState, ReactNode, useMemo, useRef } from 'react';

// ----- REDUX -----
import { useAppDispatch, useAppSelector } from '../hooks/useRedux';
import { grayzoneActions, grayzoneSelectors } from '../features/grayzone/grayzoneSlice';

// ----- MODULES -----
import ReactGridLayout from 'react-grid-layout';
import { v4 as uuid_v4 } from 'uuid';
import amplitude from 'amplitude-js';

// ----- OURS -----
import { generateDefaultWidgets, getBreakPointFromWidth, getMinDim, MIN_DIM_FEED, MIN_DIM_MAP, MIN_DIM_PHOTO, MIN_DIM_TIMELINE } from '../helpers/grayzone';

// ----- TYPES -----
import { Breakpoint, Breakpoints, GridLayout, Layouts, Widget, WidgetType } from '../types/grayzone';

// ----- CONSTANTS -----
const INITIAL_BREAKPOINTS: Breakpoints = { [Breakpoint.LG]: 1250, [Breakpoint.MD]: 1000, [Breakpoint.SM]: 0 };
const INITIAL_COLUMNS: Breakpoints = { [Breakpoint.LG]: 12, [Breakpoint.MD]: 10, [Breakpoint.SM]: 6 };

export type GridContextValue = {
  gridLayout: GridLayout;
  columns: Breakpoints;
  currentColumns: number;
  breakpoints: Breakpoints;
  currentBreakpoint: Breakpoint;
  widgetSize: { w: number; h: number };
  isItemHidden: (widgetId: string) => boolean;
  hideItem: (widgetId: string) => void;
  showItem: (widgetId: string, widgetType: WidgetType) => void;
  addItem: (widgetType: WidgetType) => void;
  removeItem: (itemIdToRemove: string) => void;
  updateColumns: (newColumns: Breakpoints) => void;
  updateBreakpoints: (newBreakpoints: Breakpoints) => void;
  updateWidgetSize: (size: { w: number; h: number }) => void;
  onLayoutChange: (_: never, newLayouts: Layouts) => void;
  onBreakpointChange: (newBreakpoint: Breakpoint, newCols: number) => void;
  onDrop: (layout: ReactGridLayout.Layout[], layoutItem: ReactGridLayout.Layout, _event: DragEvent) => void;
  onResizeWidget: (id: string) => void;
  addResizeCallback: (id: string, callback: () => void) => void;
  removeResizeCallback: (id: string) => void;
};

// ----- CONTEXT -----
export const GridContext = createContext<GridContextValue | undefined>(undefined);

// ----- PROPS VALIDATION -----
type Props = {
  children: ReactNode;
  gridLayoutId: string;
};

const GridProvider = ({ children, gridLayoutId }: Props) => {
  // ----- Redux -----
  const dispatch = useAppDispatch();

  // ----- Selectors -----
  const gridLayoutSelectors = useMemo(() => grayzoneSelectors.gridLayouts.getSelectors(), []);

  const loadedLayout = useAppSelector((state) => gridLayoutSelectors.selectById(state.grayzone.gridLayouts, gridLayoutId));

  // ----- Local State -----
  const [breakpoints, setBreakpoints] = useState<Breakpoints>(INITIAL_BREAKPOINTS);
  const [currentBreakpoint, setCurrentBreakpoint] = useState<Breakpoint>(getBreakPointFromWidth(breakpoints, window.innerWidth));
  const [currentColumns, setCurrentColumns] = useState<number>(INITIAL_COLUMNS[currentBreakpoint]);
  const [columns, setColumns] = useState<Breakpoints>(INITIAL_COLUMNS);
  const resizeCallbacks = useRef<Map<string, () => void>>(new Map());
  const [widgetSize, setWidgetSize] = useState<{ w: number; h: number }>({ w: 2, h: 2 });

  // ----- Queries -----

  // ----- Helpers -----

  // ----- Error Catching -----
  if (!loadedLayout) {
    throw new Error('Current slice layout is not defined!');
  }
  /**
   * Adds an widget with a specified type to the grid
   *
   * @param widgetType The type of the widget being added (i.e. map, feed, etc.)
   */
  const addItem = (widgetType: WidgetType) => {
    const newUuid = uuid_v4();

    // TODO: add min width and height
    const newLayout: ReactGridLayout.Layout = { i: newUuid, x: 0, y: Infinity, ...getMinDim(widgetType) };

    const newItem: Pick<Widget, 'id' | 'type' | 'gridId'> = { id: newUuid, type: widgetType, gridId: loadedLayout.id };
    const widgets = generateDefaultWidgets([newItem]);

    const newLayouts = { ...loadedLayout.layouts, [currentBreakpoint]: [...loadedLayout.layouts[currentBreakpoint], newLayout] };

    dispatch(grayzoneActions.updateGridLayout({ id: loadedLayout.id, changes: { layouts: { ...newLayouts }, widgetIds: [...loadedLayout.widgetIds, widgets[0].id] } }));
    dispatch(grayzoneActions.createNewWidgets(widgets));

    amplitude.getInstance().logEvent('Added a widget', newItem);
  };

  /**
   * NOTE: Not being used currently, no drag functionality enabled for widgets
   * Drag event handler for new items being dragged onto the grid from outside.
   *
   * @param newLayout The new layout generated by RGL after the drop is completed.
   * @param newLayoutItem The new layout item being dragged onto the grid
   * @param event The drag event corresponding to the item being dragged onto the grid
   */
  const onDrop = (newLayout: ReactGridLayout.Layout[], newLayoutItem: ReactGridLayout.Layout, event: DragEvent) => {
    const widgetType = event.dataTransfer?.getData('widget_type') as WidgetType | undefined;

    let widgetDims;
    // minw & minh based on type
    switch (widgetType) {
      case WidgetType.FEED: {
        widgetDims = MIN_DIM_FEED;
        break;
      }
      case WidgetType.PHOTO: {
        widgetDims = MIN_DIM_PHOTO;
        break;
      }
      case WidgetType.TIMELINE: {
        widgetDims = MIN_DIM_TIMELINE;
        break;
      }
      case WidgetType.MAP: {
        widgetDims = MIN_DIM_MAP;
        break;
      }
    }

    if (!widgetType) {
      throw new Error('Draggable widget event arrived to onDrop handler WITHOUT type! This is required!');
    }

    if (!newLayoutItem) {
      throw new Error('New layout item was undefined!');
    }

    const updatedNewLayoutItem = { ...newLayoutItem, ...widgetDims, i: uuid_v4() };

    const newItem: Pick<Widget, 'id' | 'type' | 'gridId'> = { id: updatedNewLayoutItem.i, type: widgetType, gridId: loadedLayout.id };
    const newWidgets: Widget[] = generateDefaultWidgets([newItem]);

    const updatedLayout = [...newLayout.filter((obj) => obj.i !== '__dropping-item__'), updatedNewLayoutItem];
    const newLayouts = { ...loadedLayout.layouts, [currentBreakpoint]: updatedLayout };

    // setLayouts(newLayouts);
    dispatch(grayzoneActions.updateGridLayout({ id: loadedLayout.id, changes: { layouts: { ...newLayouts }, widgetIds: [...loadedLayout.widgetIds, newWidgets[0].id] } }));
    dispatch(grayzoneActions.createNewWidgets(newWidgets));
  };

  // GZC 1.5? hiding instead of deleting widget altogether
  // note, grid layout will still hold the widget ids, but delete from layout if 'hidden'
  const hideItem = (widgetId: string) => {
    const newLayouts = (Object.keys(loadedLayout.layouts) as Array<Breakpoint>).reduce((runningObj, breakpoint) => {
      runningObj[breakpoint] = loadedLayout.layouts[breakpoint].filter((item) => item.i !== widgetId);
      amplitude.getInstance().logEvent('Removed a widget');
      return runningObj;
    }, {} as Layouts);

    // setLayouts(newLayouts);
    dispatch(grayzoneActions.updateGridLayout({ id: loadedLayout.id, changes: { layouts: { ...newLayouts } } }));
  };
  const showItem = (widgetId: string, widgetType: WidgetType) => {
    // this logic might be bugged for when we resize window and our layouts are not consistent?
    // TODO: set y to Infinity and change schema in backend to allow for y to be a number or Infinity
    // Infinity doesn't get cast back into a number unless the window is actively in that breakpoint
    const newLayout: ReactGridLayout.Layout = { i: widgetId, x: 0, y: 0, ...getMinDim(widgetType) };
    const newLayouts: Layouts = {
      [Breakpoint.LG]: [...loadedLayout.layouts[Breakpoint.LG], newLayout],
      [Breakpoint.MD]: [...loadedLayout.layouts[Breakpoint.MD], newLayout],
      [Breakpoint.SM]: [...loadedLayout.layouts[Breakpoint.SM], newLayout]
    };

    dispatch(grayzoneActions.updateGridLayout({ id: loadedLayout.id, changes: { layouts: { ...newLayouts } } }));
  };
  const isItemHidden = (widgetId: string) => {
    return !(Object.keys(loadedLayout.layouts) as Array<Breakpoint>).some((breakpoint) => {
      return loadedLayout.layouts[breakpoint].some((item) => item.i === widgetId);
    });
  };

  /**
   * Remove a grid item with a specific id
   * @param itemIdToRemove The id of the grid item to be deleted. This corresponds to the id of the widget being deleted.
   */
  const removeItem = (itemIdToRemove: string) => {
    const newLayouts = (Object.keys(loadedLayout.layouts) as Array<Breakpoint>).reduce((runningObj, breakpoint) => {
      runningObj[breakpoint] = loadedLayout.layouts[breakpoint].filter((item) => item.i !== itemIdToRemove);
      amplitude.getInstance().logEvent('Removed a widget');
      return runningObj;
    }, {} as Layouts);

    // setLayouts(newLayouts);
    dispatch(
      grayzoneActions.updateGridLayout({ id: loadedLayout.id, changes: { layouts: { ...newLayouts }, widgetIds: loadedLayout.widgetIds.filter((id) => id !== itemIdToRemove) } })
    );
    dispatch(grayzoneActions.deleteWidget(itemIdToRemove));
  };

  /**
   * Update the current columns with a new set.
   *
   * @param newColumns New columns with which to update the state.
   */
  const updateColumns = (newColumns: Breakpoints) => {
    setColumns(newColumns);
  };
  /**
   * Update the current breakpoints with a new set.
   *
   * @param newBreakpoints New breakpoints with which to update the state.
   */
  const updateBreakpoints = (newBreakpoints: Breakpoints) => {
    setBreakpoints(newBreakpoints);
  };

  /**
   * Adds a callback to be used after a widget is resized
   *
   * @param id Id of the callback being set
   * @param callback The callback being set
   */
  const addResizeCallback = (id: string, callback: () => void) => {
    if (resizeCallbacks.current.has(id)) {
      // we shouldn't be here, duplicate id
      throw new Error('Duplicate widget ID detected or deleted widget did not handle unregistering resize callback');
    }

    resizeCallbacks.current.set(id, callback);
  };

  /**
   * Removes a specified callback from the set of resize callbacks
   * @param id Id of the callback to be removed
   */
  const removeResizeCallback = (id: string) => {
    resizeCallbacks.current.delete(id);
  };

  /**
   * Updates the widget size to be used for items being dragged onto the grid from the outside
   * @param size New dimensions to be used for newly dragged items
   */
  const updateWidgetSize = (size: { w: number; h: number }) => {
    setWidgetSize(size);
  };

  // ----- Handlers -----

  /**
   * Handles a change in layouts (change in widget sizes, positions, or quantity)
   * @param _ Current layouts
   * @param newLayouts New set of layouts to apply to the grid
   */
  const onLayoutChange = (_: ReactGridLayout.Layout[], newLayouts: ReactGridLayout.Layouts) => {
    dispatch(grayzoneActions.updateGridLayout({ id: loadedLayout.id, changes: { layouts: { ...(newLayouts as Layouts) } } }));
  };

  /**
   * Handles a change in breakpoint. If window size changes enough to enter a different breakpoint, this function will be called.
   * @param newBreakpoint New breakpoint being used in the grid layout
   * @param newCols New columns being used in the grid layout
   */
  const onBreakpointChange = (newBreakpoint: Breakpoint, newCols: number) => {
    setCurrentBreakpoint(newBreakpoint);
    setCurrentColumns(newCols);
  };

  /**
   * Function that is called when a widget is resized. Uses a provided id to call a specific resize callback which presumably
   * was set prior to this function being called.
   * @param id Id of the resize callback to use
   */
  const onResizeWidget = (id: string) => {
    if (resizeCallbacks.current.has(id)) {
      resizeCallbacks.current.get(id)?.();
    }
  };

  // ----- Effects -----

  // ----- Return Component -----

  const values = useMemo((): GridContextValue => {
    return {
      columns,
      currentColumns,
      breakpoints,
      currentBreakpoint,
      widgetSize,
      gridLayout: loadedLayout,
      isItemHidden,
      hideItem,
      showItem,
      addItem,
      removeItem,
      onLayoutChange,
      onBreakpointChange,
      onDrop,
      updateColumns,
      onResizeWidget,
      addResizeCallback,
      removeResizeCallback,
      updateBreakpoints,
      updateWidgetSize
    };
  }, [
    loadedLayout,
    columns,
    currentColumns,
    breakpoints,
    currentBreakpoint,
    widgetSize,
    isItemHidden,
    hideItem,
    showItem,
    addItem,
    removeItem,
    onLayoutChange,
    onBreakpointChange,
    onDrop,
    updateColumns,
    updateBreakpoints,
    onResizeWidget,
    updateWidgetSize
  ]);

  return <GridContext.Provider value={values}>{children}</GridContext.Provider>;
};

export default GridProvider;
