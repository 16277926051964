import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import Theme from '../premiseTheme';

type StyleProps = {
  $dim: number;
  $disabled: boolean;
  $isClicked: boolean;
  $background: string;
  $padding: string;
  $boxShadow: string;
  $mini: boolean;
};
const IconButtonDiv = styled.div<StyleProps>`
  display: inline-block;
  padding: ${(props) => props.$padding};
  width: ${(props) => props.$dim}px;
  height: ${(props) => props.$dim}px;
  border-radius: ${(props) => (props.$mini ? Theme.borderRadius.borderRadiusSemiSharp : Theme.borderRadius.borderRadius)}px;
  opacity: ${(props) => (props.$disabled ? 0.2 : 1)};
  background: ${(props) => props.$background};
  cursor: ${(props) => (props.$disabled ? 'default' : 'pointer')};
  transition: ${(props) => (props.$isClicked ? 'none' : 'ease-in-out all 0.2s')};
  outline: 0;
  outline-color: ${Theme.color['brand-primary-25']};
  outline-offset: 0px;
  box-shadow: ${(props) => props.$boxShadow};
  &:focus-within {
    box-shadow: ${Theme['box-shadow'].focus};
  }
`;

type Props = {
  children: React.ReactNode;
  className?: string; // for styled components
  onClick?: React.MouseEventHandler;
  variant?: 'default' | 'mini';
  shadow?: boolean;
  disabled?: boolean;
  selected?: boolean;
  style?: React.CSSProperties;
};
const IconButton = ({ className, children, onClick, variant = 'default', shadow = false, disabled = false, selected = false, style }: Props) => {
  const buttonRef = useRef<HTMLDivElement | null>(null);
  const [isHover, setIsHover] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  // setColor
  const fill = useMemo(() => {
    if (isHover) {
      if (selected) {
        // hover and click is same
        return Theme.color['brand-primary-125'];
      } else {
        return isClicked ? Theme.color['brand-primary'] : Theme.color['neutral-25'];
      }
    } else {
      return selected ? Theme.color['brand-primary'] : Theme.color['neutral-0'];
    }
  }, [isHover, selected, isClicked]);

  // calc box shadow
  const boxShadow = shadow ? Theme['box-shadow'].card : '';

  // other style props
  const padding = variant === 'default' ? Theme.spacings['x-small'] : Theme.spacings['xxx-small'];
  const dim = (variant === 'default' ? 48 : 28) - parseInt(padding) * 2; // width & height
  const iconFill = isClicked || selected ? Theme.color['neutral-0'] : Theme.color['neutral-75'];
  const size = variant === 'default' ? Theme.iconScale.regular : Theme.iconScale.small;

  return (
    <IconButtonDiv
      ref={buttonRef}
      className={className}
      tabIndex={disabled ? undefined : 0} // lets the div be focusable
      onMouseEnter={() => !disabled && setIsHover(true)}
      onMouseLeave={() => {
        setIsHover(false);
        setIsClicked(false);
      }}
      onMouseDown={(e) => {
        e.preventDefault();
        if (disabled) return;

        onClick?.(e);
        setIsClicked(true);
      }}
      $padding={padding}
      $dim={dim}
      $disabled={disabled}
      $background={fill}
      $isClicked={isClicked}
      $boxShadow={boxShadow}
      $mini={variant === 'mini'}
      onMouseUp={() => !disabled && setIsClicked(false)}
      style={style}
    >
      {React.Children.map<ReactNode, ReactNode>(children, (child) => {
        // https://stackoverflow.com/questions/42261783/how-to-assign-the-correct-typing-to-react-cloneelement-when-giving-properties-to
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { size, color: iconFill });
        }
      })}
    </IconButtonDiv>
  );
};

export default IconButton;
