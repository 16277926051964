// ----- REACT -----
import { useEffect, useState, useMemo, memo, useCallback } from 'react';

// ----- REDUX -----
import { useAppSelector, useAppDispatch } from '../../../hooks/useRedux';
import { grayzoneActions, grayzoneSelectors } from '../grayzoneSlice';

// ----- API -----
import { gdeltEndpoints } from 'api/gdelt';

// ----- MODULES -----
import { styled, Dialog, Box, Typography, Autocomplete, TextField, useTheme, Chip, DialogActions, Button, Skeleton } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { addDays, differenceInCalendarDays, isWithinInterval } from 'date-fns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Sparklines, SparklinesLine, SparklinesReferenceLine, SparklinesSpots } from 'react-sparklines';
import { v4 as uuid_v4 } from 'uuid';

import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';

// ----- OURS -----
import { GDELTEventQueryResultAdapter } from 'helpers/grayzone';
import useAppSnackbar from 'hooks/useAppSnackbar';

// ----- CONSTANTS -----

// ----- STYLES -----
const StyledDialog = styled(Dialog)(({ theme }) => ({
  borderRadius: theme.borderRadius.borderRadius
}));

const ModalContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacings.small,
  width: '500px'
}));

// ----- TYPES -----
import { FeedDataType, GDELTEventQueryResult, GDELTKeywordQueryDataItem } from 'types/grayzone';
import { HideOutlineIcon, ShowOutlineIcon } from 'PremiseDesign/Icons';

// ----- COMPONENT -----
const EventQueryModal = () => {
  const theme = useTheme();
  const { enqueue } = useAppSnackbar();

  // ----- Redux -----
  const dispatch = useAppDispatch();

  // ----- Selectors -----
  const GDELTEventQueryResultSelectors = useMemo(() => GDELTEventQueryResultAdapter.getSelectors(), []);
  const primaryViewConfig = useAppSelector(grayzoneSelectors.viewConfigurations.selectPrimaryViewConfiguration);
  const eventToQuery = useAppSelector(grayzoneSelectors.timeline.selectEventToQuery);
  const eventQueryResults = useAppSelector(grayzoneSelectors.timeline.selectEventQueryResults);
  if (!primaryViewConfig || !eventToQuery) {
    throw new Error('A primary view must be configured and eventToQuery must not be null!');
  }
  const existingTimelineResult = GDELTEventQueryResultSelectors.selectById(eventQueryResults, eventToQuery.id);

  // ----- Local State -----
  const [selectedKeyWords, setSelectedKeywords] = useState<string[]>([]);
  const [queryParamsChanged, setQueryParamsChanged] = useState<boolean>(false);
  const [selectedSourceCountries, setSelectedSourceCountries] = useState<string[]>([...primaryViewConfig.countries]);
  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(eventToQuery?.startDate ? new Date(eventToQuery.startDate) : null);
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(eventToQuery?.endDate ? new Date(eventToQuery.endDate) : null);
  const [latestQueryResult, setLatestQueryResult] = useState<GDELTEventQueryResult | null>(null);

  // ----- Queries -----
  const [triggerGetGDELTKeywordTimeline, GDELTKeywordTimelineResult] = gdeltEndpoints.getGDELTKeywordTimeline.useLazyQuery();

  // ----- Helpers -----

  // ----- Handlers -----

  const handleClickedLaunchQuery = useCallback(async () => {
    if (!selectedStartDate || !selectedEndDate || selectedKeyWords.length === 0) {
      enqueue('Please enter at least one keyword and a valid date range', { variant: 'warning' });
      return;
    }
    try {
      const parsedStartDate = `${String(selectedStartDate.getFullYear()).padStart(4, '0')}${String(selectedStartDate.getMonth() + 1).padStart(2, '0')}${String(
        selectedStartDate.getDate()
      ).padStart(2, '0')}000000`;

      let adjustedEndDate = selectedEndDate;
      const calendarDiff = Math.abs(differenceInCalendarDays(selectedStartDate, selectedEndDate));
      if (calendarDiff < 7) {
        adjustedEndDate = addDays(selectedStartDate, 8);
      }

      const parsedEndDate = `${String(adjustedEndDate.getFullYear()).padStart(4, '0')}${String(adjustedEndDate.getMonth() + 1).padStart(2, '0')}${String(
        adjustedEndDate.getDate()
      ).padStart(2, '0')}000000`;

      const res = await triggerGetGDELTKeywordTimeline({
        format: 'json',
        mode: 'TimelineVolInfo',
        query: `${selectedKeyWords.join(' ')}${
          selectedSourceCountries.length > 0
            ? selectedSourceCountries.length > 1
              ? ` ${selectedSourceCountries
                  .map((country, index) => {
                    const sanitized = country.replace(/\s/, '');

                    if (index !== selectedSourceCountries.length - 1) {
                      return `${index === 0 ? '(' : ''}sourcecountry:${sanitized} OR`;
                    } else {
                      return `sourcecountry:${sanitized})`;
                    }
                  })
                  .join(' ')}`
              : ` sourcecountry:${selectedSourceCountries[0]}`
            : ''
        }`, // sourcelang:eng
        startdatetime: parsedStartDate,
        enddatetime: parsedEndDate
        // sourcecountry: primaryViewConfig.countries[0]
      }).unwrap();

      const data = res.timeline?.[0]?.data;
      if (data !== undefined) {
        setLatestQueryResult({
          id: eventToQuery.id,
          eventSetId: eventToQuery.eventSetId,
          eventName: eventToQuery.name,
          startDate: selectedStartDate.getTime(),
          endDate: selectedEndDate.getTime(),
          data: data
            .map(
              (item) =>
                ({
                  ...item,
                  id: uuid_v4(),
                  toparts: item.toparts.map((article) => ({
                    ...article,
                    type: FeedDataType.EventQueryArticle,
                    eventName: eventToQuery.name
                  }))
                } as GDELTKeywordQueryDataItem & { id: string })
            )
            .filter((item) => {
              const itemDate = new Date(item.date);
              const startDate = new Date(selectedStartDate.getFullYear(), selectedStartDate.getMonth(), selectedStartDate.getDate());
              const endDate = new Date(selectedEndDate.getFullYear(), selectedEndDate.getMonth(), selectedEndDate.getDate());
              return isWithinInterval(itemDate, { start: startDate, end: endDate });
            }),
          keywords: selectedKeyWords,
          sourceCountries: selectedSourceCountries,
          hidden: false
        });
      } else {
        enqueue('There were no results from your query', { variant: 'info' });
      }

      setQueryParamsChanged(false);
    } catch (e) {
      const error = e as FetchBaseQueryError;
      if ((error.data as string).includes('Invalid/Unsupported Country.')) {
        enqueue('At least one of the specified countries is invalid', { variant: 'error' });
      } else {
        enqueue('Something went wrong and the query failed', { variant: 'error' });
      }
      console.error(e);
    }
  }, [selectedStartDate, selectedEndDate, selectedSourceCountries, selectedKeyWords, eventToQuery]);

  // ----- Effects -----

  // useEffect(() => {
  // }, [selectedSourceCountries, selectedStartDate, selectedEndDate, selectedKeyWords]);

  useEffect(() => {
    if (existingTimelineResult !== undefined) {
      setSelectedStartDate(new Date(existingTimelineResult.startDate));
      setSelectedEndDate(new Date(existingTimelineResult.endDate));
      setSelectedKeywords([...existingTimelineResult.keywords]);
    } else {
      setSelectedStartDate(new Date(eventToQuery.startDate));
      setSelectedEndDate(new Date(eventToQuery.endDate));
      setSelectedKeywords([]);
    }
    setLatestQueryResult(null);
  }, [eventToQuery, existingTimelineResult]);

  useEffect(() => {
    if (eventToQuery !== null) {
      // const existingTimelineResult = existingTimelineResult;
      if (existingTimelineResult !== undefined) {
        setSelectedSourceCountries([...existingTimelineResult.sourceCountries]);
      } else {
        setSelectedSourceCountries([...primaryViewConfig.countries]);
      }
    } else {
      setSelectedSourceCountries([...primaryViewConfig.countries]);
    }
  }, [primaryViewConfig.countries, eventToQuery]);

  // ----- Return Component -----
  return (
    <StyledDialog open={eventToQuery !== null} onClose={() => dispatch(grayzoneActions.updateEventToQuery(null))}>
      {eventToQuery !== null && (
        <ModalContainer>
          <Box display="flex" flexDirection="row" gap={theme.spacings.small} alignItems="center">
            <Typography variant="h1">Add News Query</Typography>
            <Chip size="small" label={<Typography variant="label-small">{eventToQuery.name}</Typography>} />
          </Box>

          <Autocomplete
            options={[] as string[]}
            renderInput={(params) => {
              return <TextField {...params} placeholder="Add keyword or term" variant="standard" label="Keywords & Terms" />;
            }}
            value={selectedKeyWords}
            freeSolo
            title="Keywords"
            multiple
            renderTags={(tagValue, getTagProps) => tagValue.map((option, index) => <Chip {...getTagProps({ index })} key={`chip-${index}`} size="small" label={option} />)}
            onChange={(_, value) => {
              setSelectedKeywords(value);
              setQueryParamsChanged(true);
            }}
            style={{ flex: 1, padding: `${theme.spacings.small} 0px` }}
          />
          <Autocomplete
            options={[] as string[]}
            renderInput={(params) => {
              return <TextField {...params} placeholder="Add source country" variant="standard" label="Source Country" />;
            }}
            value={selectedSourceCountries}
            freeSolo
            title="Source Countries"
            multiple
            renderTags={(tagValue, getTagProps) => tagValue.map((option, index) => <Chip {...getTagProps({ index })} key={`chip-${index}`} size="small" label={option} />)}
            onChange={(_, value) => {
              setSelectedSourceCountries(value);
              setQueryParamsChanged(true);
            }}
            style={{ flex: 1, padding: `${theme.spacings.small} 0px` }}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box display="flex" flexDirection="row" justifyContent={'space-between'}>
              <DesktopDatePicker
                label="Start Date"
                inputFormat="MM/dd/yyyy"
                value={selectedStartDate}
                onChange={(date) => {
                  setSelectedStartDate(date);
                  if (date === null) setSelectedEndDate(null);
                  setQueryParamsChanged(true);
                }}
                renderInput={(params) => <TextField {...params} variant="standard" color="primary" />}
              />
              <DesktopDatePicker
                label="End Date"
                inputFormat="MM/dd/yyyy"
                minDate={selectedStartDate}
                value={selectedEndDate}
                onChange={(date) => {
                  setSelectedEndDate(date);
                  setQueryParamsChanged(true);
                }}
                renderInput={(params) => <TextField {...params} variant="standard" color="primary" />}
              />
            </Box>
          </LocalizationProvider>

          <Box width="100%" margin="15px 0px">
            {(!(latestQueryResult || existingTimelineResult) || GDELTKeywordTimelineResult.isFetching) && (
              <>
                <Box width="100%" height="65px">
                  <Skeleton
                    variant="rectangular"
                    width="100%"
                    height="100%"
                    style={{ margin: '15px 0px', borderRadius: theme.borderRadius.borderRadiusSemiSharp }}
                    animation={GDELTKeywordTimelineResult.isFetching ? 'pulse' : false}
                  />
                </Box>

                <Box display="flex" justifyContent="space-between" alignItems="center" paddingTop={theme.spacings['x-small']}>
                  <Typography variant="label-small-tight">Please build and launch a query to see results</Typography>
                  <Button onClick={handleClickedLaunchQuery} variant="text" disabled={!selectedStartDate || !selectedEndDate || selectedKeyWords.length < 1}>
                    Launch Query
                  </Button>
                </Box>
              </>
            )}
            {(latestQueryResult || existingTimelineResult) && !GDELTKeywordTimelineResult.isFetching && (
              <>
                <Box width="100%" height="65px">
                  <Sparklines
                    data={(latestQueryResult ?? existingTimelineResult)?.data.map((point) => point.value) ?? []}
                    style={{ width: '100%', height: '100%' }}
                    preserveAspectRatio="xMinYMin"
                    height={30}
                  >
                    <SparklinesLine
                      color={theme.color['brand-primary']}
                      // onMouseMove={(event: 'enter' | 'click', value: number, point: Point) => {
                      //   if (event === 'enter') console.log(value);
                      // }}
                    />
                    <SparklinesSpots style={{ fill: theme.color['brand-primary'], outlineColor: theme.color['brand-primary'], outlineWidth: '5px' }} />
                    {/* <SparklinesText text={new Date(eventToQuery.startDate).toLocaleDateString()} point={{ x: 0, y: 15 }} fontSize={5} fontFamily={theme.fontFamily.heading} /> */}
                    <SparklinesReferenceLine type="mean" value={10} />
                  </Sparklines>
                </Box>

                <Box display="flex" justifyContent="space-between" alignItems="center" paddingTop={theme.spacings['x-small']}>
                  <Typography variant="label-small-tight">% of total daily global GDELT articles matching your query</Typography>
                  <Button onClick={handleClickedLaunchQuery} variant="text" disabled={!selectedStartDate || !selectedEndDate || selectedKeyWords.length < 1 || !queryParamsChanged}>
                    Update Query
                  </Button>
                </Box>
              </>
            )}
          </Box>

          <DialogActions>
            {existingTimelineResult !== undefined && (
              <>
                <Button
                  variant="outlined"
                  startIcon={
                    existingTimelineResult.hidden ? (
                      <ShowOutlineIcon size={theme.iconScale['x-small']} color={theme.color['brand-primary']} />
                    ) : (
                      <HideOutlineIcon size={theme.iconScale['x-small']} color={theme.color['brand-primary']} />
                    )
                  }
                  onClick={() => {
                    dispatch(grayzoneActions.updateEventQueryResult({ id: eventToQuery.id, changes: { hidden: !existingTimelineResult.hidden } }));
                  }}
                >
                  {existingTimelineResult.hidden ? 'Show' : 'Hide'}
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => {
                    dispatch(grayzoneActions.deleteEventQueryResult(eventToQuery.id));
                  }}
                >
                  Delete Query
                </Button>
              </>
            )}

            <Button
              disabled={latestQueryResult === null}
              variant="contained"
              onClick={() => {
                if (!latestQueryResult) {
                  return;
                }
                if (existingTimelineResult !== undefined) {
                  dispatch(grayzoneActions.updateEventQueryResult({ id: eventToQuery.id, changes: { ...latestQueryResult } }));
                } else {
                  dispatch(grayzoneActions.addEventQueryResult(latestQueryResult));
                }
              }}
            >
              {existingTimelineResult ? 'Replace Existing' : 'Add to Timeline'}
            </Button>
          </DialogActions>
        </ModalContainer>
      )}
    </StyledDialog>
  );
};

export default memo(EventQueryModal);
