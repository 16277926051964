/* eslint-disable max-len */
// BASEUI-GENERATED-REACT-ICON
// DO NOT EDIT THIS FILE DIRECTLY, SEE README.md
import * as React from 'react';
import type { IconProps } from '../Icon';
import Icon from '../Icon';

const CollapseOutlineIcon = (props: IconProps): JSX.Element => {
  const {
    title = 'Collapse Outline Icon',
    size,
    color,
    onClick,
    ...restProps
  } = props;
  return (
    <Icon
      title={title}
      size={size}
      color={color}
      onClick={onClick}
      {...restProps}
    >
      <path d="M8.44259 18C7.87648 18.0007 7.41805 17.5422 7.41868 16.9761C7.4193 16.41 7.87873 15.9506 8.44485 15.95L14.5172 15.9433L6.33199 7.75806C5.91006 7.33613 5.88726 6.67485 6.28106 6.28106C6.67485 5.88726 7.33613 5.91006 7.75806 6.33199L15.9433 14.5172L15.95 8.44484C15.9506 7.87873 16.41 7.4193 16.9761 7.41868C17.5422 7.41805 18.0007 7.87648 18 8.44259L17.9906 16.9645C17.99 17.5306 17.5306 17.99 16.9645 17.9906L8.44259 18Z"></path>
    </Icon>
  );
};

export default CollapseOutlineIcon;
/* eslint-enable max-len */
