// ----- REACT -----
import { memo, MutableRefObject, useCallback, useEffect, useMemo, useState } from 'react';

// ----- REDUX -----
import { useAppSelector } from '../../../hooks/useRedux';
import { grayzoneSelectors } from '../grayzoneSlice';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { grayzoneEndpoints } from '../../../api/grayzone';
import { sentimentEndpoints } from '../../../api/sentiment';

// ----- PDS -----
import { ProfileOutlineIcon } from '../../../PremiseDesign/Icons';

// ----- MUI -----
import { CloudOffOutlined, ShareOutlined } from '@mui/icons-material';
import { Box, Chip, styled, Typography, useTheme } from '@mui/material';

// ----- MODULES -----
import { Layout } from 'react-grid-layout';

// ----- OURS -----
import SkeletonPlaceholder from '../../../components/SkeletonPlaceholder';
import Timeline from '../../../components/widgets/Timeline';
import Map from '../../../components/widgets/Map';
import Feed from '../../../components/widgets/Feed';
import Grid from '../../../components/Grid';
import WidgetContainer from '../../../components/widgets/WidgetContainer';
import Photo from '../../../components/widgets/Photo';
import { formQuestionAdapter, GDELTEventQueryResultAdapter, viewAccessAdapter } from '../../../helpers/grayzone';
import { filterItems, SENTIMENT_CATEGORICAL_COLORS } from '../helpers';
import useSelectWidgetConfigurations from '../../../hooks/useSelectWidgetConfigurations';
import useAppSnackbar from '../../../hooks/useAppSnackbar';
import AddEventModal from 'features/grayzone/containers/AddEventModal';
import EventQueryModal from './EventQueryModal';

// ----- HOOKS -----
import useGrid from '../../../hooks/useGrid';

// ----- TYPES -----
import { Widget, WidgetType, FormQuestion, TimeseriesPoint, GenericMap, ViewPermission, FeedDataType, GDELTdataType, ACLEDdataType } from '../../../types/grayzone';

// ----- STYLES -----
import { Spinner } from '../../../styles';

const StyledChip = styled(Chip)(({ theme }) => ({
  ...theme.typography['label-small-bold'],
  color: theme.color['neutral-75'],
  fontSize: '12px'
}));

// ----- COMPONENT -----
const GridView = () => {
  const theme = useTheme();

  // ----- State -----
  const [questionData, setQuestionData] = useState<FormQuestion | undefined>();
  const [timelineData, setTimelineData] = useState<TimeseriesPoint[] | GenericMap<TimeseriesPoint[]>>([]);
  const [categoricalColorScheme, setCategoricalColorScheme] = useState<GenericMap<string> | undefined>();

  // ----- Custom Hooks -----
  const { currentBreakpoint, gridLayout } = useGrid();
  const { enqueue } = useAppSnackbar();

  // ----- Selectors -----
  const viewConfigurationSelectors = useMemo(() => grayzoneSelectors.viewConfigurations.getSelectors(), []);
  const eventQueryResultSelectors = useMemo(() => GDELTEventQueryResultAdapter.getSelectors(), []);
  const widgets = useAppSelector((state) => grayzoneSelectors.widgets.selectAllWidgetsByGridLayoutIdMapped(state, gridLayout.id));
  const { [WidgetType.FEED]: feedConfigs } = useSelectWidgetConfigurations();
  const addEventModalOpen = useAppSelector(grayzoneSelectors.widgets.selectAddEventModalOpen);
  const currentViewConfiguration = useAppSelector((state) => viewConfigurationSelectors.selectById(state.grayzone.viewConfigurations, gridLayout.viewConfigId));
  const primaryView = useAppSelector(grayzoneSelectors.viewConfigurations.selectPrimaryView);
  const primaryViewConfiguration = useAppSelector(grayzoneSelectors.viewConfigurations.selectPrimaryViewConfiguration);
  const eventToQuery = useAppSelector(grayzoneSelectors.timeline.selectEventToQuery);
  const eventQueryResults = useAppSelector(grayzoneSelectors.timeline.selectEventQueryResults);

  if (!primaryViewConfiguration || !primaryView) {
    throw new Error('No current view found! A view must be configured.');
  }
  // ----- Queries -----
  const min_date_alert =
    currentViewConfiguration && currentViewConfiguration?.dateRange[0] ? new Date(Number(currentViewConfiguration.dateRange[0])).toISOString().split('T')[0] : undefined;
  const max_date_alert =
    currentViewConfiguration && currentViewConfiguration?.dateRange[1] ? new Date(Number(currentViewConfiguration.dateRange[1])).toISOString().split('T')[0] : undefined;

  const timeseriesParams = {
    form_id: currentViewConfiguration?.forms[0] ?? skipToken,
    question_name: currentViewConfiguration?.questions[0] ?? skipToken,
    hasc_code: currentViewConfiguration?.countries[0] ?? skipToken
  };
  const feedQueryParams = {
    ...timeseriesParams,
    min_date_alert: min_date_alert ?? skipToken,
    max_date_alert: max_date_alert ?? skipToken
  };

  const revereQuery = grayzoneEndpoints.getRevere.useQuery(feedQueryParams);
  const questionsQuery = sentimentEndpoints.getQuestionsFromForm.useQuery(
    { selectedForms: currentViewConfiguration?.forms || skipToken },
    { skip: currentViewConfiguration?.forms?.length === 0 ?? false }
  );
  const getTimeseriesQuery = sentimentEndpoints.getTimeseries.useQuery(timeseriesParams);
  const viewAccessesQueryResult = grayzoneEndpoints.getViewAccess.useQuery({});

  // ----- Helpers -----
  const getChipContent = useCallback(
    (viewId: string) => {
      if (viewAccessesQueryResult.isFetching) {
        return <Chip size="small" icon={<Spinner size="12px" />} label="Loading" />;
      }
      const data = viewAccessesQueryResult.data;
      if (data) {
        const access = viewAccessAdapter.defaultSelectors.selectById(data, viewId);
        if (!access) {
          return <StyledChip size="small" label="Unpublished" icon={<CloudOffOutlined style={{ fontSize: '14px', color: theme.color['neutral-75'] }} />} />;
        } else if ((access.permissions & ViewPermission.OWNER) > 0) {
          return <StyledChip size="small" label="My Views" icon={<ProfileOutlineIcon size={theme.iconScale['x-small']} color={theme.color['neutral-75']} />} />;
        } else {
          return <StyledChip size="small" label="Shared" icon={<ShareOutlined style={{ fontSize: '14px', color: theme.color['neutral-75'] }} />} />;
        }
      } else {
        return <StyledChip size="small" label="Unpublished" icon={<CloudOffOutlined style={{ fontSize: '14px', color: theme.color['neutral-75'] }} />} />;
      }
    },
    [viewAccessesQueryResult]
  );

  // ----- Effects -----
  useEffect(() => {
    if (questionsQuery.isError) {
      return enqueue(`Couldn't fetch question data`, { variant: 'error' });
    }

    if (questionsQuery.isSuccess) {
      const extractedQuestionData = formQuestionAdapter.defaultSelectors.selectById(questionsQuery.data, currentViewConfiguration?.questions[0] ?? '');
      if (extractedQuestionData) {
        setQuestionData(extractedQuestionData);
      }
    }
  }, [questionsQuery.isError, questionsQuery.isSuccess, questionsQuery.data]);

  const flattenedNewsData = useMemo(() => {
    const d = revereQuery.data
      ? [
          ...revereQuery.data.acled,
          ...revereQuery.data.gdelt,
          ...eventQueryResultSelectors
            .selectAll(eventQueryResults)
            .filter((result) => !result.hidden)
            .flatMap((result) => result.data.flatMap((item) => item.toparts))
        ]
      : [];
    return filterItems(d, feedConfigs, primaryViewConfiguration.dateFilter);
  }, [revereQuery.data, feedConfigs, primaryViewConfiguration.dateFilter, eventQueryResults]);

  useEffect(() => {
    const d = getTimeseriesQuery.data?.data ?? [];
    if (d.length > 0) {
      if (d[0]?.response !== 'ordinal_sentiment') {
        // categorical
        const buckets: GenericMap<number[]> = {};
        d.forEach((point) => {
          if (!buckets[point.response]) {
            buckets[point.response] = [point.smoothed_mean];
          } else {
            buckets[point.response].push(point.smoothed_mean);
          }
        });
        const sorted = Object.keys(buckets)
          .map((key) => {
            return { key, avg: buckets[key].reduce((sum, a) => sum + a) / buckets[key].length };
          })
          .sort((a, b) => b.avg - a.avg);

        const shuffledColors = SENTIMENT_CATEGORICAL_COLORS.map((value) => ({ value, sort: Math.random() }))
          .sort((a, b) => a.sort - b.sort)
          .map(({ value }) => value);

        const categoricalColorMap: GenericMap<string> = {};
        [...Array(Math.min(sorted.length, 5)).keys()].forEach((value) => {
          categoricalColorMap[sorted[value].key] = shuffledColors[value];
        });
        setCategoricalColorScheme(categoricalColorMap);

        // remove extra data
        const top5keys = new Set(Object.keys(categoricalColorMap));
        const dataMapped: GenericMap<TimeseriesPoint[]> = {};
        d.forEach((point) => {
          if (top5keys.has(point.response)) {
            if (dataMapped[point.response] === undefined) {
              dataMapped[point.response] = [point];
            } else {
              dataMapped[point.response].push(point);
            }
          }
        });
        return setTimelineData(dataMapped);
      }

      setCategoricalColorScheme(undefined);
      return setTimelineData(d);
    }
  }, [getTimeseriesQuery.data]);

  // ----- Helpers -----
  const getWidget = useCallback(
    (widget: Widget, layout: Layout, menuRef: MutableRefObject<JSX.Element[]>) => {
      switch (widget.type) {
        case WidgetType.MAP: {
          return (
            <Map
              feedData={flattenedNewsData.filter((item) => item.type !== FeedDataType.EventQueryArticle) as (GDELTdataType | ACLEDdataType)[]}
              questionData={questionData}
              widget={widget}
              categoricalColorScheme={categoricalColorScheme}
            />
          );
        }
        case WidgetType.FEED: {
          return <Feed isFetching={revereQuery.isFetching} data={flattenedNewsData} width={layout.w} widget={widget} menuRef={menuRef} />;
        }
        case WidgetType.PHOTO: {
          return <Photo nColumns={layout.w} nRows={layout.h} widget={widget} />;
        }
        case WidgetType.TIMELINE: {
          return (
            <Timeline
              id={layout.i}
              questionOrdering={questionData?.ordering}
              widget={widget}
              isLoading={getTimeseriesQuery.isFetching}
              timelineData={timelineData}
              categoricalColorScheme={categoricalColorScheme}
              feedData={flattenedNewsData.filter((item) => item.type !== FeedDataType.EventQueryArticle) as (GDELTdataType | ACLEDdataType)[]}
            />
          );
        }
        // we've hit undefined, we are dragging a new component and need a placeholder
        default: {
          return <SkeletonPlaceholder />;
        }
      }
    },
    [revereQuery.isSuccess, revereQuery.data, flattenedNewsData, questionData?.ordering, categoricalColorScheme, getTimeseriesQuery]
  );

  const gridView = useMemo(() => {
    return (
      <Grid gridLayout={gridLayout}>
        {gridLayout.layouts[currentBreakpoint]?.map((layout: Layout) => {
          const widget = widgets[layout.i];
          return widget && <WidgetContainer key={layout.i} data-grid={layout} widgetId={layout.i} renderChildren={(menuRef) => getWidget(widget, layout, menuRef)} />;
        })}
      </Grid>
    );
  }, [widgets, currentBreakpoint, gridLayout.layouts, getWidget]);

  // ----- Return Component -----
  return (
    <Box sx={{ height: '100%', width: '100%', backgroundColor: (theme) => theme.color['neutral-0'], overflowX: 'hidden' }}>
      <Box display="flex" flexDirection="row" alignItems="center" padding="16px 16px 0px 16px" gap="8px">
        <Typography variant="label-bold" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
          {primaryViewConfiguration.name}
        </Typography>
        {getChipContent(primaryView.viewId)}
      </Box>
      {gridLayout && gridView}
      {addEventModalOpen && <AddEventModal />}
      {eventToQuery && <EventQueryModal />}
    </Box>
  );
};

export default memo(GridView);
