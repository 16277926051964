/* eslint-disable max-len */
// BASEUI-GENERATED-REACT-ICON
// DO NOT EDIT THIS FILE DIRECTLY, SEE README.md
import * as React from 'react';
import type { IconProps } from '../Icon';
import Icon from '../Icon';

const GroupOutlineIcon = (props: IconProps): JSX.Element => {
  const {
    title = 'Group Outline Icon',
    size,
    color,
    onClick,
    ...restProps
  } = props;
  return (
    <Icon
      title={title}
      size={size}
      color={color}
      onClick={onClick}
      {...restProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8351 12.1139C13.0936 12.2945 13.372 12.4495 13.6667 12.5751C14.2281 12.8145 14.8482 12.9474 15.5 12.9474C16.1518 12.9474 16.7719 12.8145 17.3333 12.5751C17.7182 12.411 18.0755 12.1968 18.3966 11.9409C19.4261 11.1205 20.0833 9.87202 20.0833 8.47368C20.0833 6.00294 18.0313 4 15.5 4C14.5409 4 13.6506 4.28755 12.9147 4.77914C13.3044 5.33218 13.6071 5.94793 13.8036 6.60767C14.251 6.23224 14.839 6 15.5 6C16.9727 6 18.0833 7.15288 18.0833 8.47368C18.0833 9.79449 16.9727 10.9474 15.5 10.9474C14.819 10.9474 14.2154 10.7008 13.7632 10.3051C13.5526 10.9596 13.2368 11.5688 12.8351 12.1139ZM14.5953 21H19.1667C20.1792 21 21 20.1988 21 19.2105V17.4211C21 15.9285 20.0639 14.6493 18.7335 14.1123C18.3021 13.9382 17.8292 13.8421 17.3333 13.8421H15.5H13.6667C13.6257 13.8421 13.5848 13.8428 13.5441 13.8441C14.0743 14.4138 14.4831 15.0928 14.7307 15.8421H17.3333C18.2997 15.8421 19 16.5944 19 17.4211V19H15V19.3478C15 19.9424 14.8541 20.5039 14.5953 21Z"
      ></path>
      <path
        d="M11.0833 8.47368C11.0833 10.3695 9.50199 11.9474 7.5 11.9474C5.49801 11.9474 3.91667 10.3695 3.91667 8.47368C3.91667 6.57791 5.49801 5 7.5 5C9.50199 5 11.0833 6.57791 11.0833 8.47368ZM3 17.4211C3 16.0194 4.17094 14.8421 5.66667 14.8421H9.33333C10.8291 14.8421 12 16.0194 12 17.4211V19.2105C12 19.6239 11.6499 20 11.1667 20H3.83333C3.35013 20 3 19.6239 3 19.2105V17.4211Z"
        stroke={color}
        strokeWidth="2"
        fill="none"
      ></path>
    </Icon>
  );
};

export default GroupOutlineIcon;
/* eslint-enable max-len */
