// ----- REACT -----
import { Box } from '@mui/material';
import L from 'leaflet';
import { useEffect } from 'react';
import { MapContainer, TileLayer, Marker, useMap, MapContainerProps, Tooltip } from 'react-leaflet';

// ----- REDUX -----

// ----- API -----

// ----- MODULES -----
import { withSize, SizeMeProps } from 'react-sizeme';

// ----- OURS -----

// ----- STYLES -----

// ----- TYPES -----
import { MarkerState, MapMarker } from '../../../types/grayzone';

// ----- CONSTANTS -----
import { mapboxToken } from '../Map/utils/constants';

const markerIcons = {
  [MarkerState.DEFAULT]: L.icon({
    iconUrl: '/images/pin.svg',
    iconSize: [24, 27], // size of the icon
    iconAnchor: [12, 27] // point of the icon which will correspond to marker's location
  }),
  [MarkerState.HOVER]: L.icon({
    iconUrl: '/images/pin_hover.svg',
    iconSize: [26, 29], // size of the icon
    iconAnchor: [13, 29] // point of the icon which will correspond to marker's location
  }),
  [MarkerState.SELECTED]: L.icon({
    iconUrl: '/images/pin_selected.svg',
    iconSize: [38, 43], // size of the icon
    iconAnchor: [19, 43] // point of the icon which will correspond to marker's location
  })
};

// ----- COMPONENT -----

type Props = {
  zoomLevel: number;
  centerLatitude: number;
  centerLongitude: number;
  markers: MapMarker[];
  onSelectedMarkerChanged: (index: number) => void;
};
const MiniMapContent = ({ centerLatitude, centerLongitude, markers, onSelectedMarkerChanged, size }: Props & SizeMeProps) => {
  const mapInstance = useMap();

  // ----- Redux -----

  // ----- Local State -----

  // useMapEvents({
  //   click: () => {
  //     // const target = e.target as L.Map;
  //     setMarkerState(MarkerState.DEFAULT);
  //     setMarkerIcon(defaultMarker);
  //   }
  // });
  // ----- Selectors -----

  // ----- Queries -----

  // ----- Helpers -----

  // ----- Handlers -----

  // ----- Effects -----
  useEffect(() => {
    mapInstance.invalidateSize();
    mapInstance.setView([centerLatitude, centerLongitude], mapInstance.getZoom());
    mapInstance.setZoomAround([centerLatitude, centerLongitude], mapInstance.getZoom());
  }, [size.height, size.width, mapInstance]);

  useEffect(() => {
    mapInstance.flyTo([centerLatitude, centerLongitude], mapInstance.getZoom());
  }, [centerLatitude, centerLongitude, mapInstance]);

  // useEffect(() => {
  //   markerCoordinates.map(([lat, long]) => {
  //     const mark = new customMarker([lat, long], { icon });
  //     mark.m;
  //     const newMarker = L.marker([lat, long], { icon: markerIcon })
  //       .on('mouseover', (e) => {
  //         (e.target as L.Marker).setIcon(hoverMarker);
  //       })
  //       .on('click', (e) => {
  //         (e.target as L.Marker).setIcon(selectedMarker);
  //       })
  //       .addTo(mapInstance);

  //     return newMarker;
  //   });
  // }, [markerCoordinates, mapInstance]);

  // ----- Return Component -----
  return (
    <Box width="100%" height="100%">
      <TileLayer url={`https://api.mapbox.com/styles/v1/joeraii/ckrdksz4z0lr518paki3bz5ph/tiles/{z}/{x}/{y}?access_token=${mapboxToken}`} />
      {markers.map((marker, index) => (
        <Marker
          key={`marker-${index}`}
          position={[marker.latitude, marker.longitude]}
          icon={markerIcons[marker.state]}
          riseOnHover
          riseOffset={400}
          eventHandlers={{
            mouseover: (e) => {
              if (marker.state !== MarkerState.SELECTED) {
                (e.target as L.Marker).setIcon(markerIcons[MarkerState.HOVER]);
              }
            },
            mouseout: (e) => {
              if (marker.state !== MarkerState.SELECTED) {
                (e.target as L.Marker).setIcon(markerIcons[MarkerState.DEFAULT]);
              }
            },
            click: () => {
              mapInstance.flyTo([marker.latitude, marker.longitude], mapInstance.getZoom());
              if (marker.state !== MarkerState.SELECTED) {
                onSelectedMarkerChanged(index);
              }
            }
          }}
        >
          <Tooltip offset={[0, 0]} direction="bottom">{`${marker.tooltip}`}</Tooltip>
        </Marker>
      ))}
    </Box>
  );
};

// const SizeAwareMiniMapContent = withSize({ refreshMode: 'debounce', refreshRate: 60, monitorHeight: true })(MiniMapContent);

const MiniMap = (props: Props & MapContainerProps & SizeMeProps) => {
  const { zoomLevel, centerLatitude, centerLongitude, markers, size, onSelectedMarkerChanged, ...containerProps } = props;
  return (
    <MapContainer
      zoom={zoomLevel}
      center={[centerLatitude, centerLongitude]}
      worldCopyJump={true}
      attributionControl={false}
      dragging={false}
      zoomControl={false}
      scrollWheelZoom="center"
      doubleClickZoom={false}
      touchZoom={false}
      keyboard={false}
      {...containerProps}
    >
      <MiniMapContent
        zoomLevel={zoomLevel}
        centerLatitude={centerLatitude}
        centerLongitude={centerLongitude}
        markers={markers}
        onSelectedMarkerChanged={onSelectedMarkerChanged}
        size={size}
      />
    </MapContainer>
  );
};

export default withSize({ refreshMode: 'debounce', refreshRate: 250, monitorHeight: true })(MiniMap);
