// ----- REACT -----
import { useCallback, memo, useState, KeyboardEvent, useEffect } from 'react';

// // ----- REDUX -----
import { useAppSelector, useAppDispatch } from '../../../hooks/useRedux';
import { grayzoneActions, grayzoneSelectors } from '../grayzoneSlice';

// ----- MODULES -----

import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

// ----- OURS -----
import MapConfig from './MapConfig';
import TimelineConfig from './TimelineConfig';
import NewsFeedConfig from './FeedConfig';
import PhotoConfig from './PhotoConfig';

// ----- HOOKS -----
import useAppSnackbar from '../../../hooks/useAppSnackbar';

// ----- TYPES -----
import { WidgetType, Widget } from '../../../types/grayzone';
import { Update } from '@reduxjs/toolkit';

// ----- COMPONENT -----
const WidgetConfigModal = () => {
  // ----- Redux -----
  const dispatch = useAppDispatch();

  const { enqueue } = useAppSnackbar();

  // ----- Local State -----
  const [updates, setUpdates] = useState<Update<Widget> | undefined>();

  // ----- Selectors -----
  const modalIsOpen = useAppSelector(grayzoneSelectors.widgets.selectWidgetConfigModalOpen);
  const widgetToConfigure = useAppSelector(grayzoneSelectors.widgets.selectWidgetToConfigure);

  // ----- Helpers -----

  const getConfig = () => {
    switch (widgetToConfigure?.type) {
      case WidgetType.MAP:
        return <MapConfig publishChanges={setUpdates} widget={widgetToConfigure} />;
      case WidgetType.TIMELINE:
        return <TimelineConfig publishChanges={setUpdates} widget={widgetToConfigure} />;
      case WidgetType.FEED:
        return <NewsFeedConfig publishChanges={setUpdates} widget={widgetToConfigure} />;
      case WidgetType.PHOTO:
        return <PhotoConfig publishChanges={setUpdates} widget={widgetToConfigure} />;
      // case WidgetType.PLACEHOLDER:
      //   return <div>Placeholder</div>;
      default:
        throw new Error('Unknown widget type! Cannot generate default configuration!');
    }
  };

  // ----- Handlers -----

  const onSave = useCallback(() => {
    if (updates && Object.keys(updates.changes).length > 0) {
      dispatch(grayzoneActions.updateWidget(updates));
      enqueue('Changes applied', { variant: 'success', autoHideDuration: 1200 });
      setUpdates(undefined);
    }
  }, [updates]);

  const onClose = useCallback(() => {
    dispatch(grayzoneActions.finishConfiguringWidget());
    onSave();
  }, [updates]);

  const onKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      onClose();
    }
  };

  // ----- Effects -----
  useEffect(() => {
    // Make sure that any updates published after the modal has been closed (debounce) get pushed to app state
    if (!modalIsOpen) {
      onSave();
    }
  }, [updates]);

  // ----- Return Component -----
  if (!widgetToConfigure) {
    return null;
  }

  return (
    <Dialog open={modalIsOpen} fullWidth maxWidth="sm" onBackdropClick={onClose} onKeyDown={onKeyDown}>
      <DialogTitle>
        {`Configure ${widgetToConfigure.type
          .split('_')
          .map((word) => word[0].toUpperCase() + word.slice(1))
          .join(' ')}`}
      </DialogTitle>
      <DialogContent>{getConfig()}</DialogContent>

      <DialogActions>
        <Button onClick={onClose} variant="text">
          Finish
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(WidgetConfigModal);
