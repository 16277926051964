import { styled, CircularProgress, Box } from '@mui/material';
import { DateRange } from 'react-date-range';

export const FlexCentered = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
});

export const FlexScrollableList = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  overflowY: 'auto'
});
export const WideBox = styled(Box)(() => ({
  width: '100%'
}));

export const Spinner = styled(CircularProgress)(({ color = 'white', size = 18 }) => ({
  color,
  size
}));

export const WideDiv = styled('div')({ width: '100%' });

// ----- REACT DATE RANGE PICKER -----
export const CustomDateRange = styled(DateRange)(({ theme }) => ({
  width: '100%',
  '.rdrDayToday .rdrDayNumber span:after': {
    background: theme.color['brand-primary'],
    color: theme.color['brand-primary']
  },
  '.rdrMonth': {
    width: 'auto'
  },
  '.rdrDayInPreview': {
    color: theme.color['brand-primary']
  }
}));

export const DisappearingBox = styled(Box)<{ $active: boolean }>(({ $active }) => ({
  transition: 'opacity 0.2s ease-in',
  opacity: $active ? 1 : 0
}));

export const CONFIG_DRAWER_SIZE = 350;
