import { useCallback } from 'react';

// ----- MUI -----
import { Box, Drawer, styled, useTheme } from '@mui/material';

// ----- Redux -----
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { grayzoneActions, grayzoneSelectors } from '../../grayzoneSlice';

// ----- Ours -----
import ViewConfigDrawerContent from './ViewConfigDrawerContent';
import { CONFIG_DRAWER_SIZE } from '../../../../styles';
import { NAV_BAR_HEIGHT } from '../NavBar';
import WidgetConfigDrawerContent from './WidgetConfigDrawerContent';

const StyledDrawer = styled(Drawer)<{ $width: string }>(({ open, $width, theme }) => ({
  width: open ? $width : '0px',
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: $width,
    boxSizing: 'border-box',
    overflowX: 'hidden',
    background: theme.color['neutral-0'],
    borderRight: 'none',
    borderLeft: 'none'
  }
}));

const ConfigDrawer = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  // ----- Selectors -----
  const configDrawerOpen = useAppSelector(grayzoneSelectors.configDrawer.selectConfigDrawerOpen);
  const idle = useAppSelector(grayzoneSelectors.selectIdleState);

  // ----- Handlers -----
  const onClose = useCallback(() => {
    dispatch(grayzoneActions.toggleDrawerState());
  }, []);

  const width = `${CONFIG_DRAWER_SIZE}px`;

  return (
    <StyledDrawer $width={width} variant={'persistent'} anchor="right" open={configDrawerOpen}>
      <Box display={'inline-block'} paddingTop={idle ? '0px' : NAV_BAR_HEIGHT} paddingRight={theme.spacings.small} sx={{ overflowX: 'hidden' }}>
        <Box padding={`${theme.spacings.small} 0px ${theme.spacings.small} ${theme.spacings.small}`}>
          <ViewConfigDrawerContent onClose={onClose} />
          <WidgetConfigDrawerContent />
        </Box>
      </Box>
    </StyledDrawer>
  );
};

export default ConfigDrawer;
