// ----- REACT -----
import { useState, memo, ChangeEvent } from 'react';

// ----- REDUX -----
import { Update } from '@reduxjs/toolkit';

// ----- MODULES -----
import { Box } from '@mui/material';

// ----- OURS -----
import DebouncedTextfield from '../../../components/DebouncedTextfield';

// ----- TYPES -----
import { MapWidget } from '../../../types/grayzone';

// ----- COMPONENT -----
type Props = {
  publishChanges: (updates: Update<MapWidget>) => void;
  widget: MapWidget;
};
const MapConfig = ({ publishChanges, widget }: Props) => {
  // ----- Local State -----
  const [updates, setUpdates] = useState<Update<MapWidget>>({ id: widget.id, changes: {} });

  // ----- Helpers -----
  const generateUpdates = (newChanges: Partial<MapWidget>): Update<MapWidget> => {
    return { ...updates, changes: { ...updates.changes, ...newChanges } };
  };

  // ----- Handlers -----
  const handleNameChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const newUpdates = generateUpdates({ name: e.target.value });
    setUpdates(newUpdates);
    publishChanges(newUpdates);
  };
  const handleLatitudeChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const newUpdates = generateUpdates({ centerLatitude: Number.parseFloat(e.target.value) });
    setUpdates(newUpdates);
    publishChanges(newUpdates);
  };
  const handleLongitudeChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const newUpdates = generateUpdates({ centerLongitude: Number.parseFloat(e.target.value) });
    setUpdates(newUpdates);
    publishChanges(newUpdates);
  };

  // ----- Return Component -----
  return (
    <Box display="flex" flexDirection="column" justifyContent="center">
      <DebouncedTextfield id="input-name" onChange={handleNameChanged} defaultValue={widget.name} delay={700} label="Name" margin="normal" size="small" />
      <Box display="flex" gap="10px">
        <DebouncedTextfield
          id="input-latitude"
          fullWidth
          onChange={handleLatitudeChanged}
          defaultValue={widget.centerLatitude}
          delay={700}
          label="Latitude"
          margin="normal"
          size="small"
          type="number"
        />
        <DebouncedTextfield
          id="input-longitude"
          fullWidth
          onChange={handleLongitudeChanged}
          defaultValue={widget.centerLongitude}
          delay={700}
          label="Longitude"
          margin="normal"
          size="small"
          type="number"
        />
      </Box>
    </Box>
  );
};

export default memo(MapConfig);
