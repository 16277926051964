import { memo, useRef, useState, useLayoutEffect } from 'react';

// ----- MUI -----
import { Box, Link, styled, Typography, useTheme } from '@mui/material';

// ----- Ours -----
import { DATE_FORMATTER } from './constants';

// ----- Types -----
import { GDELTKeywordQueryDataItem } from '../../../../types/grayzone';

const ScrollContainer = styled(Box)<{ x: number; y: number }>(({ theme, x, y }) => ({
  width: '250px',
  maxHeight: '300px',
  position: 'absolute',
  left: `${x + 4}px`,
  top: `${y + 4}px`,
  boxShadow: theme['box-shadow'].overlay,
  backgroundColor: theme.color['neutral-0'],
  borderRadius: theme.borderRadius.borderRadius,
  padding: theme.spacings.small,
  zIndex: 999999999
}));
const Container = styled(Box)(({ theme }) => ({
  width: '250px',
  height: '100%',
  maxHeight: '300px',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '4px',
    marginLeft: theme.spacing(1),
    overflow: 'visible',
    borderRadius: theme.borderRadius.borderRadiusSemiSharp
  },
  '&::-webkit-scrollbar-thumb': {
    overflow: 'hidden',
    borderRadius: theme.borderRadius.borderRadiusSemiSharp,
    background: theme.color['neutral-50']
  },
  'mask-image': 'linear-gradient(to top, transparent, black),linear-gradient(to left, transparent 4px, black 4px)',
  'mask-size': '100% 20000px',
  'mask-position': 'left bottom',
  '-webkit-mask-image': 'linear-gradient(to top, transparent, black), linear-gradient(to left, transparent 4px, black 4px)',
  '-webkit-mask-size': '100% 20000px',
  '-webkit-mask-position': 'left bottom',
  transition: 'mask-position 0.2s, -webkit-mask-position 0.2s',
  '&:hover': { '-webkit-mask-position': 'left top' }
}));
const Title = styled(Typography)(({ theme }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  ...theme.typography['label-small-bold']
}));
const Item = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacings['x-small']
}));

type Props = {
  dataItem: GDELTKeywordQueryDataItem;
  containerWidth: number;
  containerLeft: number;
  containerX: number;
  containerY: number;
};
const EventQueryItemTooltip = ({ dataItem, containerWidth, containerLeft, containerX, containerY }: Props) => {
  const theme = useTheme();
  const ref = useRef<HTMLDivElement | null>(null);
  const [boundedX, setBoundedX] = useState(containerX);
  const [boundedY, setBoundedY] = useState(containerY);

  useLayoutEffect(() => {
    // for when switching selected feed item without changing to null in between
    setBoundedX(containerX);
    setBoundedY(containerY);
  }, [containerX, containerY]);

  useLayoutEffect(() => {
    // moves tooltip to left side if out of window
    if (ref.current) {
      const dims = ref.current.getBoundingClientRect();

      if (dims.left + dims.width >= containerWidth + containerLeft) {
        setBoundedX(containerX - dims.width - 8); // subtract initial padding and then another padding
      }

      const height = window.innerHeight;
      if (dims.bottom >= height) {
        setBoundedY(containerY - dims.height - 8); // subtract initial padding and then another padding
      }
    }
  }, [ref.current, boundedX, boundedY, containerWidth, containerLeft]);

  return (
    <ScrollContainer ref={ref} x={boundedX} y={boundedY}>
      <Container>
        <Box marginBottom={theme.spacings.small} display="flex" flexDirection="column" gap="5px">
          <Typography variant="label-small" display="span" color={theme.color['neutral-75']}>
            Query matches
            <Typography variant="label-small-bold" display="span" color={theme.color['neutral-75']}>
              {` ${String(dataItem.value)} % `}
            </Typography>
            of global daily articles
          </Typography>
          <Typography variant="label-small" display="span" color={theme.color['neutral-75']}>
            Top matching articles from
            <Typography variant="label-small-bold" display="span" color={theme.color['neutral-75']}>
              {` ${DATE_FORMATTER(new Date(dataItem.date).valueOf())}`}
            </Typography>
          </Typography>
        </Box>
        {dataItem.toparts.length === 0 && <Typography variant="label-small-tight">No top articles matching your query were reported for this date</Typography>}
        {dataItem.toparts.map((item, index) => {
          return (
            <Item key={`top-article-${index}`}>
              <Title>{item.title}</Title>
              <Link href={item.url} variant="label-small">
                <Typography whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                  {item.url}
                </Typography>
              </Link>
            </Item>
          );
        })}
      </Container>
    </ScrollContainer>
  );
};

export default memo(EventQueryItemTooltip);
