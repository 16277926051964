/* eslint-disable max-len */
// BASEUI-GENERATED-REACT-ICON
// DO NOT EDIT THIS FILE DIRECTLY, SEE README.md
import * as React from 'react';
import type { IconProps } from '../Icon';
import Icon from '../Icon';

const MinusOutlineIcon = (props: IconProps): JSX.Element => {
  const {
    title = 'Minus Outline Icon',
    size,
    color,
    onClick,
    ...restProps
  } = props;
  return (
    <Icon
      title={title}
      size={size}
      color={color}
      onClick={onClick}
      {...restProps}
    >
      <path d="M3.51449 11.0002C2.9622 11.0002 2.51449 11.448 2.51449 12.0002C2.51449 12.5525 2.9622 13.0002 3.51449 13.0002H20.4854C21.0377 13.0002 21.4854 12.5525 21.4854 12.0002C21.4854 11.448 21.0377 11.0002 20.4854 11.0002L3.51449 11.0002Z"></path>
    </Icon>
  );
};

export default MinusOutlineIcon;
/* eslint-enable max-len */
