import { memo, useCallback } from 'react';

// ----- Redux -----
import { useAppDispatch } from '../../../hooks/useRedux';
import { grayzoneActions } from '../../../features/grayzone/grayzoneSlice';

// ----- MUI -----
import { Box, Link, styled, Typography, useTheme } from '@mui/material';

// ----- PDS -----
import ProtestPictogram from '../../../PremiseDesign/Icons/pictograms/ProtestPictogram';
import ExplosionPictogram from '../../../PremiseDesign/Icons/pictograms/ExplosionPictogram';
import ViolencePictogram from '../../../PremiseDesign/Icons/pictograms/ViolencePictogram';
import StrategyPictogram from '../../../PremiseDesign/Icons/pictograms/StrategyPictogram';
import RiotPictogram from '../../../PremiseDesign/Icons/pictograms/RiotPictogram';
import BattlesPictogram from '../../../PremiseDesign/Icons/pictograms/BattlesPictogram';

// ----- Types -----
import { FeedDataType, ACLEDdataType, GDELTdataType, ACLEDEventEnum, GDELTTopArticle } from '../../../types/grayzone';

const ImageContainer = styled(Box)(({ theme }) => ({
  height: '96px',
  width: '96px',
  minWidth: '96px',
  backgroundColor: theme.color['neutral-25'],
  borderRadius: theme.borderRadius.borderRadiusSemiSharp,
  marginRight: theme.spacings.small,
  overflow: 'hidden'
}));
const PictogramContainer = styled(Box)(({ theme }) => ({
  height: '96px',
  width: '96px',
  minWidth: '96px',
  backgroundColor: theme.color['neutral-10'],
  border: `1px solid ${theme.color['neutral-25']}`,
  borderRadius: theme.borderRadius.borderRadiusSemiSharp,
  marginRight: theme.spacings.small,
  overflow: 'hidden',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));
const Title = styled(Typography)(({ theme }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  marginBottom: theme.spacings['xx-small'],
  ...theme.typography['label-bold']
}));
const RelevanceChip = styled(Box)<{ $backgroundColor?: string }>(({ theme, $backgroundColor }) => ({
  background: $backgroundColor ?? theme.color['brand-primary-10'],
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: theme.borderRadius.borderRadiusPill,
  padding: '2px 8px',
  marginBottom: theme.spacings['xx-small']
}));

type Props = {
  data: ACLEDdataType | GDELTdataType | GDELTTopArticle | null; // if null, display placeholder
};

const FeedItem = ({ data }: Props) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const getPictogram = useCallback((type: string) => {
    switch (type) {
      case ACLEDEventEnum.PROTEST:
        return <ProtestPictogram />;
      case ACLEDEventEnum.BATTLES:
        return <BattlesPictogram />;
      case ACLEDEventEnum.EXPLOSION:
        return <ExplosionPictogram />;
      case ACLEDEventEnum.CIVIL_VIOLENCE:
        return <ViolencePictogram />;
      case ACLEDEventEnum.STRATEGIC_DEV:
        return <StrategyPictogram />;
      case ACLEDEventEnum.RIOTS:
        return <RiotPictogram />;
    }
  }, []);

  const onMouseOver = useCallback(() => {
    if (data?.type !== FeedDataType.EventQueryArticle) {
      dispatch(grayzoneActions.setHighlightedNewsEvent(data?.id ?? null));
    }
  }, [data]);
  const onMouseLeave = useCallback(() => {
    dispatch(grayzoneActions.setHighlightedNewsEvent(null));
  }, [data]);

  const getDetails = useCallback(() => {
    if (data === null) {
      return (
        <>
          <ImageContainer />
          <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
            <Typography variant="label-small" color={theme.color['neutral-75']}>
              No data has been collected.
            </Typography>
          </Box>
        </>
      );
    }

    switch (data.type) {
      case FeedDataType.ACLED: {
        return (
          <>
            <PictogramContainer>{getPictogram(data.event_type)}</PictogramContainer>
            <Box>
              <Title>{data.event_description}</Title>
              <Typography variant="label-small-bold" display={'block'} color={theme.color['neutral-75']}>
                {data.event_type} - {data.sub_event_type}
              </Typography>
              <Typography variant="label-small" color={(theme) => theme.color['neutral-75']}>
                {data.date_event}
              </Typography>
            </Box>
          </>
        );
      }
      case FeedDataType.GDELT: {
        return (
          <>
            <ImageContainer>
              <Box component="img" sx={{ height: '100%', width: '100%', objectFit: 'cover' }} src={data.top_img} />
            </ImageContainer>
            <Box>
              {data.relevancy_score >= 0.9 && (
                <RelevanceChip>
                  <Typography variant="label-small-bold" color={(theme) => theme.color['brand-primary-150']}>
                    High relevance
                  </Typography>
                </RelevanceChip>
              )}

              <Title>{data.title}</Title>
              {/* TO-DO: data doesn't support location of url */}
              <Box>
                <Link href={data.url} variant="label-small">
                  {data.domain_text}
                </Link>
              </Box>
              <Typography variant="label-small" color={theme.color['neutral-75']}>
                {data.date_news}
              </Typography>
            </Box>
          </>
        );
      }
      case FeedDataType.EventQueryArticle: {
        return (
          <>
            <ImageContainer>
              {/* <Box component="img" sx={{ height: '100%', width: '100%', objectFit: 'cover' }} src={data.top_img} /> */}
              {/* <Skeleton variant="rectangular" sx={{ height: '100%', width: '100%' }} /> */}
            </ImageContainer>
            <Box>
              <RelevanceChip $backgroundColor={theme.color['neutral-25']}>
                <Typography variant="label-small-bold" color={theme.color['neutral-75']}>
                  {data.eventName} Query Article
                </Typography>
              </RelevanceChip>

              <Title>{data.title}</Title>
              {/* TO-DO: data doesn't support location of url */}
              <Box>
                <Link href={data.url} variant="label-small" maxWidth="50px" overflow="hidden">
                  {/* {data.url} TODO: Couldnt figure out how to do this without throwing off the layout*/}
                  Link
                </Link>
              </Box>
              <Typography variant="label-small" color={theme.color['neutral-75']}>
                {new Date(data.date).toLocaleDateString()}
              </Typography>
            </Box>
          </>
        );
      }

      default:
        throw new Error('Invalid FeedDataType for FeedItem');
    }
  }, [data, getPictogram]);

  return (
    <Box flex={1} display={'flex'} width={'100%'} marginBottom={theme.spacings.large} onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
      {getDetails()}
    </Box>
  );
};

export default memo(FeedItem);
